import en from './en.json'
import es from './es.json'

const envOverride = process.env.REACT_APP_IGNORE_LOCALE
const skipLocales = envOverride && envOverride.length && envOverride.toLowerCase().startsWith('t')

const LOCALES = skipLocales
  ? {}
  : {
      en,
      'en-US': en,
      es,
      'en-MX': es
    }

export const SUPPORTED_LOCALES = Object.keys(LOCALES)

export default LOCALES
