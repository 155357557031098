import get from 'lodash/get'

import { getVideos } from '../api/mikasa/requests'

import { FETCH_VIDEOS_REQUEST, FETCH_VIDEOS_SUCCESS, FETCH_VIDEOS_FAILURE } from './actionTypes'

export const fetchVideos = () => async (dispatch, _getState) => {
  dispatch({
    type: FETCH_VIDEOS_REQUEST
  })

  try {
    const response = await getVideos()
    const videoData = get(response, 'data.data')

    dispatch({
      type: FETCH_VIDEOS_SUCCESS,
      data: videoData
    })
  } catch (e) {
    dispatch({
      type: FETCH_VIDEOS_FAILURE
    })
  }
}
