import { Box, Card, Heading, CardBody, useTheme } from '@homebotapp/hb-react-component-catalog'
import { ReactNode } from 'react'

export interface AdditionalInfoProps {
  visible: boolean
  children: ReactNode
  headingText: string
}

export const AdditionalInfo = ({ visible, children, headingText }: AdditionalInfoProps) => {
  const { theme } = useTheme()

  if (!visible) return null

  return (
    <>
      {/*
        These nested boxes create 2 triangles (arrow facing up).
        The outside triangle acts as a border for the inside one.
      */}
      <Box
        width={0}
        position='relative'
        margin='0.5rem auto -0.0625rem auto'
        borderLeft='.5625rem solid transparent'
        borderRight='.5625rem solid transparent'
        borderBottom={`.5625rem solid var(--hb-colors-${theme.isLightThemeType ? 'blackAlpha-200' : 'whiteAlpha-200'})`}
      >
        <Box
          width={0}
          right={0}
          zIndex={1}
          top='0.0625rem'
          position='absolute'
          transform='translateX(50%)'
          borderLeft='.5rem solid transparent'
          borderRight='.5rem solid transparent'
          borderBottom={`.5rem solid var(--hb-colors-card-bg-layer-2)`}
        />
      </Box>
      <Card>
        <CardBody>
          <Heading as='h3' size='sm'>
            {headingText}
          </Heading>
          {children}
        </CardBody>
      </Card>
    </>
  )
}
