import { defineMessages } from 'react-intl'
import { HOME_INSURANCE_RISKS } from './homeInsurance'

export const MARKETS = defineMessages({
  seller: {
    id: 'Markets.seller',
    defaultMessage: 'Seller‘s market'
  },
  buyer: {
    id: 'Markets.buyer',
    defaultMessage: 'Buyer‘s market'
  }
})

export const AIRDNA_QUALITY = defineMessages({
  basic: {
    id: 'AirDna.rentalQuality.basic',
    defaultMessage: 'basic'
  },
  nice: {
    id: 'AirDna.rentalQuality.nice',
    defaultMessage: 'nice'
  },
  fancy: {
    id: 'AirDna.rentalQuality.fancy',
    defaultMessage: 'fancy'
  }
})

export const AIRDNA_FREQUENCY = defineMessages({
  seldom: {
    id: 'AirDna.rentalFrequency.seldom',
    defaultMessage: 'seldom'
  },
  often: {
    id: 'AirDna.rentalFrequency.often',
    defaultMessage: 'often'
  }
})

export const AIRDNA_TOOLIPS = defineMessages({
  description: {
    id: 'AirDna.chartTooltipHeader.monthlyAverage',
    defaultMessage: '{month} Avg.'
  },
  revenue: {
    id: 'AirDna.chartTooltip.revenue',
    defaultMessage: '${amount}' // eslint-disable-line
  },
  occupancy: {
    id: 'AirDna.chartTooltip.occupancy',
    defaultMessage: '{amount} Nights'
  },
  adr: {
    id: 'AirDna.chartTooltip.nightlyRate',
    defaultMessage: '${amount}/night' // eslint-disable-line
  }
})

export const FORM_PLACEHOLDERS = defineMessages({
  firstName: {
    id: 'Form.placeholder.firstName',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'Form.placeholder.lastName',
    defaultMessage: 'Last Name'
  },
  phone: {
    id: 'Form.placeholder.phone',
    defaultMessage: 'Phone'
  },
  email: {
    id: 'Form.placeholder.email',
    defaultMessage: 'Email Address'
  },
  address: {
    id: 'Form.placeholder.address',
    defaultMessage: 'Address'
  },
  zip: {
    id: 'Form.placeholder.zipCode',
    defaultMessage: 'Zip code'
  }
})

export const BUTTONS = defineMessages({
  back: {
    id: 'Buttons.navigation.back',
    defaultMessage: 'Back'
  },
  change: {
    id: 'Buttons.actions.change',
    defaultMessage: 'Change'
  }
})

export const SHARE = defineMessages({
  inviteLinkCopied: {
    id: 'Homeowner.share.inviteLinkCopied',
    defaultMessage: 'Share link copied to clipboard'
  },
  success: {
    id: 'Homeowner.share.success',
    defaultMessage: 'Invite sent!'
  }
})

export const SUBSCRIPTIONS = defineMessages({
  notificationSubscribed: {
    id: 'Subscriptions.subscribed',
    defaultMessage: 'You have been subscribed to the monthly report'
  },
  notificationSubscribedHomeownerEmails: {
    id: 'Subscriptions.subscribedHomeownerEmails',
    defaultMessage: 'You have been subscribed to home updates'
  },
  notificationSubscribedHomeMarket: {
    id: 'Subscriptions.subscribedHomeMarket',
    defaultMessage: 'You have been subscribed to home market updates'
  },
  notificationSubscribedListings: {
    id: 'Subscriptions.subscribedListings',
    defaultMessage: 'You have been subscribed to listing updates'
  },
  notificationSubscribedToCustomer: {
    id: 'Subscriptions.subscribedToCustomer',
    defaultMessage: 'You have been successfully subscribed!'
  },
  notificationUnsubscribed: {
    id: 'Subscriptions.unsubscribed',
    defaultMessage: 'You have been unsubscribed from the monthly report'
  },
  notificationUnsubscribedHomeownerEmails: {
    id: 'Subscriptions.unsubscribedHomeownerEmails',
    defaultMessage: 'You have been unsubscribed from home updates'
  },
  notificationUnsubscribedHomeMarket: {
    id: 'Subscriptions.unsubscribedHomeMarket',
    defaultMessage: 'You have been unsubscribed from home market updates'
  },
  notificationUnsubscribedListings: {
    id: 'Subscriptions.unsubscribedListings',
    defaultMessage: 'You have been unsubscribed from listing updates'
  },
  notificationUnsubscribedFromCustomer: {
    id: 'Subscriptions.unsubscribedFromCustomer',
    defaultMessage: 'You have been successfully unsubscribed!'
  },
  notificationError: {
    id: 'Subscriptions.error',
    defaultMessage: 'There was an error changing your subscription status'
  }
})

export const ZIP_CODE_DETAIL = defineMessages({
  popularity: {
    id: 'ZipCodeDetail.sectionHeader.popularity',
    defaultMessage: 'Temperature'
  },
  houseSize: {
    id: 'ZipCodeDetail.sectionHeader.houseSize',
    defaultMessage: 'House size'
  },
  prices: {
    id: 'ZipCodeDetail.sectionHeader.prices',
    defaultMessage: 'Prices'
  },
  timeline: {
    id: 'ZipCodeDetail.sectionHeader.timeline',
    defaultMessage: 'Buying timeline'
  },
  timelineDuration: {
    id: 'Timeline.prepare.duration',
    defaultMessage: '{daycount} days'
  },
  sqft: {
    id: 'ZipCodeTable.activeFilter.sqft',
    defaultMessage: '{value, number} sqft'
  }
})

export const ZIP_CODE_EXPLORER = defineMessages({
  searchPlaceholder: {
    id: 'ZipCodeExplorer.search.placeholder',
    defaultMessage: 'Search for a city…'
  },
  filterPlaceholder: {
    id: 'ZipCodeExplorer.filter.placeholder',
    defaultMessage: 'Filter by neighborhood…'
  }
})

export const ERRORS = defineMessages({
  defaultHeadline: {
    id: 'ErrorBoundary.default.headline',
    defaultMessage: 'Something went wrong…'
  },
  defaultMessage: {
    id: 'ErrorBoundary.default.message',
    defaultMessage: 'Our team has been notified. Try reloading the page in a few minutes.'
  },
  communicationError: {
    id: 'ErrorBoundary.communication.message',
    defaultMessage: 'Failed to communicate with server, try again shortly.'
  }
})

export const PREFERENCES = defineMessages({
  saved: {
    id: 'Preferences.notifications.saved',
    defaultMessage: 'Your preferences were saved!'
  },
  errored: {
    id: 'Preferences.notifications.errored',
    defaultMessage: 'Failed communication with our server, your preferences have not been persisted.'
  },
  duh: {
    id: 'Preferences.notifications.duh',
    defaultMessage: 'Client preferences cannot be updated in preview mode.'
  }
})

export const NO_ACCESS_ERROR = defineMessages({
  defaultMessage: {
    id: 'ErrorBoundary.noAccess.loanBalance.message',
    defaultMessage: 'Sorry, you can’t update the loan balance on behalf of your client.'
  }
})

export const HOME_DETAIL_UPDATE_ERROR = defineMessages({
  defaultMessage: {
    id: 'ErrorBoundary.valuation.homeUpdate.failure.message',
    defaultMessage:
      'Your new value has been saved in our system, but we couldn’t reflect the updates in your digest. Please try refreshing.'
  }
})

export const REFINANCE = defineMessages({
  savings: {
    id: 'Refinance.savingsDescriptor',
    defaultMessage: 'savings'
  },
  loss: {
    id: 'Refinance.lossDescriptor',
    defaultMessage: 'loss'
  },
  paymentIncrease: {
    id: 'Refinance.paymentIncrease.descriptor',
    defaultMessage: 'up'
  },
  paymentDecrease: {
    id: 'Refinance.paymentDecrease.descriptor',
    defaultMessage: 'down'
  }
})

export const LOAN_BALANCE = defineMessages({
  balanceIncreasedSuccessMessage: {
    id: 'Loan.balance.success.messsage',
    defaultMessage: 'Your balance was successfully updated!'
  }
})

export const HOME_INSURANCE = defineMessages({
  feedbackReceived: {
    id: 'Homeowner.homeInsurance.feedbackReceived',
    defaultMessage: 'Thanks for the feedback!'
  }
})

export const PROMPT_PREQUAL = defineMessages({
  email_link: {
    id: 'PromptPrequal.done.emailName',
    defaultMessage: 'Email {name}'
  }
})

export const MARKET_TEMP_HEADERS = defineMessages({
  '0-20': {
    id: 'Explorer.marketTemp.header.0-20',
    defaultMessage: 'Strong Buyer’s Advantage'
  },
  '21-40': {
    id: 'Explorer.marketTemp.header.21-40',
    defaultMessage: 'Buyer’s Advantage'
  },
  '41-60': {
    id: 'Explorer.marketTemp.header.41-60',
    defaultMessage: 'Balanced Market'
  },
  '61-80': {
    id: 'Explorer.marketTemp.header.61-80',
    defaultMessage: 'Seller’s Advantage'
  },
  '81-100': {
    id: 'Explorer.marketTemp.header.81-100',
    defaultMessage: 'Strong Seller’s Advantage'
  }
})

export const MARKET_TEMP_DESCRIPTIONS = defineMessages({
  '0-20': {
    id: 'Explorer.marketTemp.0-20',
    defaultMessage: 'Little competition and high inventory, meaning you have great negotiating power.'
  },
  '21-40': {
    id: 'Explorer.marketTemp.21-40',
    defaultMessage: 'Some competition but you’re still likely to get a good deal since inventory is high.'
  },
  '41-60': {
    id: 'Explorer.marketTemp.41-60',
    defaultMessage: 'Expect to see a nice mix of good inventory with stable home values.'
  },
  '61-80': {
    id: 'Explorer.marketTemp.61-80',
    defaultMessage: 'Decent competition and steady home value growth could make for a great investment.'
  },
  '81-100': {
    id: 'Explorer.marketTemp.81-100',
    defaultMessage: 'This means there is some fierce competition and low inventory.'
  }
})

export const HOME_INSURANCE_RISK_MESSAGES = defineMessages({
  [HOME_INSURANCE_RISKS.LOW]: {
    id: 'HomeInsurance.risk.low',
    defaultMessage: 'low'
  },
  [HOME_INSURANCE_RISKS.MID]: {
    id: 'HomeInsurance.risk.moderate',
    defaultMessage: 'moderate'
  },
  [HOME_INSURANCE_RISKS.HIGH]: {
    id: 'HomeInsurance.risk.high',
    defaultMessage: 'high'
  }
})

export const TCA_STATUS = defineMessages({
  success: {
    id: 'MortgageCoachRequestButton.success',
    defaultMessage: 'Successfully created TCA'
  },
  failure: {
    id: 'MortgageCoachRequestButton.failure',
    defaultMessage: 'Failed to create TCA'
  }
})

export const HOME_INSURANCE_CTA = defineMessages({
  snoozeSuccess: {
    id: 'Homeowner.homeInsurance.snoozeSuccess',
    defaultMessage: 'Cool, we’ll remind you next year'
  },
  confirmDetailsSuccess: {
    id: 'Homeowner.homeInsurance.confirmDetailsSuccess',
    defaultMessage: 'Your insurance details are updated'
  }
})

export const OUTREACH_BUTTON = defineMessages({
  success: {
    id: 'success',
    defaultMessage: 'Message sent!'
  },
  failure: {
    id: 'failure',
    defaultMessage: 'Please try again later!'
  }
})
