import LineScale from './LineScale'
import styles from './Loading.module.scss'

const LoadingApp = () => (
  <div className={styles.appLoader} data-qa='testLoading'>
    <LineScale />
  </div>
)

export default LoadingApp
