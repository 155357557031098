import { defineMessages, useIntl } from 'react-intl'
import { IconExclamationTriangle } from '../../../Components/shared/Icon'
import { Button, Text, Heading } from '@homebotapp/hb-react-component-catalog'
import { requestMailTo } from '../../../api/iframe/requests'
import { useSignUpProvider } from '../SignUpContext'
import { GLOBAL_SIGNUP } from '../../../constants/tracking/trackingEventLocations/globalTrackingEventLocation'
import styles from './ErrorContents.module.scss'

const MSG = defineMessages({
  retry: {
    id: 'Chromebot.landingPage.steps.failureSubmit.retry',
    defaultMessage: 'Retry'
  },
  reachOut: {
    id: 'Chromebot.landingPage.steps.failureSubmit.reachOut',
    defaultMessage: 'reach out to {emailButton}'
  },
  somethingWentWrong: {
    id: 'Chromebot.landingPage.steps.failureSubmit.header',
    defaultMessage: 'Something went wrong'
  },
  ifTheProblemContinues: {
    id: 'Chromebot.landingPage.steps.failureSubmit.message',
    defaultMessage: 'If the problem continues,'
  },
  letsSeeIfWeCanTryAgain: {
    id: 'Chromebot.landingPage.steps.failureSubmit.retryDescription',
    defaultMessage: "Let's see if we can try again."
  }
})

const EmailButton = () => {
  const supportEmailUrl = 'support@homebot.ai'
  return (
    <Button size='sm' onClick={() => requestMailTo(supportEmailUrl)}>
      {supportEmailUrl}
    </Button>
  )
}

export const ErrorContents = () => {
  const intl = useIntl()
  const { handleCreateClick, loading, firstName, lastName } = useSignUpProvider()

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <IconExclamationTriangle className={styles.triangle} />
        <Heading>{intl.formatMessage(MSG.somethingWentWrong)}</Heading>
        <Text color='neutral-900' size='sm'>
          {intl.formatMessage(MSG.letsSeeIfWeCanTryAgain)}
        </Text>
        <Button
          tracking={{
            guid: 'qyqHu8duLH3JrPU3Auy4wU',
            ui_context: GLOBAL_SIGNUP
          }}
          className={styles.retryButton}
          isLoading={loading}
          onClick={() => handleCreateClick(firstName, lastName)}
        >
          {intl.formatMessage(MSG.retry)}
        </Button>
      </div>
      <div className={styles.bottom}>
        <Text className={styles.reachOut}>{intl.formatMessage(MSG.ifTheProblemContinues)} </Text>
        <Text>{intl.formatMessage(MSG.reachOut, { emailButton: <EmailButton /> })}</Text>
      </div>
    </div>
  )
}
