import { TOGGLE_AUTH_MODAL } from '../actions/actionTypes'

export const initialState = {
  visible: false
}

export default (state = initialState, { type, visible }) => {
  switch (type) {
    case TOGGLE_AUTH_MODAL:
      return {
        ...state,
        visible
      }
    default:
      return state
  }
}
