import {
  SET_MAP_CENTER,
  SET_ZIPCODE,
  SET_ZIP_VISIBLE,
  SORT_ZIP_EXPLORE,
  SET_FILTER_QUERY
} from '../actions/actionTypes'
import { ZIP_DETAIL, ZIP_EXPLORE } from '../constants/zip_modal'

const initialState = {
  open: false,
  loading: false,
  view: ZIP_DETAIL,
  zipcode: null,
  sort: 'score',
  visibleZips: [],
  mapCenter: {
    center: null,
    zoom: null
  },
  filterQuery: null
}

export default (
  state = initialState,
  { type, zipcode, sort, visibleZips, mapCenter, filterQuery, goToLastKnownPosition = true }
) => {
  switch (type) {
    case SORT_ZIP_EXPLORE:
      return {
        ...state,
        open: true,
        view: ZIP_EXPLORE,
        sort
      }

    case SET_ZIPCODE:
      return {
        ...state,
        zipcode
      }

    case SET_ZIP_VISIBLE:
      return {
        ...state,
        loading: false,
        visibleZips
      }

    case SET_MAP_CENTER:
      return {
        ...state,
        mapCenter
      }

    case SET_FILTER_QUERY:
      return {
        ...state,
        filterQuery
      }

    default:
      return state
  }
}
