import { createSelector } from 'reselect'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import { HOME_INSURANCE_TYPES, HOME_INSURANCE_RISKS } from '../../constants/homeInsurance'

const selectFromHomeInsurance = path => createSelector(state => state.homeInsurance, partialRight(get, path))

const selectFromHomeInsuranceData = path => createSelector(state => state.homeInsurance.data, partialRight(get, path))

export const selectShowDetail = createSelector(state => state.homeInsurance, partialRight(get, 'showDetail'))

export const selectLoading = selectFromHomeInsurance('loading')
export const selectInsuranceModuleOverride = selectFromHomeInsurance('override_enabled')
export const selectEnabled = selectFromHomeInsuranceData('enabled')

export const selectDaysUntilRenewal = selectFromHomeInsuranceData('daysUntilRenewal')
export const selectYearsSincePurchase = createSelector(selectFromHomeInsuranceData('yearsSincePurchase'), years =>
  parseInt(years, 10)
)
export const selectYearsSinceRenewal = createSelector(selectFromHomeInsuranceData('yearsSinceRenewal'), years =>
  parseInt(years, 10)
)

export const selectEstimatedValue = selectFromHomeInsuranceData('estimatedValue')
export const selectInsuranceModuleType = selectFromHomeInsuranceData('homeInsuranceType')
export const selectInsurancePolicyStartDate = selectFromHomeInsuranceData('insurancePolicyStartDate')
export const selectClientHasConfirmedInsurancePolicyStartDate = selectFromHomeInsuranceData(
  'clientHasConfirmedInsurancePolicyStartDate'
)

export const selectRiskYearsSince = createSelector(
  selectYearsSincePurchase,
  selectYearsSinceRenewal,
  (yearsSincePurchase, yearsSinceRenewal) => {
    return yearsSinceRenewal || yearsSincePurchase
  }
)

export const selectCurrentRisk = createSelector(selectRiskYearsSince, years => {
  return yearsSinceRiskMapping(parseInt(years, 10)).risk
})

export const selectCurrentRiskPercent = createSelector(selectRiskYearsSince, years => {
  return yearsSinceRiskMapping(parseInt(years, 10)).radialPercent
})

export const selectInsuranceModuleEnabled = createSelector(
  selectEnabled,
  selectInsuranceModuleType,
  (enabled, type) => enabled && type && type !== HOME_INSURANCE_TYPES.DISABLED
)

const yearsSinceRiskMapping = yearsSince => {
  if (isNaN(yearsSince) || yearsSince < 1) {
    return {
      risk: undefined,
      radialPercent: 0
    }
  }

  let risk = {
    risk: HOME_INSURANCE_RISKS.LOW,
    radialPercent: 25
  }
  if (yearsSince >= 2) {
    risk = {
      risk: HOME_INSURANCE_RISKS.MID,
      radialPercent: 50
    }
  }
  if (yearsSince >= 3) {
    risk = {
      risk: HOME_INSURANCE_RISKS.HIGH,
      radialPercent: 75
    }
  }

  return risk
}

export const selectHomeHasInsurancePolicyStartDate = createSelector(selectInsurancePolicyStartDate, startDate =>
  Boolean(startDate)
)
