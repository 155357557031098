import { Icon, Text } from '@homebotapp/hb-react-component-catalog'
import { NavigationTab } from './NavigationTab'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { selectRouteToSearch } from '../../../store/selectors/routes'
import { Link as ReactRouterLink } from 'react-router-dom'
import { GlobalTrackingEventLocation, TrackingEventConstants } from '../../../constants/tracking'
import { useTrackingContext } from '../../../providers/tracking'
import { NavigationTabProps } from '../../../types/navigation'
import { HBREIcon } from '../../../Components/shared/HBRE/HBREIcon'

// Todo: Need up update the intl keys from BottomNav to Navigation
const MSG = defineMessages({
  search: {
    id: 'NavigationTab.search',
    defaultMessage: 'Search'
  }
})

export const SearchTab = ({ isSelected, ...tabProps }: NavigationTabProps) => {
  const intl = useIntl()
  const searchRoute = useSelector(selectRouteToSearch)
  // Used solely for legacy tracking event
  const { trackingClient } = useTrackingContext()

  const handleClick = e => {
    trackingClient?.buyerNavigationLinkClicked({
      buyer_navigation_link: TrackingEventConstants.SEARCH_HOMES
    })
  }

  return (
    <NavigationTab
      {...tabProps}
      as={ReactRouterLink}
      to={searchRoute}
      onClick={handleClick}
      tracking={{
        guid: 'guuCyEVyAP8mUbfcxRjxFM',
        ui_context: GlobalTrackingEventLocation.GLOBAL_NAVIGATION_BAR,
        descriptor: MSG.search.defaultMessage
      }}
    >
      <HBREIcon />
      <Text
        sx={{ textWrap: 'nowrap' }}
        size={['3xs', 'sm']}
        m={0}
        marginLeft={['0', null, null, '2']}
        marginTop={['1', null, null, '0']}
        fontWeight='semibold'
      >
        {intl.formatMessage(MSG.search)}
      </Text>
    </NavigationTab>
  )
}
