import Button from '../Button'

export interface EmailProps {
  value: string
  text?: string
  minimal?: boolean
  className?: string
  iconStart?: React.ElementType
}

const Email = ({ value, text, className, ...restProps }: EmailProps) => (
  <Button data-qa='email' minimal element='a' href={`mailto:${value}`} className={className} {...restProps}>
    {text || value}
  </Button>
)

export default Email
