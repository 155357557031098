import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

const WizardContext = createContext({
  loading: PropTypes.bool,
  currentStep: PropTypes.string,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  goTo: PropTypes.func,
  restart: PropTypes.func
})

const propTypes = {
  onStepChange: PropTypes.func,
  initialStep: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  steps: PropTypes.array.isRequired
}

const WizardProvider = ({ onStepChange = () => null, initialStep, children, steps }) => {
  const [currentStep, setCurrentStep] = useState(initialStep)
  const [stepHistory, setStepHistory] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // this means data is still fetching

    if (!currentStep && initialStep) {
      setCurrentStep(initialStep)
      setLoading(false)
    }
    // this means the data is re-hydrated
    if (currentStep) {
      setLoading(false)
    }
  }, [currentStep, initialStep])

  const immutablePush = (arr, newEntry) => [...arr, newEntry]

  const immutablePop = arr => arr.slice(0, -1)

  const store = {
    loading,
    steps,
    initialStep: steps.includes(initialStep) ? initialStep : steps[0],
    currentStep,
    goBack: () => {
      const currentStepsIndex = steps.indexOf(currentStep)
      const newStep = steps[Math.max(0, currentStepsIndex - 1)]

      if (isEmpty(stepHistory)) {
        return setCurrentStep(newStep, onStepChange(newStep))
      }

      const lastVisitedStep = stepHistory[stepHistory.length - 1]

      setCurrentStep(lastVisitedStep, onStepChange(lastVisitedStep))
      setStepHistory(immutablePop(stepHistory))
    },
    goForward: () => {
      setStepHistory(immutablePush(stepHistory, currentStep))

      const current = steps.indexOf(currentStep)
      const maxStep = steps.length - 1
      const newStep = steps[Math.min(maxStep, current + 1)]
      setCurrentStep(newStep, onStepChange(newStep))
    },
    goTo: stepName => {
      if (steps.includes(stepName)) {
        setStepHistory(immutablePush(stepHistory, currentStep))
        setCurrentStep(stepName, onStepChange(stepName))
      }
    }
  }

  return <WizardContext.Provider value={store}>{children}</WizardContext.Provider>
}

const useWizardProvider = () => {
  const context = useContext(WizardContext)

  if (!context) {
    throw new Error('useWizardProvider must be used within an WizardProvider')
  }

  return context
}

WizardProvider.propTypes = propTypes

export { WizardProvider, useWizardProvider, WizardContext }
