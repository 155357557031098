import { Avatar } from '../..'
import classnames from 'classnames'
import TextArea from 'react-textarea-autosize'
import { DirectMessageButton } from './DirectMessageButton'

import styles from './QuestionSingle.module.scss'

interface QuestionSingleProps {
  avatarUri?: string
  className?: string
  inputValue: string
  isFocused: boolean
  isLoading: boolean
  name?: string
  onBlur: (e) => void
  onChange: (e) => void
  onFocus: (e) => void
  onSubmit: (e) => void
  onTextAreaClick: (e) => void
  placeholder?: string
}

const QuestionSingle = ({
  className,
  onChange,
  onSubmit,
  inputValue,
  name,
  placeholder,
  avatarUri,
  isLoading,
  isFocused,
  onBlur,
  onFocus,
  onTextAreaClick
}: QuestionSingleProps) => {
  return (
    <div
      data-qa='questionSingle'
      className={classnames(className, styles.question, avatarUri && styles.withAvatar, isFocused && styles.focused)}
    >
      <TextArea
        className={styles.textarea}
        value={inputValue}
        name={name}
        placeholder={placeholder}
        aria-label={`dm ${inputValue}`}
        onChange={onChange}
        onClick={onTextAreaClick}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {avatarUri && <Avatar className={styles.avatar} src={avatarUri} />}
      <DirectMessageButton
        onClick={onSubmit}
        loading={isLoading}
        className={styles.button}
        disabled={!inputValue && !placeholder}
      />
    </div>
  )
}

export default QuestionSingle
