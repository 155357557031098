import { TabList, Tabs, TabsProps } from '@homebotapp/hb-react-component-catalog'
import { selectClientShouldSeeMarketsAndBuyerNavButtons } from '../../../store/selectors/navigation'
import { useSelector } from 'react-redux'
import { OwnTab } from './OwnTab'
import { MarketsTab } from './MarketsTab'
import { SearchTab } from './SearchTab'
import { BuyTab } from './BuyTab'
import { selectClientIsGuest } from '../../../store/selectors/client'
import { selectHomebotListingsFeatureEnabled } from '../../../store/selectors/customerProfile'
import { selectActiveNavigationTab } from '../../../store/slices/navigation'
import { NAVIGATION_TAB_NAMES, NavigationTabProps } from '../../../types/navigation'
import { ForYouTab } from './ForYouTab'

type NavigationTabType = typeof OwnTab | typeof BuyTab | typeof MarketsTab | typeof SearchTab

type NavigationTabsProps = Omit<TabsProps, 'children'> & {
  children?: React.ReactNode
}

// If this is too constricting of an abstraction, we can make this
// component just take children and pass a list of tabs
// in the DesktopHeader and BottomNav components
export const NavigationTabs = (tabsProps: NavigationTabsProps) => {
  const activeNavigationTab = useSelector(selectActiveNavigationTab)
  const isGuest = useSelector(selectClientIsGuest)
  const showMarketsAndBuyerNavButtons = useSelector(selectClientShouldSeeMarketsAndBuyerNavButtons)
  const searchEnabled = useSelector(selectHomebotListingsFeatureEnabled)

  // Naive lookup table to map tab names to components
  const tabNameToComponentMap = {
    [NAVIGATION_TAB_NAMES.HOMES]: (props: NavigationTabProps) => <OwnTab {...props} />,
    [NAVIGATION_TAB_NAMES.BUYER]: (props: NavigationTabProps) =>
      (isGuest || showMarketsAndBuyerNavButtons) && <BuyTab {...props} />,
    [NAVIGATION_TAB_NAMES.FORYOU]: (props: NavigationTabProps) => searchEnabled && <ForYouTab {...props} />,
    [NAVIGATION_TAB_NAMES.MARKETS]: (props: NavigationTabProps) =>
      (isGuest || showMarketsAndBuyerNavButtons) && <MarketsTab {...props} />,
    [NAVIGATION_TAB_NAMES.SEARCH]: (props: NavigationTabProps) => searchEnabled && <SearchTab {...props} />
  }

  const indexOfActiveNavigationTab = Object.keys(tabNameToComponentMap).indexOf(activeNavigationTab)

  return (
    <Tabs {...tabsProps} index={indexOfActiveNavigationTab} isLazy height='100%'>
      <TabList overflowX='auto' overflowY='hidden' height='100%'>
        {Object.values(tabNameToComponentMap).map((Component: NavigationTabType, index) => (
          <Component key={index} isSelected={indexOfActiveNavigationTab === index} />
        ))}
      </TabList>
    </Tabs>
  )
}
