import { ErrorContents } from './ErrorContents'
import { Panel } from '../../../Components'
import { IconButton, Icon } from '@homebotapp/hb-react-component-catalog'
import { useSignUpProvider } from '../SignUpContext'
import styles from './SignUpError.module.scss'

export const SignUpError = () => {
  const { submitError, setSubmitError } = useSignUpProvider()

  if (!submitError) {
    return null
  }

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <IconButton
          className={styles.close}
          colorScheme='primary'
          variant='ghost'
          aria-label='signup-error-close-button'
          onClick={() => setSubmitError(false)}
          icon={<Icon name='times' />}
        />
        <Panel>
          <ErrorContents />
        </Panel>
      </div>
    </div>
  )
}
