// 4 Different Themes
export const DEFAULT = 'hb_default'
export const DEFAULT_NEUTRAL = 'hb_default_neutral'
export const LIGHT = 'light'
export const LIGHT_NEUTRAL = 'light_neutral'

// Two Different Types - Default and Light
export const DEFAULT_THEME_TYPES = [DEFAULT, DEFAULT_NEUTRAL]
export const LIGHT_THEME_TYPES = [LIGHT, LIGHT_NEUTRAL]

export const THEME_TYPES = [DEFAULT, DEFAULT_NEUTRAL, LIGHT, LIGHT_NEUTRAL]

export const LOADING_THEME = 'LOADING_THEME'
