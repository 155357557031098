import {
  FETCH_MORTGAGE_RATES_REQUEST,
  FETCH_MORTGAGE_RATES_SUCCESS,
  FETCH_MORTGAGE_RATES_FAILURE
} from '../actions/actionTypes'

export const initialState = {
  15: {
    isLoading: true
  },
  30: {
    isLoading: true
  }
}

export default (state = initialState, { type, term, data }) => {
  switch (type) {
    case FETCH_MORTGAGE_RATES_REQUEST:
      return {
        ...state,
        [term]: {
          ...state[term],
          isLoading: true
        }
      }

    case FETCH_MORTGAGE_RATES_SUCCESS:
      return {
        ...state,
        [term]: {
          ...state[term],
          isLoading: false,
          data
        }
      }

    case FETCH_MORTGAGE_RATES_FAILURE:
      return {
        ...state,
        [term]: {
          ...state[term],
          isLoading: false
        }
      }

    default:
      return state
  }
}
