import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from './Button'
import { IconTimes } from '../Icon'

import styles from './CloseButton.module.scss'

const defaultProps = {
  iconStart: IconTimes,
  minimal: true
}

const propTypes = {
  className: PropTypes.string
}

const CloseButton = ({ className, ...restProps }) => (
  <Button
    data-qa='closeButton'
    aria-label='close button'
    className={classnames(className, styles.closeButton)}
    {...restProps}
  />
)

CloseButton.defaultProps = defaultProps
CloseButton.propTypes = propTypes

export default CloseButton
