import React, { Children } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './BulletedList.module.scss'

const propTypes = {
  // class name composed on the ul
  className: PropTypes.string,

  // class name composed on the li
  itemClass: PropTypes.string,
  children: PropTypes.node
}

const BulletedList = ({ className, itemClass, children }) => (
  <ul className={className}>
    {Children.toArray(children).map((child, index) => (
      <li className={classnames(itemClass, styles.listItem)} key={`li-${child.key || index}`}>
        {child}
      </li>
    ))}
  </ul>
)

BulletedList.propTypes = propTypes
export default BulletedList
