import { Box, BoxProps, ColorProps } from '@homebotapp/hb-react-component-catalog'
import { useEffect } from 'react'

type BannerProps = BoxProps & {
  // TODO: export color schemes from hb-react-component-catalog
  // colorScheme?: SemanticColorSchemes
  colorScheme?: 'brand' | 'primary' | 'success' | 'warning' | 'danger'
  children: BoxProps['children']
}

export const Banner = ({ colorScheme = 'brand', ...boxProps }: BannerProps) => {
  useEffect(() => {
    document.documentElement.style.setProperty('--banner-height', '2.5rem')

    return () => {
      document.documentElement.style.removeProperty('--banner-height')
    }
  }, [])

  return (
    <Box
      {...boxProps}
      position='fixed'
      className='homebot-banner'
      // The banner CSS var is set whenever this component is rendered.
      height='var(--banner-height)'
      bg={`${colorScheme}.400`}
      top='0'
      left='0'
      right='0'
      zIndex='banner'
    />
  )
}
