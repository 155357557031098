import { useIntl, defineMessages } from 'react-intl'
import { ProdAppStoreQrCode } from '../QrCodes/ProdAppStoreQrCode'
import { LocalAppStoreQrCode } from '../QrCodes/LocalAppStoreQrCode'
import { FaygoAppStoreQrCode } from '../QrCodes/FaygoAppStoreQrCode'
import { Box, Flex, ComponentTrackingType, Text, useTheme } from '@homebotapp/hb-react-component-catalog'
import { NativeCTALinks } from '../../NativeCTALinks/NativeCTALinks'
import { MontuckyAppStoreQrCode } from '../QrCodes/MontuckyAppStoreQrCode'

export const MSG = defineMessages({
  insights: {
    id: 'NativeAppQrCodeModal.insights',
    defaultMessage:
      'Download the Homebot mobile app to get easy access to your home report, search for new homes, and get notified of changes in your market. All for free.'
  },
  scan: {
    id: 'NativeAppQrCodeModal.scanToDownload',
    defaultMessage: 'Scan to download to your device'
  },
  orExplore: {
    id: 'NativeAppQrCodeModal.orExploreAppStores',
    defaultMessage: 'Or explore the app stores'
  },
  explore: {
    id: 'NativeAppQrCodeModal.exploreAppStores',
    defaultMessage: 'Explore the app stores'
  }
})

interface QrCodeWithAppStoreLinksProps {
  hideQrCode?: boolean
  tracking: ComponentTrackingType
}

// created using https://forqrcode.com/
// QR code link should follow this pattern:
// https://c.homebot.test/appStoreRedirect
// https://buyers.homebotapp.com/appStoreRedirect
// https://faygo-c.dev.aws.homebot.services/appStoreRedirect
export const QrCodeWithAppStoreLinks = ({ tracking, hideQrCode }: QrCodeWithAppStoreLinksProps) => {
  const intl = useIntl()
  const { theme } = useTheme()
  const isLightMode = theme.isLightThemeType

  return (
    <>
      <Text size='sm'>{intl.formatMessage(MSG.insights)}</Text>
      {!hideQrCode && (
        <Box
          padding={4}
          marginY={6}
          display='flex'
          borderRadius={8}
          border='1px solid'
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
          borderColor={isLightMode ? 'blackAlpha.50' : 'whiteAlpha.50'}
          backgroundColor={isLightMode ? 'blackAlpha.50' : 'whiteAlpha.50'}
        >
          {window.location.host === 'c.homebot.test' ? (
            <LocalAppStoreQrCode theme={isLightMode ? 'light' : 'dark'} size='200' />
          ) : window.location.host === 'faygo-c.dev.aws.homebot.services' ? (
            <FaygoAppStoreQrCode theme={isLightMode ? 'light' : 'dark'} size='200' />
          ) : window.location.host === 'montucky-c.dev.aws.homebot.services' ? (
            <MontuckyAppStoreQrCode theme={isLightMode ? 'light' : 'dark'} size='200' />
          ) : (
            <ProdAppStoreQrCode theme={isLightMode ? 'light' : 'dark'} size='200' />
          )}
          <Text size='xs' marginTop={3} marginBottom={0} fontWeight='semibold' color='neutral-900'>
            {intl.formatMessage(MSG.scan)}
          </Text>
        </Box>
      )}
      <Flex marginTop={4} direction='column' alignItems={hideQrCode ? 'start' : 'center'}>
        <Text size='2xs' fontWeight='semibold' casing='uppercase'>
          {intl.formatMessage(hideQrCode ? MSG.explore : MSG.orExplore)}
        </Text>
        <NativeCTALinks tracking={tracking} />
      </Flex>
    </>
  )
}
