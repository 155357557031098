import {
  FETCH_SHOULD_YOU_SELL_REQUEST,
  FETCH_SHOULD_YOU_SELL_SUCCESS,
  FETCH_SHOULD_YOU_SELL_FAILURE
} from '../actions/actionTypes'

export const initialState = {
  cashPosition: undefined,
  appreciation: undefined,
  equityValue: undefined,
  loading: true,
  error: null
}

const reducer = (state = initialState, action) => {
  const { error, cashPosition, appreciation, equityValue, type } = action

  switch (type) {
    case FETCH_SHOULD_YOU_SELL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_SHOULD_YOU_SELL_SUCCESS:
      return {
        ...state,
        appreciation,
        equityValue,
        cashPosition,
        loading: false
      }

    case FETCH_SHOULD_YOU_SELL_FAILURE:
      return {
        ...state,
        error: error,
        loading: false
      }
    default:
      return state
  }
}

export default reducer
