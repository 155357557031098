import { useState } from 'react'
import { matchPath } from 'react-router'
import { getCurrentPath } from '../../../helpers/browser'
import { useDispatch } from 'react-redux'
import { useIntl, defineMessages } from 'react-intl'
import { SSO_REDIRECT_URI } from '../../../constants/auth'
import { NAVIGATION_DELAY, PATH_HELPERS } from '../../../constants/navigation'
import { createDigest } from '../../../actions/client'
import {
  Heading,
  ModalBody,
  ModalContent,
  ModalHeader,
  Input,
  Form,
  Button,
  Text,
  Box,
  Link,
  FormControl,
  FormErrorMessage,
  FormLabel
} from '@homebotapp/hb-react-component-catalog'
import { FadeOnMount } from '../../../Components/shared/Animations'
import { AuthErrorModalErrorTypes } from '../../../types/error'
import { HOMEOWNER_DIGEST_AUTH_ERROR_MODAL } from '../../../constants/tracking/trackingEventLocations/homeownerDigestTrackingEventLocation'

export const MSG = defineMessages({
  title: {
    id: 'AuthorizationModal.title',
    defaultMessage: 'This link has expired to protect your security.'
  },
  buyerCta: {
    id: 'AuthorizationModal.beforeField',
    defaultMessage: 'Please confirm your email address to receive an updated Market Report'
  },
  homeownerCta: {
    id: 'AuthorizationModal.beforeField.homeowner',
    defaultMessage: 'Please confirm your email address to receive an updated home value estimate'
  },
  buyerSuccess: {
    id: 'AuthorizationModal.successMessage',
    defaultMessage:
      "Updated Market Report sent to the address provided. If you're still having issues, please contact your agent."
  },
  homeownerSuccess: {
    id: 'AuthorizationModal.successMessage.homeowner',
    defaultMessage:
      "Updated home value estimate sent to the address provided. If you're still having issues, please contact your agent."
  },
  loginLinkBlock: {
    id: 'AuthorizationModal.afterField',
    defaultMessage: "If you're a Homebot real estate agent or lender, please {loginLink}"
  },
  loginLink: {
    id: 'AuthorizationModal.loginLinkText',
    defaultMessage: 'log in'
  },
  submit: {
    id: 'general.submit',
    defaultMessage: 'Submit'
  },
  success: {
    id: 'AuthorizationModal.success',
    defaultMessage: 'Success!'
  },
  error: {
    id: 'AuthorizationModal.email.error',
    defaultMessage: 'Please provide a valid email address!'
  },
  email: {
    id: 'AuthorizationModal.email',
    defaultMessage: 'Email'
  }
})

type errorTypes = AuthErrorModalErrorTypes.HOMEOWNER_ERROR | AuthErrorModalErrorTypes.BUYER_ERROR

export type LinkExpiredErrorProps = {
  errorType: errorTypes
}

export interface EmailFormData {
  email: string
}

export const LinkExpiredError = ({ errorType }: LinkExpiredErrorProps) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [complete, setComplete] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOnSubmit = data => {
    setLoading(true)

    const { email } = data

    const match = matchPath<{ clientId: string }>(getCurrentPath(), {
      path: PATH_HELPERS.report.matchPath
    })

    if (match) {
      const { clientId } = match.params
      dispatch(createDigest({ clientId, email }))

      setTimeout(() => {
        setComplete(true)
        setLoading(false)
      }, NAVIGATION_DELAY)
    } else {
      setLoading(false)
    }
  }

  const loginLink = (
    <Link
      href={SSO_REDIRECT_URI}
      tracking={{
        guid: 'eysX59DDGap8B34X7ET6tn',
        ui_context: HOMEOWNER_DIGEST_AUTH_ERROR_MODAL,
        descriptor: MSG.loginLink.defaultMessage
      }}
    >
      {intl.formatMessage(MSG.loginLink)}
    </Link>
  )

  return (
    <ModalContent data-qa='link-expired-error' margin='4'>
      {!complete && (
        <>
          <ModalHeader>
            <Heading as='div' color='brand' size='md'>
              {intl.formatMessage(MSG.title)}
            </Heading>
          </ModalHeader>
          <ModalBody>
            <Text size='sm'>
              {errorType === 'buyerError' ? intl.formatMessage(MSG.buyerCta) : intl.formatMessage(MSG.homeownerCta)}
            </Text>
            <Box paddingTop='8'>
              <Form onSubmit={(data: EmailFormData) => handleOnSubmit(data)}>
                {({ formState: { errors } }) => (
                  <>
                    <FormControl isInvalid={!!errors?.email}>
                      <FormLabel>{intl.formatMessage(MSG.email)}</FormLabel>
                      <Input
                        isRequired
                        placeholder='Enter a valid email'
                        name='email'
                        size='lg'
                        rules={{
                          validate: {
                            isValidEmail: (v: string) => v.includes('@') || intl.formatMessage(MSG.error)
                          }
                        }}
                        tracking={{
                          guid: 'giFtyamrXA4m5ZkPSuW3aP',
                          ui_context: HOMEOWNER_DIGEST_AUTH_ERROR_MODAL,
                          descriptor: 'Email'
                        }}
                      />
                      <FormErrorMessage>{errors?.email?.message?.toString()}</FormErrorMessage>
                    </FormControl>
                    <Button
                      isLoading={loading}
                      isDisabled={loading}
                      type='submit'
                      marginY='8'
                      colorScheme='primary'
                      tracking={{
                        guid: 'uMV29YMHLJaonGHcUHyPVQ',
                        ui_context: HOMEOWNER_DIGEST_AUTH_ERROR_MODAL,
                        descriptor: MSG.submit.defaultMessage
                      }}
                    >
                      {intl.formatMessage(MSG.submit)}
                    </Button>
                  </>
                )}
              </Form>
            </Box>
            <hr />
            <Text marginY='4' size='sm'>
              {intl.formatMessage(MSG.loginLinkBlock, { loginLink })}
            </Text>
          </ModalBody>
        </>
      )}
      {complete && (
        <FadeOnMount>
          <ModalHeader>
            <Heading as='div' color='success' size='md'>
              {intl.formatMessage(MSG.success)}
            </Heading>
          </ModalHeader>
          <ModalBody>
            <Text size='sm' marginBottom='4'>
              {errorType === 'buyerError'
                ? intl.formatMessage(MSG.buyerSuccess)
                : intl.formatMessage(MSG.homeownerSuccess)}
            </Text>
          </ModalBody>
        </FadeOnMount>
      )}
    </ModalContent>
  )
}
