import qs from 'qs'
import delay from 'lodash/delay'

export const redirect = location => {
  window.location.assign(location)
}

export const createNewEvent = eventName => {
  let event
  if (typeof Event === 'function') {
    event = new Event(eventName)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
  }
  return event
}

export const getLanguages = () => [...(navigator.languages || []), navigator.language || navigator.userLanguage]

export const getOrigin = () => window.location.origin
export const getHost = () => window.location.host

export const getTopLevelDomain = () => {
  // https://yoohoo-c.dev.aws.homebot.services/ will return homebot.services
  // mydomain.com will return mydomain.com
  return getHost().split('.').slice(-2).join('.')
}

export const getFullLocation = () => window.location.origin + window.location.pathname

export const getDocumentTitle = () => document.title

export const setDocumentTitle = title => (document.title = title)

export const scrollTo = (top = 0, left = 0, behavior = 'smooth') => {
  const scrollMethod = (...args) => delay(() => (window.scroll || window.scrollTo)(...args))

  if ('scrollBehavior' in document.documentElement.style) {
    return scrollMethod({ top, left, behavior })
  }

  return scrollMethod(left, top)
}

export const getCurrentSearchParams = () => {
  const search = window.location.search
  const withoutLeadingQuestionMark = search.replace(/^\?/, '')
  return qs.parse(withoutLeadingQuestionMark)
}

export const getCurrentPath = () => window.location.pathname

function elementWithParents(element) {
  if (!element) {
    return []
  }

  const elementList = [element]
  let el = element.offsetParent
  while (el) {
    elementList.push(el)
    el = el.offsetParent
  }
  return elementList
}

export const getNoTransformPosition = element =>
  elementWithParents(element).reduce(
    ({ top, left }, el) => ({
      top: top + el.offsetTop,
      left: left + el.offsetLeft
    }),
    { top: 0, left: 0 }
  )

export const scrollToElement = (el, { padding = 16 } = {}) => {
  if (!el) {
    return
  }

  const { top } = getNoTransformPosition(el)

  return scrollTo(top - padding)
}

export const scrollToAnchor = (anchor, { padding = 16 } = {}) => {
  if (!anchor) {
    return
  }

  const el = document.getElementById(anchor.replace('#', ''))

  if (!el) {
    return
  }

  const { top } = getNoTransformPosition(el)
  return scrollTo(top - padding, 0)
}

const storageSupport = storageKey => {
  const key = '___CHECK_SUPPORT___'

  try {
    const storage = window[storageKey]
    storage.setItem(key, key)
    storage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export const localStorageSupport = storageSupport('localStorage')
const sessionStorageSupport = storageSupport('sessionStorage')

const safeStorageGetter = (support, storageKey) => {
  const memory = {}

  return () => {
    if (support) {
      window[storageKey]
    }

    return {
      getItem(key) {
        return key in memory ? memory[key] : null
      },
      setItem(key, val) {
        memory[key] = String(val || '')
      },
      removeItem(key) {
        delete memory[key]
      }
    }
  }
}

export const getSafeLocalStorage = safeStorageGetter(localStorageSupport, 'localStorage')

export const getSafeSessionStorage = safeStorageGetter(sessionStorageSupport, 'sessionStorage')

export const postMessage = (...args) => window.parent.postMessage(...args)

export const isIFramed = () => window.parent !== window

export const windowClose = () => window.close()

export const clearTimeout = id => window.clearTimeout(id)

export const matchMedia = query => window.matchMedia(query)

export const pushToWindowResize = (key, func) => {
  if (!window.onresizeMap) {
    window.onresizeMap = {
      INITIAL: typeof window.onresize === 'function' ? window.onresize : () => undefined
    }
  }

  if (typeof func === 'function') {
    window.onresizeMap[key] = func
  }

  window.onresize = () => Object.keys(window.onresizeMap).forEach(key => window.onresizeMap[key]())
}

export const removeFromWindowResize = key => {
  delete window.onresizeMap[key]
}

export const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

export const calcElementToTop = element => {
  if (!element) {
    return null
  }

  const box = element.getBoundingClientRect()
  const body = document.body
  const docEl = document.documentElement
  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const clientTop = docEl.clientTop || body.clientTop || 0

  return box.top + scrollTop - clientTop
}
