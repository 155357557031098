import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import defaultPhotoSrc from '../../../assets/homes/img/default-photo.svg'
import styles from './Avatar.module.scss'

const backgroundImage = src =>
  [src, defaultPhotoSrc]
    .filter(Boolean)
    .map(img => `url(${img})`)
    .join(',')

const Avatar = ({ className, src, alt = 'avatar', ...restProps }) => (
  <div data-qa='Avatar' className={classnames(styles.root, className)} {...restProps}>
    <div
      className={styles.img}
      style={{
        backgroundImage: backgroundImage(src)
      }}
      role='img'
      title={alt}
      aria-label={alt}
    />
  </div>
)

Avatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string
}

export default Avatar
