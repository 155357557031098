import { QrCodeSvgProps } from './QrCodeSvgTypes'
import styles from './QrCode.module.scss'
import classNames from 'classnames'

export const LocalAppStoreQrCode = ({ size, theme }: QrCodeSvgProps) => (
  <svg
    className={classNames(styles.qrCode, theme === 'light' ? styles.qrCodeLight : styles.qrCodeDark)}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    xmlSpace='preserve'
    width={size}
    height={size}
    viewBox='0 0 792 792'
  >
    <g fill={theme === 'light' ? '#000000' : '#ffffff'}>
      <g transform='translate(288,48) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,48) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,48) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,48) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,48) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,48) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,72) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,72) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,72) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,72) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,72) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,72) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,96) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,96) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,96) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,96) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,96) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,120) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,120) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,120) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,120) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,120) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,120) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,120) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,144) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,168) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,192) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,192) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,192) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,192) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,192) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,192) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,192) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,216) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,216) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,216) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,216) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,216) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,216) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,216) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(120,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(144,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(168,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(192,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,240) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(144,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(168,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(720,264) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(72,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(192,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,288) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(120,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(144,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,312) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(72,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(120,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(192,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,336) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(72,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(720,360) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(72,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(168,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(192,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,384) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(72,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(144,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(168,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,408) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(144,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(192,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,432) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(120,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(144,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(720,456) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(192,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,480) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(168,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,504) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(96,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(192,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(216,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(720,528) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(720,552) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,576) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(720,600) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,624) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(336,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(480,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(720,648) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(288,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(552,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,672) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(384,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(528,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(600,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(648,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(696,696) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(240,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(264,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(312,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(360,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(408,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(432,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(456,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(504,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(576,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(624,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(672,720) scale(4.12)'>
        <circle cx='3' cy='3' r='3' />
      </g>
      <g transform='translate(48,48)'>
        <g transform='scale(12)'>
          <path d='M0,7L0,7c0,3.9,3.1,7,7,7h0c3.9,0,7-3.1,7-7v0c0-3.9-3.1-7-7-7h0C3.1,0,0,3.1,0,7z M7,12L7,12c-2.8,0-5-2.2-5-5v0 c0-2.8,2.2-5,5-5h0c2.8,0,5,2.2,5,5v0C12,9.8,9.8,12,7,12z' />
        </g>
      </g>
      <g transform='translate(576,48)'>
        <g transform='scale(12)'>
          <path d='M0,7L0,7c0,3.9,3.1,7,7,7h0c3.9,0,7-3.1,7-7v0c0-3.9-3.1-7-7-7h0C3.1,0,0,3.1,0,7z M7,12L7,12c-2.8,0-5-2.2-5-5v0 c0-2.8,2.2-5,5-5h0c2.8,0,5,2.2,5,5v0C12,9.8,9.8,12,7,12z' />
        </g>
      </g>
      <g transform='translate(48,576)'>
        <g transform='scale(12)'>
          <path d='M0,7L0,7c0,3.9,3.1,7,7,7h0c3.9,0,7-3.1,7-7v0c0-3.9-3.1-7-7-7h0C3.1,0,0,3.1,0,7z M7,12L7,12c-2.8,0-5-2.2-5-5v0 c0-2.8,2.2-5,5-5h0c2.8,0,5,2.2,5,5v0C12,9.8,9.8,12,7,12z' />
        </g>
      </g>
      <g transform='translate(96,96)'>
        <g transform='scale(12)'>
          <circle cx='3' cy='3' r='3' />
        </g>
      </g>
      <g transform='translate(624,96)'>
        <g transform='scale(12)'>
          <circle cx='3' cy='3' r='3' />
        </g>
      </g>
      <g transform='translate(96,624)'>
        <g transform='scale(12)'>
          <circle cx='3' cy='3' r='3' />
        </g>
      </g>
    </g>
  </svg>
)
