import PropTypes from 'prop-types'

const propTypes = {
  // condition: PropTypes.bool, // too strict?
  children: PropTypes.node
}

export const If = ({ condition, children }) => (condition ? children : null)
If.propTypes = propTypes

export const Unless = ({ condition, children }) => (!condition ? children : null)
Unless.propTypes = propTypes
