import React from 'react'

import { Heading as SwashbuckleHeading } from '@homebotapp/hb-react-component-catalog'

const defaultProps = {
  as: 'h2',
  size: 'md',
  fontWeight: 'light',
  marginBottom: '3'
}

const Heading = props => <SwashbuckleHeading {...props} />

Heading.defaultProps = defaultProps

export default Heading
