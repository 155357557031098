import React from 'react'
import classnames from 'classnames'
import styles from './Ring.module.scss'

interface RingProps {
  color: 'blue' | 'white'
  className?: string
  children: React.ReactNode
}

export const Ring = ({ className, children, color }: RingProps) => (
  <span
    className={classnames(
      className,
      styles.root,
      styles.animate,
      color ? styles[`color-${color}`] : styles[`color-blue`]
    )}
  >
    {children}
  </span>
)
