import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { defaultToPx } from '../../../helpers/utils'

import styles from './Iframe.module.scss'

const propTypes = {
  aspect: PropTypes.oneOf(['video-landscape', '16:9', '4:3', 'fixed']),
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onReady: PropTypes.func,
  ref: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

const defaultProps = {
  aspect: 'video-landscape'
}

const getAspectStyle = (aspect, height) => {
  switch (aspect) {
    case 'video-landscape':
    case '16:9':
      return styles[`aspect-16_9`]
    case '4:3':
      return styles[`aspect-4_3`]
    case 'fixed':
    default:
      return height && height.match(/%$/) ? styles[`aspect-fixed`] : styles[`aspect-none`]
  }
}

const Iframe = ({ className, src, title, aspect, width, height, ref, onReady }) => (
  <div
    className={classnames(styles.root, getAspectStyle(aspect, height), className)}
    style={{ width: defaultToPx(width), height: defaultToPx(height) }}
  >
    <iframe
      className={styles.iframe}
      src={src}
      title={title}
      name={title}
      allowtransparency='true'
      frameBorder='0'
      scrolling='no'
      allowFullScreen
      mozallowfullscreen='true'
      webkitallowfullscreen='true'
      oallowfullscreen='true'
      msallowfullscreen='true'
      width='100%'
      height='100%'
      ref={ref}
      onLoad={onReady}
    />
  </div>
)

Iframe.propTypes = propTypes
Iframe.defaultProps = defaultProps

export default Iframe
