import numeral from 'numeral'

// precision is the number of places to round to

// 0 = round to nearest whole number, 123.456 => 123
// 1 = round to nearest ones place, 123.456 => 123.5
// 2 = round to nearest tens place, 123.456 => 123.46
// -1 = round to nearest ones place the other way, 12345.6789 => 12350
// -2 = round to nearest tens place the other way, 12345.6789 => 12300
export const roundWithPrecision = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0)

  return Math.round(value * multiplier) / multiplier
}

export const formatToCurrency = (value, format, precision) => {
  const defaultFormat = '$0,0[.]00'

  if (!value && value !== 0) return null

  const roundedValue = roundWithPrecision(value, precision)
  let currency

  if (format) {
    currency = numeral(roundedValue).format(format)
  } else {
    currency = numeral(roundedValue).format(defaultFormat)
  }

  return currency
}

export const formatAbbrCurrency = value => {
  if (value < 1000) {
    return numeral(value).format('($0,0)')
  }

  if (value < 1000000) {
    return numeral(value).format('($0a)')
  }

  return numeral(value).format('($0.00a)')
}

export const formatToPercent = (value, precision = 1) => {
  const suffix = '%'

  if (!value && value !== 0) return `0${suffix}`
  const roundedValue = roundWithPrecision(value, precision)

  return `${roundedValue}${suffix}`
}

export const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

export const formatToPhone = value => {
  if (!value) return null
  value = value.replace(/[^\d]/g, '')
  if (value.length < 10) return null
  if (value.length === 10) {
    value = ['1', value].join('')
  }
  return value.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$2-$3-$4')
}

export const snakeCaseKeys = obj => {
  return Object.keys(obj).reduce((newObj, key) => {
    const snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

    newObj[snakeKey] = obj[key]
    return newObj
  }, {})
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
