import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Select from './Select'

const propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]))
}

const defaultOptions = () => moment.months().map((m, i) => ({ key: i, value: m }))

const MonthSelect = ({ options, ...restProps }) => <Select options={options || defaultOptions()} {...restProps} />

MonthSelect.propTypes = propTypes

export default MonthSelect
