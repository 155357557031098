import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFadeOnMount } from '../../../hooks/animations'
import { ActiveOverlayContext } from '../Routing/OverlayContext'
import { defaultOpts } from '../../../constants/animations'
import { buildDelayCss } from './generic'

export interface FadeOnMountHookProps {
  buffer?: number
  delay?: number
  duration?: number
  extraStyles?: Record<string, unknown>
  opacity?: number
}

export interface FadeOnMountProps {
  buffer?: number
  children: React.ReactNode
  className?: string
  delay?: number
  disabled?: boolean
  duration?: number
  extraStyles?: Record<string, unknown>
  interval?: number
  opacity?: number
}

export const FadeOnMount = ({
  buffer,
  children,
  className,
  delay,
  disabled,
  duration,
  extraStyles,
  interval,
  opacity
}: FadeOnMountProps) => {
  const css = 'local-animation'
  const maxIntervals = 3
  const suppliedInterval = interval || defaultOpts.interval

  const fadeHookProps: FadeOnMountHookProps = {
    buffer,
    delay,
    duration,
    extraStyles,
    opacity
  }

  const styles = useFadeOnMount(fadeHookProps)
  const [animating, setAnimating] = useState(true)
  const { exitingPanel } = useContext(ActiveOverlayContext) || {
    exitingPanel: false
  }

  useEffect(() => {
    setTimeout(() => setAnimating(false), suppliedInterval * (maxIntervals + 1))
  }, [suppliedInterval])

  return (
    <>
      {animating && <style>{buildDelayCss(css, maxIntervals, suppliedInterval)}</style>}
      <div className={classnames(css, className)} style={disabled && !exitingPanel ? {} : styles}>
        {children}
      </div>
    </>
  )
}

FadeOnMount.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  delay: PropTypes.number,
  disabled: PropTypes.bool,
  duration: PropTypes.number,
  opacity: PropTypes.number,
  useDelay: PropTypes.bool
}

export default FadeOnMount
