import compact from 'lodash/compact'
import isNumber from 'lodash/isNumber'
import { createSelector } from 'reselect'
import { formatDisplayNameByLocationLevel } from '../../helpers/listings/search'
import { RootState } from '../../types/rootState'

const getFilter = (state: RootState) => state.filters?.filter

export const selectFilter = createSelector(getFilter, filter => {
  const level = filter?.location?.level
  const name = filter?.location?.name

  return {
    ...filter,
    displayName: formatDisplayNameByLocationLevel(level, name)
  }
})

export const selectFiltersCount = (state: RootState) => {
  const {
    minBathroomsCount,
    minBedroomsCount,
    minPrice,
    maxPrice,
    monthlyExpensesInput,
    minSqft,
    maxSqft,
    minAcres,
    maxAcres,
    minYearBuilt,
    maxYearBuilt,
    propertyTypes,
    statuses,
    hasSellerConcession,
    isAssumable
  } = state.filters.filter
  const priceFilters = compact([minPrice, maxPrice])
  const sqftFilters = compact([minSqft, maxSqft])
  const acreFilters = compact([minAcres, maxAcres])
  const yearBuiltFilters = compact([minYearBuilt, maxYearBuilt])
  const monthlyExpenseFilters = compact([
    monthlyExpensesInput?.minMonthlyExpenses,
    monthlyExpensesInput?.maxMonthlyExpenses,
    monthlyExpensesInput?.downpaymentAmountDollars
  ])
  const filterTypes = compact([
    priceFilters,
    sqftFilters,
    acreFilters,
    yearBuiltFilters,
    monthlyExpenseFilters,
    minBathroomsCount,
    minBedroomsCount,
    propertyTypes,
    statuses,
    hasSellerConcession,
    isAssumable
  ])

  return filterTypes.reduce((totalCount: number, filterType: string | string[] | number | number[]) => {
    if (isNumber(filterType) || filterType?.length) {
      return totalCount + 1
    }
    return totalCount
  }, 0)
}
