import {
  Link,
  FieldValues,
  Form,
  Input,
  Button,
  Flex,
  Box,
  Heading,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel
} from '@homebotapp/hb-react-component-catalog'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useWizardProvider } from '../../../Components/shared/WizardContext'
import { BotHeadSpin } from '../../../Components'
import { IconPencil } from '../../../Components/shared/Icon'
import { useSignUpProvider } from '../SignUpContext'
import { openLink } from '../../../api/iframe/requests'
import { PRIVACY_LINK, TOS_LINK } from '../../../constants/navigation'
import { GLOBAL_SIGNUP } from '../../../constants/tracking/trackingEventLocations/globalTrackingEventLocation'
import styles from './Name.module.scss'

export const MSG = defineMessages({
  firstNameLabel: {
    id: 'FormField.label.firstName',
    defaultMessage: 'First Name'
  },
  firstNameRequired: {
    id: 'FormField.validation.firstNameRequired',
    defaultMessage: 'First name is required.'
  },
  lastNameLabel: {
    id: 'FormField.label.lastName',
    defaultMessage: 'Last Name'
  },
  lastNameRequired: {
    id: 'FormField.validation.lastNameRequired',
    defaultMessage: 'Last name is required.'
  },
  createAnAccount: {
    id: 'NewBuyerSignUp.nameStep.createBtn',
    defaultMessage: 'Create an account'
  },
  accountFor: {
    id: 'NewBuyerSignUp.nameStep.account.for',
    defaultMessage: 'Account for'
  },
  lastStep: {
    id: 'NewBuyerSignUp.nameStep.salutation',
    defaultMessage: 'Last step!'
  },
  createAnAccountHelperText: {
    id: 'NewBuyerSignUp.nameStep.helper',
    defaultMessage: "Create an account so you can view customer insights for each home you're interested in."
  }
})

export const Name = () => {
  const intl = useIntl()
  const { iFramed, loading, email, handleCreateClick } = useSignUpProvider()
  const { goBack } = useWizardProvider()

  const handleSubmitNameStep = (data: FieldValues) => {
    // todo - discuss typing with DS folks if we can make this cleaner
    handleCreateClick(data.firstName, data.lastName)
  }

  const linkProps = (link: typeof TOS_LINK | typeof PRIVACY_LINK) => {
    if (iFramed) {
      return {
        onClick: () => openLink(link)
      }
    }
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: link
    }
  }

  if (loading) {
    return <BotHeadSpin panelSpacing />
  }
  return (
    <div className={styles.nameStepContainer}>
      <Heading color='neutral-900' textAlign='left' className={styles.headline}>
        {intl.formatMessage(MSG.lastStep)}
      </Heading>
      <Text color='neutral-700' size='sm'>
        {intl.formatMessage(MSG.createAnAccountHelperText)}
      </Text>
      {email && (
        <div className={styles.email}>
          <Text size='sm'>{intl.formatMessage(MSG.accountFor)}</Text>{' '}
          {/* @ts-ignore - can remove when wizard provider is typed */}
          <Button size='xs' rightIcon={<IconPencil />} onClick={goBack}>
            {/* max-width equal to the flex-basis property of the parent container */}
            <Box maxWidth={'32rem'} overflow='hidden'>
              {email}
            </Box>
          </Button>
        </div>
      )}
      <div className={styles.forms}>
        <Form className={styles.form} onSubmit={data => handleSubmitNameStep(data)}>
          {({ formState: { errors } }) => (
            <Flex flexDirection='column' justifyContent='space-between' flex={1}>
              <div>
                <FormControl isInvalid={!!errors?.firstName}>
                  <FormLabel>{intl.formatMessage(MSG.firstNameLabel)}</FormLabel>
                  <Input
                    size='sm'
                    name='firstName'
                    data-qa={`signup-email-input-firstName`}
                    tracking={{
                      guid: 'afjc3WqbDNhXwu2fUpQ3cM',
                      ui_context: GLOBAL_SIGNUP,
                      descriptor: MSG.firstNameLabel.defaultMessage
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(MSG.firstNameRequired)
                      }
                    }}
                  />
                  <FormErrorMessage>{errors?.firstName?.message?.toString()}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors?.lastName} mt={4}>
                  <FormLabel>{intl.formatMessage(MSG.lastNameLabel)}</FormLabel>
                  <Input
                    size='sm'
                    name='lastName'
                    data-qa={`signup-email-input-lastName`}
                    tracking={{
                      guid: '9y8dE64cHUjEWcc1fuR77m',
                      ui_context: GLOBAL_SIGNUP,
                      descriptor: MSG.lastNameLabel.defaultMessage
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(MSG.lastNameRequired)
                      }
                    }}
                  />
                  <FormErrorMessage>{errors?.lastName?.message?.toString()}</FormErrorMessage>
                </FormControl>
              </div>
              <div>
                <Flex direction='column'>
                  <Button
                    colorScheme='primary'
                    marginBottom={3}
                    tracking={{
                      guid: 'kufvNyZVbZ8Rdjnd7SXVsj',
                      ui_context: GLOBAL_SIGNUP,
                      descriptor: MSG.createAnAccount.defaultMessage
                    }}
                    type='submit'
                    data-qa={`signup-create-an-account-submit-btn`}
                  >
                    {intl.formatMessage(MSG.createAnAccount)}
                  </Button>
                </Flex>
                <Box marginTop={8}>
                  <Text size='xs'>
                    <FormattedMessage
                      id='NewBuyerSignUp.nameStep.disclaimer'
                      defaultMessage="By signing up, you're agreeing to our {termsOfUse} and {privacy}"
                      values={{
                        termsOfUse: (
                          <Link {...linkProps(TOS_LINK)}>
                            <FormattedMessage id='NewBuyerSignUp.nameStep.termsOfUse' defaultMessage='Terms of Use' />
                          </Link>
                        ),
                        privacy: (
                          <Link {...linkProps(PRIVACY_LINK)}>
                            <FormattedMessage
                              id='NewBuyerSignUp.nameStep.privacyPolicy'
                              defaultMessage='Privacy policy'
                            />{' '}
                          </Link>
                        )
                      }}
                    />
                  </Text>
                </Box>
              </div>
            </Flex>
          )}
        </Form>
      </div>
    </div>
  )
}
