// Components
import Svg from '../Components/shared/Svg'

const AlertSvg = props => (
  <Svg viewBox='0 0 14 14' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 5.5V11h-1V5.5h1zm.375-2A.89.89 0 017 4.375a.89.89 0 01-.875-.875A.89.89 0 017 2.625a.89.89 0 01.875.875zM13 7c0-3.306-2.694-6-6-6a6.01 6.01 0 00-6 6c0 3.306 2.694 6 6 6s6-2.694 6-6zm1 0c0 3.861-3.139 7-7 7s-7-3.139-7-7 3.139-7 7-7 7 3.139 7 7z'
      fill='var(--brand-warning-600)'
    />
  </Svg>
)

export default AlertSvg
