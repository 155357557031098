// Components
import Svg from '../Components/shared/Svg'

export const ForSaleSignSvg = props => (
  <Svg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.769542 1.53908C0.345385 1.53908 0 1.1937 0 0.769542C0 0.345385 0.345385 0 0.769542 0H17.6934C18.1155 0 18.4609 0.345385 18.4609 0.769542C18.4609 1.1937 18.1155 1.53908 17.6914 1.53908H16.9218V3.07817C17.7722 3.07817 18.4609 3.76692 18.4609 4.61725V10.7716C18.4609 11.6219 17.7722 12.3106 16.9218 12.3106H6.15431C5.30398 12.3106 4.61523 11.6219 4.61523 10.7716V4.61523C4.61523 3.7649 5.30398 3.07615 6.15431 3.07615V1.53908H3.07615V19.2305C3.07615 19.6546 2.73076 20 2.3066 20C1.88245 20 1.53908 19.6546 1.53908 19.2305V1.53908H0.769542ZM7.69138 3.07615H15.3828V1.53908H7.69138V3.07615Z'
      fill='var(--brand-neutral-cool-200)'
    />
  </Svg>
)
