import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import cssVars from 'css-vars-ponyfill'
import times from 'lodash/times'
import { FormattedMessage } from 'react-intl'
import PropTypesCustom from '../../../helpers/customPropTypes'
import { Avatar } from '../..'
import BotHeadColored from '../Avatar/BotHeadColored'
import { useUniqueId } from '../../../hooks'
import { createNewEvent, isTouchDevice } from '../../../helpers/browser'
import { KEY_CODES, MOUSE_CODES } from '../../../constants/utility'
import { TickMark } from './TickMark'

import styles from './Ruler.module.scss'

const propTypes = {
  value: PropTypesCustom.willParseInt.isRequired,
  min: PropTypesCustom.willParseInt.isRequired,
  max: PropTypesCustom.willParseInt.isRequired,
  start: PropTypesCustom.willParseInt,
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['homebot', 'customer']).isRequired,
      amount: PropTypesCustom.willParseInt.isRequired
    })
  ),
  majorTick: PropTypesCustom.willParseInt,
  minorTick: PropTypesCustom.willParseInt,
  tickColor: PropTypes.string,
  labelWrapClass: PropTypes.string,
  labelRenderFunc: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  step: PropTypesCustom.willParseInt,
  intervalWidth: PropTypesCustom.willParseInt,
  displayValue: PropTypes.bool,
  useClickBehavior: PropTypes.bool,
  name: PropTypes.string,
  disableKeyNav: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func
}

const defaultProps = {
  step: 1,
  intervalWidth: 9,
  name: 'range_selector',
  pins: []
}

const Ruler = ({
  value,
  name,
  min,
  max,
  start,
  pins,
  majorTick,
  minorTick,
  tickColor,
  labelWrapClass,
  labelRenderFunc,
  onChange,
  onFocus,
  onBlur,
  className,
  step,
  intervalWidth,
  displayValue,
  onKeyDown,
  onKeyUp,
  disableKeyNav,
  useClickBehavior,
  ...restProps
}) => {
  const homebotPin = Object.assign(
    {},
    pins.find(p => p.type === 'homebot')
  )
  const customerPin = Object.assign(
    {},
    pins.find(p => p.type === 'customer')
  )
  homebotPin.amount = homebotPin.amount ? parseInt(homebotPin.amount, 10) : 0
  customerPin.amount = customerPin.amount ? parseInt(customerPin.amount, 10) : 0

  const tickWidth = parseFloat(styles.cssTickWidth, 10)
  const baseDuration = parseFloat(styles.cssBaseDuration, 10) * (styles.cssBaseDuration.endsWith('ms') ? 1 : 1000)

  const root = useRef(null)
  const controller = useRef(null)
  const viewer = useRef(null)
  const input = useRef(null)
  const id = useUniqueId(`${name}_`)

  const [myValue, setMyValue] = useState(-1)
  const [incValue, setIncValue] = useState(-1)
  const [percentOff, setPercentOff] = useState(0)
  const [mouseStart, setMouseStart] = useState(null)
  const [keyDownTime, setKeyDownTime] = useState(null)

  const useStep = parseInt(step, 10)
  const useMajor = majorTick ? parseInt(majorTick, 10) : 0
  const useMinor = minorTick ? parseInt(minorTick, 10) : 0
  let useStart = start ? parseInt(start, 10) : 0
  if (!useStart && homebotPin.amount) {
    useStart = homebotPin.amount
  }

  let useMin = parseInt(min, 10)
  if (useMin % useStep) {
    useMin += useStep - (useMin % useStep)
  }

  let useMax = parseInt(max, 10)
  if (useMax % useStep) {
    useMax += useStep - (useMax % useStep)
  }

  if (useMin > useMax) {
    const swap = useMin
    useMin = useMax
    useMax = swap
  }

  const labels = []
  const intervalsNeeded = (useMax - useMin) / useStep
  const ticksNeeded = intervalsNeeded + 1

  let width
  width = ticksNeeded * tickWidth
  width += intervalsNeeded * intervalWidth
  width -= tickWidth

  let firstMajor
  let firstMinor
  times(ticksNeeded, i => {
    const val = i * useStep + useMin

    if (val % useMinor === 0 && isNaN(firstMinor)) {
      firstMinor = i
    }

    if (val % useMajor === 0) {
      if (isNaN(firstMajor)) {
        firstMajor = i
      }

      const start = useMin + firstMajor * useStep
      const intervalsFromStart = (val - start) / useStep
      const left = intervalsFromStart * (tickWidth + intervalWidth)
      const moreThanMinorTickFromStart = Math.abs(val - useStart) > useMinor

      labels.push(
        <TickMark
          key={`major_label_${i}`}
          className={labelWrapClass}
          value={val}
          left={left}
          render={labelRenderFunc}
          showLabel={moreThanMinorTickFromStart}
        />
      )
    }
  })

  const getValueFromScroll = useCallback(
    scroll => {
      if (controller.current) {
        const intervalsScrolled = (scroll / width) * intervalsNeeded
        const intervalsRounded = Math.round(intervalsScrolled)
        const tickVal = useMin + intervalsRounded * useStep
        return Math.max(Math.min(tickVal, useMax), useMin)
      }
    },
    [useMin, useMax, useStep, width, intervalsNeeded]
  )

  const getPercentFromValue = useCallback(
    val => {
      if (!root.current || !input.current || !controller.current) {
        return 0
      }

      const centerVal = useMin + intervalsNeeded * useStep * 0.5
      const intervalDelta = centerVal - val
      const stepPercent = (100 * intervalDelta) / intervalsNeeded / useStep
      return stepPercent
    },
    [useMin, useStep, intervalsNeeded]
  )

  // mount-ness to set css vars (and hide while doing that)
  useEffect(() => {
    if (root.current) {
      cssVars({
        include: 'style,link[rel="stylesheet"][type="text/css"]',
        variables: {
          '--widthPx': `${width}px`,
          '--intervalPx': `${intervalWidth}px`,
          '--homebot': (useStart - useMin) / useStep,
          '--customer': customerPin.amount ? (customerPin.amount - useMin) / useStep : 0,
          '--major': useMajor / useStep,
          '--minor': useMinor / useStep,
          '--majorOffset': firstMajor,
          '--minorOffset': firstMinor
        }
      })
      root.current.setAttribute('data-varsLoaded', 'true')
    }
  }, [
    width,
    useMin,
    useStart,
    firstMajor,
    firstMinor,
    useMajor,
    useMinor,
    useStep,
    baseDuration,
    intervalWidth,
    customerPin
  ])

  // set scroll when incoming value changes (from initial or updated prop)
  useEffect(() => {
    const scrollbox = controller.current
    if (scrollbox && incValue !== value) {
      if (incValue !== myValue) {
        setIncValue(value)
      } else {
        const parsedValue = parseInt(value, 10)
        const stepsFromLeft = Math.round((parsedValue - useMin) / useStep)
        const totalSteps = (useMax - useMin) / useStep
        const left = (width * stepsFromLeft) / totalSteps

        if (left === 0) {
          // ensures box scroll at initialization
          scrollbox.scrollLeft = 1
        }

        if (scrollbox.scrollTo) {
          scrollbox.scrollTo({ left, behavior: 'smooth' })
        } else {
          scrollbox.scrollLeft = left
        }
      }
    }
  }, [incValue, myValue, value, useMin, useMax, useStep, width])

  // fire synthectic onChange when myValue is updated (but not value or incValue - redundant)
  useEffect(() => {
    if (myValue !== value && myValue !== incValue) {
      const target = input.current
      if (target && typeof onChange === 'function') {
        const event = createNewEvent('change')
        const tracker = input._valueTracker
        if (tracker) {
          tracker.setValue(myValue)
        }
        target.dispatchEvent(event)
        onChange(event)
      }
    }
  }, [myValue, incValue, value, onChange])

  // when mouseStart is set (from mousedown), build and manage mousemove and mopuseup events
  useEffect(() => {
    const handleMove = mouseStart
      ? evt => {
          const clientX = evt.clientX
          const scrollbox = controller.current

          if (mouseStart !== null) {
            const delta = clientX - mouseStart.clientX
            const scroll = mouseStart.scrollLeft - delta
            scrollbox.scrollLeft = scroll
          }
        }
      : null
    const handleUp = () => setMouseStart(null)

    if (handleMove) {
      document.body.classList.add(styles.grabbing)
      document.addEventListener('mousemove', handleMove)
      document.addEventListener('mouseup', handleUp)
    }

    return () => {
      document.body.classList.remove(styles.grabbing)
      document.removeEventListener('mousemove', handleMove)
      document.removeEventListener('mouseup', handleUp)
    }
  }, [mouseStart])

  const showStart = useStart >= useMin && useStart <= useMax

  return (
    <div ref={root} className={classnames(styles.root, className)}>
      <div className={styles.viewable}>
        <div ref={viewer} className={styles.rulerContainer}>
          <TickMark className={styles.value} value={myValue} showLabel={displayValue} color={tickColor} />
          <div className={styles.ruler} style={{ transform: `translateX(${percentOff}%)` }}>
            {showStart && (
              <TickMark
                className={classnames(
                  styles.homebot,
                  useStart % useStep === 0 && styles.step,
                  useStart % useMinor === 0 && styles.minor,
                  useStart % useMajor === 0 && styles.major
                )}
                value={useStart}
                icon={BotHeadColored}
                render={labelRenderFunc}
                showLabel
              />
            )}
            {!!customerPin.amount && customerPin.photoUri && (
              <TickMark
                className={classnames(
                  styles.customer,
                  customerPin.amount % useStep === 0 && styles.step,
                  customerPin.amount % useMinor === 0 && styles.minor,
                  customerPin.amount % useMajor === 0 && styles.major
                )}
                value={customerPin.amount}
                icon={() => <Avatar className={styles.icon} src={customerPin.photoUri} />}
                render={labelRenderFunc}
                showLabel
              />
            )}
            <div className={classnames(styles.ticks, styles.step)} />
            <div className={classnames(styles.ticks, styles.minor)} />
            <div className={classnames(styles.ticks, styles.major)}>{labels}</div>
          </div>
        </div>
      </div>
      <div className={classnames(styles.controllable, mouseStart !== null && styles.mouseDown)}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={styles.rulerContainer}
          onScroll={e => {
            const newValue = getValueFromScroll(e.target.scrollLeft)
            if (newValue !== value) {
              setMyValue(newValue)
            }
            setPercentOff(getPercentFromValue(newValue))
          }}
          onMouseDown={e => {
            const code = e.which || e.button
            const ignore = code && code > MOUSE_CODES.leftButton
            const scrollbox = controller.current
            if (scrollbox && !useClickBehavior && !ignore && !isTouchDevice()) {
              setMouseStart({
                clientX: e.clientX,
                scrollLeft: scrollbox.scrollLeft
              })
            }
          }}
          ref={controller}
        >
          <div className={styles.ruler}>
            <input
              id={id}
              ref={input}
              className={styles.input}
              type='range'
              name={name}
              value={myValue}
              step={step}
              min={useMin}
              max={useMax}
              onKeyDown={e => {
                const code = e.which || e.keyCode
                const scrollbox = controller.current
                if (scrollbox && code && !disableKeyNav) {
                  const comboBonusMultiplier = keyDownTime
                    ? Math.min(useMajor / useStep, Math.pow(1.5, Math.floor((new Date() - keyDownTime) / 333)))
                    : 1
                  switch (code) {
                    case KEY_CODES.leftArrow:
                    case KEY_CODES.downArrow:
                      scrollbox.scrollLeft -= (tickWidth + intervalWidth) * comboBonusMultiplier
                      break

                    case KEY_CODES.rightArrow:
                    case KEY_CODES.upArrow:
                      scrollbox.scrollLeft += (tickWidth + intervalWidth) * comboBonusMultiplier
                      break

                    default:
                      break
                  }

                  if (keyDownTime === null) {
                    setKeyDownTime(new Date())
                  }
                }

                if (onKeyDown && typeof onKeyDown === 'function') {
                  onKeyDown(e)
                }

                if (code !== KEY_CODES.tab) {
                  // allow move focus
                  e.preventDefault()
                }
              }}
              onKeyUp={e => {
                if (keyDownTime) {
                  setKeyDownTime(null)
                }

                if (onKeyUp && typeof onKeyUp === 'function') {
                  onKeyUp(e)
                }
              }}
              readOnly
              {...restProps}
            />
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
            <label className={!useClickBehavior && styles.onTop} htmlFor={id} onClick={e => e.preventDefault()}>
              {/* eslint-enable jsx-a11y/click-events-have-key-events */}
              {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
              <FormattedMessage
                id='Ruler.general.label.type'
                defaultMessage='Ranged value selector - adjust with arrow keys'
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

Ruler.propTypes = propTypes
Ruler.defaultProps = defaultProps

export default Ruler
