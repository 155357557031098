// Components
import Svg from '../Components/shared/Svg'

export const MagnifyingGlassSvg = props => (
  <Svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 15.2692C16 15.9359 15.4359 16.5 14.7692 16.5C14.4444 16.5 14.1368 16.3632 13.8974 16.141L10.5983 12.8419C9.48718 13.6282 8.13675 14.0385 6.76923 14.0385C3.02564 14.0385 0 11.0128 0 7.26923C0 3.52564 3.02564 0.5 6.76923 0.5C10.5128 0.5 13.5385 3.52564 13.5385 7.26923C13.5385 8.63675 13.1282 9.98718 12.3419 11.0983L15.641 14.3974C15.8632 14.6197 16 14.9444 16 15.2692ZM11.0769 7.26923C11.0769 4.89316 9.1453 2.96154 6.76923 2.96154C4.39316 2.96154 2.46154 4.89316 2.46154 7.26923C2.46154 9.6453 4.39316 11.5769 6.76923 11.5769C9.1453 11.5769 11.0769 9.6453 11.0769 7.26923Z'
      fill='var(--brand-neutral-warm-500)'
    />
  </Svg>
)
