import { FETCH_CUSTOMER_POWERUPS_SUCCESS, FETCH_CUSTOMER_POWERUPS_FAILURE } from '../actions/actionTypes'

export const initialState = {
  lastRecievedAt: null,
  error: null
}

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case FETCH_CUSTOMER_POWERUPS_SUCCESS:
      return {
        lastRecievedAt: new Date(),
        error: null,
        ...payload.powerUps
      }

    case FETCH_CUSTOMER_POWERUPS_FAILURE:
      return {
        lastRecievedAt: new Date(),
        error: payload.error
      }

    default:
      return state
  }
}
