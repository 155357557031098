import { Svg } from '../../../../..'

export const SearchResultsEmptyIcon = () => (
  <Svg width='84' height='59' viewBox='0 0 84 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <ellipse opacity='0.3' cx='28' cy='43' rx='16' ry='16' transform='rotate(-180 28 43)' fill='#EB4F92' />
    <ellipse opacity='0.12' cx='21.5' cy='16.5' rx='16.5' ry='16.5' transform='rotate(-180 21.5 16.5)' fill='#EB4F92' />
    <path
      d='M83 35.2134C83.1961 35.2134 83.3741 35.0988 83.4552 34.9202C83.5363 34.7417 83.5056 34.5322 83.3765 34.3845L72.8217 22.3025V6.73908C72.8217 6.46294 72.5978 6.23908 72.3217 6.23908H65.1783C64.9022 6.23908 64.6783 6.46294 64.6783 6.73908V14.2926L54.8503 4.64322C54.7549 4.54961 54.6261 4.49805 54.4925 4.50006C54.3589 4.50206 54.2316 4.55746 54.1392 4.65389L25.6392 34.3673C25.5005 34.5119 25.4615 34.7253 25.5401 34.9096C25.6187 35.0939 25.7997 35.2134 26 35.2134H32.6189V58C32.6189 58.2761 32.8427 58.5 33.1189 58.5H50.9406H58.0594H75.8811C76.1573 58.5 76.3811 58.2761 76.3811 58V35.2134H83Z'
      fill='#EB4F92'
      stroke='#EB4F92'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.61538 57C1.65705 57 -1.982e-06 55.3429 -1.8964e-06 53.3846C-1.8547e-06 52.4306 0.401709 51.5267 1.05449 50.8237L10.7457 41.1325C8.4359 37.8686 7.23077 33.9017 7.23077 29.8846C7.23077 18.8878 16.1186 10 27.1154 10C38.1122 10 47 18.8878 47 29.8846C47 40.8814 38.1122 49.7692 27.1154 49.7692C23.0983 49.7692 19.1314 48.5641 15.8675 46.2543L6.17628 55.9455C5.5235 56.5983 4.56944 57 3.61538 57ZM27.1154 42.5385C34.0951 42.5385 39.7692 36.8643 39.7692 29.8846C39.7692 22.9049 34.0951 17.2308 27.1154 17.2308C20.1357 17.2308 14.4615 22.9049 14.4615 29.8846C14.4615 36.8643 20.1357 42.5385 27.1154 42.5385Z'
      fill='#F5DFE8'
    />
  </Svg>
)
