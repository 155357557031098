export const willParseInt = (props, propName, componentName) => {
  let error
  try {
    const num = parseInt(props[propName], 10)
    if (isNaN(num)) {
      error = new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. NaN returned from parseInt.`)
    }
  } catch (err) {
    error = new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed with error: ${err.message}`
    )
  }

  if (error) {
    throw error
  }
}

const createCustomPropType = (func, isRequired) => (props, propName, componentName) => {
  const prop = props[propName]
  if (prop === null || prop === undefined) {
    if (isRequired) {
      throw new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${prop}\`.`
      )
    }
    // not required, move along
  } else {
    func(props, propName, componentName)
  }
}

const propTypesCustom = {
  willParseInt: createCustomPropType(willParseInt, false)
}

Object.keys(propTypesCustom).reduce((toExtend, key) => {
  toExtend[key].isRequired = createCustomPropType(willParseInt, true)
  return toExtend
}, propTypesCustom)

export default propTypesCustom
