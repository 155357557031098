import PropTypes from 'prop-types'
import Svg from '../Components/shared/Svg'

interface ExitFullScreenSvgProps {
  className?: string
}

const ExitFullScreenSvg = ({ className }: ExitFullScreenSvgProps) => (
  <Svg className={className} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.5714 20.5714H32V25.1429H25.1429V32H20.5714V20.5714ZM0 20.5714H11.4286V32H6.85714V25.1429H0V20.5714ZM6.85714 0H11.4286V11.4286H0V6.85714H6.85714V0ZM32 6.85714V11.4286H20.5714V0H25.1429V6.85714H32Z'
      fill='#000'
    />
  </Svg>
)

ExitFullScreenSvg.propTypes = {
  className: PropTypes.string
}

export default ExitFullScreenSvg
