import clone from 'lodash/clone'
import maxBy from 'lodash/maxBy'

export const transformHomePreview = data => {
  const transformed = clone(data)
  const { avmHistory } = transformed

  transformed.avms = avmHistory
  transformed.currentAVM = maxBy(avmHistory, ({ date }) => new Date(date))

  transformed.avmHistory = {
    valuationCount: avmHistory.length,
    segmentCount: 1,
    segments: [
      {
        count: avmHistory.length,
        source: {},
        valuations: avmHistory.map(({ amount, date }) => ({
          id: null,
          type: 'avm',
          date,
          valuation: amount
        }))
      }
    ]
  }

  return transformed
}
