const ESCAPE = 'Escape'
const ENTER = 'Enter'
const SPACE = 'Space'

const NAV_FORWARD_KEYS = [ENTER, SPACE]
const NAV_BACKWARD_KEYS = [ESCAPE]

export const handleKeyNavForward = fn => e => NAV_FORWARD_KEYS.includes(e.key) && fn()
export const handleKeyNavBack = fn => e => NAV_BACKWARD_KEYS.includes(e.key) && fn()

/**
 * @function Predicate
 * @param {*} value
 * @return {bool}
 */

/**
 * Condition for validating an input
 *
 * @typedef {Object} Condition
 * @property {string} message - alerts the user to the error of their ways
 * @property {Predicate} validate - function determines value validity
 */

/**
 * validate a single input with a list of custom conditions
 *
 * @param {Node} el - dom element on which to run validations and setCustomValidity
 * @param {Condition[]=} conditions - custom validations to run on the input value
 * @param {Transform} [parse] - optional transform to run on raw value before passing to condition validate. Use to avoid common transforms in each condition
 * @returns {Array} ([isValid: {bool}, value: {*}])
 */
export const validateInput = (el, conditions, parse) => {
  el.setCustomValidity('')
  const unparsedValue = el.type && el.type === 'checkbox' ? el.checked : el.value
  const value = typeof parse === 'function' ? parse(unparsedValue) : unparsedValue

  const defaultValid = el.validity.valid
  if (!(defaultValid && conditions)) return [defaultValid, value]

  const firstInvalidCondition = conditions.find(({ message, validate }) => {
    const invalid = !validate(value)
    if (invalid) el.setCustomValidity(message)
    return invalid
  })

  const fieldIsValid = firstInvalidCondition === undefined
  return [fieldIsValid, value]
}
