import {
  FETCH_RECOMMENDATIONS,
  FETCH_RECOMMENDATIONS_SUCCESS,
  FETCH_RECOMMENDATIONS_FAILURE
} from '../actions/actionTypes'

export const initialState = {
  data: [],
  loading: false,
  error: null
}

export default (state = initialState, { type, data, error }) => {
  switch (type) {
    case FETCH_RECOMMENDATIONS:
      return { ...state, loading: true }
    case FETCH_RECOMMENDATIONS_SUCCESS:
      return { ...state, loading: false, data }
    case FETCH_RECOMMENDATIONS_FAILURE:
      return { ...state, loading: false, error }
    default:
      return state
  }
}
