// import { ListingAddress } from '../types'

import { ListingAddress } from '../api/gqlaxy/gql/generated/graphql'

export const formatListingAddress = (address: ListingAddress): string => {
  if (!address) return ''
  const { number, street, unitNumber, city, stateOrProvince, postalCode } = address
  const streetAddress = `${number ? `${number} ` : ''}${street ? `${street}` : ''}`
  const unit = unitNumber ? `${unitNumber}` : ''
  const stateZip = `${stateOrProvince} ${postalCode}`
  return `${streetAddress}${unit ? ` ${unit}` : ''}, ${city ? `${city}, ` : ''} ${stateZip}`
}

export const formatStreetAddress = (address: ListingAddress): string => {
  if (!address) return ''
  const { number, street, unitNumber } = address
  const streetAddress = `${number ? `${number} ` : ''}${street ? `${street}` : ''}`
  const unit = unitNumber ? `${unitNumber}` : ''
  return `${streetAddress}${unit ? ` ${unit}` : ''}`
}

export const formatCityStateZip = (address: ListingAddress): string => {
  if (!address) return ''
  const { city, stateOrProvince, postalCode } = address
  return `${city ? `${city}, ` : ''}${stateOrProvince} ${postalCode}`
}
