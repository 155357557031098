import PropTypes from 'prop-types'
import classnames from 'classnames'
import Heading from './Heading'
import Section from './Section'
import Footer from './Footer'
import AsyncPanel from './AsyncPanel'

import styles from './Panel.module.scss'
import { Box, BoxProps } from '@homebotapp/hb-react-component-catalog'

type PanelProps = BoxProps

const Panel = ({ className = '', children, ...boxProps }: PanelProps) => (
  <Box className={classnames(className, styles.root)} {...boxProps}>
    {children}
  </Box>
)

Panel.Heading = Heading
Panel.Section = Section
Panel.Footer = Footer
Panel.Async = AsyncPanel

export default Panel
