import { compose, setDisplayName, setPropTypes, withProps } from 'recompose'
import PropTypes from 'prop-types'

const withRootDir = compose(
  setDisplayName('withRootDir'),
  setPropTypes({
    match: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }),
  withProps(({ match: { url: rootDir } }) => ({
    rootDir: rootDir.replace(/\/$/, '')
  }))
)

export default withRootDir
