import get from 'lodash/get'
import * as Sentry from '@sentry/browser'
// Utils
import { QUESTION_CITY } from '../constants/client/onboarding'

// Action Types
import {
  SET_BUYER_ONBOARDING_STATE,
  SET_BUYER_ONBOARDING_ADDRESS,
  SET_BUYER_ONBOARDING_GOAL,
  SET_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE,
  FETCH_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE_ERROR
} from './actionTypes'

// Requests
import { getAverageMedianPrice as requestGetAverageMedianPrice } from '../api/mikasa/requests'

// Actions
import { saveClientAnswer } from './client'

export const setBuyerOnboardingState = state => dispatch => {
  dispatch({
    type: SET_BUYER_ONBOARDING_STATE,
    onboardingState: state
  })
}

export const setBuyerOnboardingAddress = home => dispatch => {
  dispatch({
    type: SET_BUYER_ONBOARDING_ADDRESS,
    address: home
  })
}

export const setBuyerOnboardingAverageMedianPrice = price => {
  return {
    type: SET_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE,
    price
  }
}

export const fetchAverageMedianPrice =
  ({ lat = null, lng = null } = {}) =>
  async dispatch => {
    try {
      const response = await requestGetAverageMedianPrice({ lat, lng })
      const price = get(response, 'data.averageMedianPriceForCity')

      dispatch(setBuyerOnboardingAverageMedianPrice(price))
    } catch (error) {
      dispatch(fetchAverageMedianPriceError(error.message))

      Sentry.captureException(error, {
        extra: {
          lat,
          lng
        }
      })
    }
  }

export const fetchAverageMedianPriceError = error => {
  return {
    type: FETCH_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE_ERROR,
    error
  }
}

export const setBuyerOnboardingGoal = goal => dispatch => {
  dispatch({
    type: SET_BUYER_ONBOARDING_GOAL,
    goal
  })
}

export const saveCity = city => async dispatch => {
  dispatch(
    saveClientAnswer({
      question: QUESTION_CITY,
      response: {
        value: city
      }
    })
  )
}
