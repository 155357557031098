import { useSelector } from 'react-redux'
import useViewport from '../../hooks/useViewport'
import { selectActiveNavigationTab } from '../../store/slices/navigation'
import { NAVIGATION_TAB_NAMES } from '../../types/navigation'
import { DesktopHeader } from './Header/DesktopHeader'
import { MobileHeader } from './Header/MobileHeader/MobileHeader'
import { MobileSearchHeader } from './Header/MobileSearchHeader/MobileSearchHeader'

export const AppHeader = () => {
  const { isLargeOrAbove } = useViewport()
  const activeNavigationItem = useSelector(selectActiveNavigationTab)
  const userIsInASearchPage =
    activeNavigationItem === NAVIGATION_TAB_NAMES.SEARCH || activeNavigationItem === NAVIGATION_TAB_NAMES.FORYOU

  if (isLargeOrAbove) {
    return <DesktopHeader />
  } else {
    // Search pages have a different header than the rest of the app
    return userIsInASearchPage ? <MobileSearchHeader /> : <MobileHeader />
  }
}
