import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from '../actions/actionTypes'

const initialState = {
  message: undefined,
  messageType: undefined
}

export default (state = initialState, { type, message, messageType }) => {
  switch (type) {
    case HIDE_NOTIFICATION:
      return initialState
    case SHOW_NOTIFICATION:
      return {
        ...state,
        message,
        messageType
      }

    default:
      return state
  }
}
