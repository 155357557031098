import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getBearerToken } from '../../auth'
import * as Sentry from '@sentry/browser'
import { camelizeKeys } from '../../helpers/utils'
import { SavedSearchModel } from '../../types/savedSearches'
import { CreateSavedSearchPayload } from '../../Components/homeSearch/Listings/SavedSearchToggle/ConfirmSavedSearchButton/ConfirmSavedSearchModal/ConfirmSavedSearchModal'

async function transformRes(res: Response) {
  try {
    const data = await res.json()
    const parsedData = camelizeKeys(data)
    // this is slightly different than other endpoints (normally we would return parsedData?.data)
    return parsedData
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const savedSearchesApi = createApi({
  reducerPath: 'savedSearchesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MIKASA_URI,
    prepareHeaders: headers => {
      const token = getBearerToken()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      headers.set('Content-Type', 'application/vnd.api+json')
      headers.set('Accept', 'application/vnd.api+json')
      return headers
    }
  }),
  tagTypes: ['SavedSearches'],
  endpoints: builder => ({
    getSavedSearches: builder.query<SavedSearchModel[], string>({
      query: clientId => ({
        url: `clients/${clientId}/client_saved_home_searches`,
        responseHandler: res => transformRes(res)
      }),
      // Provides a list of `SavedSearches` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `SavedSearch` element was added.
      // https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example
      // @ts-ignor
      providesTags: result =>
        // is result available?
        result
          ? // successful query
            [...result.map(({ id }) => ({ type: 'SavedSearches', id }) as const), { type: 'SavedSearches', id: 'LIST' }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'SavedSearches', id: 'LIST' }` is invalidated
            [{ type: 'SavedSearches', id: 'LIST' }]
    }),
    createSavedSearch: builder.mutation<SavedSearchModel, { clientId: string; payload: CreateSavedSearchPayload }>({
      query: ({ clientId, payload }) => ({
        url: `clients/${clientId}/client_saved_home_searches`,
        method: 'POST',
        body: JSON.stringify(payload)
      }),
      invalidatesTags: ['SavedSearches']
    }),
    deleteSavedSearch: builder.mutation<void, { clientId: string; savedSearchId: string }>({
      query: ({ clientId, savedSearchId }) => ({
        url: `clients/${clientId}/client_saved_home_searches/${savedSearchId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SavedSearches']
    })
  })
})

export const { useGetSavedSearchesQuery, useCreateSavedSearchMutation, useDeleteSavedSearchMutation } = savedSearchesApi
