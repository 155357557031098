import { ClientsTrackingClientTypes } from '../../quartermaster/src'
import { getShowPmiModules, createHomeownerMessage } from '../../api/mikasa/requests'
import { Pmi } from '../../types/pmi'
import * as Sentry from '@sentry/browser'
import { RootState } from '../../types/rootState'
import { CUSTOMER_MESSAGES } from '../../constants/customerMessages'
import { IDLE, FAILED, PENDING, SUCCEEDED } from '../../types/loadingStatus'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

export interface fetchPmiParamTypes {
  homeUuid: string
  clientId: string
}

export const fetchShowPmiModules = createAsyncThunk(
  'fetchShowPmiModules',
  async ({ clientId, homeUuid }: fetchPmiParamTypes, thunkApi) => {
    try {
      const response = await getShowPmiModules(clientId, homeUuid)
      return thunkApi.dispatch(setPmiState(response.data.data.metadata))
    } catch (error) {
      Sentry.captureException(error)
      return thunkApi.rejectWithValue(error)
    }
  }
)

export interface DMRequestParamTypes {
  message: string
  trackingClient: ClientsTrackingClientTypes | undefined
}

export const sendPmiRemovalDMRequest = createAsyncThunk(
  'sendPmiRemovalDMRequest',
  async ({ message, trackingClient }: DMRequestParamTypes, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const homeId = state.home.data.id
    const clientId = state.client.data.id

    try {
      const options = {
        topic: CUSTOMER_MESSAGES.MORTGAGE_INSURANCE.topic,
        topicKey: CUSTOMER_MESSAGES.MORTGAGE_INSURANCE.topicKey,
        message,
        clientId
      }

      return await createHomeownerMessage(homeId, options, trackingClient)
    } catch (error) {
      Sentry.captureException(error)
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const sendEstimatedPMIRemovalInquiry = createAsyncThunk(
  'sendEstimatedPMIRemovalInquiry',
  async ({ message, trackingClient }: DMRequestParamTypes, thunkApi) => {
    const state = thunkApi.getState() as RootState
    const homeId = state.home.data.id
    const clientId = state.client.data.id

    try {
      const options = {
        topic: CUSTOMER_MESSAGES.MORTGAGE_INSURANCE.topic,
        topicKey: CUSTOMER_MESSAGES.MORTGAGE_INSURANCE.topicKey,
        message,
        clientId
      }

      return await createHomeownerMessage(homeId, options, trackingClient)
    } catch (error) {
      Sentry.captureException(error)
      return thunkApi.rejectWithValue(error)
    }
  }
)

const initialState: Pmi = {
  status: null,
  ltcvPercent: null,
  estimatedLoanId: null,
  monthlyPmiPayment: null,
  fetchShowPmiModulesStatus: IDLE,
  pmiDMRequestedStatus: IDLE,
  showPmiProvidedModule: null,
  showPmiEstimatedModule: null,
  totalPmiSavingsInDollars: null,
  estimatedPMIInquiryStatus: IDLE
}

const pmiSlice = createSlice({
  name: 'Pmi',
  initialState,
  reducers: {
    setPmiState: (state, action: PayloadAction<Partial<Pmi>>) => {
      return { ...state, ...action.payload }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShowPmiModules.pending, state => {
        state.fetchShowPmiModulesStatus = PENDING
      })
      .addCase(fetchShowPmiModules.fulfilled, state => {
        state.fetchShowPmiModulesStatus = SUCCEEDED
      })
      .addCase(fetchShowPmiModules.rejected, state => {
        state.fetchShowPmiModulesStatus = FAILED
      })
      .addCase(sendPmiRemovalDMRequest.pending, state => {
        state.pmiDMRequestedStatus = PENDING
      })
      .addCase(sendPmiRemovalDMRequest.fulfilled, state => {
        state.pmiDMRequestedStatus = SUCCEEDED
      })
      .addCase(sendPmiRemovalDMRequest.rejected, state => {
        state.pmiDMRequestedStatus = FAILED
      })
      .addCase(sendEstimatedPMIRemovalInquiry.pending, state => {
        state.estimatedPMIInquiryStatus = PENDING
      })
      .addCase(sendEstimatedPMIRemovalInquiry.fulfilled, state => {
        state.estimatedPMIInquiryStatus = SUCCEEDED
      })
      .addCase(sendEstimatedPMIRemovalInquiry.rejected, state => {
        state.estimatedPMIInquiryStatus = FAILED
      })
  }
})

export const { setPmiState } = pmiSlice.actions

export const selectPmiDMRequestedStatus = (state: RootState) => state.pmi.pmiDMRequestedStatus
export const selectEstimatedPMIInquiryStatus = (state: RootState) => state.pmi.estimatedPMIInquiryStatus
export const selectShowPmiProvidedModule = (state: RootState) => state.pmi.showPmiProvidedModule

export default pmiSlice.reducer
