import classnames from 'classnames'

import styles from './Icon.module.scss'

const ICON_VARIANTS = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'neutralCool',
  'neutralWarm',
  'white'
] as const
type IconVariants = (typeof ICON_VARIANTS)[number]

const ICON_VARIANT_COUNTS = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'] as const
type IconVariantCounts = (typeof ICON_VARIANT_COUNTS)[number]

interface IconProps {
  variant?: IconVariants
  variantCount?: IconVariantCounts
  flipped?: boolean
  className?: string
}

const rootProps = ({ className, flipped, variant, variantCount, ...restProps }: IconProps) => {
  let variantStyle = ''

  if (variant && !variantCount) {
    variantStyle = styles[`icon__${variant}`]
  }
  if (variant && variantCount) {
    variantStyle = styles[`icon__${variant}-${variantCount}`]
  }

  return {
    className: classnames(styles.icon, flipped && styles.flipped, variantStyle, className),
    ...restProps
  }
}

const Icon = props => <i {...rootProps(props)} />

Icon.className = styles.icon
Icon.Variants = ICON_VARIANTS
Icon.VariantsCount = ICON_VARIANT_COUNTS

export default Icon
