import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getBuyerTrackingEventSubmitted, setBuyerTrackingEventSubmitted } from '../actions/buyer'
import { selectAuthedAsClient } from '../store/selectors/auth'
import { selectClientId } from '../store/selectors/client'
import { useCreateClientEvent } from '../hooks/useCreateClientEvent'
import { ClientEventName, ClientEventSource } from '../api/gqlaxy/gql/generated/graphql'

type useCreateClientEventOnViewProps = {
  eventName: ClientEventName
  eventSource?: ClientEventSource
  fireOnlyOncePerSession?: boolean
  location?: string
  eventProperties?: any
  listingId?: string
}

export const useCreateClientEventOnView = ({
  eventName,
  eventSource,
  location,
  fireOnlyOncePerSession = true,
  eventProperties = {},
  listingId
}: useCreateClientEventOnViewProps) => {
  const { mutateAsync: createClientEvent } = useCreateClientEvent({ disableErrorPrompts: true })

  const clientId = useSelector(selectClientId) as string
  const authedAsCustomer = !useSelector(selectAuthedAsClient)
  const tracked = getBuyerTrackingEventSubmitted(
    eventSource ? (location ? `${eventName}_${eventSource}_${location}` : `${eventName}_${eventSource}`) : eventName
  )
  const shouldFire = !fireOnlyOncePerSession || (fireOnlyOncePerSession && !tracked)

  return useCallback(() => {
    if (clientId && eventName && !authedAsCustomer && shouldFire) {
      const clientEventInput: {
        eventName: ClientEventName
        properties: Record<string, any>
        eventSource?: ClientEventSource
        listingId?: string
      } = {
        eventName: eventName,
        properties: eventProperties
      }
      if (listingId) {
        clientEventInput.listingId = listingId
      }
      if (eventSource) {
        clientEventInput.eventSource = eventSource
      }
      createClientEvent({
        clientEventInput: clientEventInput
      })
      if (fireOnlyOncePerSession) {
        setBuyerTrackingEventSubmitted(
          eventSource
            ? location
              ? `${eventName}_${eventSource}_${location}`
              : `${eventName}_${eventSource}`
            : eventName
        )
      }
    }
  }, [clientId, listingId, eventName, eventProperties, authedAsCustomer, tracked, shouldFire, fireOnlyOncePerSession])
}
