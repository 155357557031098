import {
  SET_LOAN_EDITING,
  SET_LOAN_BALANCE_EDITING,
  CREATE_HOME_LOAN_REQUEST,
  CREATE_HOME_LOAN_SUCCESS,
  CREATE_HOME_LOAN_FAILURE,
  DELETE_LOAN_REQUEST,
  DELETE_LOAN_SUCCESS,
  DELETE_LOAN_FAILURE,
  UPDATE_LOAN_REQUEST,
  UPDATE_LOAN_SUCCESS,
  UPDATE_LOAN_FAILURE,
  CREATE_LOAN_BALANCE_REQUEST,
  CREATE_LOAN_BALANCE_SUCCESS,
  CREATE_LOAN_BALANCE_FAILURE
} from '../actions/actionTypes'

const initialState = {
  currentLoan: null,
  deleting: false,
  editing: false,
  saving: false,
  editingBalance: false
}

export default (state = initialState, { currentLoan, data, editing, editingBalance, error, type }) => {
  switch (type) {
    case SET_LOAN_EDITING:
      return {
        ...state,
        currentLoan,
        editing
      }

    case CREATE_HOME_LOAN_REQUEST:
    case UPDATE_LOAN_REQUEST:
      return {
        ...state,
        saving: true
      }

    case DELETE_LOAN_REQUEST:
      return {
        ...state,
        deleting: true
      }

    case CREATE_HOME_LOAN_SUCCESS:
    case DELETE_LOAN_SUCCESS:
    case UPDATE_LOAN_SUCCESS:
      return {
        ...state,
        editing: false,
        saving: false,
        deleting: false,
        currentLoan: null,
        data
      }

    case CREATE_HOME_LOAN_FAILURE:
    case DELETE_LOAN_FAILURE:
    case UPDATE_LOAN_FAILURE:
      return {
        ...state,
        editing: false,
        saving: false,
        deleting: false,
        currentLoan: null,
        error
      }

    case SET_LOAN_BALANCE_EDITING:
      return {
        ...state,
        currentLoan,
        editingBalance
      }

    case CREATE_LOAN_BALANCE_REQUEST:
      return {
        ...state,
        saving: true
      }

    case CREATE_LOAN_BALANCE_SUCCESS:
      return {
        ...state,
        editing: false,
        saving: false,
        currentLoan: null
      }

    case CREATE_LOAN_BALANCE_FAILURE:
      return {
        ...state,
        editing: false,
        saving: false,
        currentLoan: null
      }

    default:
      return state
  }
}
