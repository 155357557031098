import { useState } from 'react'
import * as d3Scale from 'd3-scale'
import { useUniqueId } from '../../../hooks'
import { pushToWindowResize } from '../../../helpers/browser'

const minWindow = 350
const maxWindow = 1200

const linearScaleBy = (value, base, margin, paramMin, paramMax) => {
  const scale = d3Scale
    .scaleLinear()
    .domain([paramMin, paramMax])
    .range([base - margin, base + margin])
  return scale(Math.max(paramMin, Math.min(paramMax, value)))
}

const windowWidthScaler = ({ base, margin }) => linearScaleBy(window.outerWidth, base, margin, minWindow, maxWindow)
const windowHeightScaler = ({ base, margin }) => linearScaleBy(window.outerHeight, base, margin, minWindow, maxWindow)
const windowRatioScaler = ({ base, margin }) => {
  const value = window.outerWidth / window.outerHeight
  const min = minWindow / maxWindow
  const max = maxWindow / minWindow

  return linearScaleBy(value, base, margin, min, max)
}

export const BOUNDED_EXPLOSION = {
  recycle: false,
  // After 30 minutes of tinkering, colors can't use our CSS vars
  colors: ['#3CA6B4', '#EE76AA', '#CDD6E1', '#E3E9F0', '#5CB6C0'],
  numberOfPieces: 250,
  tweenDuration: 100,
  wind: 0,
  gravity: {
    base: 0.2,
    margin: -0.05,
    scaler: windowRatioScaler
  },
  initialVelocityX: {
    base: 5,
    margin: 2,
    scaler: windowWidthScaler
  },
  initialVelocityY: {
    base: 12,
    margin: 3,
    scaler: windowHeightScaler
  }
}

const getBase = defaults =>
  Object.keys(defaults).reduce((obj, key) => {
    obj[key] = defaults[key].base || defaults[key]
    return obj
  }, {})

const getSmoothed = defaults =>
  Object.keys(defaults).reduce((obj, key) => {
    obj[key] = typeof defaults[key].scaler === 'function' ? defaults[key].scaler(defaults[key]) : defaults[key]
    return obj
  }, {})

const calcDynamic = defaults => () => {
  const id = useUniqueId('Confetti_')
  const getter = () => getSmoothed(defaults)
  const [myProps, setMyProps] = useState(getter())

  pushToWindowResize(id, () => setMyProps(getter()))

  return myProps
}

export default {
  // static objects
  explosion: getBase(BOUNDED_EXPLOSION),
  'explosion-smooth': getSmoothed(BOUNDED_EXPLOSION)
}

export const DYNAMIC_CONFETTI_DEFAULTS = {
  // hooks
  explosion: calcDynamic(BOUNDED_EXPLOSION)
}
