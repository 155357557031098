import { Icon, Text } from '@homebotapp/hb-react-component-catalog'
import { NavigationTab } from './NavigationTab'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { INITIALIZE_ROUTE, selectMarketExploreRoute } from '../../../store/selectors/routes'
import { Link as ReactRouterLink } from 'react-router-dom'
import { GlobalTrackingEventLocation, TrackingEventConstants } from '../../../constants/tracking'
import { selectClientIsGuest } from '../../../store/selectors/client'
import { hasCustomerToken } from '../../../auth'
import { toggleAuthModal } from '../../../actions/homeSearchAuthModal'
import { FROM } from '../../../mappings/navigation'
import { useTrackingContext } from '../../../providers/tracking'
import { NavigationTabProps } from '../../../types/navigation'
import { setCreateClientFrom } from '../../../store/slices/navigation'
import { PATH_HELPERS } from '../../../constants/navigation'

const MSG = defineMessages({
  markets: {
    id: 'NavigationTab.markets',
    defaultMessage: 'Markets'
  }
})

export const MarketsTab = ({ isSelected, ...tabProps }: NavigationTabProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isGuest = useSelector(selectClientIsGuest)
  // Used solely for legacy tracking event
  const { trackingClient } = useTrackingContext()

  const marketsRoute = useSelector(selectMarketExploreRoute)

  const buildMarketsRoute = () => {
    if (hasCustomerToken()) {
      const impersonatedClientId: string = localStorage.getItem('impersonatedClientId') || ''
      const impersonatedClientIsBuyer: boolean = localStorage.getItem('impersonatedClientHbbAccess') === 'true'

      const buyRoute =
        impersonatedClientIsBuyer && !!impersonatedClientId
          ? PATH_HELPERS.markets.buildPath(impersonatedClientId, '/explore/map')
          : INITIALIZE_ROUTE
      return buyRoute
    } else {
      return marketsRoute
    }
  }

  const handleClick = e => {
    trackingClient?.buyerNavigationLinkClicked({
      buyer_navigation_link: TrackingEventConstants.EXPLORE_MARKETS
    })

    if (isGuest && !hasCustomerToken()) {
      e.preventDefault()
      dispatch(toggleAuthModal(true))
      return
    }
    dispatch(setCreateClientFrom(FROM.MARKET_TAB))
  }

  return (
    <NavigationTab
      {...tabProps}
      as={ReactRouterLink}
      to={buildMarketsRoute()}
      onClick={handleClick}
      tracking={{
        guid: '5MngyASRep9wmkUAgL9xZA',
        ui_context: GlobalTrackingEventLocation.GLOBAL_NAVIGATION_BAR,
        descriptor: MSG.markets.defaultMessage
      }}
    >
      <Icon
        name='map-marked-alt'
        data-qa='map-marked-alt'
        marginRight={['0', null, null, '2']}
        marginBottom={['1', null, null, '0']}
      />
      <Text sx={{ textWrap: 'nowrap' }} size={['3xs', 'sm']} m={0} fontWeight='semibold'>
        {intl.formatMessage(MSG.markets)}
      </Text>
    </NavigationTab>
  )
}
