import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import classnames from 'classnames'
import { useUniqueId } from '../../../hooks'
import { calcElementToTop } from '../../../helpers/browser'
import { Panel } from '../..'

import styles from './AsyncPanel.module.scss'
import { Box, BoxProps } from '@homebotapp/hb-react-component-catalog'

type AsyncPanelProps = BoxProps & {
  show?: boolean
  disableOffscreenShow?: boolean
  extraWide?: boolean
}

const AsyncPanel = ({
  show,
  disableOffscreenShow,
  extraWide,
  className,
  children,
  id,
  'data-qa': dataQa = 'async-panel',
  ...boxProps
}: AsyncPanelProps) => {
  const panelId = id || useUniqueId('AsyncPanel_')

  const [start, setStart] = useState(false)
  const [growTo, setGrowTo] = useState(null)
  const [visible, setVisible] = useState(false)

  // Initiate Start
  useEffect(() => {
    const panel = document.getElementById(panelId)
    const waypointBelowView = window.scrollY <= calcElementToTop(panel)

    // Run once - two entries: waypoint is below view or start has been fired from Waypoint
    const canGrow = show && panel && growTo === null
    const startOffscreen = waypointBelowView && !disableOffscreenShow
    const shouldStart = start || startOffscreen

    if (canGrow && shouldStart) {
      const box = panel.children[0].getBoundingClientRect()

      setStart(true)
      setGrowTo(box.height)
      setTimeout(() => setVisible(true), 350)
    }
  }, [show, start, growTo, disableOffscreenShow, panelId])

  const handleWaypointEnter = () => {
    if (show && !start) {
      setStart(true)
    }
  }

  let height: string | number = 0
  if (show) {
    height = visible ? 'auto' : `${growTo}px`
  }

  return (
    <>
      <Waypoint onEnter={handleWaypointEnter} />
      <Box
        data-qa={dataQa}
        id={panelId}
        // Todo: move styles to css-in-js
        className={classnames(
          styles.wrapper,
          start && styles.start,
          show && visible && styles.visible,
          extraWide && styles.extraWide
        )}
        style={{
          height
        }}
        {...boxProps}
      >
        <Panel className={className}>{children}</Panel>
      </Box>
    </>
  )
}

export default AsyncPanel
