import { Text } from '../../..'
import { defineMessages, useIntl } from 'react-intl'
import { IconHome } from '../../Icon'
import { HomebotListingsButton } from '../../../buyers/Button/HomebotListingsButton/HomebotListingsButton'
import baseStyles from '../ListingsCarousel.module.scss'
import styles from './ListingsCarouselLastSlide.module.scss'

export const MSG = defineMessages({
  more: {
    id: 'MarketSpotLight.see.more.slide',
    defaultMessage: 'Want to see even more homes?'
  },
  moreBtn: {
    id: 'MarketSpotLight.see.all.button',
    defaultMessage: 'View more'
  }
})

export interface ListingsCarouselLastSlideProps {
  url: string
}

export const ListingsCarouselLastSlide = ({ url }: ListingsCarouselLastSlideProps) => {
  const intl = useIntl()

  return (
    <div className={styles.lastListingContainer}>
      <HomebotListingsButton key='lastSlide' listingsUrl={url} className={baseStyles.buttonParent}>
        <IconHome variantCount='900' variant='neutralCool' className={styles.homeIcon} />
        <Text variantCount='900' variant='neutralCool' className={styles.lastSlideText}>
          {intl.formatMessage(MSG.more)}
        </Text>
        <Text size='s' variant='secondary' variantCount='600'>
          {intl.formatMessage(MSG.moreBtn)}
        </Text>
      </HomebotListingsButton>
    </div>
  )
}
