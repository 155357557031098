import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { setLocaleData } from '../actions/locale'
import LOCALES from '../locales'
import { Locale } from '../types/locale'
import { RootState } from '../types/rootState'

interface CustomIntlProviderProps {
  children: React.ReactNode
}

export const CustomIntlProvider = ({ children }: CustomIntlProviderProps) => {
  const dispatch = useDispatch()
  const urlParams = new URLSearchParams(window.location.search)
  const locale = useSelector<RootState, Locale>(state => state.locale)
  const optionalLangFromQueryStringForLocalDev = urlParams.get('lang')
  const lang = optionalLangFromQueryStringForLocalDev ?? locale?.locale ?? 'en'

  useEffect(() => {
    document.documentElement.lang = lang

    if (locale?.locale !== lang) {
      dispatch(setLocaleData(lang))
    }
  }, [lang, locale])

  return (
    <IntlProvider locale={lang} key={lang} messages={LOCALES[lang.slice(0, 2)]}>
      {children}
    </IntlProvider>
  )
}
