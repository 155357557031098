const Refs = {}

export const registerRef = (name, ref) => {
  if (Refs[name]) {
    return
  }

  Refs[name] = ref
}

export const unregisterRef = name => {
  if (!Refs[name]) {
    return
  }

  delete Refs[name]
}

export const getRef = name => {
  return Refs[name] || {}
}
