import { getCustomMailer } from '../api/mikasa/requests'
import { FETCH_CUSTOM_MAILER_REQUEST, FETCH_CUSTOM_MAILER_SUCCESS, FETCH_CUSTOM_MAILER_FAILURE } from './actionTypes'

export const fetchCustomMailer = customMailerId => async dispatch => {
  dispatch({ type: FETCH_CUSTOM_MAILER_REQUEST })

  try {
    const response = await getCustomMailer(customMailerId)
    const customMailer = response.data.data

    dispatch({
      type: FETCH_CUSTOM_MAILER_SUCCESS,
      customMailer
    })
  } catch (error) {
    dispatch({
      type: FETCH_CUSTOM_MAILER_FAILURE,
      error
    })
  }
}
