import React, { useState } from 'react'
import classnames from 'classnames'
import YTPlayer from 'react-youtube'
import { FormattedMessage } from 'react-intl'
import { Iframe, LoadingWrapper } from '../..'
import ErrorLogoWithMessage from '../Error/LogoWithMessage'
import { PlayerTypeEnum } from '../../../constants/features'
import { VideoSourceType } from '../../../types/videos'
import styles from './Player.module.scss'

interface PlayerProps {
  videoId: string
  videoType: VideoSourceType
  className?: string
  title?: string
}

export const Player = ({ videoId, videoType, title, className }: PlayerProps) => {
  const [ready, setReady] = useState(false)
  const [errored, setErrored] = useState(false)

  return (
    <LoadingWrapper className={classnames(styles.root, className)} done={ready || errored} alwaysShowChildren>
      {errored ? (
        <div className={styles.errorMessageContainer}>
          <div className={styles.errorMessage}>
            <ErrorLogoWithMessage>
              <FormattedMessage
                id='Player.error'
                defaultMessage='Error returned from {videoType} after requesting video playback'
                values={{ videoType }}
              />
            </ErrorLogoWithMessage>
          </div>
        </div>
      ) : (
        {
          [PlayerTypeEnum.YOUTUBE]: (
            <YouTube
              onReady={e => {
                setReady(true)
              }}
              onError={e => {
                setErrored(true)
              }}
              videoId={videoId}
            />
          ),
          [PlayerTypeEnum.BOMBBOMB]: <BombBomb videoId={videoId} onReady={() => setReady(true)} />,
          [PlayerTypeEnum.VIMEO]: <Vimeo title={title} videoId={videoId} onReady={() => setReady(true)} />,
          [PlayerTypeEnum.WISTIA]: <Wistia title={title} videoId={videoId} onReady={() => setReady(true)} />,
          [PlayerTypeEnum.PITCHHUB]: <Pitchhub videoId={videoId} onReady={() => setReady(true)} />
        }[videoType]
      )}
    </LoadingWrapper>
  )
}

interface YoutubeVideoProps {
  videoId: string
  className?: string
  onReady?: (e: Event) => void
  onError?: (e: unknown) => void
}

const YouTube = ({ videoId, onReady, onError }: YoutubeVideoProps) => (
  <YTPlayer
    onReady={onReady}
    onError={onError}
    videoId={videoId}
    containerClassName={classnames(styles.ytContainer)}
    className={styles.iframe}
    opts={{
      width: '100%',
      height: '100%'
    }}
  />
)

interface IFrameVideoProps {
  videoId: string
  onReady?: (e: Event) => void
  title?: string
}

const BombBomb = ({ videoId, onReady }: IFrameVideoProps) => (
  <Iframe
    src={`https://bbemaildelivery.com/bbext/?p=vidEmbed&id=${videoId}`}
    title='BombBomb Player'
    aspect='16:9'
    onReady={onReady}
  />
)

const Wistia = ({ videoId, onReady, title }: IFrameVideoProps) => (
  <Iframe
    src={`https://fast.wistia.net/embed/iframe/${videoId}?autoPlay=false`}
    title={title ?? 'Wistia Player'}
    aspect='16:9'
    onReady={onReady}
  />
)

const Vimeo = ({ videoId, onReady, title }: IFrameVideoProps) => (
  <Iframe
    src={`https://player.vimeo.com/video/${videoId}?h=be83838d50`}
    title={title ?? 'Vimeo Player'}
    aspect='16:9'
    onReady={onReady}
  />
)

const Pitchhub = ({ videoId, onReady }: IFrameVideoProps) => (
  <Iframe
    src={`https://player.pitchhub.com/en/public/player/iframe/${videoId}`}
    title='Pitchhub Player'
    aspect='16:9'
    onReady={onReady}
  />
)
