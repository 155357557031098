import { createSelector } from 'reselect'
import mapValues from 'lodash/mapValues'
import { Dictionary } from '../../../quartermaster/src'
import { Home, Valuation } from '../../../quartermaster/src/types'
import { selectCurrentCELs as selectCurrentCELLoans, selectHighestRateLoan } from './loans'

// TODO: TS state interface
type State = any

const getHomeData = (state: State) => state.home.data

const dictionary = Dictionary()

const mergeLoans =
  (fn: any): any =>
  (state: State) => ({
    ...getHomeData(state),
    loans: fn(state)
  })

export const bindDictionary = (home: Home) =>
  mapValues(dictionary, (f: any) => (...args: any) => {
    if (home) return f.call(dictionary, home, ...args)
    return undefined
  })

export const selectCurrentCELs: (state: any) => any = createSelector(mergeLoans(selectCurrentCELLoans), bindDictionary)

export const selectFromCurrentCELs: (state: State) => any = (attr: any) => (state: State) =>
  selectCurrentCELs(state)[attr]()

export const selectAll: (state: State) => any = createSelector(getHomeData, bindDictionary)

export const selectFromAll = (attr: string) => (state: State) => selectAll(state)[attr]()

export const selectHighestRateLoanDictionary = createSelector(
  // @ts-ignore
  mergeLoans((state: State) => [selectHighestRateLoan(state)]),
  bindDictionary
)

export const selectAvmWithoutCMA = createSelector(getHomeData, home =>
  home ? dictionary.avmObjectWithoutCMA(home) : undefined
)

export const sortMostRecentAVMs = (avms: Valuation[]) => dictionary.sortMostRecentAVMs(avms)
