import Button from '../../Button'
import { MouseEvent } from 'react'
import classnames from 'classnames'
import { IconCommentingFa } from '../../Icon'

import styles from './DirectMessageButton.module.scss'

export interface DirectMessageButtonProps {
  loading: boolean
  disabled: boolean
  className?: string
  onClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const DirectMessageButton = ({ onClick, loading, disabled, className }: DirectMessageButtonProps) => {
  return (
    <Button
      compact
      primaryCustom
      onClick={onClick}
      disabled={disabled}
      data-qa='directMessageButton'
      aria-label='send direct message'
      className={classnames(className, styles.button, loading && styles.loading)}
    >
      {!loading && <IconCommentingFa className={styles.icon} />}
    </Button>
  )
}
