import React from 'react'
import classnames from 'classnames'
import { useUniqueId } from '../../../hooks'
import { Text } from '../..'
import styles from './FormFieldSqFt.module.scss'
import NumberInput from '../Input/NumberInput'

interface FormFieldSqFtProps {
  value?: number
  children: React.ReactNode
  className?: string
  name: string
  suffix: string
  allowNegative: boolean
  id?: string
  hideLabel?: boolean
  inputClassName?: string
  labelClass?: string
}

export const FormFieldSqFt = ({
  value,
  children,
  className,
  hideLabel,
  id,
  inputClassName,
  labelClass,
  name,
  suffix,
  allowNegative
}: FormFieldSqFtProps) => {
  const defaultId = useUniqueId(`${name}_`)
  const myId = id || defaultId

  return (
    <div className={classnames(styles.root, className)}>
      <label className={classnames(styles.label, labelClass)} htmlFor={myId}>
        <Text
          element='span'
          variant='neutralCool'
          variantCount='700'
          size='xs'
          className={classnames(hideLabel && styles.hide)}
        >
          {children}
        </Text>
        <div className={styles.inputAndSuffixWrapper}>
          <NumberInput
            allowNegative={allowNegative}
            id={myId}
            name={name}
            data-qa={name}
            className={inputClassName}
            placeholder='0'
            onChange={() => null}
            defaultValue={value}
          />
          {suffix && <div className={styles.suffix}>{suffix}</div>}
        </div>
      </label>
    </div>
  )
}
