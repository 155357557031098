import { createSelector } from 'reselect'
import moment from 'moment'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import partialRight from 'lodash/partialRight'
import sumBy from 'lodash/sumBy'
import { Finance } from '../../../quartermaster/src'
import { Loan } from '../../../quartermaster/src/types'
import Helpers from '../../../charts/lib/Helpers'
import { LOAN_TERMS, LOAN_STATUS, LOAN_TYPE } from '../../../constants/loan'
import { SERVER_DATE } from '../../../constants/formats'
import { sumSquareMatrix } from '../../../helpers/refinance'
import { getThe } from '../utils'

const nonHELOC = 'CEL'
const { CURRENT, DELETED, EXPIRED } = LOAN_STATUS

const sumBy1: (stuff: object[], key: string) => number = sumBy
const maxBy1: (stuff: any[], getter: (get: any) => any) => any | undefined = maxBy

// TODO: TS state interface
type State = any

interface LoansByStatus {
  [key: string]: Loan[]
}
interface LoansByType {
  [key: string]: Loan[]
}
interface LoanBalanceDetail {
  uuid: string
  id: string
  loanId: string
  date: string
  termYears: number | null
  termYearsEstimated: number
  amount: number
  principalLeft: number | null | undefined
}

const getLoans = (state: State) => get(state, 'home.data.loans')

export const selectByStatus = createSelector<State, LoansByStatus>(getLoans, (loans: Loan) => ({
  [CURRENT]: [],
  [DELETED]: [],
  [EXPIRED]: [],
  ...groupBy(Helpers.sortByLoanType(loans), 'status')
}))

export const selectCurrentLoans = createSelector<State, Loan[]>(
  selectByStatus,
  (byStatus: LoansByStatus) => (byStatus || { current: [] }).current || []
)

const selectCurrentByType = createSelector<State, LoansByType>(selectCurrentLoans, (loans: Loan[]) =>
  groupBy(loans, ({ loanType }) => (loanType === LOAN_TYPE.HELOC ? LOAN_TYPE.HELOC : nonHELOC))
)

export const selectCurrentHelocs = createSelector<State, Loan[]>(
  selectCurrentByType,
  (byType: LoansByType) => byType[LOAN_TYPE.HELOC] || []
)

// CEL = "closed end loan", basically a traditional non-heloc mortgage
export const selectCurrentCELs = createSelector<State, Loan[]>(
  selectCurrentByType,
  (byType: LoansByType) => byType[nonHELOC] || []
)

export const selectTotalPrincipalPaidOnAllCurrentCELs = createSelector<State, number>(
  selectCurrentCELs,
  (currentCELLoans: Loan[]) => {
    if (!currentCELLoans || !currentCELLoans.length) {
      return 0
    }
    const sum = currentCELLoans.reduce((acc, loan) => {
      acc += loan.progress?.principalPaid ?? 0
      return acc
    }, 0)
    return sum
  }
)

export const selectTotalInterestPaidOnAllCurrentCELs = createSelector<State, number>(
  selectCurrentCELs,
  (currentCELLoans: Loan[]) => {
    if (!currentCELLoans || !currentCELLoans.length) {
      return 0
    }
    const sum = currentCELLoans.reduce((acc, loan) => {
      acc += loan.progress?.interestPaid ?? 0
      return acc
    }, 0)
    return sum
  }
)

export const selectLoanBalanceDetails = createSelector<State, LoanBalanceDetail[] | null | undefined>(
  selectCurrentCELs,
  (loans: Loan[]) =>
    map(loans, ({ uuid, id, loanId, date, termYears, termYearsEstimated, amount, progress }) => ({
      uuid,
      id,
      loanId,
      date,
      termYears,
      termYearsEstimated,
      amount,
      principalLeft: get(progress, 'principalLeft')
    }))
)

export const getRate = getThe('rate')
export const getRateEstimated = getThe('rateEstimated')
export const getTermYears = getThe('termYears')
export const getTermYearsEstimated = getThe('termYearsEstimated')
export const getAmount = getThe('amount')
export const getDate = getThe('date')

export const getLoanInterestRate = createSelector<any, number>(
  getRate,
  getRateEstimated,
  (rate, rateEstimated) => rate || rateEstimated
)

export const getLoanTermYears = createSelector(
  getTermYears,
  getTermYearsEstimated,
  (termYears, termYearsEstimated) => termYears || termYearsEstimated
)

export const selectHighestRateLoan = createSelector<State, Loan | undefined>(selectCurrentCELs, (loans: Loan[]) =>
  maxBy1(loans, getLoanInterestRate)
)

export const selectHighestRate = createSelector<State, number>(selectHighestRateLoan, getLoanInterestRate)

export const selectFirstCEL = createSelector<State, Loan>(selectCurrentCELs, (loans: Loan[]) => get(loans, 0))

export const selectFirstLoanAmount = createSelector<State, number>(selectFirstCEL, getAmount)

export const selectFirstLoanTermYears = createSelector<State, number>(selectFirstCEL, getLoanTermYears)

export const selectFirstLoanRate = createSelector<State, number>(selectFirstCEL, getLoanInterestRate)

export const selectFirstLoanDate = createSelector<State, string>(selectFirstCEL, getDate)

export const isUnverified = (loan: Loan) => !loan.termYears || !loan.rate

export const selectUnverifiedCELs = createSelector<State, Loan[]>(selectCurrentCELs, (loans: Loan[]) =>
  loans.filter(isUnverified)
)

export const selectCELsOutstandingPrincipal = createSelector<State, number>(selectCurrentCELs, (loans: Loan[]) =>
  sumBy1(loans, 'progress.principalLeft')
)

export const selectCELsPaymentForExistingLoans = createSelector<State, number>(selectCurrentCELs, (loans: Loan[]) =>
  sumBy1(loans, 'facts.monthlyPayment')
)

const fiveMonthsAgo = moment().subtract(5, 'months')
const refiEligibleLoan = (loan: Loan) => moment(loan.date, SERVER_DATE).isBefore(fiveMonthsAgo)

export const selectRefiEligible = createSelector(selectCurrentCELs, loans => loans.every(refiEligibleLoan))

export const isValidTerm = (term: string) => LOAN_TERMS.includes(parseInt(term, 10))

export const calculateInterestPaidForExistingLoans = createSelector(selectCurrentCELs, loans => {
  if (loans.length === 0) {
    return []
  }

  const loanMatrix = map(loans, loan => getInterestPaidForExistingLoan(loan))

  if (loanMatrix.length !== 1) {
    return sumSquareMatrix(loanMatrix)
  }

  return loanMatrix[0]
})

export const getInterestPaidForExistingLoan = (loan: Loan) => {
  const trimmedLoanSchedule = trimLoanSchedule(loan)

  return Finance.calculateInterestPaidOverSchedule(trimmedLoanSchedule)
}

const getFromLoan = (key: string) => partialRight(get, key)

export const getLoanSchedule = getFromLoan('schedule')

const getLoanStatus = getFromLoan('status')

export const getLoanStatusIsCurrent = createSelector(getLoanStatus, status => status === LOAN_STATUS.CURRENT)

const getFromLoanProgress = (key: string) => partialRight(get, ['progress', key])

export const getLoanPaymentsMade = getFromLoanProgress('paymentsMade')
export const getLoanPrincipalLeft = getFromLoanProgress('principalLeft')
export const getLoanPrincipalDiff = getFromLoanProgress('principalDiff')
export const getLoanBalanceEffectiveDate = getFromLoan('balanceEffectiveDate')

export const trimLoanSchedule = (loan: Loan) => {
  const schedule = getLoanSchedule(loan)
  if (!schedule) {
    return schedule
  }

  const begin = getLoanPaymentsMade(loan)
  const end = schedule.length
  return schedule.slice(begin, end)
}

export const selectPrincipalDiff = createSelector<State, number>(selectCurrentCELs, (loans: Loan[]) =>
  sumBy1(loans, 'progress.principalDiff')
)

export const selectInterestDiff = createSelector<State, number>(selectCurrentCELs, (loans: Loan[]) =>
  sumBy1(loans, 'progress.interestDiff')
)

export const selectEditableLoan = (state: State) => get(state, 'loan.currentLoan')

export const selectFromEditableLoan = (key: string) => createSelector(selectEditableLoan, loan => get(loan, key))

export const selectEditableLoanCanonicalID = selectFromEditableLoan('loanId')
export const selectEditableLoanBalance = selectFromEditableLoan('balance')
export const selectEditableLoanHomeID = selectFromEditableLoan('homeId')
export const selectEditableLoanEstimatedBalance = selectFromEditableLoan('progress.principalLeft')
export const selectEditableLoanAmount = selectFromEditableLoan('amount')
