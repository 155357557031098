import { defineMessage, useIntl } from 'react-intl'
import { ListingStatusEnum } from '../types/listingStatus'

const InfoIcon = () => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4 0L8 8H0L4 0Z' fill='#9676EE' />
  </svg>
)

const SuccessIcon = () => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4' cy='4' r='4' fill='var(--brand-success-600)' />
  </svg>
)

const DangerIcon = () => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4' cy='4' r='4' fill='var(--hb-colors-danger-500)' />
  </svg>
)

const WarningIcon = () => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4' cy='4' r='4' fill='var(--hb-colors-warning-500)' />
  </svg>
)

const STATUSES = {
  [ListingStatusEnum.ACTIVE]: {
    icon: SuccessIcon,
    msg: defineMessage({
      id: 'Listings.status.active',
      defaultMessage: 'Active'
    })
  },
  [ListingStatusEnum.ACTIVE_UNDER_CONTRACT]: {
    icon: InfoIcon,
    msg: defineMessage({
      id: 'Listings.status.activeUnderContract',
      defaultMessage: 'Active Under Contract'
    })
  },
  [ListingStatusEnum.CANCELLED]: {
    icon: DangerIcon,
    msg: defineMessage({
      id: 'Listings.status.cancelled',
      defaultMessage: 'Cancelled'
    })
  },
  [ListingStatusEnum.CLOSED]: {
    icon: DangerIcon,
    msg: defineMessage({
      id: 'Listings.status.closed',
      defaultMessage: 'Closed'
    })
  },
  [ListingStatusEnum.COMING_SOON]: {
    icon: SuccessIcon,
    msg: defineMessage({
      id: 'Listings.status.comingSoon',
      defaultMessage: 'Coming Soon'
    })
  },
  [ListingStatusEnum.DELETE]: {
    icon: DangerIcon,
    msg: defineMessage({
      id: 'Listings.status.delete',
      defaultMessage: 'Delete'
    })
  },
  [ListingStatusEnum.EXPIRED]: {
    icon: DangerIcon,
    msg: defineMessage({
      id: 'Listings.status.expired',
      defaultMessage: 'Expired'
    })
  },
  [ListingStatusEnum.HOLD]: {
    icon: WarningIcon,
    msg: defineMessage({
      id: 'Listings.status.hold',
      defaultMessage: 'Hold'
    })
  },
  [ListingStatusEnum.INCOMPLETE]: {
    icon: WarningIcon,
    msg: defineMessage({
      id: 'Listings.status.incomplete',
      defaultMessage: 'Incomplete'
    })
  },
  [ListingStatusEnum.PENDING]: {
    icon: InfoIcon,
    msg: defineMessage({
      id: 'Listings.status.pending',
      defaultMessage: 'Pending'
    })
  },
  [ListingStatusEnum.WITHDRAWN]: {
    icon: DangerIcon,
    msg: defineMessage({
      id: 'Listings.status.withdrawn',
      defaultMessage: 'Withdrawn'
    })
  },
  [ListingStatusEnum.UNKNOWN]: {
    icon: WarningIcon,
    msg: defineMessage({
      id: 'Listings.status.unknown',
      defaultMessage: 'Unknown'
    })
  }
}

export const useListingStatusDisplayObj = (status: ListingStatusEnum) => {
  const intl = useIntl()
  const statusObj = STATUSES[status] || STATUSES[ListingStatusEnum.UNKNOWN]

  return {
    icon: statusObj.icon,
    text: intl.formatMessage(statusObj.msg)
  }
}
