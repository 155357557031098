import React, { useState, InputHTMLAttributes, FocusEvent, ElementType, forwardRef } from 'react'
import classnames from 'classnames'
import Input from './Input'

import styles from './TextInput.module.scss'

const StyledInput = React.forwardRef((props: InputHTMLAttributes<HTMLInputElement>, ref) => (
  <Input className={styles.input} ref={ref} {...props} />
))

StyledInput.displayName = 'StyledInput'

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  input?: ElementType
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { className, onFocus, onBlur, input: MyInput = StyledInput, ...restProps } = props

  const [focused, setFocused] = useState(false)

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocused(true)
    onFocus && onFocus(e)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocused(false)
    onBlur && onBlur(e)
  }

  return (
    <div className={classnames(styles.root, focused && styles.focused, className)}>
      <MyInput onFocus={handleFocus} onBlur={handleBlur} ref={ref} {...restProps} />
    </div>
  )
})

TextInput.displayName = 'TextInput'

export default TextInput
