import InlineBulletedList from './InlineBulletedList'
import BulletedList from './BulletedList'
import ProConList from './ProCon'

BulletedList.Inline = InlineBulletedList

export default {
  Bulleted: BulletedList,
  ProConList
}

export { BulletedList, InlineBulletedList, ProConList }
