import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../types/rootState'
import { AppDispatch } from '.'

type DispatchFunc = () => AppDispatch

export const useAppDispatch: DispatchFunc = () => useDispatch<AppDispatch>()
// use "useAppSelector" instead of "useSelector" to get the correct types without having to pass generic
// ideally, all our reducers would be typed, and we could simply infer the type of the store from them like the RTK docs suggest
// but for now we can just use RootState
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
