import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import { selectFromHomeData } from './..'
import { getThe, getFrom } from '../../utils'
import { selectCurrentCELs, selectCELsOutstandingPrincipal } from '../loans'
import { selectLatestValuationForCurrentHome } from '../../valuationHistory'

export const getHomeAnswers = getThe('answers')
const getFromAnswers = getFrom(getHomeAnswers)
export const getIsHomePaidInFull = getFromAnswers('isHomePaidInFull')
export const getHomeBaths = getFromAnswers('factBathrooms')
export const getHomeBeds = getFromAnswers('factBedrooms')
export const getHomeAdditionalUpdates = getFromAnswers('factAdditionalUpdates')
export const getHomeSqFt = getFromAnswers('factFinishedSqFt')
export const getDateOfLastClientAcknowledgedValuation = getFromAnswers('dateOfLastClientAcknowledgedValuation')
export const getHomeInsuranceReminderSnoozed = getFromAnswers('snoozeHomeInsuranceReminder')
export const selectHomeAnswers = selectFromHomeData(getHomeAnswers)

const selectIsHomePaidInFull = selectFromHomeData(getIsHomePaidInFull)
export const selectPaidInFull = createSelector(
  selectIsHomePaidInFull,
  selectCurrentCELs,
  selectCELsOutstandingPrincipal,
  (isHomePaidInFull, loans, loanBalance) =>
    (isBoolean(isHomePaidInFull) ? isHomePaidInFull : isHomePaidInFull === 'true') ||
    (loans?.length > 0 && loanBalance === 0)
)

export const selectHomeSqFt = selectFromHomeData(getHomeSqFt)
export const selectHomeBeds = selectFromHomeData(getHomeBeds)
export const selectHomeBathsJson = selectFromHomeData(getHomeBaths)
export const selectHomeBaths = createSelector(selectHomeBathsJson, baths => {
  try {
    const bathsJson = !isEmpty(baths) ? JSON.parse(`{${baths}}`) : null
    return mapValues(bathsJson, parseInt)
  } catch {
    return {}
  }
})
export const selectHomeAdditionalUpdatesJson = selectFromHomeData(getHomeAdditionalUpdates)
export const selectHomeAdditionalUpdates = createSelector(selectHomeAdditionalUpdatesJson, updates => {
  try {
    return !isEmpty(updates) ? JSON.parse(`{${updates}}`) : {}
  } catch {
    return {}
  }
})

export const selectDateOfLastAcknowledgedValuation = createSelector(
  selectFromHomeData(getDateOfLastClientAcknowledgedValuation),
  dateOfLastClientAcknowledgedValuation => {
    return dateOfLastClientAcknowledgedValuation || null
  }
)

export const selectClientHasAcknowledgedLatestAvmValuation = createSelector(
  selectLatestValuationForCurrentHome,
  selectDateOfLastAcknowledgedValuation,
  (latestValuationForCurrentHome, dateOfLastClientAcknowledgedValuation) => {
    if (!latestValuationForCurrentHome) return false

    return latestValuationForCurrentHome.date === dateOfLastClientAcknowledgedValuation
  }
)

export const selectClientSnoozedReminder = createSelector(
  selectFromHomeData(getHomeInsuranceReminderSnoozed),
  snoozed => (isBoolean(snoozed) ? snoozed : snoozed === 'true')
)

export const selectHomePriceWizardCompletedAt = createSelector(
  selectHomeAnswers,
  answers => answers?.homePriceWidgetCompletedAt
)
