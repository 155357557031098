import {
  SET_PRICE_POINT,
  SEND_PREQUAL_REQUEST,
  SEND_PREQUAL_SUCCESS,
  SEND_PREQUAL_FAILURE,
  DISPLAY_PREQUAL_PROMPT,
  SET_AFFORDABILITY_METHOD,
  SET_AFFORDABILITY_VALUES,
  TOGGLE_AFFORDABILITY_MODAL
} from './actionTypes'
import {
  selectBuyerMethod,
  selectPersistableAffordabilityValues,
  selectPromptPrequalFromAffordability
} from '../store/selectors/affordability'
import * as Sentry from '@sentry/browser'
import { saveClientAnswer } from './client'
import { updateBuyerInfo } from './buyerInfo'
import { RootState } from '../types/rootState'
import { createBuyerMessage } from '../api/mikasa/requests'
import { selectBuyerId } from '../store/selectors/buyerInfo'
import { selectCustomerType } from '../store/selectors/customerProfile'
import { QUESTION_REQUESTED_PREQUAL } from '../constants/affordability'
import { CUSTOMER_MESSAGES } from '../constants/customerMessages'
import { TrackingEventConstants } from '../constants/tracking'
import { TrackingEventHelpers } from '../helpers/tracking'

export const setAffordabilityMethod = affordabilityMethod => ({
  type: SET_AFFORDABILITY_METHOD,
  affordabilityMethod
})

export const setDefaultAffordabilityMethod = () => async (dispatch, getState: () => RootState) => {
  const state: RootState = getState()
  // @ts-ignore
  const defaultMethod = selectBuyerMethod(state)

  dispatch(setAffordabilityMethod(defaultMethod))
}

export const setPricePoint = pricePoint => ({
  type: SET_PRICE_POINT,
  pricePoint
})

export const toggleAffordabilityModal = () => ({
  type: TOGGLE_AFFORDABILITY_MODAL
})

export const setAffordabilityValues = affordability => ({
  type: SET_AFFORDABILITY_VALUES,
  ...affordability
})

export const displayPrequalPrompt = () => ({
  type: DISPLAY_PREQUAL_PROMPT
})

export const persistAffordability = () => (dispatch, getState: () => RootState) => {
  const state: RootState = getState()
  // @ts-ignore
  const values = selectPersistableAffordabilityValues(state)
  // @ts-ignore
  const showPrompt = selectPromptPrequalFromAffordability(state)

  if (showPrompt) {
    dispatch(displayPrequalPrompt())
  } else {
    dispatch(toggleAffordabilityModal())
  }

  dispatch(updateBuyerInfo(values))
}

export const sendPrequalRequestForClient = trackingClient => async (dispatch, getState: () => RootState) => {
  const state: RootState = getState()
  const buyerId = selectBuyerId(state)
  const customerType = selectCustomerType(state)

  dispatch({ type: SEND_PREQUAL_REQUEST })
  trackingClient?.clientRequestedInfo({
    request_type: TrackingEventConstants.CLIENT_REQUESTED_PREQUAL,
    customer_type: TrackingEventHelpers.getTrackingCustomerType(customerType)
  })

  try {
    await createBuyerMessage(
      buyerId,
      {
        metadata: null,
        topic: CUSTOMER_MESSAGES.BUYER_PREQUAL_REQUEST.topic,
        topicKey: CUSTOMER_MESSAGES.BUYER_PREQUAL_REQUEST.topicKey,
        message: CUSTOMER_MESSAGES.BUYER_PREQUAL_REQUEST.message.defaultMessage
      },
      trackingClient
    )

    dispatch({ type: SEND_PREQUAL_SUCCESS })
    dispatch(
      saveClientAnswer({
        question: QUESTION_REQUESTED_PREQUAL,
        response: {
          value: true
        }
      })
    )
  } catch (error) {
    Sentry.captureException(error)
    dispatch({
      type: SEND_PREQUAL_FAILURE,
      error
    })
  }
}
