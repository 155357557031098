import React from 'react'
import classnames from 'classnames'
import styles from './SectionWrapper.module.scss'

interface SectionWrapperProps {
  className?: string
  children: React.ReactNode
}

const SectionWrapper = ({ className, children }: SectionWrapperProps) => (
  <section className={classnames(className, styles.container)}>{children}</section>
)

export default SectionWrapper
