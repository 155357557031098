import { SET_FILTER } from '../actions/actionTypes'

const initialState = {
  filter: {
    location: {},
    boundingBox: {},
    minBathroomsCount: null,
    minBedroomsCount: null,
    minPrice: null,
    maxPrice: null,
    minSqft: null,
    maxSqft: null,
    minAcres: null,
    maxAcres: null,
    minYearBuilt: null,
    maxYearBuilt: null,
    propertyTypes: []
  }
}

const filtersReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SET_FILTER: {
      return {
        ...state,
        filter: { ...state.filter, ...payload.filter }
      }
    }
    default:
      return state
  }
}

export default filtersReducer
