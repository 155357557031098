import Button from '../Button'
import Format from '../Format'

export interface PhoneProps {
  value: string
  text?: string
  minimal?: boolean
  className?: string
  iconStart?: React.ElementType
}

const Phone = ({ value, text, className, ...restProps }: PhoneProps) => (
  <Button data-qa='phone' minimal element='a' href={`tel:${value}`} className={className} {...restProps}>
    {text || <Format.Phone value={value} />}
  </Button>
)

export default Phone
