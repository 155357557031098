import { UseMutationOptions, useQueryClient } from 'react-query'
import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import { useChangeFeedLocationMutation } from '../../api/gqlaxy/gql/generated/graphql-hooks'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import { ChangeFeedLocationMutation, ChangeFeedLocationMutationVariables } from '../../api/gqlaxy/gql/generated/graphql'
import { QueryKey } from '../../constants/queryKey'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E, V> extends UseMutationOptions<D, E, V> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}

export const useChangeFeedLocation = (
  option: Option<ChangeFeedLocationMutation, Error, ChangeFeedLocationMutationVariables> = {}
) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // local endpoint
  })

  const { disableErrorPrompts, errorPrompts, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        duration: 4000,
        position: 'top-right'
      })
    },
    [disableErrorPrompts, errorPrompts, options, toast]
  )

  return useChangeFeedLocationMutation(client, {
    onError: handleError,
    onSettled: () => {
      queryClient.invalidateQueries(QueryKey.GetUserFeeds)
      queryClient.invalidateQueries(QueryKey.GetFeed)
    },
    ...options
  })
}
