import { Flex, Image, Link, Text } from '@homebotapp/hb-react-component-catalog'

import eyeClosed from '../../assets/homes/img/eye-entypo.svg'
import { defineMessages, useIntl } from 'react-intl'

const MSG = defineMessages({
  learnMoreAboutThisLink: {
    id: 'Preview.learnMoreAboutGalleries',
    defaultMessage: 'Learn more about Galleries'
  },
  pageNotReady: {
    id: 'Preview.pageNotReady',
    defaultMessage: 'This page isn’t ready for preview mode'
  },
  somePartsOfGalaxy: {
    id: 'Preview.somePartsOfGalaxy',
    defaultMessage: 'We want you to see Homebot like your clients do, but some parts of the galaxy are just for them.'
  }
})

export const NoPreviewModeModal = () => {
  const intl = useIntl()

  return (
    <Flex
      bg='neutral.900'
      position='fixed'
      opacity={1}
      zIndex={1}
      width='100vw'
      height='100vh'
      alignItems='center'
      justifyContent='center'
      backgroundImage='https://assets.homebotapp.com/images/space-man.jpeg'
      backgroundRepeat='no-repeat'
      backgroundSize='100vw 100vh'
    >
      <Flex
        flexDirection='column'
        px='24px'
        py='12px'
        textAlign='center'
        width='400px'
        bg='neutral.50'
        borderRadius='8px'
        alignItems='center'
      >
        <Flex bg='whiteAlpha.200' borderRadius='50%' height='48px' width='48px' mb={2} justifyContent='center'>
          <Image loading='lazy' src={eyeClosed} alt='eye-closed' />
        </Flex>
        <Text mb={2} color='neutral-900' size='17px'>
          {intl.formatMessage(MSG.pageNotReady)}
        </Text>
        <Text color='neutral-700' size='15px'>
          {intl.formatMessage(MSG.somePartsOfGalaxy)}
        </Text>

        <Link
          href='https://help.homebotapp.com/en/articles/9407016-for-you-galleries'
          size='xs'
          target='_blank'
          mb='.125rem'
          tracking={{
            ui_context: 'Unauthorized_Impersonated_User',
            descriptor: `Clicked on Learn More About Galleries`,
            guid: `3GtcZMbe9HLD7YUKKW23094`
          }}
        >
          {intl.formatMessage(MSG.learnMoreAboutThisLink)}
        </Link>
      </Flex>
    </Flex>
  )
}
