import React, { ReactNode, ElementType } from 'react'
import classnames from 'classnames'

import styles from './BaseButton.module.scss'

const defaultProps = {
  role: 'button'
}

export interface IBaseButtonProps {
  className?: string
  children?: ReactNode
  disabled?: boolean
  element?: ElementType // render something other than a 'button' tag, such as an anchor
  dataQa?: string // optional for testing purposes
  role?: string
  onClick?: (args?: any) => void
}

const BaseButton = React.forwardRef(
  ({ className, dataQa, onClick, element: Element = 'button', ...restProps }: IBaseButtonProps, ref) => (
    <Element
      data-qa={dataQa}
      className={classnames(styles.root, className)}
      ref={ref}
      onClick={onClick}
      {...restProps}
    />
  )
)

BaseButton.defaultProps = defaultProps
BaseButton.displayName = 'BaseButton'

export default BaseButton
