import { Icon, Text } from '@homebotapp/hb-react-component-catalog'
import { NavigationTab } from './NavigationTab'
import { defineMessages, useIntl } from 'react-intl'
import { Link as ReactRouterLink } from 'react-router-dom'
import { routeToHome } from '../../../store/selectors/routes'
import { useDispatch, useSelector } from 'react-redux'
import { selectHomeId } from '../../../store/selectors/home'
import { selectClientHomes, selectClientId, selectClientIsGuest } from '../../../store/selectors/client'
import { GlobalTrackingEventLocation } from '../../../constants/tracking'
import { hasCustomerToken } from '../../../auth'
import { toggleAuthModal } from '../../../actions/homeSearchAuthModal'
import { NavigationTabProps } from '../../../types/navigation'

// Todo: Need up update the intl keys from BottomNav to Navigation
const MSG = defineMessages({
  home: {
    id: 'NavigationTab.own',
    defaultMessage: 'Own'
  }
})

export const OwnTab = ({ isSelected, ...tabProps }: NavigationTabProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const clientId = useSelector(selectClientId)
  const isGuest = useSelector(selectClientIsGuest)
  const homes = useSelector(selectClientHomes)
  const homeId = useSelector(selectHomeId)
  const currentSelectedHomeId = homeId ?? homes?.[0]?.id

  const iconName = 'homeowners'

  const buildOwnRoute = () => {
    const clientHasNoHomes = !homes || homes.length === 0
    if (hasCustomerToken() && !homeId && !clientId) {
      const impersonatedClientId = localStorage.getItem('impersonatedClientId')
      const impersonatedClientHomeId = localStorage.getItem('impersonatedClientHomeId')
      const ownRoute = routeToHome(impersonatedClientId, impersonatedClientHomeId)
      return ownRoute
    } else {
      const ownRoute = clientHasNoHomes ? routeToHome(clientId, 'new') : routeToHome(clientId, currentSelectedHomeId)
      return ownRoute
    }
  }

  const handleClick = e => {
    if (isGuest && !hasCustomerToken()) {
      e.preventDefault()
      dispatch(toggleAuthModal(true))
      return
    }
  }

  return (
    <NavigationTab
      {...tabProps}
      as={ReactRouterLink}
      to={buildOwnRoute()}
      onClick={handleClick}
      tracking={{
        guid: 'uPJt1bJYz6sjaS9MAF8B5n',
        ui_context: GlobalTrackingEventLocation.GLOBAL_NAVIGATION_BAR,
        descriptor: MSG.home.defaultMessage
      }}
    >
      <Icon
        name={iconName}
        data-qa={iconName}
        marginRight={['0', null, null, '2']}
        marginBottom={['1', null, null, '0']}
      />
      <Text sx={{ textWrap: 'nowrap' }} size={['3xs', 'sm']} m={0} fontWeight='semibold'>
        {intl.formatMessage(MSG.home)}
      </Text>
    </NavigationTab>
  )
}
