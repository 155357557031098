import React from 'react'
import isUndefined from 'lodash/isUndefined'

const FormatContainer =
  formatter =>
  ({ value, children, ...options }) =>
    isUndefined(children) ? (
      formatter(value, options)
    ) : (
      <>
        {formatter.prefix}
        {children}
        {formatter.suffix}
      </>
    )

export default FormatContainer
