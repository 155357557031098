import React from 'react'
import classnames from 'classnames'
import Text from '../../Text'

import styles from './DashboardHeading.module.scss'

const defaultProps = {
  element: 'h1',
  size: 'xxl',
  weight: 'light'
}

const Heading = ({ className, ...restProps }) => (
  <Text className={classnames(className, styles.root)} variant='neutralCool' variantCount='900' {...restProps} />
)

Heading.defaultProps = defaultProps

export default Heading
