// Helper function to generate a color ramp based on a base color taken in as variant 500 (in the 50-900 scale)
export function generateColorRamp(baseColor, steps = 10) {
  const parsedColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(baseColor)
  const baseRGB = parsedColor
    ? {
        r: parseInt(parsedColor[1], 16),
        g: parseInt(parsedColor[2], 16),
        b: parseInt(parsedColor[3], 16)
      }
    : null
  if (!baseRGB) return null

  // We need to have enough leeway to do the ramp, with the original color being the 500 value,
  //  the darkest color being 50, and the lightest being 900
  // Limits set at 80 & 20 gives us about 10 units darker and lighter before we get so dark/light that the color is unreadable
  // If the lightness is too light or too dark, we'll return null as the "error" state
  const hsl = rgbToHsl(baseRGB.r, baseRGB.g, baseRGB.b)
  if (hsl.l > 80 || hsl.l < 20) return new Array(10).fill(baseColor)

  const ramp = new Array(10)
  ramp[5] = baseColor // base color = 500

  // We need two partial ramps, one for darker and one for lighter - both of which might be different in step size
  // Base color = 500, the lighter scale: 600 - 900 : 4 steps
  let stepSize = (90 - hsl.l) / 4

  for (let i = 1; i <= 4; i++) {
    const offset = stepSize * i
    const lightness = hsl.l + offset
    const color = hslToRgb(hsl.h, hsl.s, lightness)
    ramp[i + 5] = rgbToHex(color.r, color.g, color.b)
  }

  // Base color = 500, darker scale: 50 - 400 : 5 steps
  stepSize = (hsl.l - 10) / 5

  for (let i = 5; i >= 1; i--) {
    const offset = stepSize * i
    const lightness = hsl.l - offset
    const color = hslToRgb(hsl.h, hsl.s, lightness)
    ramp[5 - i] = rgbToHex(color.r, color.g, color.b)
  }

  return ramp
}

// Function to convert RGB to HSL
function rgbToHsl(r, g, b) {
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h,
    s,
    l = (max + min) / 2

  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return { h: h * 360, s: s * 100, l: l * 100 }
}

// Function to convert HSL to RGB
function hslToRgb(h, s, l) {
  h /= 360
  s /= 100
  l /= 100
  let r, g, b

  if (s === 0) {
    r = g = b = l
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) }
}

// Function to convert RGB to hex color code
function rgbToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

// helper function to add a color ramp to the theme for all slots
export function addColorRampToThemeWithFunction(
  func,
  ramp,
  prefix,
  slots = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]
) {
  for (let i = 0; i < slots.length; i++) {
    const slot = slots[i]
    const color = ramp[i]
    func(`${prefix}${slot}`, color)
  }
}

// helper function to remove a color ramp from the theme for all slots
export function removeColorRampFromThemeWithFunction(
  func,
  prefix,
  slots = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]
) {
  for (let i = 0; i < slots.length; i++) {
    const slot = slots[i]
    // the following is not perfect - i wanted to allow for custom removal functions,
    //  but also account for the fact that removeProperty must be bound in order to invoke
    if (document.documentElement?.style?.getPropertyValue?.(`--${prefix}${slot}`)) {
      if (func.name === 'removeProperty') {
        func.bind(document.documentElement.style)(`--${prefix}${slot}`)
      } else {
        func(`${prefix}${slot}`)
      }
    }
  }
}
