import defineIcon from './defineIcon'

export const IconBotHeadCircle = defineIcon('icon-bot-head-circle')
export const IconCommentingFa = defineIcon('icon-commenting-fa')
export const IconCopy = defineIcon('icon-copy')
export const IconDollar = defineIcon('icon-dollar')
export const IconEllipsisH = defineIcon('icon-ellipsis-h')
export const IconFilter = defineIcon('icon-filter')
export const IconFire = defineIcon('icon-fire')
export const IconHome = defineIcon('icon-home')
export const IconHomeAdd = defineIcon('icon-home-add')
export const IconHomeFav = defineIcon('icon-home-fav')
export const IconLaptop = defineIcon('icon-laptop')
export const IconLinkedIn = defineIcon('icon-linkedin')
export const IconMapMarker = defineIcon('icon-map-marker')
export const IconMapMarkerFilled = defineIcon('icon-map-marker-mdc')
export const IconMobile = defineIcon('icon-mobile')
export const IconPaperPlane = defineIcon('icon-paper-plane')
export const IconPlus = defineIcon('icon-plus')
export const IconPlusBTB = defineIcon('icon-plus-btb')
export const IconRecordVideo = defineIcon('icon-record-video')
export const IconTimes = defineIcon('icon-times')
export const IconTimesCircle = defineIcon('icon-times-circle')
export const IconTruck = defineIcon('icon-truck')
export const IconPencil = defineIcon('icon-pencil')
export const IconPhone = defineIcon('icon-phone')
export const IconStarBts = defineIcon('icon-star-bts')
export const IconCheck = defineIcon('icon-check')
export const IconCheckCircle = defineIcon('icon-check-circle')
export const IconAngleDown = defineIcon('icon-angle-down')
export const IconAngleLeft = defineIcon('icon-angle-left')
export const IconAngleRight = defineIcon('icon-angle-right')
export const IconCondo = defineIcon('icon-condo')
export const IconExternalLink = defineIcon('icon-external-link')
export const IconExclamationTriangle = defineIcon('icon-exclamation-triangle')
export const IconInfoCircleFa = defineIcon('icon-info-circle-fa')
export const IconInfoCircle = defineIcon('icon-info-circle')
export const IconQuestionCircle = defineIcon('icon-question-circle')
export const IconEquals = defineIcon('icon-equals')
export const IconMinus = defineIcon('icon-minus')
export const IconMinusBTB = defineIcon('icon-minus-btb')
export const IconTilde = defineIcon('icon-tilde')
export const IconBell = defineIcon('icon-bell')
export const IconBellFilled = defineIcon('icon-bell-bts')
export const IconEmergency = defineIcon('icon-emergency')
export const IconHomeImprovement = defineIcon('icon-homeimprovement')
export const IconAda = defineIcon('icon-ada')
export const IconWallet = defineIcon('icon-wallet')
export const IconGraduationCap = defineIcon('icon-graduation-cap')
export const IconSolar = defineIcon('icon-solar')
export const IconPlane = defineIcon('icon-plane')
export const IconTradeUp = defineIcon('icon-tradeup')
export const IconRentBuy = defineIcon('icon-rentbuy')
export const IconSelling = defineIcon('icon-selling')
export const IconSellPocket = defineIcon('icon-sellpocket')
export const IconRentalHome = defineIcon('icon-rentalhome')
export const IconHexagon = defineIcon('icon-hexagon')
export const IconBuoy = defineIcon('icon-buoy')
export const IconLock = defineIcon('icon-lock')
export const IconSingleUser = defineIcon('icon-single-user')
export const IconHeart = defineIcon('icon-heart')
export const IconHeartEmpty = defineIcon('icon-heart-empty')
export const IconSearch = defineIcon('icon-search')
export const IconWrench = defineIcon('icon-wrench-filled')
export const IconThumbsUp = defineIcon('icon-thumbs-up')
export const IconThumbsDown = defineIcon('icon-thumbs-down')
export const IconArrowLeft = defineIcon('icon-long-arrow-left')
export const IconArrowRight = defineIcon('icon-long-arrow-right')
export const IconArrowRightBig = defineIcon('icon-arrow-right')
export const IconArrowLeftBig = defineIcon('icon-arrow-left')
export const IconBathtub = defineIcon('icon-bathtub')
export const IconSink = defineIcon('icon-sink')
export const IconShareArrow = defineIcon('icon-share-arrow')
export const IconShower = defineIcon('icon-shower')
export const IconToilet = defineIcon('icon-toilet')
export const IconLink = defineIcon('icon-link')
export const IconFacebook = defineIcon('icon-facebook-official')
export const IconComment = defineIcon('icon-comment-text-outline')
export const IconCommentLeft = defineIcon('icon-comment-text-outline')
export const IconCommentRight = defineIcon('icon-comment-text-outline', true)
export const IconPlayCircle = defineIcon('icon-play-circle-outline')
export const IconEnvelope = defineIcon('icon-envelope')
export const IconBolt = defineIcon('icon-bolt')
export const IconImageInverted = defineIcon('icon-image-inverted')
export const IconVideoCamera = defineIcon('icon-video-camera')
export const IconEye = defineIcon('icon-eye')
export const IconEyeHidden = defineIcon('icon-eye-entypo')
