import sortBy from 'lodash/sortBy'
import moment from 'moment'
import Constants from './constants'

const Helpers = {
  getAppreciationPercent: function (avm: number, originalSoldPrice: number) {
    return Math.round(((avm - originalSoldPrice) / originalSoldPrice) * 100)
  },

  sortByDate: function (arr: any[], date_field: string) {
    return sortBy(arr, function (obj) {
      if (!obj[date_field]) return -1
      return -Helpers.formatDateForSort(obj[date_field])
    })
  },

  formatDateForSort: function (date: Date): string {
    return moment(date).format('YYYYMMDD')
  },

  isFalsey: function (val: any): boolean {
    // useful for parsing angular vals as true/false
    if (!val || parseInt(val) === 0 || val === 'false' || val === 'undefined') return true
    else return false
  },

  calcPeriods: function (years: number, loanStartDate: Date, loanEndDate: Date): number {
    if (loanStartDate) {
      const date = moment(loanStartDate)
      if (loanEndDate) return years * 12 + date.diff(moment(new Date(loanEndDate)), 'months')
      else return years * 12 + date.diff(moment(), 'months')
    } else {
      return years * 12
    }
  },

  calcMaturityDate: function (years: number, soldDate: Date): string {
    return moment(soldDate).add(years, 'years').format('YYYY-MM-DD')
  },

  normalizeArrayFor30YearLoan: function (loanSchedule: number[]): number[] {
    const size = loanSchedule.length

    if (size < Constants.monthsIn30TermLoan) {
      const arrayPadding = Constants.monthsIn30TermLoan - size
      return loanSchedule.concat(Array(arrayPadding).fill(Constants.noInterestPaid))
    }

    return loanSchedule
  }
}

export default Helpers
