import produce from 'immer'

import {
  SET_CUSTOMER_PROFILE,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_REQUEST,
  FETCH_CUSTOMER_PROFILE_FAILURE,
  SET_CLIENT_FETCH_ID
} from '../actions/actionTypes'

export const initialState = {
  data: undefined,
  sponsorProfile: undefined,
  loading: true,
  error: null
}

export default (state = initialState, { type, data, loading, error }) =>
  produce(state, draft => {
    switch (type) {
      case SET_CLIENT_FETCH_ID:
        return {
          ...initialState
        }

      case SET_CUSTOMER_PROFILE:
        draft.data = data
        draft.loading = loading
        break

      case FETCH_CUSTOMER_PROFILE_SUCCESS:
        draft.data = data
        draft.loading = false
        draft.error = null
        break

      case FETCH_CUSTOMER_PROFILE_REQUEST:
        draft.error = null
        break

      case FETCH_CUSTOMER_PROFILE_FAILURE:
        draft.error = error
        draft.loading = false
        break

      default:
        break
    }
  })
