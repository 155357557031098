import { ReactNode } from 'react'
import classnames from 'classnames'
import styles from './Dashboard.module.scss'

interface DashboardProps {
  className?: string
  onClick?: () => void
  onFocus?: () => void
  'data-qa'?: string
  children?: ReactNode
}

export const Dashboard = (props: DashboardProps) => {
  return (
    <div
      className={classnames(props.className, styles.dashboard)}
      onClick={props.onClick}
      data-qa={props['data-qa']}
      onFocus={props.onFocus}
    >
      {props.children}
    </div>
  )
}
