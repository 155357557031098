import React from 'react'

// Components
import Svg from '../Components/shared/Svg'

const CheckMarkSvg = props => (
  <Svg viewBox='0 0 14 11' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.589 2.629L5.392 9.793c-.302.3-.699.45-1.095.45-.395 0-.791-.15-1.093-.45L.454 7.055a1.534 1.534 0 010-2.178 1.552 1.552 0 012.187 0l1.656 1.65L10.401.45a1.552 1.552 0 012.188 0 1.535 1.535 0 010 2.178z'
      fill='var(--brand-success-700)'
    />
  </Svg>
)

export default CheckMarkSvg
