import get from 'lodash/get'
import { connect } from 'react-redux'
import { branch, compose, renderNothing } from 'recompose'

const ModalContainer = compose(
  connect((store, { open, openKey }) => ({
    open: openKey != null ? get(store, openKey) : open
  })),
  branch(({ open }) => !open, renderNothing)
)

export default ModalContainer
