/* eslint-disable */
import map from 'lodash/map'
import pick from 'lodash/pick'
import sortBy from 'lodash/sortBy'
import cloneDeep from 'lodash/cloneDeep'
import each from 'lodash/each'
import filter from 'lodash/filter'

import moment from 'moment'
import numeral from 'numeral'
import { LOAN_TYPE } from '../../constants/loan'

/**
 * Helpers
 * This is a temporary copy until we can get a centralized package in place
 * Imports and exports are rewritten for buyers, but the body should be strict
 * copy-paste
 */

let GlobalHelpers = {
  abbreviateMoney: number => {
    if (
      (number > 100000 && number < 999999) ||
      (number > 100000000 && number < 999999999) ||
      (number > 100000000000 && number < 999999999999)
    ) {
      return numeral(number).format('$0a')
    }

    let formatted = numeral(number).format('$0.0a')

    return formatted.replace('.0', '')
  },

  dateFormat: 'YYYY-MM-DD',

  cleanUndefinedValues: function (obj) {
    return pick(obj, function (val) {
      return val !== undefined
    })
  },

  clientNameDisplay: function (users) {
    if (!users || !users.length) return ''

    if (users.length === 1)
      return `${users[0].last_name}, ${users[0].first_name}`

    if (users[0].last_name === users[1].last_name)
      return `${users[0].last_name}, ${users[0].first_name} & ${
        users[1].first_name
      }`
    else
      return `${users[0].last_name}, ${users[0].first_name} & ${
        users[1].last_name
      }, ${users[1].first_name}`
  },

  everyNth: function (arr, n) {
    if (n === 0) return arr

    return filter(arr, function (i, v) {
      return v % n === 0
    })
  },

  repeatArray: function (val, len) {
    var a = []
    for (var i = 0; i < len; i++) {
      a.push(val)
    }
    return a
  },

  getFloatFromString: function (str) {
    str = str || ''
    if (typeof str === 'number') return str

    str = str.replace(/[^0-9.]/g, '')

    try {
      return parseFloat(str) || null
    } catch (e) {
      throw 'Error parsing float from string.'
    }
  },

  getIntFromString: function (str) {
    str = str || ''

    if (typeof str === 'number') return str

    str = str.replace(/,/g, '')

    try {
      return parseInt(str.match(/\d+/)[0], 10) || null
    } catch (e) {
      throw 'Error parsing int from string.'
    }
  },

  sortByDate: function (arr, date_field) {
    return sortBy(arr, function (obj) {
      if (!obj[date_field]) return -1
      return -GlobalHelpers.formatDateForSort(obj[date_field])
    })
  },

  currentLoanSort: function (loan) {
    if (loan.loanType === LOAN_TYPE.FIRST_CEL) return -40
    else if (loan.loanType === LOAN_TYPE.SECOND_CEL) return -30
    else if (loan.loanType === LOAN_TYPE.THIRD_CEL) return -20

    return -10
  },

  sortByLoanType: function (loans) {
    var sortedLoans = cloneDeep(loans)
    sortedLoans = GlobalHelpers.sortByDate(sortedLoans, 'date')
    return sortBy(sortedLoans, GlobalHelpers.currentLoanSort)
  },

  formatCurrency: function (val) {
    var val = parseFloat(val)
    return Math.round(val * 100) / 100
  },

  formatDateForSort: function (date) {
    return moment(date).format('YYYYMMDD')
  },

  formatPhone: function (number, dashes) {
    number = number || ''
    var str = number.toString().replace(/[^0-9]/g, '')

    if (!str || str.length < 9) return ''

    if (!dashes) return parseInt(str, 10)

    if (str.slice(0, 1) === '1') str = str.slice(1)

    return str.slice(0, 3) + '-' + str.slice(3, 6) + '-' + str.slice(6)
  },

  homesFilter: function (home, query) {
    var str = ''
    query = query || ''
    query = query.toLowerCase().trim()

    if (!query || query.length < 2) return true

    each(home.users, function (user) {
      str += user.first_name + ' ' + user.last_name
    })

    str += ' ' + home.address.street + ' ' + home.address.city

    return str.toLowerCase().indexOf(query) !== -1
  },

  homesUnsentFilter: function (home) {
    var thirtyDaysAgo = moment()
      .subtract(30, 'days')
      .format('X')

    function where (event) {
      if (
        !event ||
        event.source !== 'mandrill' ||
        event.event !== 'sent' ||
        moment(event.created).format('X') < thirtyDaysAgo
      )
        return false
      else return true
    }

    if (filter(home.activity, where).length < 1) return true
    else return false
  },

  homesNeedsCMAFilter: function (home) {
    return home.hasRequestedCma && !home.agentAVM
  },

  clientsFilter: function (client, query) {
    var str = ''
    query = query || ''
    query = query.toLowerCase().trim()

    if (!query || query.length < 2) return true

    if (client.homes && client.homes.length > 0) {
      each(client.homes, function (home) {
        str += ' ' + home.address.street + ' ' + home.address.city
      })
    }

    str += ' ' + client.first_name + ' ' + client.last_name

    return str.toLowerCase().indexOf(query) !== -1
  },

  isActiveFilter: function (event) {
    return (
      event.event === 'view' ||
      event.event === 'click' ||
      event.event === 'open'
    )
  },

  isFalsey: function (val) {
    // useful for parsing angular vals as true/false
    if (!val || parseInt(val) === 0 || val === 'false' || val === 'undefined')
      return true
    else return false
  },

  importFilter: function (user, query) {
    var str = ''
    query = query || ''
    query = query.toLowerCase().trim()

    if (!query || query.length < 2) return true

    if (user.street && user.city) {
      str += ' ' + user.street + ' ' + user.city
    }

    str += ' ' + user.first_name + ' ' + user.last_name

    return str.toLowerCase().indexOf(query) !== -1
  },

  parseAPIError: function (response) {
    if (
      response &&
      response.data &&
      response.data.error &&
      response.data.error.message
    )
      return response.data.error.message
    else if (
      response &&
      response.data &&
      response.data.error &&
      response.data.error.details &&
      response.data.error.details.messages
    )
      return map(response.data.error.details.messages, function (message, i) {
        return message[0]
      })
    else return 'An error has occurred.'
  },

  calcPeriods: function (years, loanStartDate, loanEndDate) {
    if (loanStartDate) {
      var date = moment(loanStartDate)
      if (loanEndDate)
        return years * 12 + date.diff(moment(new Date(loanEndDate)), 'months')
      else return years * 12 + date.diff(moment(), 'months')
    } else {
      return years * 12
    }
  },

  getRateLookupType: function (years, isAdjustable) {
    if (years === 30 && !isAdjustable) return 'FIX30YR'
    else if (years === 15 && !isAdjustable) return 'FIX15YR'
    else if (years === 5 && isAdjustable) return 'ARM5YR'
    else if (years === 1 && isAdjustable) return 'ARM1YR'

    return 'FIX30YR'
  },

  sortRealEstateAgents: function (agents) {
    return agents.sort(function (agent1, agent2) {
      var agent1count = agent1.homes ? agent1.homes.length : 0
      var agent2count = agent2.homes ? agent2.homes.length : 0

      // sort by number of homes
      if (agent1count < agent2count) return 1
      else if (agent1count > agent2count) return -1

      // then by first_name
      if (agent1.first_name < agent2.first_name) return -1
      else if (agent1.first_name > agent2.first_name) return 1
      // nothing to split them
      else return 0
    })
  },

  calcMaturityDate: function (years, soldDate) {
    return moment(soldDate)
      .add(years, 'years')
      .format('YYYY-MM-DD')
  },

  getEmailMatchRegex: function (email) {
    var pattern = GlobalHelpers.escapeStringForRegex(email)
    return new RegExp(
      '.*^' + pattern + '$.*',
      'i'
    ) /* case-insensitive RegExp search */
  },

  escapeStringForRegex: function (string) {
    // escapes special characters
    // $& means the whole matched string
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  },

  sortUsers: function (users) {
    return users.sort((a, b) => {
      return new Date(a.created) - new Date(b.created)
    })
  }
}

// RESUME rewritten export statement for buyers

export default GlobalHelpers
