import { useMediaQuery } from 'react-responsive'

export const SMALL_SCREEN = 420
export const MEDIUM_SCREEN = 640
export const LARGE_SCREEN = 860

const useViewport = () => {
  const isSmall = useMediaQuery({ query: `(max-width: ${SMALL_SCREEN}px)` })
  const isMedium = useMediaQuery({ query: `(max-width: ${MEDIUM_SCREEN}px)` })
  const isLarge = !isSmall && !isMedium

  const isSmallOrAbove = useMediaQuery({
    query: `(min-width: ${SMALL_SCREEN}px)`
  })
  const isMediumOrAbove = useMediaQuery({
    query: `(min-width: ${MEDIUM_SCREEN}px)`
  })
  const isLargeOrAbove = useMediaQuery({
    query: `(min-width: ${LARGE_SCREEN}px)`
  })

  return {
    isSmall,
    isMedium,
    isLarge,
    isSmallOrAbove,
    isMediumOrAbove,
    isLargeOrAbove
  }
}

export default useViewport
