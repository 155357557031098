import { Finance } from '../quartermaster/src'
import compact from 'lodash/compact'
import { equitySearchTable } from '../constants/equity'

const sumM = (array1: number[], array2: number[]): number[] => array1.map((value, index) => array2[index] + value)

export const sumSquareMatrix = (matrix: number[][]) => matrix.reduce(sumM)

interface Rate {
  term: number
  standardizedRate: number
}

export const generateMortgagePaymentDifference = ({
  rate,
  loanBalance,
  currentLoanPayment
}: {
  rate: Rate
  loanBalance: number
  currentLoanPayment: number
}) => {
  const costs = Finance.calculateLoanFees(loanBalance)
  const newLoanPayment = Finance.calculatePayment(loanBalance + costs, rate.term * 12, rate.standardizedRate)

  return Math.round(newLoanPayment - currentLoanPayment)
}

export const generateQualifiedListings = ({
  currentLoanPayment,
  equity,
  homeValue,
  listings,
  thirtyYearAvgRate
}: {
  currentLoanPayment?: number
  equity?: number
  homeValue?: number
  listings?: any[]
  thirtyYearAvgRate?: Rate
} = {}) => {
  const emptyListings: any[] = []

  if (!currentLoanPayment || !equity || !homeValue || !listings || !thirtyYearAvgRate) {
    return emptyListings
  }

  if (!sellingNetsMinAmt({ homeValue, equity })) {
    return emptyListings
  }

  const qualifiedListings = listings.map((listing, i) => {
    // Initial Values
    const cashAfterSale = cashAfterSelling({ homeValue, equity })
    let pocketAmount = pocketedCashAmount({
      priceOfNewHouse: listing.price,
      equity,
      homeValue,
      percentDown: 0
    })
    let downPayment = calculateDownPayment({
      price: listing.price,
      percentDown: equitySearchTable.TWENTY_PERCENT_DOWN
    })
    let loanBalanceOnNewHome = calculateNewHomeloanBalance({
      newHomePrice: listing.price,
      downPaymentAmount: downPayment
    })
    let mortgagePaymentDifference = generateMortgagePaymentDifference({
      rate: thirtyYearAvgRate,
      loanBalance: loanBalanceOnNewHome,
      currentLoanPayment
    })
    const newLoanPayment = Math.round(currentLoanPayment + mortgagePaymentDifference)
    let percentDown = equitySearchTable.TWENTY_PERCENT_DOWN

    const ninetyFivePercentOfCurrentValue = calculateNinetyFivePercentOfHomeValue({
      homeValue
    })

    if (listing.price > ninetyFivePercentOfCurrentValue) {
      return null
    }

    if (pocketAmount < equitySearchTable.MINIMUM_POCKETED_CASH) {
      return null
    }

    if (
      newPaymentIsGreaterThanExistingByPercent({
        newLoanPayment,
        currentLoanPayment
      })
    ) {
      const halfPocketAmt = pocketAmount * equitySearchTable.MAX_PAYMENT_INCREASE

      if (halfPocketAmt < equitySearchTable.MINIMUM_POCKETED_CASH) {
        return null
      }

      // Overrides
      downPayment = Math.round(downPayment + halfPocketAmt)
      loanBalanceOnNewHome = Math.round(listing.price - downPayment)
      percentDown = downPayment / listing.price
      pocketAmount = Math.round(halfPocketAmt)
      mortgagePaymentDifference = generateMortgagePaymentDifference({
        rate: thirtyYearAvgRate,
        loanBalance: loanBalanceOnNewHome,
        currentLoanPayment
      })
    }

    return {
      cashAfterSelling: cashAfterSale,
      downPayment,
      loanBalance: loanBalanceOnNewHome,
      mortgagePaymentDifference,
      percentDown,
      pocketAmount,
      purchasePrice: listing.price,
      ...listing
    }
  })

  const filteredListings = [...emptyListings, ...qualifiedListings]

  return compact(filteredListings)
}

export const calculateNinetyFivePercentOfHomeValue = ({ homeValue }: { homeValue: number }) => {
  const percentOfHomeValue = 0.05

  return Math.round(homeValue - homeValue * percentOfHomeValue)
}

export const calculateDownPayment = ({ price, percentDown }: { price: number; percentDown: number }) =>
  Math.round(price * percentDown)

export const calculateNewHomeloanBalance = ({
  newHomePrice,
  downPaymentAmount
}: {
  newHomePrice: number
  downPaymentAmount: number
}) => Math.round(newHomePrice - downPaymentAmount)

export const calculateSellingFees = (homeValue: number) => {
  const AVERAGE_SELLING_FEES = 0.07
  return Math.round(homeValue * AVERAGE_SELLING_FEES)
}

export const cashAfterSelling = ({ equity, homeValue }: { equity?: number; homeValue?: number } = {}) => {
  const sellingFees = homeValue ? calculateSellingFees(homeValue) : 0
  return Math.round((equity || 0) - sellingFees)
}

export const pocketedCashAmount = (
  {
    priceOfNewHouse,
    equity,
    homeValue,
    percentDown
  }: {
    priceOfNewHouse: number
    equity?: number
    homeValue?: number
    percentDown?: number
  } = { priceOfNewHouse: 0 }
) => {
  const downPaymentOnNewHome = priceOfNewHouse * (percentDown || 0.2)
  return Math.round(cashAfterSelling({ equity, homeValue }) - downPaymentOnNewHome)
}

export const sellingNetsMinAmt = ({
  equity,
  homeValue,
  requiredPocketAmt
}: {
  equity?: number
  homeValue?: number
  requiredPocketAmt?: number
} = {}) => cashAfterSelling({ equity, homeValue }) >= (requiredPocketAmt || equitySearchTable.MINIMUM_POCKETED_CASH)

export const purchasePriceIsReasonableForMarket = ({
  purchasePrice,
  cheapestPriceInMarket
}: {
  purchasePrice?: number
  cheapestPriceInMarket?: number
} = {}) => (purchasePrice && cheapestPriceInMarket ? purchasePrice >= cheapestPriceInMarket : false)

export const newPaymentIsGreaterThanExistingByPercent = ({
  newLoanPayment,
  currentLoanPayment,
  percent
}: {
  newLoanPayment?: number
  currentLoanPayment?: number
  percent?: number
} = {}) =>
  ((newLoanPayment || 0) - (currentLoanPayment || 0)) / (currentLoanPayment || 0) >=
  (percent || equitySearchTable.MAX_PAYMENT_INCREASE)

export const generatePriceBrackets = ({
  cheapestMarket,
  currentLoanPayment,
  equity,
  homeValue,
  initialPriceStepDownAmt,
  initialPurchasePrice,
  thirtyYearFixedRate
}: {
  cheapestMarket?: {
    priceMin: number
  }
  currentLoanPayment?: number
  equity?: number
  homeValue?: number
  initialPriceStepDownAmt?: number
  initialPurchasePrice?: number
  thirtyYearFixedRate?: Rate
} = {}) => {
  let index = 0
  let priceStepDown = initialPriceStepDownAmt || 0
  let nextPurchasePrice = initialPurchasePrice || 0
  let increaseDownPayment = false
  const stepDownBrackets: any[] = []

  if (
    !cheapestMarket ||
    !currentLoanPayment ||
    !equity ||
    !homeValue ||
    !initialPriceStepDownAmt ||
    !initialPurchasePrice ||
    !thirtyYearFixedRate
  ) {
    return stepDownBrackets
  }

  if (!sellingNetsMinAmt({ equity, homeValue })) {
    return stepDownBrackets
  }

  while (
    purchasePriceIsReasonableForMarket({
      purchasePrice: nextPurchasePrice,
      cheapestPriceInMarket: cheapestMarket.priceMin
    }) &&
    index <= 2
  ) {
    // Initial setting of values
    let downPayment = Math.round(nextPurchasePrice * equitySearchTable.TWENTY_PERCENT_DOWN)
    let loanBalance = Math.round(nextPurchasePrice - downPayment)
    let mortgagePaymentDifference = generateMortgagePaymentDifference({
      rate: thirtyYearFixedRate,
      loanBalance,
      currentLoanPayment
    })
    let percentDown = downPayment / nextPurchasePrice
    let pocketAmount = pocketedCashAmount({
      priceOfNewHouse: nextPurchasePrice,
      equity,
      homeValue
    })

    const newLoanPayment = Math.round(currentLoanPayment + mortgagePaymentDifference)
    const cashAfterSale = cashAfterSelling({ homeValue, equity })

    if (
      newPaymentIsGreaterThanExistingByPercent({
        newLoanPayment,
        currentLoanPayment
      }) ||
      increaseDownPayment
    ) {
      increaseDownPayment = true
      const halfPocketAmt = pocketAmount * equitySearchTable.MAX_PAYMENT_INCREASE

      if (halfPocketAmt < equitySearchTable.MINIMUM_POCKETED_CASH) {
        break
      }

      // Overrides
      downPayment = Math.round(downPayment + halfPocketAmt)
      loanBalance = Math.round(nextPurchasePrice - downPayment)
      percentDown = downPayment / nextPurchasePrice
      pocketAmount = Math.round(halfPocketAmt)

      if (loanBalance <= 0) {
        break
      }

      mortgagePaymentDifference = generateMortgagePaymentDifference({
        rate: thirtyYearFixedRate,
        loanBalance,
        currentLoanPayment
      })
    } else {
      pocketAmount = pocketedCashAmount({
        priceOfNewHouse: nextPurchasePrice,
        equity,
        homeValue
      })
    }

    const bracket = {
      cashAfterSelling: cashAfterSale,
      downPayment,
      homeValue,
      loanBalance,
      mortgagePaymentDifference,
      percentDown,
      pocketAmount,
      purchasePrice: nextPurchasePrice
    }

    stepDownBrackets.push(bracket)

    priceStepDown *= index + 1
    nextPurchasePrice = initialPurchasePrice - priceStepDown
    index += 1
  }

  return stepDownBrackets
}
