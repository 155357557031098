import React from 'react'
import PropTypes from 'prop-types'
import Format from '../Format'
import NumberInput from './NumberInput'
import { validateInput } from '../../../helpers/input'

const propTypes = {
  className: PropTypes.string,
  conditions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  parse: PropTypes.func
}

const DollarInput = ({ onChange, conditions, parse, ...restProps }) => {
  return (
    <NumberInput
      decimalScale={2}
      allowNegative={false}
      prefix='$'
      thousandSeparator
      onChange={e => {
        const [, value] = validateInput(e.target, conditions, parse)
        if (onChange) onChange(e, value)
      }}
      {...restProps}
    />
  )
}

DollarInput.propTypes = propTypes
DollarInput.parseValue = unsafeValue => {
  const unparsedValue = unsafeValue.replace(/[$,]/g, '')
  const value = parseFloat(unparsedValue, 10)
  return value
}
DollarInput.maxCondition = max => ({
  message: `Value must be less than or equal to ${Format.currency(max)}`,
  validate: value => value <= max
})
DollarInput.minCondition = min => ({
  message: `Value must be greater than or equal to ${Format.currency(min)}`,
  validate: value => value >= min
})
export default DollarInput
