import numeral from 'numeral'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

export const dasherize = function (str) {
  return str.replace(/[A-Z](?:(?=[^A-Z])|[A-Z]*(?=[A-Z][^A-Z]|$))/g, (s, i) => (i > 0 ? '-' : '') + s.toLowerCase())
}

export const buildUrlParamObj = function (url, paramChar) {
  if (!url.includes(paramChar)) {
    throw Error(
      `The ${paramChar} paramChar does not exist in the Url. Please choose a character that indicates the beginning of the param queries.`
    )
  }

  const query = url.substr(url.indexOf(paramChar) + 1)
  const queryParams = query.split('&')
  const paramsObj = {}

  queryParams.forEach(p => {
    const param = p.split('=')

    if (param.length === 2) {
      paramsObj[param[0]] = param[1]
    } else if (param.length === 1) {
      paramsObj[param[0]] = true
    } else if (!param.length) {
      return false
    }
  })

  return paramsObj
}

const NUMERAL_FORMAT = ['0a', '0.00a', '0.0a']

export const getAbbreviatedNumeralFormat = arg => {
  const { length } = String(arg)

  if (length < 4) {
    return '0'
  }

  return NUMERAL_FORMAT[length % NUMERAL_FORMAT.length]
}

export const getSplitNumeralFormat = arg => {
  const format = getAbbreviatedNumeralFormat(arg)

  if (format === '0') {
    return [arg, '']
  }

  const numeralized = numeral(arg).format(format)

  return [parseFloat(numeralized.slice(0, -1)), numeralized.slice(-1)]
}

export const defaultToPx = w => {
  if (typeof w === 'string' && w.match(/^\d+[%a-z]+$/i)) return w

  const val = parseFloat(w, 10)
    .toFixed(3)
    .replace(/\.*0+$/, '')
  if (!isNaN(val)) return `${val}px`
  return null
}

export const roundToNearestThousand = value => Math.round(value / 1000) * 1000

export const roundToNearestTenThousand = value => Math.round(value / 10000) * 10000

export const camelizeKeys = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v))
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key])
      }),
      {}
    )
  }
  return obj
}

export const kebablizeCamelCasedString = str => {
  return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

export const snakeCaseStrings = str => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .replace(/-{1,}/g, '_')
    .toLowerCase()
}

export const snakeCaseKeys = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => snakeCaseKeys(v))
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: snakeCaseKeys(obj[key])
      }),
      {}
    )
  }
  return obj
}

export const formatNumValue = val => {
  return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
