import {
  FETCH_BRANDING_STATS_REQUEST,
  FETCH_BRANDING_STATS_SUCCESS,
  FETCH_BRANDING_STATS_FAILURE
} from '../actions/actionTypes'

export const initialState = {
  data: null,
  loading: true,
  error: null
}

export default (state = initialState, action) => {
  const { error, data, type } = action
  switch (type) {
    case FETCH_BRANDING_STATS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_BRANDING_STATS_SUCCESS:
      return {
        ...state,
        data,
        loading: false
      }

    case FETCH_BRANDING_STATS_FAILURE:
      return {
        ...state,
        error: error,
        loading: false
      }
    default:
      return state
  }
}
