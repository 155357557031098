export const ADD_SPACE = 'ADD_SPACE'
export const NEW_HOME = 'NEW_HOME'
export const ELIMINATE_CREDIT_CARD_DEBT = 'ELIMINATE_CREDIT_CARD_DEBT'
export const EMERGENCY_RESERVE = 'EMERGENCY_RESERVE'
export const EUROTRIP = 'EUROTRIP'
export const EXPLORE_INVESTMENTS = 'EXPLORE_INVESTMENTS'
export const HOME_IMPROVEMENTS = 'HOME_IMPROVEMENTS'
export const RENTAL_PROPERTY = 'RENTAL_PROPERTY'
export const RENT_BUY = 'RENT_BUY'
export const SELL_POCKET = 'SELL_POCKET'
export const SOLAR_PANELS = 'SOLAR_PANELS'
export const STUDENT_DEBT = 'STUDENT_DEBT'
