import React, { useEffect, useRef } from 'react'
import { validateInput } from '../../../helpers/input'
import PropTypes from 'prop-types'

const getNameConditionsParseFromFieldDescription = fieldDescription =>
  typeof fieldDescription === 'string' ? { name: fieldDescription } : fieldDescription

/**
 * @function ElementByNameGetter
 * @param {string} name - the name of the input
 * @returns {Node} - the dom element with that name
 /*

 /**
 * Condition for validating an input
 *
 * @typedef {string | Object} FieldDescription
 * @property {string} name - alerts the user to the error of thier ways
 * @property {Condition[]} conditions - function determines value validity
 * @param {Transform} [parse] - optional transform to run on raw value before passing to condition validate. Use to avoid common transforms in each condition
 */

/**
 * validate list of inputs with their default and custom conditions. Useful in onSubmit and initial form mount
 *
 * @param {ElementByNameGetter} elByName - function that returns the element
 * @param {FieldDescription[]} inputs - the inputs in the form
 * @returns {Object} ({[name]: value}) - form data
 */
export const validateForm = (elByName, inputs) =>
  inputs.reduce((acc, input) => {
    const { name, conditions, parse } = getNameConditionsParseFromFieldDescription(input)
    const el = elByName(name)
    if (el.forEach && el.length > 1) {
      el.forEach(element => {
        if (element.value === el.value) {
          acc[name] = element.value
        }
      })
    } else {
      const [, value] = validateInput(el, conditions, parse)
      acc[name] = value
    }

    return acc
  }, {})

const Condition = PropTypes.shape({
  message: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired
})

const FormFieldDescription = PropTypes.shape({
  name: PropTypes.string.isRequired,
  conditions: PropTypes.arrayOf(Condition),
  parse: PropTypes.func
})

const propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.oneOfType([FormFieldDescription, PropTypes.string])),
  onSubmit: PropTypes.func.isRequired
}

const Form = ({ fields, onSubmit, ...restProps }) => {
  const root = useRef(null)

  useEffect(() => {
    if (fields) {
      validateForm(
        name =>
          root.current.querySelector(`input[name="${name}"]`) || root.current.querySelector(`select[name="${name}"]`),
        fields
      )
    }
  }, [fields])

  return (
    <form
      ref={root}
      onSubmit={e => {
        let data

        if (fields) {
          data = validateForm(name => e.target.elements[name], fields)
        }

        onSubmit(e, data)
      }}
      {...restProps}
    />
  )
}

Form.propTypes = propTypes

export default Form
