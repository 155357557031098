import { createSelector, createStructuredSelector } from 'reselect'
import every from 'lodash/every'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import { selectLatestMortgageRates, selectMortgageLoadingState } from './mortgageRate'
import { selectNewestPrequalAmount, selectHasPrequal } from './prequal'
import { isValidTerm } from './home/loans'
import { DEFAULT_LOAN_TERM } from '../../constants/loan'
import { METHODS } from '../../constants/affordability'
import {
  selectLoading as selectBuyerLoading,
  selectIncome as selectBuyerIncome,
  selectDebt as selectBuyerDebt,
  selectLoanTerm as selectBuyerLoanTerm,
  selectDownPayment as selectBuyerDownPayment,
  selectAffordability as selectBuyerAffordability,
  selectAffordabilityMethod as selectBuyerAffordabilityMethod
} from './buyerInfo'
import { selectHasRequestedPrequal } from './client'
import { selectNewestPrequal, selectPrequalifications } from './prequal'

export const selectLoading = createSelector(
  selectBuyerLoading,
  selectMortgageLoadingState,
  (buyer, rates) => buyer || rates
)

export const selectStateBranch = state => state.affordability

export const selectFromAffordability = path => createSelector(selectStateBranch, partialRight(get, path))

export const selectFromAffordabilityWithDefault = (path, defaultSelector) =>
  createSelector(selectFromAffordability(path), defaultSelector, (mine, selected) =>
    mine === undefined ? selected : mine
  )

export const calcPV = (annualInterestRate, nper, pmt) =>
  Math.round((pmt / annualInterestRate) * (1 - Math.pow(1 + annualInterestRate, -nper)))

export const calcMonthlyPayment = (annualIncome, debt) => {
  const monthlyIncome = annualIncome / 12
  const monthly28 = 0.28 * monthlyIncome
  const monthly38 = 0.38 * monthlyIncome - debt
  return monthly38 > 0 ? Math.min(monthly28, monthly38) : monthly28
}

export const calcMaxHomePrice = (loanTermYears, annualIncome, debt, downPayment, annualInterestRate) => {
  const loanTermMonths = loanTermYears * 12
  const monthlyPayment = calcMonthlyPayment(annualIncome, debt)
  const normalizedAnnualInterestRate = annualInterestRate > 1 ? annualInterestRate / 100 : annualInterestRate
  const monthlyInterestRate = normalizedAnnualInterestRate / 12
  const loanAmount = calcPV(monthlyInterestRate, loanTermMonths, monthlyPayment)
  return loanAmount + downPayment
}

export const selectShowModal = selectFromAffordability('showModal')
export const selectIncome = selectFromAffordabilityWithDefault('income', selectBuyerIncome)
export const selectDebt = selectFromAffordabilityWithDefault('debt', selectBuyerDebt)
export const selectDownPayment = selectFromAffordabilityWithDefault('downPayment', selectBuyerDownPayment)
export const selectLoanTerm = selectFromAffordabilityWithDefault('loanTerm', selectBuyerLoanTerm)
export const selectLoanTermOrDefault = createSelector(selectLoanTerm, term => term || DEFAULT_LOAN_TERM)
export const selectAffordabilityMethod = selectFromAffordability('affordabilityMethod')
export const selectShowPrequalPrompt = selectFromAffordability('showPrequalPrompt')
export const selectSendingPrequalRequest = selectFromAffordability('sendingPrequalRequest')
export const selectPrequalRequested = selectFromAffordability('prequalRequested')
export const selectPrequalRequestSuccess = selectFromAffordability('prequalRequestSuccess')
export const selectPrequalRequestFailure = selectFromAffordability('prequalRequestFailure')

export const selectBuyerMethod = createSelector(
  selectBuyerAffordabilityMethod,
  selectNewestPrequal,
  selectHasPrequal,
  (selectedMethod, latestPrequal, hasPrequal) => {
    const fallback = hasPrequal && latestPrequal ? METHODS.PREQUAL : METHODS.PRICEPOINT

    const method = selectedMethod || fallback

    if (method === METHODS.PREQUAL && !hasPrequal) {
      return METHODS.PRICEPOINT
    }

    return method
  }
)

export const selectBuyerIsPrequal = createSelector(
  selectBuyerMethod,
  selectedMethod => selectedMethod === METHODS.PREQUAL
)

export const selectPricePoint = selectFromAffordabilityWithDefault('pricePoint', selectBuyerAffordability)
export const selectModalMethod = selectFromAffordabilityWithDefault(
  'affordabilityMethod',
  selectBuyerAffordabilityMethod
)

export const selectPromptPrequalFromAffordability = createSelector(
  selectModalMethod,
  selectPrequalifications,
  selectHasRequestedPrequal,
  (method, prequals, requested) => {
    const methodRequiresPrompt = [METHODS.CALCULATOR, METHODS.PRICEPOINT].includes(method)
    const hasPrequal = prequals.length > 0

    return methodRequiresPrompt && !hasPrequal && !requested
  }
)

export const selectRate = createSelector(
  selectLoanTermOrDefault,
  selectLatestMortgageRates,
  (term, rates) => rates[term]
)

export const isValidNumber = num => {
  const i = parseInt(num, 10)
  return !Number.isNaN(i) && num >= 0
}

const selectNumericValidity = selector => createSelector(selector, isValidNumber)

export const selectIncomeValidity = selectNumericValidity(selectIncome)
export const selectDebtValidity = selectNumericValidity(selectDebt)
export const selectDownPaymentValidity = selectNumericValidity(selectDownPayment)
export const selectLoanTermValidity = createSelector(selectLoanTerm, isValidTerm)

export const selectCalcValidity = createSelector(
  selectIncomeValidity,
  selectDebtValidity,
  selectDownPaymentValidity,
  (...args) => every(args, Boolean)
)

export const selectCalculatedAffordability = createSelector(
  selectLoanTermOrDefault,
  state => parseInt(selectIncome(state)) || 0,
  state => parseInt(selectDebt(state)) || 0,
  state => parseInt(selectDownPayment(state)) || 0,
  state => selectRate(state) || 5,
  calcMaxHomePrice
)

export const selectMonthlyPayment = createSelector(
  selectCalculatedAffordability,
  state => parseInt(selectIncome(state)) || 0,
  state => parseInt(selectDebt(state)) || 0,
  (affordability, income, debt) => (affordability ? calcMonthlyPayment(income, debt) : 0)
)

export const selectAffordability = createSelector(
  selectBuyerMethod,
  selectPricePoint,
  selectCalculatedAffordability,
  selectNewestPrequalAmount,
  (method, pricePoint, calc, prequal) =>
    ({
      [METHODS.PRICEPOINT]: pricePoint,
      [METHODS.CALCULATOR]: calc,
      [METHODS.PREQUAL]: prequal
    })[method]
)

export const selectModalAffordability = createSelector(
  selectModalMethod,
  selectPricePoint,
  selectCalculatedAffordability,
  selectNewestPrequalAmount,
  (method, pricePoint, calc, prequal) =>
    ({
      [METHODS.PRICEPOINT]: pricePoint,
      [METHODS.CALCULATOR]: calc,
      [METHODS.PREQUAL]: prequal
    })[method]
)

export const selectPersistableAffordabilityValues = createStructuredSelector({
  income: selectIncome,
  debt: selectDebt,
  downPayment: selectDownPayment,
  loanTerm: selectLoanTermOrDefault,
  affordabilityMethod: selectModalMethod,
  affordability: selectModalAffordability
})
