import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getBearerToken } from '../../auth'
import * as Sentry from '@sentry/browser'

async function transformRes(res: Response) {
  try {
    return await res.json()
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const favoriteListingsApi = createApi({
  reducerPath: 'favoriteListingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MIKASA_URI,
    prepareHeaders: headers => {
      const token = getBearerToken()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      headers.set('Content-Type', 'application/vnd.api+json')
      headers.set('Accept', 'application/vnd.api+json')
      return headers
    }
  }),
  tagTypes: ['FavoriteListings'],
  endpoints: builder => ({
    getFavoriteListingIds: builder.query<string[], void>({
      query: () => ({
        url: `favorite-listings`,
        responseHandler: res => transformRes(res)
      }),
      // Provides a list of `FavoriteListings` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `FavoriteListings` element was added.
      // https://redux-toolkit.js.org/rtk-query/usage/mutations#revalidation-example
      providesTags: result =>
        // is result available?
        result
          ? // successful query
            [...result.map(id => ({ type: 'FavoriteListings', id }) as const), { type: 'FavoriteListings', id: 'LIST' }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'FavoriteListings', id: 'LIST' }` is invalidated
            [{ type: 'FavoriteListings', id: 'LIST' }]
    }),
    toggleFavoriteListing: builder.mutation<{ listing_id: string; is_favorited: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `favorite-listings/${id}`,
        method: 'POST'
      }),
      transformResponse: (data: { listing_id: string; is_favorited: boolean }, meta, arg) => data,
      invalidatesTags: ['FavoriteListings']
      // if we ever decide to do an optimistic update - onQueryStarted is useful for optimistic updates
      // The 2nd parameter is the destructured `MutationLifecycleApi`
      // async onQueryStarted(arg, { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }) {},
      // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
      // async onCacheEntryAdded(
      //   arg,
      //   { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry }
      // ) {}
    })
  })
})

export const { useGetFavoriteListingIdsQuery, useToggleFavoriteListingMutation } = favoriteListingsApi
