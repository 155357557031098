import { useSelector } from 'react-redux'

// Selectors
import { selectInsuranceModuleEnabled, selectLoading } from '../store/selectors/homeInsurance'
import { useCreateHomeClientEventOnce } from './.'

// Utils
import { ClientEventName } from '../api/gqlaxy/gql/generated/graphql'

const useHomeInsurance = () => {
  const shouldRender = useSelector(selectInsuranceModuleEnabled)
  const loading = useSelector(selectLoading)
  const trackViewedEvent = useCreateHomeClientEventOnce({ eventName: ClientEventName.ViewedHomeInsurancePanel })

  trackViewedEvent()

  return { shouldRender, loading }
}

export default useHomeInsurance
