import { useState, useEffect, useContext } from 'react'
import { defaultOpts } from '../../constants/animations'
import { ActiveOverlayContext } from '../../Components/shared/Routing/OverlayContext'

export const useFadeOnMount = (opts = {}, initial) => {
  const buffer = opts.buffer || defaultOpts.buffer
  const delay = opts.delay || defaultOpts.delay
  const duration = opts.duration || defaultOpts.duration
  const extraStyles = opts.extraStyles
  const opacity = opts.opacity || 0

  const useDelay = !isNaN(opts.delay)

  const transition = `all ease-in-out ${duration * 2}ms`
  const [style, setStyle] = useState({
    opacity
  })

  useEffect(() => {
    setTimeout(() => {
      setStyle({
        opacity: 1,
        transition,
        ...extraStyles,
        transitionDelay: useDelay && `${delay}ms`
      })
    }, buffer)
  }, [])

  const { exitingPanel } = useContext(ActiveOverlayContext) || {
    exitingPanel: false
  }
  if (exitingPanel) {
    return {
      opacity,
      transition: `opacity ease-in-out ${defaultOpts.duration}ms`,
      transitionDelay: `${initial ? 0 : defaultOpts.interval}ms`
    }
  }

  return style
}

export default useFadeOnMount
