/*
* finance.js v0.1
* By: Trent Richardson [http://trentrichardson.com]
*
* Copyright 2012 Trent Richardson
* You may use this project under MIT or GPL licenses.
* http://trentrichardson.com/Impromptu/GPL-LICENSE.txt
* http://trentrichardson.com/Impromptu/MIT-LICENSE.txt

modified for homebot and node

*/

import moment from 'moment'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import sum from 'lodash/sum'
import Constants from './constants'
import Helpers from './helpers'
import * as Types from '../types'

const { monthsInYear, percentToDecimal, loanFees } = Constants

interface DefaultFormatSettings {
  before: string
  after: string
  precision: number | null
  decimal: string
  thousand: string
  group: number
  negative: string
  [key: string]: any
}

interface DefaultFormats {
  USD: DefaultFormatSettings
  GBP: DefaultFormatSettings
  EUR: DefaultFormatSettings
  percent: DefaultFormatSettings
  number: DefaultFormatSettings
  defaults: DefaultFormatSettings
  [key: string]: DefaultFormatSettings
}

const financeFormats: DefaultFormats = {
  USD: {
    before: '$',
    after: '',
    precision: 0,
    decimal: '.',
    thousand: ',',
    group: 3,
    negative: '-'
  }, // $
  GBP: {
    before: '£',
    after: '',
    precision: 2,
    decimal: '.',
    thousand: ',',
    group: 3,
    negative: '-'
  }, // £ or &#163;
  EUR: {
    before: '€',
    after: '',
    precision: 2,
    decimal: '.',
    thousand: ',',
    group: 3,
    negative: '-'
  }, // € or &#8364;
  percent: {
    before: '',
    after: '%',
    precision: 0,
    decimal: '.',
    thousand: ',',
    group: 3,
    negative: '-'
  },
  number: {
    before: '',
    after: '',
    precision: null,
    decimal: '.',
    thousand: ',',
    group: 3,
    negative: '-'
  },
  defaults: {
    before: '',
    after: '',
    precision: 0,
    decimal: '.',
    thousand: ',',
    group: 3,
    negative: '-'
  }
}

const finance = {
  version: '0.1',

  /*
   * Defaults
   */
  settings: {
    format: 'number',
    formats: financeFormats
  },

  defaults: function (object: DefaultFormatSettings, defs: { [key: string]: any }): DefaultFormatSettings {
    for (const key in defs) {
      if (Object.prototype.hasOwnProperty.call(defs, key)) {
        if (object[key] == null) object[key] = defs[key]
      }
    }
    return object
  },

  /*
   * Formatting
   */

  // add a currency format to library
  addFormat: function (key: string, options: DefaultFormatSettings): boolean {
    this.settings.formats[key] = this.defaults(options, this.settings.formats.defaults)
    return true
  },

  // remove a currency format from library
  removeFormat: function (key: string): boolean {
    delete this.settings.formats[key]
    return true
  },

  // format a number or currency test
  format: function (num: number | string, settings: DefaultFormatSettings, override: DefaultFormatSettings) {
    let myNum: number = parseFloat(num.toString())
    let settingFormat: DefaultFormatSettings

    if (settings === undefined) settingFormat = this.settings.formats[this.settings.format]
    else if (typeof settings === 'string') settingFormat = this.settings.formats[settings]

    settingFormat = this.defaults(settings, this.settings.formats.defaults)

    if (override !== undefined) settings = this.defaults(override, settings)

    // set precision
    if (settingFormat.precision != null) myNum = parseFloat(myNum.toFixed(settingFormat.precision))

    const isNeg = myNum < 0
    const numParts = Math.abs(myNum).toString().split('.')
    const baseLen = numParts[0].length

    // add thousands and group
    numParts[0] = numParts[0].replace(/(\d)/g, function (str, m1, offset) {
      return offset > 0 && (baseLen - offset) % settingFormat.group === 0 ? settingFormat.thousand + m1 : m1
    })

    // add decimal
    let myNumString = numParts.join(settingFormat.decimal)

    // add negative if applicable
    if (isNeg && settingFormat.negative) {
      myNumString = settingFormat.negative[0] + myNumString
      if (settingFormat.negative.length > 1) myNumString += settingFormat.negative[1]
    }

    return settingFormat.before + myNumString + settingFormat.after
  },

  /*
   * Financing
   */

  //  calculate total of principal + interest (yearly) for x months
  calculateAccruedInterest: function (principal: number, months: number, rate: number) {
    const monthlyInterestRate = rate / (monthsInYear * percentToDecimal)

    return principal * Math.pow(1 + monthlyInterestRate, months) - principal
  },

  //  determine the amount financed
  calculateAmount: function (finMonths: number, finInterest: number, finPayment: number): number {
    let result = 0

    if (finInterest === 0) {
      result = finPayment * finMonths
    } else {
      const monthlyInterestRate = finInterest / (monthsInYear * percentToDecimal)
      const interestOverMonths = Math.pow(monthlyInterestRate + 1, finMonths)
      const amount = finPayment / ((monthlyInterestRate * interestOverMonths) / (interestOverMonths - 1))
      result = Math.round(amount * percentToDecimal) / percentToDecimal
    }

    return result
  },

  // We estimate the APR by first calculating the actual monthly payment
  // We then use a binary search to find the APR that matches the actual monthly payment
  calculateAPR: function (loanAmount: number, numPayments: number, baseRate: number, costs: number): number {
    const monthlyRate = baseRate / 100 / 12

    const actualMonthlyPayment =
      ((loanAmount + costs) * monthlyRate * Math.pow(1 + monthlyRate, numPayments)) /
      (Math.pow(1 + monthlyRate, numPayments) - 1)

    let lowerBoundGuessRate = 0
    let upperBoundGuessRate = 1 // Setting it to 1 or 100% just as an initial high value
    let guessRate, calculatedMonthlyPaymentWithGuessRate, finalGuessRate

    const tolerance = 0.01 // e.g., only a 1 cent difference

    for (let i = 0; i < 100; i++) {
      // Refine guess rate with each iteration
      guessRate = (lowerBoundGuessRate + upperBoundGuessRate) / 2

      calculatedMonthlyPaymentWithGuessRate =
        (loanAmount * guessRate * Math.pow(1 + guessRate, numPayments)) / (Math.pow(1 + guessRate, numPayments) - 1)

      // If the calculated monthly payment is within the tolerance, we're done
      if (Math.abs(calculatedMonthlyPaymentWithGuessRate - actualMonthlyPayment) < tolerance) {
        finalGuessRate = guessRate * 12 * 100

        return parseFloat(finalGuessRate.toFixed(6))
        // If the calculated payment is less than the actual monthly payment, we need to increase the guess rate
      } else if (calculatedMonthlyPaymentWithGuessRate < actualMonthlyPayment) {
        lowerBoundGuessRate = guessRate
        // If the calculated payment is greater than the actual monthly payment, we need to decrease the guess rate
      } else {
        upperBoundGuessRate = guessRate
      }
    }

    // After 100 iterations, if no convergence, return the last guess
    finalGuessRate = guessRate * 12 * 100

    return parseFloat(finalGuessRate.toFixed(6))
  },

  //  determine the months financed
  calculateMonths: function (finAmount: number, finInterest: number, finPayment: number): number {
    let result = 0

    if (finInterest === 0) {
      result = Math.ceil(finAmount / finPayment)
    } else {
      result = Math.round(
        (((-1 / monthsInYear) *
          Math.log(1 - (finAmount / finPayment) * (finInterest / percentToDecimal / monthsInYear))) /
          Math.log(1 + finInterest / percentToDecimal / monthsInYear)) *
          monthsInYear
      )
    }

    return result
  },

  //  determine the interest rate financed http://www.hughchou.org/calc/formula.html
  calculateInterestRate: function (finAmount: number, finMonths: number, finPayment: number): number {
    let min_rate = 0
    let max_rate = 100
    let mid_rate = (min_rate + max_rate) / 2
    while (min_rate < max_rate - 0.0001) {
      mid_rate = (min_rate + max_rate) / 2
      const j = mid_rate / (monthsInYear * percentToDecimal)
      const guessed_pmt = finAmount * (j / (1 - Math.pow(1 + j, finMonths * -1)))

      if (guessed_pmt > finPayment) {
        max_rate = mid_rate
      } else {
        min_rate = mid_rate
      }
    }
    return parseFloat(mid_rate.toFixed(2))
  },

  //  determine the payment
  calculatePayment: function (finAmount: number, finMonths: number, finInterest: string | number): number {
    finInterest = parseFloat((finInterest || 0).toString())

    let result = 0

    if (finInterest === 0) {
      result = finAmount / finMonths
    } else {
      const i = finInterest / percentToDecimal / monthsInYear
      const i_to_m = Math.pow(i + 1, finMonths)
      const p = finAmount * ((i * i_to_m) / (i_to_m - 1))
      result = Math.round(p * percentToDecimal) / percentToDecimal
    }

    return result
  },

  calculateInterest: function (
    finAmount: number,
    finMonths: number,
    finInterest: number,
    finDate: Date,
    finExtraPay: number
  ): number {
    const payment = this.calculatePayment(finAmount, finMonths, finInterest)
    let balance = finAmount
    let totalInterest = 0.0
    let ep = 0
    let currInterest: number | null = null
    let currPrincipal: number | null = null
    let currDate = finDate ? new Date(finDate) : new Date()

    currDate = moment(currDate.getTime())
      .add(moment.duration({ M: 1 }))
      .toDate()

    for (let i = 0; i < finMonths; i++) {
      if (finExtraPay && ep === 0 && moment(currDate.getTime()).isAfter(new Date())) {
        // don't calc payment unless its the future, not back dating extra payments
        if (finExtraPay) ep = finExtraPay
      }

      currInterest = (balance * finInterest) / 1200
      totalInterest += currInterest
      currPrincipal = payment - currInterest + ep
      balance -= currPrincipal

      if (balance < 0) break

      currDate = moment(currDate.getTime())
        .add(moment.duration({ M: 1 }))
        .toDate()
    }

    return totalInterest
  },

  calculateHomeLoanAmortization: function (
    loanAmount: number | string,
    loanTermMonths: number,
    loanRate: number,
    loanDate: Date,
    bulkPayments: {
      amount: number
      date: Date
    }[]
  ) {
    const amount = parseInt(loanAmount.toString(), 10)
    const financeDate = moment(loanDate)
      .add(moment.duration({ M: 1 }))
      .startOf('month')
      .toDate()
    const currentMonth = moment().startOf('month').toDate()
    return this.calculateAmortization(
      amount,
      loanTermMonths,
      loanRate,
      financeDate,
      0,
      bulkPayments || null,
      currentMonth
    )
  },

  /**
   * calculateAmortization
   *
   * @param {number}   finAmount - original loan amount
   * @param {number}   finMonths - months in loan term
   * @param {number}   finInterest - interest rate in percent (e.g. 3.8)
   * @param {date}     finDate - loan origination date
   * @param {number}   finExtraPay - extra payment at origination
   * @param {Object[]} finBulkPayments - add one-time payments at a point in time
   *   @param {number} finBulkPayments[].amount - amount to pay extra
   *   @param {date}   finBulkPayments[].date - date to pay extra
   * @param {date}     calcDate - calculate amount paid so-far up to this date
   *
   * @returns {Object} amortization data - calculated amortization
   */
  calculateAmortization: function (
    finAmount: number,
    finMonths: number,
    finInterest: number,
    finDate?: Date,
    finExtraPay?: number,
    finBulkPayments?:
      | null
      | {
          amount: number
          date: Date
        }[], // [{ amount: 1000, date: Date.new }]
    calcDate?: Date
  ): {
    amount: number
    rate: number
    periods: number
    periods_saved: number
    principal_paid: number
    interest_paid: number
    principal: number
    interest: number
    extra_payment_total: number
    bulk_payment_total: number
    payment: {
      total: number
      principal: number
      interest: number
    }
    extra_payment: number | undefined
    start_date: string
    maturity_date: string
    schedule: Types.LoanSchedule[]
  } {
    const payment: number = this.calculatePayment(finAmount, finMonths, finInterest)
    let balance = finAmount
    let totalInterest = 0.0
    let totalPrincipal = 0.0
    const schedule: Types.LoanSchedule[] = []
    const bulkPayments = finBulkPayments || []
    let currPayment = 0
    let currInterestPayment = 0
    let currPrincipalPayment = 0
    let currExtraPayment = 0
    let todayPrincipalPaid = 0
    let todayInterestPaid = 0
    let todayPrincipal = 0
    let todayInterest = 0
    let isFuture = false
    let totalExtraPayments = 0
    let totalBulkPayments = 0
    let extraPayment = 0
    let lastMonth = 0
    const endDate = calcDate ? new Date(calcDate) : new Date() // used to calc total paid
    let currDate = finDate ? new Date(finDate) : new Date()

    currDate = moment(currDate.getTime())
      .add(moment.duration({ M: 1 }))
      .toDate()

    for (let i = 0; i < finMonths; i++) {
      let currBulkPayment = 0
      let calculatePaidSoFar = false

      // save what the Principal is now
      if (!isFuture && moment(currDate.getTime()).isSame(endDate, 'month')) {
        calculatePaidSoFar = true
        isFuture = true

        // don't calc payment unless its the future, not back dating extra payments
        if (finExtraPay) extraPayment = finExtraPay
      }

      // Still calculate extra payment interest saved and years saved
      // even if the loan is brand new and before the first payment
      if (moment(currDate.getTime()).isAfter(endDate) && finExtraPay && !extraPayment) {
        extraPayment = finExtraPay
      }

      // Copy global payment variables
      currPayment = payment
      currExtraPayment = extraPayment

      // Calculate and charge interest
      currInterestPayment = (balance * finInterest) / (12 * 100)
      totalInterest += currInterestPayment

      // Don't let balance fall below zero
      if (balance < currPayment - currInterestPayment) {
        // When current payment is greater than balance:
        currPayment = balance + currInterestPayment
        currExtraPayment = 0
        currPrincipalPayment = balance
      } else if (balance < currPayment + currExtraPayment - currInterestPayment) {
        // When current payment and extra payment are greater than balance:
        currExtraPayment = balance + currInterestPayment - currPayment
        currPrincipalPayment = balance
      } else {
        // Else calculate dollars towards principal
        currPrincipalPayment = currPayment - currInterestPayment + currExtraPayment
      }

      // Handle bulk payments on the date specified
      for (let i = 0; i < bulkPayments.length; i++) {
        const { amount, date } = bulkPayments[i]

        if (moment(currDate.getTime()).isSame(date, 'month')) {
          const remainingBalance = balance - currPrincipalPayment

          if (amount > remainingBalance) {
            currPrincipalPayment += remainingBalance
            currBulkPayment += remainingBalance
          } else {
            currPrincipalPayment += amount
            currBulkPayment += amount
          }
        }
      }

      // Update running totals
      totalExtraPayments += currExtraPayment
      totalBulkPayments += currBulkPayment
      totalPrincipal += currPrincipalPayment
      balance -= currPrincipalPayment

      // Add period calculations to final schedule
      schedule.push({
        balance: Math.max(balance, 0),
        principal: totalPrincipal, // principal should never be larger than amount
        interest: totalInterest,
        payment: currPayment + currExtraPayment, // payment should never be larger than balance
        paymentToPrincipal: currPrincipalPayment, // payment should never be larger than balance
        paymentToInterest: currInterestPayment,
        extraPayment: currExtraPayment, // total extra monthly payments
        bulkPayment: currBulkPayment, // total extra one-time payments
        date: moment(currDate.getTime()).format('YYYY-MM-DD')
      })

      // Save data in result relevant to the calcDate or end of loan
      if (calculatePaidSoFar) {
        todayPrincipalPaid = totalPrincipal
        todayInterestPaid = totalInterest
        todayPrincipal = currPrincipalPayment
        todayInterest = currInterestPayment
      }

      // Save total principal and interest at the end of the loan
      if (
        balance <= 0 && // must be at end of schedule
        moment(currDate).isBefore(endDate, 'month') // past the end of the loan
      ) {
        todayPrincipalPaid = totalPrincipal
        todayInterestPaid = totalInterest
      }

      // Break out if balance is paid-off
      if (balance <= 0) {
        lastMonth = i
        break
      }

      currDate = moment(currDate.getTime())
        .add(moment.duration({ M: 1 }))
        .toDate()
    }

    return {
      amount: finAmount,
      rate: finInterest,
      periods: finMonths,
      periods_saved: lastMonth === 0 ? 0 : finMonths - (lastMonth + 1),
      principal_paid: todayPrincipalPaid,
      interest_paid: todayInterestPaid,
      principal: totalPrincipal,
      interest: totalInterest,
      extra_payment_total: totalExtraPayments,
      bulk_payment_total: totalBulkPayments,
      payment: {
        total: payment,
        principal: todayPrincipal,
        interest: todayInterest
      },
      extra_payment: finExtraPay,
      start_date: moment(finDate).format('YYYY-MM-DD'),
      maturity_date: schedule[schedule.length - 1].date,
      schedule: schedule
    }
  },

  getMaturityDate: function (
    finAmount: number,
    finMonths: number,
    finInterest: number,
    finDate: Date,
    finExtraPay: number,
    finBulkPayments: {
      amount: number
      date: Date
    }[]
  ): Types.LoanScheduleDate {
    const amortization = this.calculateAmortization(
      finAmount,
      finMonths,
      finInterest,
      finDate,
      finExtraPay,
      finBulkPayments
    )

    const maturity_date = amortization.schedule[amortization.schedule.length - 1].date
    const interest = amortization.schedule[amortization.schedule.length - 1].interest

    // update periods to reflect months since sold
    const soldDate = moment(finDate)
    finMonths = finMonths + soldDate.diff(moment(), 'months')

    // get time savings from amortization
    const date = moment(amortization.schedule[amortization.schedule.length - 1].date)
    const months_saved = finMonths - date.diff(moment(), 'months')

    return {
      date: maturity_date,
      monthsLeft: finMonths - months_saved,
      interestPaid: interest,
      extraPaid: finExtraPay,
      timeSavings: {
        years: parseInt((months_saved / monthsInYear).toString(), 10),
        months: months_saved % monthsInYear
      }
    }
  },

  calculateLoanFees: function (loanBalance: number): number {
    if (loanBalance <= 250000) {
      return loanFees.small
    } else if (loanBalance > 250000 && loanBalance <= 499999) {
      return loanFees.medium
    } else {
      return loanFees.large
    }
  },

  calculateInterestPaidOverSchedule: function (loanSchedule: Types.LoanSchedule[], costs = 0): number[] {
    const collectInterestPaid = map(loanSchedule, 'paymentToInterest')
    const normalizedArray = Helpers.normalizeArrayFor30YearLoan(collectInterestPaid)
    const groupedLoanSchedule = chunk(normalizedArray, monthsInYear)
    const yearResolution = map(groupedLoanSchedule, sum)
    yearResolution[0] = yearResolution[0] + costs

    let runningCalc = 0

    const totalInterestPaidPerYear = map(yearResolution, (interestPaidOverYear: number) => {
      return (runningCalc = runningCalc + interestPaidOverYear)
    })

    return totalInterestPaidPerYear
  }
}

export default finance
