import { useContext } from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'
import { ActiveOverlayContext } from '../Routing/OverlayContext'
import { Text, StatusIcon } from '../..'
import { Button, ComponentTrackingType } from '@homebotapp/hb-react-component-catalog'

import styles from './OutreachButton.module.scss'

export interface OutreachButtonProps {
  className?: string
  requested?: boolean
  success: boolean
  failure: boolean
  buttonMessage: { id: string; defaultMessage: string }
  resultMessage: string
  handleClick: (args) => void
  secondary?: boolean
  children?: React.ReactNode
  iconStart?: React.ReactNode
  colorScheme?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  tracking?: ComponentTrackingType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  restProps?: any
}

export const OutreachButton = ({
  buttonMessage,
  className,
  failure,
  handleClick,
  requested,
  colorScheme,
  resultMessage,
  success,
  tracking,
  restProps // I know people hate this but it's for any other props the DS/chakra button might take
}: OutreachButtonProps) => {
  const { exitingPanel } = useContext(ActiveOverlayContext) || {
    exitingPanel: false
  }

  const intl = useIntl()
  const resultTextColor = success ? 'success' : failure ? 'warning' : 'neutralCool'

  return (
    <div data-qa='outreach-btn-component' className={classnames(styles.container, className)}>
      {!success && !failure && (
        <Button
          data-qa='outreach-btn'
          className={styles.button}
          colorScheme={colorScheme}
          isLoading={requested}
          onClick={handleClick}
          tracking={tracking}
          {...restProps}
        >
          {intl.formatMessage(buttonMessage)}
        </Button>
      )}
      {(success || failure) && (
        <div
          className={classnames(
            exitingPanel && styles.disableShow,
            styles.slideOpenContainer,
            (success || failure) && styles.ready
          )}
        >
          {/* @ts-ignore */}
          <StatusIcon
            className={classnames(styles.icon, failure && styles.failure)}
            failure={failure}
            spin={!success && !failure}
            success={success}
          />
          <Text
            variant={resultTextColor}
            variantCount='700'
            size='s'
            element='span'
            className={styles.resultText}
            weight='semibold'
          >
            {resultMessage}
          </Text>
        </div>
      )}
    </div>
  )
}
