import { createBrowserHistory } from 'history'
import { selectRouteKey } from './store/selectors/routes'
import { scrollTo } from './helpers/browser'

const history = createBrowserHistory()
let prevFeature = selectRouteKey()

export const unlisten = history.listen(() => {
  const currentFeature = selectRouteKey()

  if (currentFeature !== prevFeature) {
    prevFeature = currentFeature
    scrollTo(0, 0, 'auto')
  }
})

export default history
