// Does what it says — gets the width of the browser's scrollbar
export function getScrollBarWidth() {
  let output: number | undefined = undefined

  // https://stackoverflow.com/a/13382873
  // https://adminhacks.com/scrollbar-size/
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'

  // @ts-ignore Needed for WinJS apps
  outer.style.msOverflowStyle = 'scrollbar'

  document.body.appendChild(outer)

  const widthNoScroll = outer.offsetWidth
  // force scrollbars
  outer.style.overflow = 'scroll'

  // add innerdiv
  const inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)

  const widthWithScroll = inner.offsetWidth

  // remove divs
  outer.parentNode?.removeChild(outer)

  const width = widthNoScroll - widthWithScroll
  output = width

  if (output === undefined) {
    output = 0
    console.error(`ScrollBar width wasn't able to be calculated`)
  }

  return output
}

export function getScrollBarWidthFormatted(option: 'full' | 'half', unit: 'px' | `rem` | 'none', rootFontSize = 16) {
  let output: string | number

  const rawWidth = getScrollBarWidth()
  let workingWidth = 0

  if (option === 'full') {
    workingWidth = rawWidth
  }
  if (option === 'half') {
    workingWidth = rawWidth / 2
  }

  switch (unit) {
    case 'px':
      output = `${workingWidth}px`
      return output

    case 'rem':
      output = `${workingWidth / rootFontSize}rem` as string
      return output
    case 'none':
      output = workingWidth
      return output
  }
}
