import { GeoAreaLevel } from '../../../../../../../api/gqlaxy/gql/generated/graphql'

export const convertAutocompleteLevelToGeoAreaLevel = (level = 'city') => {
  switch (level) {
    case 'city':
      return GeoAreaLevel.City
    case 'postal_code':
      return GeoAreaLevel.PostalCode
    default:
      return GeoAreaLevel.City
  }
}
