import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@homebotapp/hb-react-component-catalog'
import { useDispatch, useSelector } from 'react-redux'
import { selectHomeSearchAuthModalVisible } from '../../store/selectors/homeSearchAuthModal'
import { toggleAuthModal } from '../../actions/homeSearchAuthModal'
import { WizardProvider, useWizardProvider } from '../../Components/shared/WizardContext'
import { SIGN_UP_STEPS, SIGN_UP_STEPS_ORDERED } from '../../constants/signUp'
import { SignUpProvider, useSignUpProvider } from './SignUpContext'
import { Steps } from './Steps'
import { defineMessages, useIntl } from 'react-intl'
import BotHead from '../../Components/shared/Avatar/BotHeadColored'
import homeImage from './sign-in--house.jpg'
import { GLOBAL_SIGNUP } from '../../constants/tracking/trackingEventLocations/globalTrackingEventLocation'
import { constructFiltersAndOmitEmpty } from '../../helpers/listings/filters'
import { selectClientIsGuest } from '../../store/selectors/client'

export const MSG = defineMessages({
  heading: {
    id: 'SignUpBody.image.header',
    defaultMessage: 'Homebot · Since 2015'
  },
  body: {
    id: 'SignUpBody.image.body',
    defaultMessage: 'Connecting people to make informed homeownership decisions together'
  }
})

const HomeImage = () => {
  const intl = useIntl()

  return (
    <Box
      display={['none', null, 'block']}
      position='relative'
      backgroundImage={`url(${homeImage})`}
      backgroundSize='cover'
      backgroundPosition='60% center'
      flex='1'
    >
      <Box position='absolute' bottom='6' paddingY='0' paddingX='6' maxWidth='20rem'>
        <Heading size='2xs' variant='eyebrow' textAlign='left' marginBlockEnd={0}>
          {intl.formatMessage(MSG.heading)}
        </Heading>
        <Text size='xs' fontWeight='semibold' color='neutral-800'>
          {intl.formatMessage(MSG.body)}
        </Text>
      </Box>
    </Box>
  )
}

const AuthModal = ({ showCloseButton = true, size, ...restModalProps }) => {
  const dispatch = useDispatch()
  const showGuestSigninModal = useSelector(selectHomeSearchAuthModalVisible)

  const { handleIframeClose, iFramed, submitError } = useSignUpProvider()

  const { currentStep, goBack } = useWizardProvider()
  // @ts-ignore - can remove once wizardProvider is typed
  const showBackBtn = currentStep === SIGN_UP_STEPS.USER_FIRST_AND_LAST

  const handleClose = () => dispatch(toggleAuthModal(false))

  return (
    <Modal {...restModalProps} isOpen={showGuestSigninModal} onClose={handleClose} size='full'>
      <ModalOverlay />
      <ModalContent>
        {showCloseButton && <ModalCloseButton />}
        {showBackBtn && (
          <IconButton
            aria-label='Back to previous step'
            variant='ghost'
            icon={<Icon name='angle-left' />}
            size='sm'
            // @ts-ignore - can remove once wizardProvider is typed
            onClick={goBack}
            position='absolute'
            top='2'
            insetStart='3'
            color='currentColor'
            tracking={{
              guid: '7tiJRxhCQV1kNqYEH76mcV',
              descriptor: 'Back to previous step',
              ui_context: GLOBAL_SIGNUP
            }}
          />
        )}
        <ModalBody display='flex' padding={[null, null, '0']}>
          <HomeImage />
          <Flex flexDirection='column' flex='0 1 32rem' paddingY='9' paddingX={[null, null, '6']}>
            <Flex direction='column' flex='1'>
              <Flex fontWeight='light' alignItems='center' justifyContent='flex-start'>
                <Box
                  display='flex'
                  __css={{
                    svg: { width: '1.5rem', height: '1.5rem' }
                  }}
                >
                  <BotHead />
                </Box>
                <Text marginBottom={0} marginLeft={2} size='lg'>
                  homebot
                </Text>
              </Flex>
              <Steps />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const GuestAuthModal = modalProps => (
  <WizardProvider steps={SIGN_UP_STEPS_ORDERED} initialStep={SIGN_UP_STEPS.USER_EMAIL}>
    <SignUpProvider>
      <AuthModal {...modalProps} />
    </SignUpProvider>
  </WizardProvider>
)
