import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

const propTypes = {
  value: PropTypes.string
}

const CustomerTitle = ({ value, ...restProps }) => (
  <Text variant='neutralCool' variantCount='700' weight='bold' upperspaced {...restProps}>
    {value}
  </Text>
)

CustomerTitle.propTypes = propTypes

export default CustomerTitle
