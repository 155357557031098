import axios from 'axios'
import changeCaseObject from 'change-case-object'
import { getAuthHeaders } from '../../auth'

const TEST_OPTIONS = {
  contentType: 'application/vnd.api+json',
  responseType: 'json',
  transformRequest: [
    function (data, headers) {
      Object.assign(headers, getAuthHeaders())
      return JSON.stringify(data)
    }
  ],
  transformResponse: [
    function (data) {
      // Convert JsonAPI's default kebab-case to camelCase for easier destructuring in Javascript
      let parsedData = null

      try {
        parsedData = changeCaseObject.camelCase(JSON.parse(data))
      } catch (e) {}

      return parsedData || data
    }
  ]
}

function nonJsonApiClient(baseURL, options) {
  const settings = Object.assign({}, TEST_OPTIONS, options)
  const client = axios.create({
    baseURL,
    timeout: 30000,
    headers: {
      Accept: settings.contentType,
      'Content-Type': settings.contentType
    },
    transformRequest: settings.transformRequest,
    transformResponse: settings.transformResponse
  })

  return client
}

export default nonJsonApiClient
