import React from 'react'
import { If } from '../../../deprecated/Conditionals'
import { IconLock } from '../Icon'

const InlineTextLock = ({ children, enabled = true }) => (
  <>
    <If condition={!enabled}>{children}</If>
    <If condition={enabled}>
      <IconLock />
    </If>
  </>
)

export default InlineTextLock
