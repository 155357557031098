import produce from 'immer'

import {
  CREATE_HOME_REQUEST,
  CREATE_HOME_SUCCESS,
  CREATE_HOME_FAILURE,
  CREATE_HOME_FETCH_COMPILED_FAILURE,
  FETCH_HOME_SUCCESS
} from '../actions/actionTypes'
import { homeCreateState } from '../factories/homeCreate'

const initialState = homeCreateState()

export default (state = initialState, { type, data, error, timeout }) =>
  produce(state, draft => {
    switch (type) {
      case CREATE_HOME_REQUEST:
        draft.creating = true
        draft.compiling = true
        draft.created = false
        draft.error = null
        draft.timeout = null
        break

      case CREATE_HOME_SUCCESS:
        draft.creating = false
        draft.created = true
        break

      case CREATE_HOME_FAILURE:
        draft.creating = false
        draft.created = false
        draft.error = error
        break

      case CREATE_HOME_FETCH_COMPILED_FAILURE:
        draft.compiling = false
        draft.error = error
        draft.timeout = timeout
        break

      case FETCH_HOME_SUCCESS:
        draft.compiling = false
        break

      default:
        break
    }
  })
