import {
  FETCH_VALUATION_HISTORY_REQUEST,
  FETCH_VALUATION_HISTORY_SUCCESS,
  FETCH_VALUATION_HISTORY_FAILURE
} from './actionTypes'

import { getValuationHistory as requestGetValuationHistory } from '../api/mikasa/requests'

export const fetchValuationHistory = (clientId, homeId) => async dispatch => {
  dispatch({
    type: FETCH_VALUATION_HISTORY_REQUEST,
    homeId
  })

  try {
    const response = await requestGetValuationHistory(clientId, homeId)

    dispatch({
      type: FETCH_VALUATION_HISTORY_SUCCESS,
      data: response.data,
      homeId
    })
  } catch (error) {
    dispatch({
      type: FETCH_VALUATION_HISTORY_FAILURE,
      homeId,
      error
    })
  }
}
