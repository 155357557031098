import classnames from 'classnames'

import styles from './Blur.module.scss'

const BLUR_AMOUNT = ['sm', 'md', 'lg'] as const
type BlurAmount = (typeof BLUR_AMOUNT)[number]

export interface BlurProps {
  className?: string
  amount?: BlurAmount
  enabled?: boolean
  children: React.ReactNode
}

export const Blur = ({ amount, className, enabled, children }: BlurProps) => (
  <div
    className={classnames(className, enabled && styles.root, enabled && styles[`amount-${amount}`])}
    aria-hidden='true'
  >
    {children}
  </div>
)

Blur.Amount = BLUR_AMOUNT
