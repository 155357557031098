import { useIntl } from 'react-intl'
import { Box, ComponentTrackingType } from '@homebotapp/hb-react-component-catalog'
import { AppStoreBadge } from '../AppStoreBadge'

import styles from './NativeCTALinks.module.scss'

export interface NativeCTALinksProps {
  tracking: ComponentTrackingType
}

export const NativeCTALinks = ({ tracking }: NativeCTALinksProps) => {
  const intl = useIntl()

  return (
    <li className={styles.listItem}>
      <Box marginRight={3}>
        <AppStoreBadge platform='apple' locale={intl.locale} tracking={tracking} />
      </Box>
      <AppStoreBadge platform='google' locale={intl.locale} tracking={tracking} />
    </li>
  )
}
