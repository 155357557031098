export const METADATA_SCOPES = {
  COMPOSITE: 'COMPOSITE',
  QUARTILE: 'QUARTILE'
}

export const MARKET_VALUE_BREAK_POINTS = {
  VERY_HIGH: 80,
  HIGH: 60,
  MODERATE: 40,
  LOW: 20
}

export const HOT_MARKET_VALUE_BREAK_POINT = 90

export const MARKET_DEMAND_KEYS = {
  VERY_HIGH: 'veryHigh',
  HIGH: 'high',
  MODERATE: 'moderate',
  LOW: 'low',
  VERY_LOW: 'veryLow'
}
