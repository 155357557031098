export const MARKET_PANEL_VIEW_EVENT = 'viewed-market-panel'
export const HOME_INSURANCE_PANEL_VIEW_EVENT = 'viewed-home-insurance-panel'
export const SHOULD_SELL_VIEW_EVENT = 'viewed-should-you-sell'
export const EXPANDED_HOME_APPRECIATION_EVENT = 'expanded-home-appreciation'
export const VIEWED_DREAM_HOME_PANEL = 'viewed-dream-home-panel'
export const VIEWED_VIDEO_EMAIL = 'viewed-video-email'
export const REFI_SLIDER_INTERACTION = 'refi-slider-interaction'
export const INSTANT_OFFER_REQUESTED = 'instant-offer-requested'
export const VIEWED_REVERSE_MORTGAGE_PANEL = 'viewed-reverse-mortgage-panel'
export const CMA_REQUEST = 'cma-request'
export const REFERRAL_INVITE_SENT = 'referral-invite-sent'
export const VIEW = 'view'
export const LISTING_FAVORITED_EVENT = 'listing-favorited-event'
export const SAVED_SEARCH_CREATED_EVENT = 'saved-search-created-event'
export const DETAILS_PAGE_VIEWED_EVENT = 'details-page-viewed-event'
export const BUYER_VIEWED_REPORT = 'buyer-viewed-report'
export const BUYER_VIEWED_MARKETS = 'buyer-viewed-markets'
export const EXPANDED_CASH_POSITION_EVENT = 'expanded-cash-position'
export const MOVED_CASH_POSITION_SLIDER = 'moved-cash-position-slider'
export const EXPANDED_MARKET_TEMP_SELL_EVENT = 'expanded-market-temp'
export const BUYER_VIEWED_HOME_SEARCH_EVENT = 'buyer-viewed-home-search-event'
