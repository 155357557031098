import { useDelayLoading } from '../../../../../hooks'
import { BotHeadSpinWithMessage } from '../../BotHeadSpinWithMessage'
import { DELAY_UNTIL_SHOW_LOADER } from '../constants'

export interface DelayedBotHeadSpinWithMessageProps {
  loading: boolean
  className?: string
  children: React.ReactNode
}

export const DelayedBotHeadSpinWithMessage = ({ loading, className, children }: DelayedBotHeadSpinWithMessageProps) => {
  const showSpinner = useDelayLoading(loading, DELAY_UNTIL_SHOW_LOADER)

  if (!showSpinner) return <div data-qa='loading-placeholder' className={className} />

  return showSpinner && <BotHeadSpinWithMessage className={className}>{children}</BotHeadSpinWithMessage>
}
