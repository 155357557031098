import { IDLE, FAILED, PENDING, SUCCEEDED, LoadingStatus } from '../../types/loadingStatus'
import * as Sentry from '@sentry/browser'
import { RootState } from '../../types/rootState'
import { initialize } from '../../vendor/bombBomb'
import { INIT, BombBombInstance, BombBombStepType } from '../../types/bombBomb'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initializeBombBomb = createAsyncThunk('initializeBombBomb', async (apiKey: string, thunkApi) => {
  if (apiKey && process.env.REACT_APP_SSO_API_URL) {
    try {
      thunkApi.dispatch(setBombBombIsEnabled(true))
      const bombBombInstance = await initialize(apiKey)

      bombBombInstance.onError = () => {
        thunkApi.dispatch(setBombBombStatus(FAILED))
      }

      return thunkApi.dispatch(setBombBombInstance(bombBombInstance))
    } catch (e) {
      Sentry.captureException(e)
      return thunkApi.rejectWithValue(e)
    }
  }

  Sentry.captureMessage('BombBomb ApiKey or REACT_APP_SSO_API_URL not set')
  return false
})

export interface BombBombState {
  instance: BombBombInstance | null
  step: BombBombStepType
  status: LoadingStatus
  isEnabled: boolean
  hidden: boolean
  open: boolean
  video: any
}

const initialState: BombBombState = {
  isEnabled: false,
  instance: null,
  hidden: false,
  status: IDLE,
  video: null,
  open: false,
  step: INIT
}

const bombBombSlice = createSlice({
  name: 'bombBomb',
  initialState,
  reducers: {
    setBombBombInstance: (state, action: PayloadAction<BombBombInstance>) => {
      state.instance = action.payload
    },
    setBombBombStep: (state, action: PayloadAction<BombBombStepType>) => {
      state.step = action.payload
    },
    setBombBombStatus: (state, action: PayloadAction<LoadingStatus>) => {
      state.status = action.payload
    },
    setBombBombIsEnabled: (state, action: PayloadAction<boolean>) => {
      state.isEnabled = action.payload
    },
    setBombBombHidden: (state, action: PayloadAction<boolean>) => {
      state.hidden = action.payload
    },
    setBombBombOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.open = true
      } else {
        state.hidden = false
        state.video = null
        state.open = false
        state.step = INIT
      }
    },
    setBombBombVideo: (state, action: PayloadAction<any>) => {
      state.video = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(initializeBombBomb.pending, state => {
        if (state.status === IDLE) {
          state.status = PENDING
        }
      })
      .addCase(initializeBombBomb.fulfilled, state => {
        if (state.status === PENDING) {
          state.status = SUCCEEDED
        }
      })
      .addCase(initializeBombBomb.rejected, state => {
        if (state.status === PENDING) {
          state.status = FAILED
        }
      })
  }
})

export const selectBombBomb = (state: RootState) => state.bombBomb.instance
export const selectBombBombStep = (state: RootState) => state.bombBomb.step
export const selectBombBombOpen = (state: RootState) => state.bombBomb.open
export const selectBombBombVideo = (state: RootState) => state.bombBomb.video
export const selectBombBombHidden = (state: RootState) => state.bombBomb.hidden
export const selectBombBombStatus = (state: RootState) => state.bombBomb.status
export const selectBombBombIsEnabled = (state: RootState) => state.bombBomb.isEnabled

export const {
  setBombBombOpen,
  setBombBombStep,
  setBombBombVideo,
  setBombBombStatus,
  setBombBombHidden,
  setBombBombInstance,
  setBombBombIsEnabled
} = bombBombSlice.actions

export default bombBombSlice.reducer
