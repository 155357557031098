import { TOGGLE_DISCLAIMER, SHOW_DISCLAIMER } from '../actions/actionTypes'

const initialState = {
  showDisclaimer: false
}

export default (state = initialState, { type }) => {
  switch (type) {
    case TOGGLE_DISCLAIMER:
      return {
        ...state,
        showDisclaimer: !state.showDisclaimer
      }
    case SHOW_DISCLAIMER:
      return {
        ...state,
        showDisclaimer: true
      }

    default:
      return state
  }
}
