export const INIT = 'init'
export const SAVE = 'saveForm'
export const SEND = 'sendForm'

const BombBombSteps = [INIT, SAVE, SEND] as const
export type BombBombStepType = (typeof BombBombSteps)[number]

export interface BombBombOAuthClientCredentials {
  clientIdentifier: string
  clientSecret: string
  redirectUri: string
  type: string
}

export interface BombBombResponseObject {
  status: string
  method: string
  info: Record<string, unknown>
}

export interface BombBombVideoProperties {
  vid_id: string
  title: string
  filename: string
}

export interface BombBombCustomVideoEmailOptions {
  from_name: string
  email_id: string
  email: string
  subject: string
  html_content: string
}

export interface BombBombVideoTarget {
  target: string
  width: number
  recorderLoaded(): void
}

export interface BombBombContactProperties {
  email: string
  firstname: string
  lastname: string
  phone_number: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  country: string
  postal_code: string
  company: string
  position: string
  comments: string
  listlist: string // This was not a copy paste issue...copied this listlist verbatim from the docs in the bombbomb github link below
  id: string
}

// NOTE: I did my best to type this object based on some sub-par typing docs here:
// https://github.com/bombbomb/BBCore/blob/master/docs/build/BBCore.combined.md#BBCore+getVideoRecorder
export interface BombBombInstance {
  getNewVideoGuid: () => string
  resumeStoredSession: () => void
  getServerUrl: () => string
  getRequestUrl: () => string
  sendRequest: (method: string, [params], [success], [error]) => void
  login: (uid: string, pwd: string, success: BombBombResponseObject) => void
  credentialsSaved: () => boolean
  saveCredentials: (uid: string, pwd: string) => void
  validateSession: (onSuccess: () => void, onError: () => void) => void
  validateAccessToken: (onSuccess: () => void) => void
  isAccessToken: () => boolean
  isAuthenticated: () => boolean
  invalidateSession: () => boolean
  verifyKey: (key: string, complete: BombBombResponseObject) => void
  storeKey: (key: string) => void
  verifyJsonWebToken: (key: string, complete: BombBombResponseObject) => void
  storeOAuthTokens: (key: string) => void
  getOAuthPayload: () => string
  validateOAuthCode: (authCode: string, onSuccess: () => void, onError: () => void) => void
  refreshOAuthToken: () => void
  storeJsonWebToken: (key: string) => void
  getValidJsonWebTokenAsync: (callback: () => void) => void
  getLists: (success: BombBombResponseObject) => BombBombContactProperties[]
  createList: (listName: string, success: BombBombResponseObject) => string
  getContact: (contactId: string, success: BombBombResponseObject) => BombBombContactProperties
  getListContacts: (listId: string, success: BombBombResponseObject) => BombBombContactProperties[]
  addContact: (contact: BombBombContactProperties, success: BombBombResponseObject) => void
  bulkAddContacts: (opts: object, success: BombBombResponseObject) => void
  updateContact: (opts: object, success: BombBombResponseObject) => void
  getImportAddressesByType: (opts, success: BombBombResponseObject) => string[]
  addContactImportAddress: (opts, success: BombBombResponseObject) => void
  getEmails: (success: BombBombResponseObject) => string[]
  sendCustomVideoEmail: (opts, success: BombBombResponseObject) => void
  getDrips: (opts: object, success: BombBombResponseObject) => any
  getForms: (opts: object, success: BombBombResponseObject) => any
  getClientIntegrations: (opts, success: BombBombResponseObject) => any
  deleteVideo: (videoId: string, success: BombBombResponseObject) => void
  videoQuickSend: (opts: object, onSuccess: () => void) => void
  getEmbeddedRecorderUrl: ([options], onComplete: () => void) => string
  getVideoRecorder: (opts: object, onComplete: () => void) => any
  startVideoRecorder: (target: BombBombVideoTarget, onComplete: (video: any) => void) => void
  saveRecordedVideo: (title: string, videoId: string, videoFilename: string, success: () => void) => void
  saveRecording: (options: object) => void
}
