import { Icon as ChakraIcon, IconProps as ChakraIconProps, forwardRef, ComponentWithAs } from '@chakra-ui/react'

import { ComponentProps } from '@homebotapp/hb-react-component-catalog'
import { generateUniqueID } from '../../../helpers/id'

export interface HBREIconProps extends ComponentProps<ChakraIconProps> {}

const uniqueId = generateUniqueID()
const clipId = `hbre-icon-clip-${uniqueId}`

const HBREIconSvg = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24' {...props}>
      <g clipPath={`url(#${clipId})`}>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12.36.394a.566.566 0 00-.72 0L.208 9.794a.566.566 0 00.36 1.003h2.035v11.24c0 .937.76 1.698 1.699 1.698h15.724c.938 0 1.699-.76 1.699-1.699v-11.24h1.708c.531 0 .77-.665.36-1.003L12.358.394zm4.037 19.572l-1.786-1.786a4.648 4.648 0 01-2.625.806 4.661 4.661 0 01-4.667-4.667 4.661 4.661 0 014.667-4.666 4.661 4.661 0 014.666 4.666c0 .968-.294 1.877-.805 2.626l1.786 1.786a.874.874 0 01-1.236 1.235zm-1.488-5.647a2.918 2.918 0 01-2.92 2.92 2.918 2.918 0 01-2.92-2.92 2.918 2.918 0 012.92-2.92 2.918 2.918 0 012.92 2.92z'
          clipRule='evenodd'
        ></path>
      </g>
      <defs>
        <clipPath id={clipId}>
          <path fill='#fff' d='M0 0H24V24H0z'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export const HBREIcon: ComponentWithAs<'svg', HBREIconProps> = forwardRef<HBREIconProps, 'svg'>(
  ({ name, 'aria-hidden': ariaHidden = true, 'data-qa': dataQa = 'HBREIcon', ...svgProps }: HBREIconProps, ref) => {
    let Component = HBREIconSvg

    return <ChakraIcon as={Component} aria-hidden={ariaHidden} ref={ref} data-qa={dataQa} {...svgProps} />
  }
)
