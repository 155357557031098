import React from 'react'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { If, Unless, StatusIcon } from '../..'

import styles from './Loading.module.scss'

const LoadingWrapper = ({ innerElement: Element = 'div', done, className, alwaysShowChildren, children }) => (
  <div data-qa='loading-wrapper' className={className}>
    <Unless condition={done}>
      <Element className={classnames(styles.spinner, alwaysShowChildren && styles.absolute)}>
        <span className={styles.loadingDescribe}>
          <FormattedMessage id='Generic.describe.loading' defaultMessage='loading' />
        </span>
        <StatusIcon spin />
      </Element>
    </Unless>
    <If condition={done || alwaysShowChildren}>{children}</If>
  </div>
)

export default LoadingWrapper
