import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Components
import { Button, If } from '../..'

// Styles
import styles from './Dropdown.module.scss'

const propTypes = {
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    wrapperClassName: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.string,
    dataqa: PropTypes.string
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownToggleProps: PropTypes.shape({
    className: PropTypes.string,
    iconEnd: PropTypes.func
  }),
  open: PropTypes.bool.isRequired,
  segmentedDropdown: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
}

const defaultProps = {
  buttonProps: {},
  dropdownToggleProps: {},
  segmentedDropdown: true
}

const Dropdown = ({
  buttonProps,
  children,
  className,
  disabled,
  dropdownToggleProps,
  open,
  segmentedDropdown,
  setOpen
}) => {
  const { className: primaryActionClassName, onClick, label, children: Children, size, dataqa } = buttonProps
  const { wrapperClassName, ...restButtonProps } = buttonProps

  const dropdownRef = useRef(null)

  const { className: dropdownClassName, iconEnd } = dropdownToggleProps

  const handlePrimaryClick = useCallback(
    e => {
      e.stopPropagation()

      if (onClick) {
        onClick()
      }

      if (!segmentedDropdown && setOpen) {
        setOpen(!open)
      }
    },
    [setOpen, open, segmentedDropdown, onClick]
  )

  const handleDropdownClick = useCallback(
    e => {
      e.stopPropagation()

      if (setOpen) {
        setOpen(!open)
      }
    },
    [open, setOpen]
  )

  useEffect(() => {
    const pageClickEvent = e => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setOpen(!open)
      }
    }

    if (open) {
      window.addEventListener('click', pageClickEvent)
    }

    return () => {
      window.removeEventListener('click', pageClickEvent)
    }
  }, [open, setOpen])

  return (
    <div className={classnames(className, styles.container)}>
      <div className={classnames(wrapperClassName, styles.buttonWrapper)}>
        <Button
          {...restButtonProps}
          className={classnames(primaryActionClassName ?? styles.button, {
            [styles.smallButton]: size === 'small'
          })}
          onClick={handlePrimaryClick}
          disabled={disabled}
          data-qa={dataqa || label}
        >
          {/* to render old props such as avatar to agentDropdown */}
          {Children}
          {/* to render new props such as NR dropdown / new implementations */}
          {label}
        </Button>
        <If condition={segmentedDropdown}>
          <div className={styles.verticalRule} />
          <Button
            disabled={disabled}
            className={classnames(styles.dropdownToggleButton, {
              [styles.smallDropdownToggleButton]: size === 'small'
            })}
            iconEnd={iconEnd}
            onClick={handleDropdownClick}
          />
        </If>
      </div>
      <div
        ref={dropdownRef}
        className={classnames(dropdownClassName, styles.dropdownContainer, {
          [styles.open]: open
        })}
      >
        {children}
      </div>
    </div>
  )
}

Dropdown.propTypes = propTypes
Dropdown.defaultProps = defaultProps

export default Dropdown
