import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

// Components
import { FormField, Text } from '../..'
import BaseCheckBox from './BaseCheckBox'

// Styles
import styles from './CheckBox.module.scss'

const propTypes = {
  // attach a class to the checkbox to custom style the label
  className: PropTypes.string,

  // compose a className on the label element
  labelClass: PropTypes.string,

  // inject a styled component to render the text inside the label, passed on children
  labelText: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  // inject a styled input component
  input: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

const DefaultLabelText = ({ className, ...restProps }) => (
  <Text
    element='span'
    variant='neutralCool'
    variantCount='700'
    size='xs'
    className={classnames(styles.text, className)}
    {...restProps}
  />
)

const DefaultInput = ({ className, ...restProps }) => (
  <BaseCheckBox className={classnames(styles.input, className)} {...restProps} />
)

const CheckBox = ({ className, labelText = '', labelClass = '', input: Input = DefaultInput, ...restProps }) => {
  const LabelText = labelText || DefaultLabelText

  return (
    <FormField
      className={classnames(styles.root, className)}
      labelClass={classnames(styles.labelClass, labelClass)}
      labelText={LabelText}
      input={Input}
      {...restProps}
    />
  )
}

CheckBox.propTypes = propTypes

DefaultLabelText.propTypes = {
  className: PropTypes.string
}

DefaultInput.propTypes = {
  className: PropTypes.string
}

export default CheckBox
