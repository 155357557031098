/* eslint-disable prettier/prettier */
import { AtomEffect } from 'recoil'
import { StorageKey } from '../constants/StorageKey'

export const storageEffect =
  <T extends unknown>(key: StorageKey): AtomEffect<T> =>
    ({ setSelf, onSet, trigger }) => {
      const load = async () => {
        const stored = await localStorage.getItem(key)
        const value = stored === null ? undefined : JSON.parse(stored)
        setSelf(value)
      }

      if (trigger === 'get') {
        load()
      }

      // Subscribe to state changes and persist them to localForage
      onSet((newValue, _, isReset) => {
        if (isReset) {
          localStorage.removeItem(key)
        }

        if (newValue) {
          localStorage.setItem(key, JSON.stringify(newValue))
        }
      })
    }
