import get from 'lodash/get'
import memoize from 'lodash/memoize'

import { AUTH_USER_TYPES } from './constants'

export const parseJWT = (token: string) => {
  const content = (token || '').split('.')[1]

  if (!content) {
    return undefined
  }

  try {
    const jwtContentRaw = atob(content)
    return JSON.parse(jwtContentRaw)
  } catch (e) {
    return undefined
  }
}

export const getClientIdFromToken = (token: string) => {
  const parsedToken = get(parseJWT(token), 'acr.values[0].context', '')
  if (!parsedToken?.includes('client')) {
    return null
  }
  const clientId = parsedToken.replace(/client:/, '')
  return clientId
}

export const getParsedJWT = (token: string) => parseJWT(token)

export const getUserIdFromToken = (token: string) => get(parseJWT(token), 'sub', '').replace(/users\//, '') || null

export const getUserTypeFromToken = (token: string) => {
  if (!token) return AUTH_USER_TYPES.UNAUTHED

  const email = getUserEmailFromToken(token)
  if (email && email.includes('@homebot.ai')) {
    return AUTH_USER_TYPES.HOMEBOTTER
  }

  return get(parseJWT(token), 'acr.values[0].roles[0]', '') === 'digest_consumer'
    ? AUTH_USER_TYPES.CLIENT
    : AUTH_USER_TYPES.CUSTOMER
}

export const getUserEmailFromToken = (token: string) => get(parseJWT(token), 'email', '')
