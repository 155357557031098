import produce from 'immer'
import {
  VALUATION_INITIALIZE_STATE,
  ADD_TYV_VALUATION_REQUEST,
  ADD_TYV_VALUATION_SUCCESS,
  ADD_TYV_VALUATION_FAILURE,
  VALUATION_REFRESH_HOME_DATA_SUCCESS,
  VALUATION_REFRESH_HOME_DATA_FAILURE,
  SET_VALUATION_HOME_FACTS_PROGRESS,
  SET_VALUATION_NEEDS_REFRESH
} from '../actions/actionTypes'
import { VALUATION_TYPES } from '../constants/valuation'

const initialState = {
  requestedAmount: null,
  requestedType: null,
  requestedUpdate: null,
  success: false,
  failure: false,
  error: null,
  needsRefresh: false,
  progress: null
}

export default (state = initialState, { type, amount, updated, progress, error }) =>
  produce(state, draft => {
    switch (type) {
      case VALUATION_INITIALIZE_STATE:
        Object.assign(draft, initialState)
        break

      case ADD_TYV_VALUATION_REQUEST:
        draft.requestedAmount = amount
        draft.requestedType = VALUATION_TYPES.TYV
        draft.requestedUpdate = updated
        draft.success = false
        draft.failure = false
        draft.error = null
        break

      case ADD_TYV_VALUATION_SUCCESS:
        draft.success = true
        draft.failure = false
        draft.error = null
        draft.needsRefresh = updated
        break

      case ADD_TYV_VALUATION_FAILURE:
        draft.success = false
        draft.failure = true
        draft.error = error
        break

      case VALUATION_REFRESH_HOME_DATA_SUCCESS:
        draft.needsRefresh = false
        break

      case VALUATION_REFRESH_HOME_DATA_FAILURE:
        draft.needsRefresh = false
        draft.failure = true
        draft.error = error
        break

      case SET_VALUATION_HOME_FACTS_PROGRESS:
        draft.progress = progress
        break

      case SET_VALUATION_NEEDS_REFRESH:
        draft.needsRefresh = true
        break
      default:
        break
    }
  })
