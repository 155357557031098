import React from 'react'
import classnames from 'classnames'
import { number, string, func, oneOfType, node, any } from 'prop-types'
import isNull from 'lodash/isNull'
import { FormattedMessage } from 'react-intl'
import { useSlideOnMount } from '../../../../hooks/animations'
import { getScrollBarWidthFormatted } from '../../../../helpers/scrollbar'

import Header from '../../Header'
import { Button, Text } from '../../..'
import { IconAngleLeft } from '../../Icon'

import styles from './OverlayDefaultHeader.module.scss'

const ProgressBar = ({ progress }) => (
  <div className={styles.headerBarContents}>
    <div className={styles.headerBarProgressBackground}>
      <div className={styles.headerBarProgress} style={{ width: `${progress}%` }} />
    </div>
  </div>
)
ProgressBar.propTypes = {
  progress: number
}

const BackButton = ({ goBack }) => (
  <Button className={styles.backButton} data-qa='back-btn-header' aria-label='back button' onClick={goBack}>
    <span className={styles.buttonInner}>
      <IconAngleLeft className={styles.icon} />
      <Text
        className={styles.backButtonText}
        transform='uppercase'
        size='xxs'
        weight='semibold'
        variant='neutralCool'
        variantCount='700'
      >
        <FormattedMessage id='Button.Back' defaultMessage='Back' />
      </Text>
    </span>
  </Button>
)
BackButton.propTypes = {
  goBack: func
}

const scrollBarWidth = getScrollBarWidthFormatted('full', 'rem')

const OverlayDefaultHeader = ({ title, renderTitle, progress, goBack, className, children }) => {
  let myTitle = title
  if (renderTitle) {
    myTitle = renderTitle(title)
  }

  const style = useSlideOnMount({ top: '-60px' }, true)

  return (
    <Header style={{ ...style, paddingRight: scrollBarWidth }} className={classnames(styles.headerBar, className)}>
      <div className={styles.headerBarContents}>
        <div className={styles.headerBarLeft}>
          <BackButton goBack={goBack} />
        </div>
        <div className={styles.headerBarCenter} data-qa='overlay-header-title'>
          {myTitle}
        </div>
        <div className={styles.headerBarRight} />
      </div>
      <div className={styles.headerBarBreak} />
      {!isNull(progress) ? <ProgressBar progress={progress} /> : null}
      {children}
    </Header>
  )
}

OverlayDefaultHeader.defaultProps = {
  progress: null,
  title: ''
}

OverlayDefaultHeader.propTypes = {
  progress: number,
  title: oneOfType([string, func, node]),
  renderTitle: func,
  goBack: func.isRequired,
  className: string,
  children: any
}

export default OverlayDefaultHeader
