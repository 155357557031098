import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'

const numberOrNull = n => {
  const num = parseFloat(n)

  if (isNaN(num)) {
    return null
  }

  return num
}

const getUnsetSet = (obj, fromPath, toPath) => {
  const datum = get(obj, fromPath)
  unset(obj, fromPath)
  set(obj, toPath, datum)
}

const deepRenameFromPairs = (data, pairs) => {
  pairs.forEach(pair => getUnsetSet(data, ...pair))
  return data
}

const transformResponseWith =
  transformer =>
  apiMethod =>
  async (...args) => {
    const response = await apiMethod(...args)

    const data = transformer(response.data)

    return {
      ...response,
      data
    }
  }

const DETAIL_RENAME_PATHS = [
  ['agent[0].office.brokerage', 'agent[0].office.corporateProfile'],
  ['agent[0].office', 'agent[0].officeProfile'],
  ['agent[0]', 'customerProfile'],
  ['customerProfile.jobTitle', 'customerProfile.title'],
  ['customerProfile.linkedinUrl', 'customerProfile.linkedinUri'],
  ['customerProfile.photo', 'customerProfile.photoUri'],
  ['customerProfile.profileUrl', 'customerProfile.websiteUri']
]

export const homeDetail = transformResponseWith(data => deepRenameFromPairs(data, DETAIL_RENAME_PATHS))

export const loan = ({
  adjustable,
  amount,
  date,
  homeId,
  rate,
  status,
  termYears,
  armYearsInitial,
  loanType,
  pmiMonthly
}) => ({
  adjustable,
  amount,
  arm_years_initial: armYearsInitial,
  date,
  homeId,
  loan_type: loanType,
  pmi_monthly: numberOrNull(pmiMonthly),
  rate,
  status,
  termYears
})

export const invite = ({ agentId, email, firstName, inviterId, lastName }) => ({
  agentId,
  email,
  firstName,
  inviterId,
  lastName
})
