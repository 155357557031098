import classnames from 'classnames'
import { Ring } from '../../Ring'
import { IconLock } from '../../Icon'
import styles from './RingLock.module.scss'

interface RingLockProps {
  className?: string
}

export const RingLock = ({ className }: RingLockProps) => (
  <Ring color='white' className={classnames(className, styles.ring)}>
    <IconLock variant='neutralCool' variantCount='900' className={styles.lock} />
  </Ring>
)
