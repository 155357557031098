import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Components
import { Button } from '../..'

// Styles
import styles from './Panel.module.scss'

const defaultProps = {
  show: true
}

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool
}

const Section = ({ children, className, onClose, show, ...restProps }) => {
  const [visible, setVisibility] = useState(show)

  const toggle = e => {
    e.preventDefault()
    setVisibility(!visible)
  }

  const sectionClass = classnames(styles.section, className, !visible && styles.hidden)

  return (
    <div className={sectionClass} {...restProps}>
      <Button.Close className={styles.closeButton} onClick={onClose || toggle} />
      <div className={styles.content}>{children}</div>
    </div>
  )
}

Section.defaultProps = defaultProps
Section.propTypes = propTypes

export default Section
