import pickBy from 'lodash/pickBy'
import { getInstance as initRecaptcha } from './vendor/recaptcha'

// Moment support
import 'moment/locale/es'

import * as Sentry from '@sentry/browser'

const { NODE_ENV } = process.env
const isProduction = NODE_ENV === 'production'

const initSentry = () => {
  const {
    REACT_APP_SENTRY_KEY: KEY,
    REACT_APP_SENTRY_APP_ID: ID,
    REACT_APP_SENTRY_ENV: ENVIRONMENT,
    REACT_APP_SENTRY_VERSION: VERSION
  } = process.env

  if (!(isProduction && KEY && ID)) {
    return
  }

  const config = pickBy(
    {
      beforeSend(event, hint) {
        const originalException = hint?.originalException

        if (
          originalException &&
          (originalException === 'Timeout' ||
            originalException?.message?.match(/Non-Error promise rejection captured with value: Timeout/i))
        ) {
          return null // Known reCAPTCHA issue -- https://github.com/getsentry/sentry-javascript/issues/2514
        }
        return event
      },
      dsn: `https://${KEY}@sentry.io/${ID}`,
      environment: ENVIRONMENT,
      allowUrls: [/homebot\.rocks/, /homebotapp\.com/, /homebot\.services/],
      ignoreErrors: [
        "InvalidCharacterError: Failed to execute 'createElement' on 'Document': The tag name provided ('<IFRAME name=",
        /Loading chunk [0-9]+ failed/
      ],
      release: VERSION || 'production'
    },
    Boolean
  )

  return Promise.resolve(Sentry.init(config))
}

const configApp = () => Promise.all([initSentry()])

export default configApp
