import React from 'react'
import classnames from 'classnames'

// Components
import { IconCheckCircle, IconInfoCircle, IconTimesCircle } from '../Icon'
import Text from '../Text'

// Styles
import styles from './ProCon.module.scss'
import { TextVariants } from '../Text/Text'

interface ProConItemProps {
  icon: any
  iconColor: TextVariants
  iconVariantCount: any
  children: React.ReactNode
  className?: string
}

const ProConItem = ({ icon: Icon, iconColor, iconVariantCount = '700', children, className }: ProConItemProps) => (
  <li className={classnames(className, styles.li)}>
    <Text variant={iconColor} variantCount={iconVariantCount} element='span' className={styles.iconCol}>
      <Icon />
    </Text>
    <Text size='s' variant='neutralCool' variantCount='700' className={styles.textCol}>
      {children}
    </Text>
  </li>
)

interface ProConInfoProps {
  className?: string
  children: React.ReactNode
}
export const Con = ({ children, className }: ProConInfoProps) => (
  <ProConItem className={className} icon={IconTimesCircle} iconColor='danger' iconVariantCount='500'>
    {children}
  </ProConItem>
)

export const Info = ({ children, className }: ProConInfoProps) => (
  <ProConItem className={className} icon={IconInfoCircle} iconColor='warning' iconVariantCount='500'>
    {children}
  </ProConItem>
)

export const Pro = ({ children, className }: ProConInfoProps) => (
  <ProConItem className={className} icon={IconCheckCircle} iconColor='success' iconVariantCount='700'>
    {children}
  </ProConItem>
)

export const ProConList = props => <ul {...props} />

ProConList.Pro = Pro
ProConList.Con = Con
ProConList.Info = Info

export default ProConList
