import { Svg } from '../../../../..'

export interface ClockIconProps {
  dataQa: string
}

export const ClockIcon = ({ dataQa }: ClockIconProps) => (
  <Svg data-qa={dataQa} width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 0.5C10.8611 0.5 14 3.63889 14 7.5C14 11.3611 10.8611 14.5 7 14.5C3.13889 14.5 0 11.3611 0 7.5C0 3.63889 3.13889 0.5 7 0.5ZM7 13.5C10.3056 13.5 13 10.8056 13 7.5C13 4.19444 10.3056 1.5 7 1.5C3.69444 1.5 1 4.19444 1 7.5C1 10.8056 3.69444 13.5 7 13.5ZM7 7.5V3.5H8V8.5H4V7.5H7Z'
      fill='#9D9298'
    />
  </Svg>
)
