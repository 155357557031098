import React from 'react'
import { withProps } from 'recompose'
import { connect } from 'react-redux'
import { selectHeightFadeTimers } from '../../../../store/selectors/transitions'
import { redrawHeightFadeKey } from '../../../../actions/transitions'
import { useUniqueId } from '../../../../hooks'

const ComponentWithAutoId = React.forwardRef(({ id, component: Component, ...restProps }, ref) => {
  const fallbackId = useUniqueId('HieghtFade_')

  return <Component id={id || fallbackId} forwardedRef={ref} {...restProps} />
})

const HeightFadeContainer = Component => {
  const ComponentWithProps = withProps(props => ({
    animating: Boolean(props.timers && props.timers[props.id]),
    forwardedRef: props.forwardedRef
  }))(Component)

  const EnhancedComponent = React.forwardRef((props, ref) => <ComponentWithProps forwardedRef={ref} {...props} />)

  const ConnectedComponentWithProps = connect(
    state => ({
      timers: selectHeightFadeTimers(state)
    }),
    dispatch => ({
      doAnimate: (id, speed) => dispatch(redrawHeightFadeKey(id, speed))
    }),
    (stateProps, dispatchProps, userProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...userProps,
      component: EnhancedComponent
    }),
    { forwardRef: true }
  )(ComponentWithAutoId)

  return ConnectedComponentWithProps
}

export default HeightFadeContainer
