import { useDispatch } from 'react-redux'
import { defineMessages, useIntl } from 'react-intl'
import { toggleAuthModal } from '../../../../../actions/homeSearchAuthModal'
import { Box, Button } from '@homebotapp/hb-react-component-catalog'

const MSG = defineMessages({
  signUp: {
    id: 'Navbar.signUp.cta',
    defaultMessage: 'Sign up'
  },
  logIn: {
    id: 'Navbar.logIn.cta',
    defaultMessage: 'Log in'
  }
})

export const DesktopAuthButtons = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch(toggleAuthModal(true))
  }

  return (
    <Box display={['none', null, 'block']}>
      <Button colorScheme='primary' onClick={handleOnClick} marginRight={2}>
        {intl.formatMessage(MSG.signUp)}
      </Button>
      <Button colorScheme='primary' variant='outline' onClick={handleOnClick}>
        {intl.formatMessage(MSG.logIn)}
      </Button>
    </Box>
  )
}
