import { fetchMortgageRates as fetchRates } from '../api/mikasa/requests'
import { FETCH_MORTGAGE_RATES_REQUEST, FETCH_MORTGAGE_RATES_SUCCESS, FETCH_MORTGAGE_RATES_FAILURE } from './actionTypes'
import { LOAN_TERMS } from '../constants/loan'

export const fetchMortgageRates = () => async dispatch => {
  await Promise.all(LOAN_TERMS.map(term => fetchMortgageRatesForTerm(term, dispatch)))
}

const fetchMortgageRatesForTerm = async (term, dispatch) => {
  dispatch(fetchMortgageRatesRequest(term))

  try {
    const res = await fetchRates(term)
    dispatch(fetchMortgageRatesSuccess(res, term))
  } catch (err) {
    dispatch(fetchMortgageRatesFailure(err, term))
  }
}

const fetchMortgageRatesRequest = term => ({
  type: FETCH_MORTGAGE_RATES_REQUEST,
  term
})

const fetchMortgageRatesSuccess = (response, term) => dispatch => {
  dispatch({
    type: FETCH_MORTGAGE_RATES_SUCCESS,
    data: response.data.data,
    term
  })
}

const fetchMortgageRatesFailure = (error, term) => ({
  type: FETCH_MORTGAGE_RATES_FAILURE,
  term,
  error: {
    message: error.message
  }
})
