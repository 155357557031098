import get from 'lodash/get'
import { createSelector } from 'reselect'

// Selectors
import {
  selectHomebotListingsFeatureEnabled,
  selectCustomerProfileLoading,
  selectCustomerEmail,
  selectCustomerId
} from './customerProfile'
import { selectClientIsGuest, selectClientIsLoadingData } from './client'

// Constants
import {
  LISTINGS_PURCHASING_POWER,
  LISTINGS_SIMILAR_HOMES,
  LISTINGS_EQUITY_SMART_SEARCH,
  HOMEBOT_LISTINGS_FEATURE,
  LISTINGS_ZIP_CODE,
  LISTINGS_FAVORITES,
  LISTINGS_SEARCH,
  LISTING_MARKERS_SEARCH
} from '../../constants/listings'

// Helpers
import { snakeCaseKeys } from '../../helpers/utils'

export const selectListing = state => state.listings?.listing || null

export const selectSimilarListings = state => state.listings.similarListings

export const selectSimilarHomesListings = state => state.listings.listings[LISTINGS_SIMILAR_HOMES] || null

export const selectPurchasePowerHomeListings = state => state.listings.listings[LISTINGS_PURCHASING_POWER] || null

export const selectEquitySmartSearchListings = state => state.listings.listings[LISTINGS_EQUITY_SMART_SEARCH] || null

export const selectHomebotListingsSearchLoading = state => state.listings.loading[LISTINGS_SEARCH] || false

export const selectHomebotListingsMarkerSearchLoading = state => state.listings.loading[LISTING_MARKERS_SEARCH] || false

export const selectHomebotListingsLoading = state => state.listings?.loading?.listings

export const selectSearchListings = state => state.listings.listings[LISTINGS_SEARCH] || null

export const selectSearchListingsTotal = state => state.listings.searchListingsTotal

export const selectSelectedListingId = state => state.listings.selectedListingId
export const selectPreviouslyViewedListingId = state => state.listings.previouslyViewedListingId

export const selectShowListingSearchModal = state => state.listings.uiFlags.showSearchModal

export const selectListingsSearchIsLoading = createSelector(
  selectCustomerProfileLoading,
  selectClientIsLoadingData,
  selectClientIsGuest,
  (customerProfileLoading, clientIsLoadingData, isGuest) => customerProfileLoading || (clientIsLoadingData && !isGuest)
)
