import { FETCH_VIDEOS_REQUEST, FETCH_VIDEOS_SUCCESS, FETCH_VIDEOS_FAILURE } from '../actions/actionTypes'

export const initialState = {
  data: undefined,
  loading: true,
  error: null
}

export default (state = initialState, action) => {
  const { error, data, type } = action
  switch (type) {
    case FETCH_VIDEOS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_VIDEOS_SUCCESS:
      return {
        ...state,
        data,
        loading: false
      }

    case FETCH_VIDEOS_FAILURE:
      return {
        ...state,
        error: error,
        loading: false
      }
    default:
      return state
  }
}
