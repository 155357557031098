import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import noop from 'lodash/noop'
import omit from 'lodash/omit'

import styles from './Select.module.scss'

const defaultProps = {
  optionKey: 'key',
  optionValue: 'value',
  onChange: noop
}

const keyLikes = [PropTypes.string, PropTypes.number]

const propTypes = {
  // style for use in prose
  inline: PropTypes.bool,

  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  // what to do when an option is chosen
  onChange: PropTypes.func,

  onBlur: PropTypes.func,
  onFocus: PropTypes.func,

  optionKey: PropTypes.string, // Specifies the property used as key on the 'options' collection
  // Used to specify optional value to render in the item. If a function,
  // will be called with the object for rendering (while leaving default
  // option rendering in place)
  optionValue: PropTypes.oneOfType([...keyLikes, PropTypes.func]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
}

class Select extends Component {
  static propTypes = propTypes
  static defaultProps = defaultProps
  constructor(props) {
    super(props)
    this.state = { focused: false }
  }

  setFocused(focused) {
    this.setState({ focused })
  }

  getOptions() {
    const { children, options } = this.props
    return options ? this.mapOptions(this.renderOption) : children
  }

  renderOption = ({ key, value }) => <option className={styles.option} key={key} value={key} children={value} />

  mapOptions(fn) {
    const { options, optionKey, optionValue } = this.props
    const renderer = isFunction(optionValue) ? optionValue : o => get(o, optionValue, o)

    return options.map(o =>
      fn({
        key: get(o, optionKey, o),
        value: renderer(o)
      })
    )
  }

  handleChange = e => this.props.onChange(e.target.value)

  render() {
    const { className, onFocus, onBlur, value, optionKey, inline } = this.props
    const { focused } = this.state
    const passedProps = omit(this.props, Object.keys(propTypes))

    const myValue = value ? (isString(value) || isNumber(value) ? value : value[optionKey]) : undefined

    return (
      <div className={classnames(styles.root, focused && styles.focused, className)}>
        <select
          {...passedProps}
          value={myValue}
          onChange={this.handleChange}
          onBlur={e => {
            this.setFocused(false)
            if (onBlur) onBlur(e)
            this.handleChange(e)
          }}
          onFocus={e => {
            this.setFocused(true)
            if (onFocus) onFocus(e)
          }}
          className={classnames(styles.select, inline && styles.inline)}
          children={this.getOptions()}
        />
      </div>
    )
  }
}

export default Select
