import { SET_REFERRER_SOURCE } from './actionTypes'
import { HOMEOWNER_AUDIENCE, BUYER_AUDIENCE } from '../constants/customMailer'
import { selectReferrerSource } from '../store/selectors/trackingSource'
import { ClientEventName } from 'src/api/gqlaxy/gql/generated/graphql'

export const trackVideoMailerView =
  ({ audienceType, videoId, videoSource }, createViewedVideoEmailClientEvent) =>
  (dispatch, getState) => {
    const state = getState()
    const referrerSource = selectReferrerSource(state)
    if (audienceType === HOMEOWNER_AUDIENCE) {
      createViewedVideoEmailClientEvent()
    } else if (audienceType === BUYER_AUDIENCE) {
      createViewedVideoEmailClientEvent({
        clientEventInput: {
          eventName: ClientEventName.ViewedVideoEmail,
          properties: {
            activity_source: referrerSource,
            audience: audienceType,
            video_id: videoId,
            video_source: videoSource
          }
        }
      })
    }
    dispatch(setReferrerSource(null))
  }

export const setReferrerSource = source => dispatch => {
  dispatch({
    type: SET_REFERRER_SOURCE,
    referrer: source
  })
}
