import produce from 'immer'
import { ADD_REDRAW_HEIGHT_FADE_KEY, REMOVE_REDRAW_HEIGHT_FADE_KEY } from '../actions/actionTypes'

const initialState = {
  heightFadeTimers: {}
}

export default (state = initialState, { type, id, timer }) =>
  produce(state, draft => {
    switch (type) {
      case ADD_REDRAW_HEIGHT_FADE_KEY:
        if (draft.heightFadeTimers[id]) {
          clearTimeout(draft.heightFadeTimers[id])
        }
        draft.heightFadeTimers[id] = timer
        break

      case REMOVE_REDRAW_HEIGHT_FADE_KEY:
        delete draft.heightFadeTimers[id]
        break

      default:
        break
    }
  })
