import PropTypes from 'prop-types'
import { compose, setPropTypes, withHandlers, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import Questions from './Questions'
import { selectRouteKey } from '../../../store/selectors/routes'
import { selectCustomerProfile, selectCustomerFirstName } from '../../../store/selectors/customerProfile'
import {
  selectSponsorship,
  selectSponsorshipFirstName,
  selectSponsorshipFullName
} from '../../../store/selectors/sponsorship'
import { selectCoBrandedIntroVideoId, selectCoBrandedIntroVideoType } from '../../../store/selectors/videos'
import { selectShouldRenderSponsorBranding } from '../../../store/selectors/brandingStats'

const propTypes = {
  disableCospo: PropTypes.bool
}

export const enhance = compose(
  setPropTypes(propTypes),
  connect((state, { disableCospo }) => ({
    isBuyer: selectRouteKey() === 'buy',
    customerProfile: selectCustomerProfile(state) || {},
    testDrive: state.buyerInfo.testDrive,
    sponsor: disableCospo ? {} : selectSponsorship(state),
    customerFirstName: selectCustomerFirstName(state),
    sponsorshipFirstName: selectSponsorshipFirstName(state),
    sponsorshipFullName: selectSponsorshipFullName(state),
    sponsorVideoId: selectCoBrandedIntroVideoId(state),
    sponsorVideoType: selectCoBrandedIntroVideoType(state),
    renderSponsorBranding: selectShouldRenderSponsorBranding(state)
  })),
  withProps(({ customerProfile }) => {
    const { firstName, lastName } = customerProfile

    return {
      customerName: `${firstName} ${lastName}`
    }
  }),
  withState('inputValues', 'setInputValues', {}),
  withHandlers({
    onChange:
      ({ setInputValues, inputValues }) =>
      e => {
        const { value, name } = e.target
        const newState = Object.assign({}, inputValues, { [name]: value })
        setInputValues(newState)
      }
  })
)

export default enhance(Questions)
