import classnames from 'classnames'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { ReactElement, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import usePortal from 'react-cool-portal'
import { matchPath } from 'react-router'
import { Route } from 'react-router-dom'
import { scrollToElement } from '../../../helpers/browser'
import { useUniqueId } from '../../../hooks'
import { useFadeOnMount } from '../../../hooks/animations'
import OverlayDefaultHeader from './Headers/OverlayDefaultHeader'
import { ActiveOverlayContext } from './OverlayContext'
import styles from './OverlayPanel.module.scss'

export const OverlayRouter = React.forwardRef<
  ReactElement,
  {
    title: string
    renderTitle?: (title: string) => ReactElement | undefined
    path: string
    className?: string
    disableAnchor?: boolean
    anchorOffset?: number
    onOpen?: () => void
    onClose?: () => void
    back?: string | null
    render?: (args: any) => ReactElement[] | ReactElement
    renderHeader?: (args: any) => ReactElement[] | ReactElement | undefined
    component?: (args: any) => JSX.Element
  }
>(
  (
    { title, renderTitle, path, className, disableAnchor, onOpen, onClose, back, component, ...restProps },
    ref: any
  ) => {
    const id = useUniqueId(`Overlay_${!!title && title.replace(/\s/g, '_')}_`)
    const { navigation, updateNavigationState, updateOverlayState } = useContext(ActiveOverlayContext) || {
      updateOverlayState: noop,
      updateNavigationState: noop,
      navigation: {}
    }
    const handleOpen = useCallback(() => {
      updateOverlayState({ id, data: { title } })
      typeof onOpen === 'function' && onOpen()
    }, [id, updateOverlayState, title, onOpen])

    const handleClose = useCallback(() => {
      updateOverlayState({ id })
      typeof onClose === 'function' && onClose()
    }, [id, updateOverlayState, onClose])

    useEffect(() => {
      if (!navigation[id]) {
        updateNavigationState({
          id,
          back,
          direct: !!matchPath(window.location.pathname, { path })
        })
      }
    }, [id, back, path, navigation, updateNavigationState])

    return (
      <Route
        path={path}
        render={args => (
          <OverlayInner
            component={component}
            id={id}
            forwardedRef={ref}
            handlePanelOpen={handleOpen}
            handlePanelClose={handleClose}
            disableAnchor={disableAnchor}
            args={args}
            title={title}
            renderTitle={renderTitle}
            className={className}
            {...restProps}
          />
        )}
      />
    )
  }
)

export const animationBuffer = 75

export const OverlayInner = ({
  component: Component,
  render,
  renderHeader,
  renderTitle,
  id,
  args,
  title,
  anchorOffset,
  className,
  handlePanelOpen,
  handlePanelClose,
  disableAnchor,
  forwardedRef
}: {
  component?: (args: any) => JSX.Element
  render?: (args: any) => ReactElement[] | ReactElement | undefined
  renderHeader?: (props: any) => ReactElement | ReactElement[] | undefined
  renderTitle?: (title: string) => ReactElement | undefined
  id: string
  args?: any
  title?: string
  anchorOffset?: number
  className?: string
  handlePanelOpen: () => void
  handlePanelClose: () => void
  disableAnchor?: boolean
  forwardedRef: React.MutableRefObject<any>
}) => {
  const fallbackRef = useRef(null)
  const [mountInner, setMountInner] = useState(false)
  const { closeStyle, navigation, doNavBack, overlays } = useContext(ActiveOverlayContext) || {
    overlays: {},
    setExitingPanel: noop
  }
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false, // Disable the built-in show/hide portal functions, default is true
    onShow: _e => {
      setTimeout(() => setMountInner(true), animationBuffer)
    },
    onHide: _e => {
      // do nothing
    },
    containerId: 'themeContainer' // mount in root to access css vars
  })

  const style = useFadeOnMount({ duration: animationBuffer * 0.66 })

  const imperative: any = () => ({
    back: () => doNavBack()
  })

  // add close to ref instances
  useImperativeHandle(forwardedRef, imperative, [doNavBack])

  // track and render open/close for portal and panel
  useEffect(() => {
    if (!isShow && Object.keys(overlays).length) {
      // show portal if currently hidden and overlays has stuff
      show()
    }

    if (!overlays[id]) {
      // track individual panel ids
      handlePanelOpen()
    }

    return () => {
      handlePanelClose()
      if (isShow && Object.keys(overlays).length === 0) {
        // hide portal if we have no more ids to show
        hide()
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // scroll background container for transition back
  const enteredAppInsidePanel = navigation[id] && navigation[id].direct
  useEffect(() => {
    if (enteredAppInsidePanel && !disableAnchor) {
      const element = forwardedRef ? forwardedRef.current : fallbackRef.current
      scrollToElement(element)
      return () => scrollToElement(element, { padding: anchorOffset || 0 })
    }
    return noop
  }, [forwardedRef, enteredAppInsidePanel, anchorOffset, disableAnchor])

  // hacky hack hack TODO: remove?
  document.documentElement.style.width = '100vw'
  document.documentElement.style.height = '100vh'
  document.documentElement.style.overflow = 'hidden'
  document.documentElement.style.position = 'fixed'
  document.documentElement.style.top = '0'
  useEffect(
    () => () => {
      document.documentElement.style.removeProperty('width')
      document.documentElement.style.removeProperty('height')
      document.documentElement.style.removeProperty('overflow')
      document.documentElement.style.removeProperty('position')
      document.documentElement.style.removeProperty('top')
    },
    []
  )

  const renderHeaderComponent = () => {
    const props = {
      goBack: () => doNavBack(),
      renderTitle,
      title,
      ...args
    }

    if (renderHeader) {
      return renderHeader(props)
    }
    return <OverlayDefaultHeader {...props} />
  }

  return (
    <>
      <div id={id} ref={forwardedRef || fallbackRef} className={styles.overlayAnchor} />
      <Portal>
        <div
          style={closeStyle || style}
          className={classnames(styles.overlayCurtain, overlays[id] && overlays.activeId !== id && styles.hidden)}
        >
          {renderHeaderComponent()}
          <div className={classnames(styles.overlayPanel, className)}>
            {mountInner && Component && <Component {...args} />}
            {mountInner && !Component && render && render(args)}
          </div>
        </div>
      </Portal>
    </>
  )
}

OverlayRouter.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  renderTitle: PropTypes.func, // Overwrites how title is rendered
  onOpen: PropTypes.func,
  back: PropTypes.string, // default route to back when no history provided
  onClose: PropTypes.func
}

OverlayInner.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  render: PropTypes.func,
  renderHeader: PropTypes.func,
  renderTitle: PropTypes.func,
  id: PropTypes.string,
  args: PropTypes.shape({
    history: PropTypes.object
  }),
  title: PropTypes.string,
  anchorOffset: PropTypes.number, // vertical offset of anchor for position in digest
  disableAnchor: PropTypes.bool, // Don't bother with anchor scroll
  className: PropTypes.string,
  handlePanelOpen: PropTypes.func,
  handlePanelClose: PropTypes.func,
  forwardedRef: PropTypes.shape({
    current: PropTypes.object
  })
}

export default OverlayRouter
