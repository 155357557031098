import {
  Modal,
  Heading,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton
} from '@homebotapp/hb-react-component-catalog'
import { RootState } from '../../../types/rootState'
import { useIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalTrackingEventLocation } from '../../../constants/tracking'
import { setIsShowingNativeAppCtaModal } from '../../../store/slices/modals'
import { QrCodeWithAppStoreLinks } from './QrCodeWithAppStoreLinks/QrCodeWithAppStoreLinks'
export const MSG = defineMessages({
  getAheadOfTheCurve: {
    id: 'NativeAppQrCodeModal.getAheadOfTheCurve',
    defaultMessage: 'Get ahead of the curve'
  }
})

export const NativeAppQrCodeModal = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isShowingNativeAppCtaModal = useSelector<RootState, boolean>(state => state.modals.isShowingNativeAppCtaModal)

  return (
    <Modal isOpen={isShowingNativeAppCtaModal} onClose={() => dispatch(setIsShowingNativeAppCtaModal(false))}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as='h2' textAlign='left' marginBottom={0}>
            {intl.formatMessage(MSG.getAheadOfTheCurve)}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom={6}>
          <QrCodeWithAppStoreLinks
            tracking={{
              guid: 'adagzzSo2BATJwZZnRQRBA',
              ui_context: GlobalTrackingEventLocation.GLOBAL_NATIVE_APP_QR_MODAL
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
