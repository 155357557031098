import { postMessage } from '../../helpers/browser'

const simpleAction = actionType => ({
  type: 'action',
  actionType
})

export const requestClose = () => postMessage(simpleAction('close'), '*')

export const requestLeadCreated = () => postMessage(simpleAction('lead-created'), '*')

export const pushLeadGenEvent = event => {
  postMessage(simpleAction(event), '*')
}

export const openLink = url =>
  postMessage(
    {
      type: 'action',
      actionType: 'openExternalLink',
      link: url
    },
    '*'
  )

export const requestMailTo = url =>
  postMessage(
    {
      type: 'action',
      actionType: 'sendEmailRequest',
      link: url
    },
    '*'
  )
