import cookies from 'js-cookie'
import get from 'lodash/get'
import { getSafeLocalStorage, getSafeSessionStorage, getTopLevelDomain } from '../helpers/browser'
import { getClientIdFromPath } from '../constants/navigation'
import { AUTH } from '../constants'
import { getClientIdFromToken, parseJWT } from '../quartermaster/src'

// TODO: TS on window?
const localStorage: any = getSafeLocalStorage()
const sessionStorage: any = getSafeSessionStorage()

/**
 * PREFER_CLIENT_AUTH
 * Used to determine whether or not to use client auth instead of agent
 * auth for the current session.
 *
 * Goal is to prefer the client JWT over the agent JWT when a user comes
 * in through the auth routes. Auth routes will automatically set the client JWT
 * from jwt in fragment, so when the client JWT gets set, we flag the entire
 * session
 */
const PREFER_CLIENT_AUTH = 'PREFER_CLIENT_AUTH'

const AUTH_COOKIE_SETTINGS = Object.freeze({
  expires: 182,
  sameSite: 'strict',
  path: '/',
  domain: getTopLevelDomain()
})

const getPreferClientFlag = () => sessionStorage.getItem(PREFER_CLIENT_AUTH) === 'true'

const setPreferClientFlag = () => sessionStorage.setItem(PREFER_CLIENT_AUTH, 'true')

const getCustomerToken = () => {
  const cookie = cookies.getJSON(AUTH.SESSION_COOKIE)
  return get(cookie, AUTH.COOKIE_TOKEN_KEY)
}

export const getClientToken = () => {
  return cookies.get(AUTH.JWT_SESSION_COOKIE) || localStorage.getItem(AUTH.ACCESS_TOKEN_KEY)
}

export const hasClientToken = () => Boolean(getClientToken())
export const hasCustomerToken = () => Boolean(getCustomerToken())

export const getBearerToken = () => {
  if (getPreferClientFlag()) {
    const clientToken = getClientToken()
    const tokenId = getClientIdFromToken(clientToken)
    const pathId = getClientIdFromPath()

    return clientToken && (tokenId === pathId || !pathId) ? clientToken : getCustomerToken()
  }
  return getCustomerToken() || getClientToken()
}

export const getParsedJWT = () => parseJWT(getBearerToken())

export const getAuthEmail = () => (getParsedJWT() || {}).email

export const UNSUPPORTED_AUTH_KINDS: string[] = []

export const isAuthTokenSupported = () => {
  const authKind = getParsedJWT()?.kind
  return !UNSUPPORTED_AUTH_KINDS.includes(authKind)
}

export const clearClientToken = () => {
  cookies.remove(AUTH.JWT_SESSION_COOKIE, AUTH_COOKIE_SETTINGS)
  localStorage.removeItem(AUTH.ACCESS_TOKEN_KEY)
}

export const setClientToken = token => {
  localStorage.setItem(AUTH.ACCESS_TOKEN_KEY, token)
  cookies.set(AUTH.JWT_SESSION_COOKIE, token, AUTH_COOKIE_SETTINGS)
  setPreferClientFlag()
}

export const getAuthHeaders = () => {
  const token = getBearerToken()
  const headers: { Authorization?: string } = {}

  if (token) {
    headers.Authorization = `Bearer ${getBearerToken()}`
  }

  return headers
}

export const isAuthenticated = () => Boolean(getBearerToken())

const getClientIdFromBearerToken = () => getClientIdFromToken(getBearerToken())

export { getClientIdFromBearerToken }
