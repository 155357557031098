import { TrackingEventConstants } from '../../constants/tracking'
import { CustomerType } from '../../types/customerType'

export const getTrackingCustomerType = (customerType: CustomerType) => {
  switch (customerType) {
    case CustomerType.LoanOfficers:
      return TrackingEventConstants.CUSTOMER_TYPE_LO
    case CustomerType.InsuranceAgents:
      return TrackingEventConstants.CUSTOMER_TYPE_IA
    case CustomerType.RealEstateAgents:
      return TrackingEventConstants.CUSTOMER_TYPE_REA
    default:
      console.error('invalid customer type provided')
      return undefined
  }
}

export const getTrackingMarketCriteria = (sortVal: 'sqft' | 'price' | 'score' | 'timeline') => {
  switch (sortVal) {
    case 'sqft':
      return TrackingEventConstants.MARKET_CRITERIA_BIGGER_HOUSE
    case 'timeline':
      return TrackingEventConstants.MARKET_CRITERIA_FASTEST_MOVE
    case 'price':
      return TrackingEventConstants.MARKET_CRITERIA_CHEAPER_BUYS
    case 'score':
      return TrackingEventConstants.MARKET_CRITERIA_POPULAR_MARKETS
    default:
      console.error('invalid sortVal provided')
      return undefined
  }
}
