import { getSafeSessionStorage } from '../helpers/browser'

const sessionStorage = getSafeSessionStorage()

export const setAgentToken = token => {
  sessionStorage.setItem('token', token)
}

const getAgentToken = () => sessionStorage.getItem('token')

export const getAgentHeaders = () => ({
  Authorization: `hb-cust-pub ${getAgentToken()}`
})
