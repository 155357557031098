import { LISTING_COUNTY } from '../../constants/listings'
import capitalize from 'lodash/capitalize'
import { ListingDetailsListing, SearchListing } from '../../types/listings'

export const formatCountyName = name => {
  const parts = name.split(',')
  const countyName = parts[0]
  const stateAbbreviation = parts[1]

  return countyName && stateAbbreviation ? `${countyName} ${capitalize(LISTING_COUNTY)}, ${stateAbbreviation}` : name
}

export const formatDisplayNameByLocationLevel = (level, name) => {
  switch (level) {
    case LISTING_COUNTY:
      return formatCountyName(name)
    default:
      return name
  }
}

export const fullAddress = (l: ListingDetailsListing | SearchListing) =>
  `${l.streetNumber} ${l.streetName} ${l.unitNumber || '\b'} ${l.city}, ${l.state} ${l.postalCode}`
