import {
  FieldValues,
  Form,
  Input,
  Button,
  Flex,
  Text,
  Heading,
  FormControl,
  FormErrorMessage,
  FormLabel
} from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { BotHeadSpin } from '../../../Components'
import { useSignUpProvider } from '../SignUpContext'
import { emailRegex } from '../../../constants/regexes'
import { GLOBAL_SIGNUP } from '../../../constants/tracking/trackingEventLocations/globalTrackingEventLocation'
import styles from './Email.module.scss'

export const MSG = defineMessages({
  emailLabelDescription: {
    id: 'NewBuyerSignUp.emailStep.helper',
    defaultMessage: 'To get things started, enter your email.'
  },
  emailLabel: {
    id: 'NewBuyerSignUp.emailStep.label',
    defaultMessage: 'Your email'
  },
  emailRequired: {
    id: 'HomePreview.signUpForm.step1EmailRequired',
    defaultMessage: 'Email address is required.'
  },
  emailInvalid: {
    id: 'HomePreview.signUpForm.step1ValidEmail',
    defaultMessage: 'Please enter a valid email address.'
  },
  continue: {
    id: 'NewBuyerSignUp.emailStep.continueBtn',
    defaultMessage: 'Continue'
  },
  cancel: {
    id: 'NewBuyerSignUp.nameStep.cancelBtn',
    defaultMessage: 'Cancel'
  },
  hiGuest: {
    id: 'NewBuyerSignUp.emailStep.salutation',
    defaultMessage: 'Hi, Guest!'
  }
})

export const Email = () => {
  const intl = useIntl()
  const { loading, handleCheckClient } = useSignUpProvider()

  const handleSubmitEmailStep = (data: FieldValues) => {
    // todo - discuss typing with DS folks if we can make this cleaner
    handleCheckClient(data.emailAddress)
  }

  if (loading) {
    return <BotHeadSpin panelSpacing />
  }

  return (
    <div className={styles.emailStep}>
      <Heading color='neutral-900' textAlign='left'>
        {intl.formatMessage(MSG.hiGuest)}
      </Heading>
      <Text color='neutral-700' size='sm'>
        {intl.formatMessage(MSG.emailLabelDescription)}
      </Text>
      <Form className={styles.form} onSubmit={data => handleSubmitEmailStep(data)}>
        {({ formState: { errors } }) => (
          <Flex flexDirection='column' justifyContent='space-between' flex={1}>
            <FormControl isInvalid={!!errors?.emailAddress}>
              <FormLabel>{intl.formatMessage(MSG.emailLabel)}</FormLabel>
              <Input
                size='sm'
                name='emailAddress'
                data-qa={`signup-email-input`}
                tracking={{
                  guid: 'feTvxxFzueSj5x7EPP7qYF',
                  ui_context: GLOBAL_SIGNUP
                }}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage(MSG.emailRequired)
                  },
                  pattern: {
                    value: emailRegex,
                    message: intl.formatMessage(MSG.emailInvalid)
                  }
                }}
              />
              <FormErrorMessage>{errors?.emailAddress?.message?.toString()}</FormErrorMessage>
            </FormControl>
            <Flex direction='column'>
              <Button
                colorScheme='primary'
                marginBottom={3}
                tracking={{
                  guid: 'cTjVjzAGSgapnuBDv73Hnx',
                  descriptor: MSG.continue.defaultMessage,
                  ui_context: GLOBAL_SIGNUP
                }}
                type='submit'
                data-qa={`signup-email-continue-btn`}
              >
                {intl.formatMessage(MSG.continue)}
              </Button>
            </Flex>
          </Flex>
        )}
      </Form>
    </div>
  )
}
