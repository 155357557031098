import { useEffect } from 'react'

const useFixBodyHeight = (scrollBarCompensationInput?: number) =>
  useEffect(() => {
    const scrollBarCompensation =
      scrollBarCompensationInput !== undefined
        ? scrollBarCompensationInput
        : window.innerWidth - document.body.offsetWidth

    document.body.style.maxHeight = '100vh'
    document.body.style.overflow = 'hidden'
    document.body.style.paddingRight = `${scrollBarCompensation}px`

    return () => {
      document.body.style.removeProperty('max-height')
      document.body.style.overflow = ''
      document.body.style.paddingRight = '0px'
    }
  }, [scrollBarCompensationInput])

export default useFixBodyHeight
