import PropTypes from 'prop-types'
import Svg from '../Svg'

const QuotesSvg = ({ className }) => (
  <Svg className={className} width='27' height='18' viewBox='0 0 27 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.2175 0.608398L12.4562 3.20166C10.4599 3.20166 8.55667 3.86026 6.74649 5.17747C4.95322 6.49469 4.05659 7.80366 4.05659 9.10441C4.05659 9.49957 4.20039 9.69716 4.48799 9.69716L4.84326 9.67246C6.07825 9.67246 7.0933 10.0347 7.88843 10.7592C8.70047 11.4672 9.1065 12.3892 9.1065 13.5253C9.1065 14.5791 8.72585 15.4764 7.96456 16.2174C7.20327 16.9418 6.29817 17.3041 5.24928 17.3041C3.98046 17.3041 2.8639 16.7936 1.8996 15.7728C0.952209 14.752 0.478516 13.4265 0.478516 11.7965C0.478516 8.98092 1.67121 6.41236 4.05659 4.09078C6.44197 1.76919 9.4956 0.608398 13.2175 0.608398ZM26.5655 0.608398L25.8042 3.20166C23.8248 3.20166 21.93 3.8685 20.1199 5.20217C18.3097 6.51938 17.4046 7.82013 17.4046 9.10441C17.4046 9.49957 17.5484 9.69716 17.836 9.69716L18.1913 9.67246C19.4093 9.67246 20.4159 10.0347 21.211 10.7592C22.0231 11.4672 22.4291 12.3892 22.4291 13.5253C22.4291 14.5791 22.0485 15.4764 21.2872 16.2174C20.5428 16.9418 19.6377 17.3041 18.5719 17.3041C17.3369 17.3041 16.2373 16.8019 15.273 15.7975C14.3087 14.7767 13.8265 13.443 13.8265 11.7965C13.8265 8.98092 15.0107 6.41236 17.3792 4.09078C19.7646 1.76919 22.8267 0.608398 26.5655 0.608398Z'
      fill='white'
    />
  </Svg>
)

QuotesSvg.propTypes = {
  className: PropTypes.string
}

export default QuotesSvg
