import {
  PLACE_SEARCHES,
  SAVED_SEARCHES,
  RECENT_SEARCHES,
  LISTING_SEARCH_ARIA_LABELLED_BY
} from '../../../../../../constants/listings'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { Text } from '../../../../..'
import { PlaceIcon } from './PlaceIcon'
import { ClockIcon } from './ClockIcon'
import { SearchResult } from './SearchResult'
import { defineMessages, useIntl } from 'react-intl'
import { useListingSearchContext } from '../../../../../../context/listingSearch'

import styles from './SearchResultsList.module.scss'

export const MSG = defineMessages({
  [RECENT_SEARCHES]: {
    id: 'SearchModal.results.recentSearches',
    defaultMessage: 'Recent'
  },
  [PLACE_SEARCHES]: {
    id: 'SearchModal.results.placeSearches',
    defaultMessage: 'Places'
  }
})

export const SearchResultsList = ({ setLeadListings, setLeadListingsIsFetching }) => {
  const intl = useIntl()
  let indexOfResultInFlattenResults = -1
  const { results, resultsListId } = useListingSearchContext()
  const types = Object.keys(results)?.filter(type => !isEmpty(results[type]))
  const TYPES_OF_SEARCHES_MAPPING = {
    [RECENT_SEARCHES]: {
      title: intl.formatMessage(MSG[RECENT_SEARCHES]),
      icon: <ClockIcon dataQa='svg-clock' />
    },
    [PLACE_SEARCHES]: {
      title: intl.formatMessage(MSG[PLACE_SEARCHES]),
      icon: <PlaceIcon dataQa='svg-place' />
    }
  }

  return (
    <>
      {types.map(type => (
        <div key={type} data-qa='resultList' className={styles.top}>
          <div className={styles.container}>
            <div className={styles.searchType}>
              <div className={styles.header}>{TYPES_OF_SEARCHES_MAPPING[type]?.icon}</div>
              <Text
                size='xxs'
                weight='semibold'
                variantCount='500'
                variant='neutralWarm'
                transform='uppercase'
                className={styles.subHeader}
              >
                {TYPES_OF_SEARCHES_MAPPING[type]?.title}
              </Text>
            </div>
            <div className={styles.listContainer}>
              <ul
                role='listbox'
                id={resultsListId}
                data-qa={resultsListId}
                className={styles.list}
                aria-labelledby={LISTING_SEARCH_ARIA_LABELLED_BY}
              >
                {results[type]?.map((result, index) => {
                  // Can't rely on the index of the search result in the flatten results since
                  // One search result can belong to both recent and saved searches
                  // See: https://github.com/homebotapp/kraken/pull/353#discussion_r987337430
                  indexOfResultInFlattenResults++
                  return (
                    <React.Fragment key={result.displayName || result?.payload?.id}>
                      <SearchResult
                        type={type}
                        index={index}
                        result={result}
                        setLeadListings={setLeadListings}
                        setLeadListingsIsFetching={setLeadListingsIsFetching}
                        isLastResult={index === results[type].length - 1}
                        indexOfResultInFlattenResults={indexOfResultInFlattenResults}
                      />
                    </React.Fragment>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
