import classnames from 'classnames'
import times from 'lodash/times'
import uniqueId from 'lodash/uniqueId'
import styles from './LineScale.module.scss'

const BAR_COUNT = 5 // Keep in sync with scss until we have json wired in

// Ahead of time for slight render performance
const BARS = times(BAR_COUNT, i => <div key={uniqueId('lineScale')} />)

interface LineScaleLoaderProps {
  className?: string
}

const LineScaleLoader = ({ className }: LineScaleLoaderProps) => (
  <div data-qa='line-scale-loader' className={classnames(styles.lineScale, className)}>
    {BARS}
  </div>
)

export default LineScaleLoader
