import history from '../../history'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AppLayout } from '../../App/Layout/AppLayout'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveNavigationTab } from '../../store/slices/navigation'
import { NAVIGATION_TAB_NAMES } from '../../types/navigation'
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Grid,
  ButtonGroup,
  Heading,
  useTheme,
  Icon,
  Switch,
  FormLabel,
  FormControl
} from '@homebotapp/hb-react-component-catalog'
import { ListingCardNew } from '../../Components/homeSearch/ListingCardNew/ListingCardNew'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { ClientEventName, ClientEventSource, FeedType, ListingCompact } from '../../api/gqlaxy/gql/generated/graphql'
import { useGetFeed } from '../../hooks/gallery/useFeed'
import { useRecoilState } from 'recoil'
import {
  CurrentSelectedLocation,
  currentSelectedLocation
} from '../../Components/homeSearch/GoogleAutoComplete/state/currentSelectedLocation'
import { GallerySubscribeModal } from '../../Components/homeSearch/GallerySubscribeModal/GallerySubscribeModal'
import { useToggleFeedSubscribed } from '../../hooks/gallery/useToggleFeedSubscribed'
import { GoogleAutoComplete } from '../../Components/homeSearch/GoogleAutoComplete/GoogleAutoComplete'
import { useChangeFeedLocation } from '../../hooks/gallery/useChangeFeedLocation'
import { HomeSearchFooter } from '../../Components/homeSearch/HomeSearchFooter/HomeSearchFooter'
import { selectHomebotListingsFeatureEnabled, selectNativeAccessFeature } from '../../store/selectors/customerProfile'
import { RootState } from '../../types/rootState'
import useViewport from '../../hooks/useViewport'
import { DownloadMobileAppPromptCard } from '../../Components/homeSearch/ListingDetails/DownloadMobileAppPromptCard/Desktop/DownloadMobileAppPromptCard'
import { MobileDownloadMobileAppPromptCard } from '../../Components/homeSearch/ListingDetails/DownloadMobileAppPromptCard/Mobile/MobileDownloadMobileAppPromptCard'
import { getClientIdFromBearerToken, isAuthTokenSupported } from '../../auth'
import { fetchProfile } from '../../actions/customerProfile'
import { fetchClientData, fetchClientHomes, setCurrentClient } from '../../actions/client'
import { NoPreviewModeModal } from '../../Components/NoPreviewModeModal/NoPreviewModeModal'
import { SEARCH_PATH_HELPERS } from '../../constants/navigation'
import { useEffectOncePerSessionConditionally, useCreateClientEvent } from '../../hooks'
import { AssumablesDirectMessage } from '../../Components/homeSearch/AssumablesDirectMessage/AssumablesDirectMessage'
import { AssumablesLearnMoreModal } from '../../Components/homeSearch/AssumablesLearnMoreModal/AssumablesLearnMoreModal'
import { GenericFeedDirectMessage } from '../../Components/homeSearch/GenericFeedDirectMessage/GenericFeedDirectMessage'
import { BuyerPerksLearnMoreModal } from '../../Components/homeSearch/BuyerPerksLearnMoreModal/BuyerPerksLearnMoreModal'
import topographicalMapDark from '../../assets/homes/img/topo-bg-wide.svg'
import topographicalMapLight from '../../assets/homes/img/topo-bg-wide-light.svg'
import { FeedEmptyState } from '../../Components/FeedEmptyState/FeedEmptyState'
import { HOME_SEARCH_FOR_YOU_INDIVIDUAL_GALLERY_PAGE } from '../../constants/tracking/trackingEventLocations/homeSearchTrackingEventLocation'

export const MSG = defineMessages({
  back: {
    id: 'Gallery.back',
    defaultMessage: 'Back'
  },
  noListingFound: {
    id: 'General.noListingFound',
    defaultMessage: 'No listings found'
  },
  subscribe: {
    id: 'Gallery.subscribe',
    defaultMessage: 'Alerts off'
  },
  subscribed: {
    id: 'Gallery.subscribed',
    defaultMessage: 'Alerts on'
  },
  homes: {
    id: 'Gallery.homes',
    defaultMessage: 'Homes'
  },
  updatedToday: {
    id: 'Gallery.updatedToday',
    defaultMessage: 'Updated today'
  },
  recentPriceDrop: {
    id: 'Gallery.priceDrops',
    defaultMessage: 'Price Drops'
  },
  recentPriceDropDescription: {
    id: 'Gallery.priceDropsDescription',
    defaultMessage: 'See places with a reduced asking price'
  },
  savedSearch: {
    id: 'Gallery.savedSearch',
    defaultMessage: 'Saved Search'
  },
  favorites: {
    id: 'Gallery.favorites',
    defaultMessage: 'Favorites'
  },
  concessions: {
    id: 'Gallery.concessions',
    defaultMessage: 'Seller Concessions'
  },
  concessionsDescription: {
    id: 'Gallery.concessionsDescription',
    defaultMessage: 'Sellers offer concessions that cover part of your expenses as an incentive to make a sale.'
  },
  recentlySold: {
    id: 'Gallery.recentlySold',
    defaultMessage: 'Recently Sold'
  },
  recentlySoldDescription: {
    id: 'Gallery.recentlySoldDescription',
    defaultMessage: 'Explore to see what the market is like'
  },
  assumables: {
    id: 'Gallery.assumables',
    defaultMessage: 'Assumable Loans'
  },
  assumablesDescription: {
    id: 'Gallery.assumablesDescription',
    defaultMessage:
      "Ask your lender if you qualify for an assumable mortgage. You may be able to save by taking over the seller's low-rate loan!"
  },
  yourStuff: {
    id: 'Gallery.yourStuff',
    defaultMessage: 'Your Stuff'
  },
  localGallery: {
    id: 'Gallery.localGallery',
    defaultMessage: 'Local Gallery'
  },
  forYou: {
    id: 'Gallery.forYou',
    defaultMessage: 'For You'
  },
  help: {
    id: 'Gallery.help',
    defaultMessage: 'Let’s find your next home'
  },
  localGalleries: {
    id: 'Gallery.localGalleries',
    defaultMessage: 'Local Galleries'
  },
  changeLocation: {
    id: 'Gallery.changeLocation',
    defaultMessage: 'Change Location'
  },
  generalGalleriesTopic: {
    id: 'Gallery.generalGalleriesTopic',
    defaultMessage: 'listing galleries'
  }
})

export const ForYouGallery = ({ match }) => {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { isMediumOrAbove } = useViewport()
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)
  const { customerProfileId, galleryId } = match.params
  const { data, refetch, isLoading: loadingFeed } = useGetFeed({ feedId: galleryId })
  const [showSubscribedModal, setShowSubscribedModal] = useState(false)
  const [showGooglePlaceSelector, setShowGooglePlaceSelector] = useState(false)
  const searchEnabled = useSelector(selectHomebotListingsFeatureEnabled)
  const { mutateAsync: changeFeedLocation, isLoading: changingFeedLocation } = useChangeFeedLocation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const clientIdFromToken = getClientIdFromBearerToken()
  const clientId = clientIdFromToken || queryParams.get('clientId')

  const [showNotAuthorisedModal, setShowNotAuthorisedModal] = useState(false)

  const { mutateAsync: toggleSub, isLoading: togglingSub } = useToggleFeedSubscribed()

  const [_, setCurrentLocation] = useRecoilState(currentSelectedLocation)

  useEffect(() => {
    if (!isAuthTokenSupported()) {
      return setShowNotAuthorisedModal(true)
    }
    if (customerProfileId) {
      dispatch(fetchProfile(customerProfileId))
    }
  }, [dispatch, customerProfileId])

  useEffect(() => {
    if (clientId) {
      dispatch(setCurrentClient(clientId))
    }
    dispatch(fetchClientData(clientId, customerProfileId))
    dispatch(fetchClientHomes(clientId))
  }, [dispatch, clientId, customerProfileId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(setActiveNavigationTab(NAVIGATION_TAB_NAMES.FORYOU))
  }, [dispatch])

  // --------- Create gallery viewed event START

  const { mutate: createClientEvent } = useCreateClientEvent({ disableErrorPrompts: true })

  const feedType = data?.feed.feedType

  // having undefined in key is fine, useEffectOncePerSessionConditionally() will do nothing
  const createClientEventEffectKey = `GalleryViewed_${feedType}`
  const sessionStorageViewedKey = `View_${feedType}`

  // deprecated, moving to single "viewed" client event
  const createGalleryViewedEvent = useCallback(() => {
    if (!feedType) {
      return
    }

    createClientEvent({
      clientEventInput: {
        eventName: ClientEventName.GalleryViewed,
        feedType
      }
    })
  }, [feedType, createClientEvent])

  const createGenericViewEvent = useCallback(() => {
    if (!feedType) {
      return
    }

    createClientEvent({
      clientEventInput: {
        eventName: ClientEventName.View,
        eventSource: ClientEventSource.Gallery,
        properties: {
          feed_type: feedType
        }
      }
    })
  }, [feedType, createClientEvent])

  useEffectOncePerSessionConditionally(createGalleryViewedEvent, createClientEventEffectKey, Boolean(feedType))
  useEffectOncePerSessionConditionally(createGenericViewEvent, sessionStorageViewedKey, Boolean(feedType))

  // --------- Create gallery viewed event END

  const onLocationChange = async (place: CurrentSelectedLocation) => {
    if (!isAuthTokenSupported()) {
      return
    }
    setCurrentLocation(place)
    setShowGooglePlaceSelector(false)
    await changeFeedLocation({ feedId: galleryId, googlePlaceId: place.place_id })
    await refetch()
  }

  const toggleSubscription = async () => {
    if (!isAuthTokenSupported()) {
      return
    }
    await toggleSub({ feedId: galleryId })
    await refetch()
    if (data?.feed.subscribed) {
      return
    }
    setShowSubscribedModal(true)
  }
  const showPageLevelLoader = useMemo(() => {
    return togglingSub || loadingFeed || changingFeedLocation || !searchEnabled
  }, [togglingSub, loadingFeed, changingFeedLocation, searchEnabled])

  const getTopicString = (feedType: FeedType) => {
    return intl.formatMessage(MSG[feedType] || MSG.generalGalleriesTopic).toLowerCase()
  }

  const learnMoreAssumableButtonProps = {
    variant: 'outline',
    bg: 'neutral.50',
    size: ['sm', 'md'],
    width: '100%',
    tracking: {
      guid: 'eHoqrJtgC74fcanmgJqeCQ',
      ui_context: 'Assumables Gallery Feed',
      descriptor: 'Info button to learn more about assumables'
    }
  }

  return (
    <AppLayout loading={showPageLevelLoader} enableAgentDropdown match={match}>
      {showNotAuthorisedModal && <NoPreviewModeModal />}
      {showGooglePlaceSelector && (
        <GoogleAutoComplete onClose={() => setShowGooglePlaceSelector(false)} onPlaceSelected={onLocationChange} />
      )}
      {showSubscribedModal && (
        <GallerySubscribeModal
          feedCategory={data?.feed.feedType as FeedType}
          onClose={() => {
            setShowSubscribedModal(false)
          }}
        />
      )}
      <Box>
        <Box
          mt={-2}
          pt={10}
          px={[5, 20]}
          backgroundImage={`url(${theme.isLightThemeType ? topographicalMapLight : topographicalMapDark})`}
          backgroundSize='cover'
          backgroundBlendMode='multiply'
          backgroundColor='whiteAlpha.500'
          _dark={{
            backgroundColor: 'blackAlpha.600'
          }}
        >
          <Button
            mb={4}
            size='sm'
            bg='neutral.200'
            onClick={() => history.goBack()}
            leftIcon={<Icon name='angle-left' />}
          >
            {intl.formatMessage(MSG.back)}
          </Button>
          <Flex pb={6} alignItems='center' justifyContent='space-between'>
            <Box>
              <Heading as='h1' m={0} size='xl' textAlign='left' fontWeight={600}>
                {data?.feed?.feedType ? intl.formatMessage(MSG[data?.feed?.feedType]) : ''}
              </Heading>
              <Text m={0} size='sm'>
                {data?.feed?.feedType
                  ? intl.formatMessage(MSG?.[`${data?.feed?.feedType}Description`] || MSG.help)
                  : ''}
              </Text>
            </Box>
          </Flex>
          {data?.feed && (
            <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={6}
              flexDir={['column', 'column', 'row']}
              gap={3}
              w='100%'
            >
              <ButtonGroup width={'100%'} maxW={'25rem'}>
                {data?.feed?.feedType === FeedType.Assumables ? (
                  <AssumablesLearnMoreModal buttonProps={learnMoreAssumableButtonProps} />
                ) : data?.feed?.feedType === FeedType.Concessions ? (
                  <BuyerPerksLearnMoreModal />
                ) : (
                  ''
                )}
              </ButtonGroup>
              <FormControl justifyContent='right' alignItems='center' as={Flex}>
                <FormLabel m={0} mr={1} p={0} htmlFor='unsubscribe' marginBottom='0'>
                  {data.feed.subscribed ? intl.formatMessage(MSG.subscribed) : intl.formatMessage(MSG.subscribe)}
                </FormLabel>
                <Switch
                  mt={1}
                  mx={0}
                  p={0}
                  tracking={{
                    guid: '6NU8QA8FBwaqxj30PgLSUB',
                    ui_context: `${data.feed.feedType} Gallery Feed Page`,
                    descriptor: data.feed.subscribed
                      ? `Subscribe to ${data.feed.feedType} feed`
                      : `Unsubscribe from ${data.feed.feedType} feed`
                  }}
                  onChange={toggleSubscription}
                  isChecked={data.feed.subscribed}
                  id='subscribe-switch'
                  name='subscribe-switch'
                />
              </FormControl>
              {data?.feed?.feedType === FeedType.Assumables ? (
                <AssumablesDirectMessage />
              ) : (
                <GenericFeedDirectMessage topic={getTopicString(data?.feed?.feedType)} />
              )}
            </Flex>
          )}

          <Stack
            borderWidth={1}
            flex={1}
            borderStyle='solid'
            bg='neutral.100'
            direction='row'
            py={2}
            mx={-20}
            px={20}
            alignItems='center'
            spacing={1}
          >
            <Link to={SEARCH_PATH_HELPERS.forYouTab.buildPath(customerProfileId, clientId)}>
              <Text m={0} fontWeight={600} size='sm' textColor='primary.500'>
                <FormattedMessage id={MSG.forYou.id} defaultMessage={MSG.forYou.defaultMessage} />
              </Text>
            </Link>
            <Text m={0} size='xs'>
              {'>'}
            </Text>
            <Text m={0} fontWeight={600} size='sm'>
              {data?.feed.feedType ? intl.formatMessage(MSG[data?.feed.feedType]) : ''}
            </Text>
          </Stack>
        </Box>

        <Box my={6} px={[5, 20]}>
          <Text as='span' m={0} mb={1} mr={1} fontWeight={600} size='md'>
            {data?.feed?.listings.length}{' '}
            <FormattedMessage id={MSG.homes.id} defaultMessage={MSG.homes.defaultMessage} />
          </Text>
          <Text as='span' mb={1} size='2xs' textColor='neutral.500' fontStyle='italic'>
            (<FormattedMessage id={MSG.updatedToday.id} defaultMessage={MSG.updatedToday.defaultMessage} />)
          </Text>
        </Box>

        {data?.feed?.listings && data.feed.listings.length > 0 ? (
          <Grid
            templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
            gap={4}
            px={[5, 20]}
          >
            {data?.feed?.listings.map(listing => (
              <ListingCardNew
                key={listing?.id}
                listing={listing as ListingCompact}
                tracking={{
                  guid: '2mGadFxrh1Z5mLgnARCqPw',
                  ui_context: HOME_SEARCH_FOR_YOU_INDIVIDUAL_GALLERY_PAGE,
                  descriptor: 'Individual Gallery Listing Clicked'
                }}
              />
            ))}
          </Grid>
        ) : (
          <Box p={10}>
            <FeedEmptyState feedType={feedType} />
          </Box>
        )}
      </Box>

      {!hasNativeAccess && (
        <Box margin={8}>
          {isMediumOrAbove ? (
            <DownloadMobileAppPromptCard isLandingPage={true} />
          ) : (
            <MobileDownloadMobileAppPromptCard isLandingPage={true} />
          )}
        </Box>
      )}

      <HomeSearchFooter />
    </AppLayout>
  )
}
