const Constants = Object.freeze({
  monthsIn30TermLoan: 360,
  noInterestPaid: 0,
  monthsInYear: 12,
  percentToDecimal: 100,
  loanFees: {
    small: 4000,
    medium: 5000,
    large: 6000
  }
})

export default Constants
