import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Button } from '../..'
import { IconInfoCircleFa } from '../Icon'

import styles from './DisclaimersButton.module.scss'

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

const DisclaimersButton = ({ className, onClick }) => (
  <div className={classnames(className, styles.container)}>
    <Button minimal iconStart={IconInfoCircleFa} onClick={onClick} className={styles.button}>
      <FormattedMessage id='App.disclaimer.toggleButton' defaultMessage='Disclaimers' />
    </Button>
  </div>
)

DisclaimersButton.propTypes = propTypes

export default DisclaimersButton
