import apiClient from '../util/apiClient'
import { getAgentHeaders } from '../../auth/widget'

export default apiClient(process.env.REACT_APP_MIKASA_URI, {
  transformRequest: [
    function (data, headers) {
      Object.assign(headers, getAgentHeaders())
      return JSON.stringify(data)
    }
  ]
})
