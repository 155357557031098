import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { If } from '../../../deprecated/Conditionals'

import styles from './Ruler.module.scss'

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  left: PropTypes.number,
  render: PropTypes.func,
  showLabel: PropTypes.bool,
  value: PropTypes.number
}

export const TickMark = ({ value, className, icon: Icon, render, showLabel, color, left }) => (
  <div
    className={classnames(styles.tick, showLabel && styles.labelWrapper, color && styles[`${color}`], className)}
    data-value={value}
    style={!isNaN(left) ? { left: `${left}px` } : undefined}
  >
    <If condition={showLabel}>
      {Icon && <Icon className={styles.icon} />}
      <div className={styles.label}>
        {render && render(value)}
        {!render && value}
      </div>
    </If>
  </div>
)

TickMark.propTypes = propTypes
