import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './ButtonGroup.module.scss'

const propTypes = {
  // add text-alignment
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify', 'start', 'end'])
}

const ButtonGroup = ({ children, className, align }) => (
  <div className={classnames(styles.root, styles[`align-${align}`], className)}>{children}</div>
)

ButtonGroup.propTypes = propTypes

export default ButtonGroup
