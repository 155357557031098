const USER_EMAIL = 'userEmail'
const USER_FIRST_AND_LAST = 'userFirstAndLast'
const COMPLETE = 'complete'
const CLIENT_NOT_FOUND = 'clientNotFound'

export const SIGN_UP_STEPS = {
  USER_EMAIL,
  USER_FIRST_AND_LAST,
  COMPLETE,
  CLIENT_NOT_FOUND
}

export const SIGN_UP_STEPS_ORDERED = [USER_EMAIL, USER_FIRST_AND_LAST, COMPLETE, CLIENT_NOT_FOUND]
