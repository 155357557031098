interface Error {
  message: string
  name: string
  stack: string
}

export type ErrorOrNull = Error | null

export enum AuthErrorModalErrorTypes {
  HOMEOWNER_ERROR = 'homeownerError',
  BUYER_ERROR = 'buyerError',
  CUSTOMER_ERROR = 'customerError'
}
