import { compose, setDisplayName, setPropTypes, withProps } from 'recompose'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const withComposedClassName = rootClass =>
  compose(
    setDisplayName('withComposedClassName'),
    setPropTypes({ className: PropTypes.string }),
    withProps(props => ({
      className: classnames(props.className, typeof rootClass === 'function' ? rootClass(props) : rootClass)
    }))
  )

export default withComposedClassName
