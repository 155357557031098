import { ADD_TOAST, REMOVE_TOAST } from '../actions/actionTypes'

const toasts = (state = [], action) => {
  switch (action.type) {
    case ADD_TOAST: {
      return [action.toast, ...state]
    }
    case REMOVE_TOAST: {
      return state.filter(toast => toast.id !== action.id)
    }
    default:
      return state
  }
}

export default toasts
