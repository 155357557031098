import get from 'lodash/get'
import {
  SHOW_HOME_INSURANCE_DETAIL,
  HIDE_HOME_INSURANCE_DETAIL,
  FETCH_HOME_INSURANCE_REQUEST,
  FETCH_HOME_INSURANCE_SUCCESS,
  FETCH_HOME_INSURANCE_FAILURE,
  SET_HOME_INSURANCE_OVERRIDE
} from './actionTypes'
import { getHomeInsurance } from '../api/mikasa/requests'

export const showHomeInsuranceDetail = () => ({
  type: SHOW_HOME_INSURANCE_DETAIL
})

export const hideHomeInsuranceDetail = () => ({
  type: HIDE_HOME_INSURANCE_DETAIL
})

export const setHomeInsuranceOverride = ({ override }) => ({
  type: SET_HOME_INSURANCE_OVERRIDE,
  override
})

export const fetchHomeInsurance = homeId => async dispatch => {
  dispatch({
    type: FETCH_HOME_INSURANCE_REQUEST
  })

  try {
    const response = await getHomeInsurance(homeId)
    const homeInsuranceData = get(response, 'data.data')

    dispatch({
      type: FETCH_HOME_INSURANCE_SUCCESS,
      data: homeInsuranceData
    })
  } catch (e) {
    dispatch({
      type: FETCH_HOME_INSURANCE_FAILURE
    })
  }
}
