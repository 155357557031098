import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import { RANGE } from '../FormField/types'
import Text from '../Text'
import Svg from '../Svg'

import styles from './Slider.module.scss'

const { circleSize } = styles
const { thumbSize } = styles

const propTypes = {
  className: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  valueFormat: PropTypes.elementType,
  name: PropTypes.string,
  id: PropTypes.string,
  minPhrase: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxPhrase: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

const defaultProps = {
  min: '0',
  max: '100',
  valueFormat: ({ value }) => value
}

const defaultCaptionPropTypes = {
  minPhrase: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxPhrase: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

const CaptionText = props => <Text size='s' variant='neutralCool' variantCount='700' element='span' {...props} />

const DefaultCaption = ({ minPhrase, maxPhrase }) => (
  <div className={styles.caption}>
    <CaptionText>{minPhrase}</CaptionText>
    <CaptionText>{maxPhrase}</CaptionText>
  </div>
)

const Slider = ({
  className,
  defaultValue,
  onChange,
  onTouchStart,
  onTouchEnd,
  onMouseDown,
  onMouseUp,
  onFocus,
  onBlur,
  value,
  name,
  id = uniqueId(name),
  caption,
  min,
  max,
  minPhrase,
  maxPhrase,
  valueFormat: FormattedValue,
  ...restProps
}) => {
  const [controlledValue, setControlledValue] = useState(defaultValue)
  const [active, setActive] = useState(false)
  const [focused, setFocused] = useState(false)
  const myValue = controlledValue || value
  const Caption = caption || DefaultCaption
  const numMin = parseFloat(min)
  const numMax = parseFloat(max)
  const numVal = parseFloat(myValue)
  const percentFromMax = (numMax - numVal) / (numMax - numMin)
  const percentFromMin = 1 - percentFromMax

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.slider}>
        <div
          className={styles.progress}
          style={{
            right: `calc((${percentFromMax} * (100% - ${thumbSize})) + ((${thumbSize} + ${circleSize}) / 2)`
          }}
        />
        <div
          className={styles.background}
          style={{
            left: `calc((${percentFromMin} * (100% - ${thumbSize})) + ((${thumbSize} + ${circleSize}) / 2)`
          }}
        />
        <Svg
          viewBox='0 0 2 2'
          className={styles.svg}
          style={{
            left: `calc((${percentFromMin} * (100% - ${thumbSize})) + ((${thumbSize} - ${circleSize}) / 2))`
          }}
        >
          <circle className={classnames(styles.focusRing, focused && styles.focused)} cx='1' cy='1' r='1' />
          <circle className={classnames(styles.circle, active && styles.active)} cx='1' cy='1' r='1' />
        </Svg>
        <input
          id={id}
          name={name}
          type={RANGE}
          onTouchStart={e => {
            setActive(true)
            if (onTouchStart) onTouchStart(e)
          }}
          onTouchEnd={e => {
            setActive(false)
            if (onTouchEnd) onTouchEnd(e)
          }}
          onMouseDown={e => {
            setActive(true)
            if (onMouseDown) onMouseDown(e)
          }}
          onMouseUp={e => {
            setActive(false)
            if (onMouseUp) onMouseUp(e)
          }}
          onFocus={e => {
            !active && setFocused(true)
            if (onFocus) onFocus(e)
          }}
          onBlur={e => {
            setFocused(false)
            if (onBlur) onBlur(e)
          }}
          onChange={event => {
            const newValue = event.target.value
            if (value == null) {
              setControlledValue(newValue)
            }
            if (onChange) {
              onChange(event)
            }
          }}
          min={min}
          max={max}
          value={myValue}
          className={classnames(styles.input)}
          aria-label='years in home slider'
          {...restProps}
        />
        <Text
          element='output'
          weight='semibold'
          variant='neutralCool'
          variantCount='900'
          className={styles.output}
          htmlFor={id}
          style={{
            left: `calc(${percentFromMin} * (100% - (${thumbSize})) + (${thumbSize} / 2))`
          }}
        >
          <FormattedValue value={myValue} />
        </Text>
        <Caption minPhrase={minPhrase} maxPhrase={maxPhrase} />
      </div>
    </div>
  )
}

DefaultCaption.propTypes = defaultCaptionPropTypes

Slider.propTypes = propTypes
Slider.defaultProps = defaultProps

export default Slider
