import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  Logo,
  Text
} from '@homebotapp/hb-react-component-catalog'
import { ReactNode, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../types/rootState'
import { selectNativeAccessFeature, selectShareCode } from '../../store/selectors/customerProfile'
import chromeLogo from '../../assets/logos/chrome-icon.png'
import safariLogo from '../../assets/logos/safari-icon.png'
import useViewport from '../../hooks/useViewport'
import { FirebaseAppUrl } from '../../App/Layout/Header/MobileHeaderDownloadApp/constants'
import { selectHomeId } from '../../store/selectors/home'

const MSG = defineMessages({
  mobileAppDrawerAlt: {
    id: 'BrowserLogo.alt',
    defaultMessage: 'browser logo'
  },
  viewHomebotIn: {
    id: 'MobileAppDrawer.viewHomebotIn',
    defaultMessage: 'View Homebot in...'
  },
  homebotApp: {
    id: 'MobileAppDrawer.homebotApp',
    defaultMessage: 'Homebot App'
  },
  open: {
    id: 'MobileAppDrawer.open',
    defaultMessage: 'Open'
  },
  continue: {
    id: 'MobileAppDrawer.continue',
    defaultMessage: 'Continue'
  },
  closeLabel: {
    id: 'MobileAppDrawer.closeLabel',
    defaultMessage: 'Close'
  }
})

export const MobileAppDrawer = () => {
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)

  const { isLargeOrAbove } = useViewport()

  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const selectedHomeId = useSelector(selectHomeId)
  const shareCode = useSelector(selectShareCode)

  const appUrl = FirebaseAppUrl.replace('$homeId', selectedHomeId).replace('$shareCode', shareCode)

  useEffect(() => {
    if (hasNativeAccess) {
      setIsOpen(!isLargeOrAbove ? true : false)
    }
  }, [hasNativeAccess, isLargeOrAbove])

  const onClose = () => {
    setIsOpen(false)
  }

  const renderOption = (title: ReactNode, button: ReactNode) => {
    return (
      <Flex mt={3}>
        {title}
        {button}
      </Flex>
    )
  }

  const getBrowserName = () => {
    const userAgent = navigator.userAgent
    let browserName = ''
    let browserLogo = ''

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'Chrome'
      browserLogo = chromeLogo
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'Firefox'
    } else if (userAgent.match(/safari/i)) {
      browserName = 'Safari'
      browserLogo = safariLogo
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'Opera'
    } else if (userAgent.match(/edg/i)) {
      browserName = 'Edge'
    } else if (userAgent.match(/msie|trident/i)) {
      browserName = 'Internet Explorer'
    } else {
      browserName = 'Unknown'
    }

    return {
      browserName,
      browserLogo
    }
  }

  return (
    <Drawer
      placement='bottom'
      onClose={() => {
        setIsOpen(false)
      }}
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent
        p={2}
        style={{
          borderRadius: '16px 16px 0 0'
        }}
      >
        <DrawerBody>
          <Flex justifyContent='space-between'>
            <Text
              fontWeight='600'
              color='white'
              _light={{
                color: 'black'
              }}
              style={{
                lineHeight: '31.92px'
              }}
              size='lg'
              flex={0.8}
            >
              {intl.formatMessage(MSG.viewHomebotIn)}
            </Text>

            <IconButton
              tracking={{
                guid: 'axLDQaq9yb3iQGDRtr3E8E',
                descriptor: "Close Homebot's Mobile App Drawer"
              }}
              variant='ghost'
              onClick={onClose}
              icon={<Icon name='times' />}
              aria-label={intl.formatMessage(MSG.closeLabel)}
            />
          </Flex>
          {renderOption(
            <Flex flex={1}>
              <Logo textLocation='none' />
              <Text
                ml={2}
                fontWeight='600'
                color='white'
                _light={{
                  color: 'black'
                }}
                style={{
                  lineHeight: '31.92px'
                }}
                size='lg'
                flex={0.8}
              >
                {intl.formatMessage(MSG.homebotApp)}
              </Text>
            </Flex>,
            <Button
              tracking={{
                guid: 'axLDbRM9yb3iQGDDDG3E8E',
                descriptor: "Open Homebot's Mobile App"
              }}
              colorScheme='primary'
              mr={2}
              onClick={() => {
                window.open(appUrl, '_blank')
              }}
            >
              {intl.formatMessage(MSG.open)}
            </Button>
          )}

          {renderOption(
            <Flex flex={1}>
              {getBrowserName().browserLogo && (
                <Image
                  width='36px'
                  height='36px'
                  src={getBrowserName().browserLogo}
                  alt={intl.formatMessage(MSG.mobileAppDrawerAlt)}
                />
              )}
              <Text
                ml={2}
                fontWeight='600'
                color='white'
                _light={{
                  color: 'black'
                }}
                style={{
                  lineHeight: '31.92px'
                }}
                size='lg'
                flex={0.8}
              >
                {getBrowserName().browserName}
              </Text>
            </Flex>,
            <Button
              tracking={{
                guid: 'axLDQaq9yb3iQGDDDG3E8E',
                descriptor: "Continue to Homebot's Web App"
              }}
              colorScheme='secondary'
              variant='solid'
              mr={2}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              {intl.formatMessage(MSG.continue)}
            </Button>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
