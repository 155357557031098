import { useEffect, useState, useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { pushToWindowResize } from '../../../../helpers/browser'

import styles from './HeightFade.module.scss'

const copyAndReplaceChildren = (fromNode, toNode) => {
  if (toNode && fromNode && fromNode.firstChild) {
    if (toNode.firstChild) {
      Array.from(toNode.children).forEach(child => child.parentNode.removeChild(child))
    }
    Array.from(fromNode.children).forEach(child => {
      if (child.id !== toNode.id) {
        const newNode = child.cloneNode(true)
        newNode.querySelectorAll('[id]').forEach(el => el.removeAttribute('id'))
        toNode.appendChild(newNode)
      }
    })
  }
}

const calcHeightById = id => {
  const main = document.getElementById(id)

  if (!main) {
    return
  }

  let top, bot
  Array.from(main.children).forEach(child => {
    const childTop = child.getBoundingClientRect().top
    const childBot = child.getBoundingClientRect().bottom
    top = top ? Math.min(top, childTop) : childTop
    bot = bot ? Math.max(bot, childBot) : childBot
  })

  return bot - top
}

const HeightFade = ({ id, forwardedRef, className, children, animating, doAnimate, speed }) => {
  const hash = useRef(Math.random().toString(36).substring(5)).current
  const mainId = id || `HeightFade_${hash}`
  const maskId = id ? `${id}_mask` : `HeightFadeMask_${hash}`

  const [height, setHeight] = useState('auto')

  useImperativeHandle(
    forwardedRef,
    () => {
      if (forwardedRef.current && doAnimate) {
        forwardedRef.current.animate = s => doAnimate(mainId, s !== undefined ? s : 'medium')
      }
      return forwardedRef.current
    },
    [forwardedRef, mainId, doAnimate]
  )

  useEffect(() => {
    if (!animating) {
      copyAndReplaceChildren(document.getElementById(mainId), document.getElementById(maskId))
    }

    const main = calcHeightById(mainId)
    if (height !== main) {
      setHeight(main)
    }
  }, [animating, mainId, height, maskId])

  pushToWindowResize(mainId, () => {
    const main = calcHeightById(mainId)
    if (main) {
      setHeight(main)
    }
  })

  const mainClasses = classnames(className, styles.wrapper, styles[speed], animating && styles.hidden)

  const maskClasses = classnames(styles.mask, !animating && styles.hidden)

  return (
    <div id={mainId} className={mainClasses} style={{ height }} ref={forwardedRef}>
      <div id={maskId} className={maskClasses} />
      {children}
    </div>
  )
}

HeightFade.propTypes = {
  speed: PropTypes.oneOf(['slow', 'medium', 'fast', 'zero']),
  id: PropTypes.string,
  forwardedRef: PropTypes.shape({
    current: PropTypes.object
  }),
  className: PropTypes.string,
  children: PropTypes.any,
  animating: PropTypes.bool,
  doAnimate: PropTypes.func
}

HeightFade.defaultProps = {
  speed: 'medium'
}

export default HeightFade
