import { useState } from 'react'

const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

export default (min, max) => {
  const randomize = () => random(min, max)
  const [value, setValue] = useState(randomize())
  const randomizeValue = () => setValue(randomize())
  return [value, randomizeValue]
}
