import { ChangeEvent, forwardRef } from 'react'
import { isValidPhoneNumber, removeCountryCode } from '../../../helpers/formatters'
import { validateInput } from '../../../helpers/input'
import NumberInput, { NumberInputProps } from './NumberInput'

export interface PhoneInputCondition {
  message: string
  validate: (value: string) => boolean
}

export const validNumber = () => ({
  message: 'Value must be 10 digits',
  validate: (value: string) => isValidPhoneNumber(value)
})

export interface PhoneInputProps extends NumberInputProps {
  className?: string
  id?: string
  value: string
  defaultValue?: string
  conditions?: PhoneInputCondition[]
  parse?: () => void
  onChange: (e: ChangeEvent<HTMLInputElement>, value?: string) => void
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ id, conditions, onChange, parse, value, defaultValue = '', ...restProps }, ref) => {
    // Phone Num from the backend contains the international code (+1)
    // Must remove international code to successfully display
    const domesticPhoneNumber = value ? removeCountryCode(value) : removeCountryCode(defaultValue)

    return (
      <NumberInput
        id={id}
        value={domesticPhoneNumber}
        data-qa='phoneNumberField'
        format='###-###-####'
        mask='_'
        ref={ref}
        onChange={e => {
          const [, parsedVal] = validateInput(e.target, conditions, parse)
          onChange?.(e, parsedVal)
        }}
        {...restProps}
      />
    )
  }
)

PhoneInput.displayName = 'PhoneInput'

export default PhoneInput
