import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { CheckBox, Text } from '../..'

import styles from './CheckBoxPill.module.scss'

const propTypes = {
  iconEnd: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  iconStart: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  dataQa: PropTypes.string,
  children: PropTypes.any
}

const defaultProps = {
  defaultChecked: false
}

const PillLabel = props => <Text element='span' color='inherit' {...props} />

const CheckBoxPill = ({
  className,
  defaultChecked,
  checked,
  onChange,
  onFocus,
  onBlur,
  onClick,
  iconEnd: IconEnd,
  iconStart: IconStart,
  dataQa,
  children,
  ...restProps
}) => {
  const [isChecked, setChecked] = useState(checked != null ? checked : defaultChecked)
  const [focused, setFocused] = useState(false)

  const controlledCheck = typeof checked === 'boolean' ? checked : isChecked

  return (
    <CheckBox
      data-qa={dataQa || 'check-box'}
      className={classnames(styles.root, controlledCheck && styles.checked, focused && styles.focused, className)}
      hide
      checked={controlledCheck}
      onChange={e => {
        setChecked(!isChecked)
        onChange && onChange(e)
      }}
      onFocus={e => {
        setFocused(true)
        onFocus && onFocus(e)
      }}
      onBlur={e => {
        setFocused(false)
        onBlur && onBlur(e)
      }}
      onClick={e => {
        onClick && onClick(e)
      }}
      labelText={PillLabel}
      {...restProps}
    >
      {IconStart && <IconStart className={styles.startIcon} />}
      {children}
      {IconEnd && <IconEnd className={styles.endIcon} />}
    </CheckBox>
  )
}

CheckBoxPill.propTypes = propTypes
CheckBoxPill.defaultProps = defaultProps

export default CheckBoxPill
