import { Toast, ToastType } from '../Toast'

import styles from './Toasts.module.scss'

interface ToastsProps {
  toasts: ToastType[]
}

const Toasts = ({ toasts }: ToastsProps) => (
  <ul className={styles.toasts}>
    {toasts.map((toast, position) => (
      <Toast {...toast} key={toast.id} />
    ))}
  </ul>
)

export { Toasts }
