import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Button from './Button'
import { IconAngleRight } from '../Icon'

import styles from './ForwardButton.module.scss'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string
}

const ForwardButton = ({ children, className, ...restProps }) => (
  <Button
    data-qa='buttonForward'
    {...restProps}
    className={classnames(styles.root, className)}
    aria-label='forward'
    iconEnd={IconAngleRight}
  >
    <span className={styles.text}>{children || <FormattedMessage id='Button.Forward' defaultMessage='Next' />}</span>
  </Button>
)

ForwardButton.propTypes = propTypes

export default ForwardButton
