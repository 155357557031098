import Text from './Text'
import Headline from './Headline'
import Strong from './Strong'

Text.Headline = Headline
Text.Strong = Strong

export default Text

export { Headline }
