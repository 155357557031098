import { useCallback } from 'react'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import {
  ToggleFavoriteListingMutation,
  useToggleFavoriteListingMutation
} from '../../api/gqlaxy/gql/generated/graphql-hooks'
import { QueryKey } from '../../constants/queryKey'
import { ListingCompact } from '../../api/gqlaxy/gql/generated/graphql'
import { useToast } from '@homebotapp/hb-react-component-catalog'
import { useIntl } from 'react-intl'

type Context = {
  listingId: string
  previousFavoriteListings: ListingCompact[]
  favoriteListings: ListingCompact[]
}

export const useToggleFavoriteListing = (options?: UseMutationOptions<ToggleFavoriteListingMutation, unknown, {}>) => {
  const queryClient = useQueryClient()

  const toast = useToast()
  const intl = useIntl()

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // Local endpoint
  })

  const showSuccessToast = (description: string) => {
    toast({
      description,
      status: 'success',
      duration: 2000,
      position: 'bottom-left'
    })
  }

  // Optimistically update the favorite listings
  const handleMutate = useCallback(
    async ({ listingId }: { listingId: string }): Promise<Context> => {
      // stop refetching favorite listings to avoid overwriting optimistic updates
      await queryClient.cancelQueries(QueryKey.GetFavoriteListings)

      const previousFavorites: any = queryClient.getQueryData(QueryKey.GetFavoriteListings)

      const previousFavoriteListings = previousFavorites?.favoriteListings?.listings ?? []
      const isFavorited = previousFavoriteListings.some(({ id }) => id === listingId)

      let favoriteListings: ListingCompact[] = []
      if (isFavorited) {
        favoriteListings = previousFavoriteListings.filter(({ id }) => id !== listingId)
        showSuccessToast(intl.formatMessage({ id: 'General.RemoveFromFavorite' }))
      } else {
        favoriteListings = previousFavoriteListings.concat({ id: listingId })
        showSuccessToast(intl.formatMessage({ id: 'Listing.Favorite.Added' }))
      }

      queryClient.setQueryData(QueryKey.GetFavoriteListings, { favoriteListings: { listings: favoriteListings } })

      return {
        previousFavoriteListings,
        favoriteListings,
        listingId
      }
    },
    [queryClient]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.GetFavoriteListings)
  }, [queryClient])

  return useToggleFavoriteListingMutation(client, {
    ...options,
    onMutate: handleMutate,
    onSettled: options?.onSettled ?? handleSettled
  })
}
