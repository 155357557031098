import debounce from 'lodash/debounce'
import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from './actionTypes'
import { TIMEOUT } from '../constants/notification'

const hideNotification = debounce(
  dispatch =>
    dispatch({
      type: HIDE_NOTIFICATION
    }),
  TIMEOUT
)

export const flashNotification =
  ({ message, messageType }) =>
  dispatch => {
    dispatch({
      type: SHOW_NOTIFICATION,
      message,
      messageType
    })

    hideNotification(dispatch)
  }

export const immediateHideNotification = () => async dispatch => {
  dispatch({
    type: HIDE_NOTIFICATION
  })
}
