// @ts-nocheck
import axios from 'axios'
import { Button, Flex, Box } from '@homebotapp/hb-react-component-catalog'
import { useState } from 'react'
import { BotHeadSpin } from '../Components'

export const MockUI = () => {
  const [link, setLink] = useState('')
  const [createdClients, setCreatedClients] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeTrait, setActiveTrait] = useState('')

  const traits = [
    'with_digest_view',
    'with_insurance_module',
    'with_insurance_win_module_low_risk',
    'with_insurance_win_module_medium_risk',
    'with_insurance_win_module_high_risk',
    'with_insurance_win_no_sold_date',
    'with_refi_win_module',
    'with_safety_net_fund_win_module',
    'with_downsize_win_module'
  ]

  const createClient = async traitString => {
    setLoading(true)
    const url = 'http://localhost:3000/api/createClient'
    const payload = {
      traits: traitString // 'with_digest_view' etc
    }

    const options = {
      headers: {
        'Content-Type': 'application/json',
        rejectUnauthorized: false,
        Factory: 'hangar'
      }
    }
    const strPayload = JSON.stringify(payload)
    const response = await axios.post(url, strPayload, options)
    setLink(response.data.accessLinkData.report_uri)
    const newClient = { ...response.data.client, createdWithTrait: traitString }
    setCreatedClients([...createdClients, newClient]) // eslint-disable-line
    setLoading(false)
  }

  const getClientsByTraitThatCreatedThem = (trait: string) => createdClients.filter(c => c.createdWithTrait === trait)

  return (
    <Flex id='wrapper' justifyContent='center' alignItems='center' direction='column' margin={12}>
      <div>
        {traits.map(trait => (
          <Box borderRadius={4} border={'solid 1px white'} padding={5} marginBottom={5}>
            <Button
              marginBottom={5}
              variant='outline'
              colorScheme='brand'
              onClick={() => {
                setActiveTrait(trait)
                createClient(trait)
              }}
            >
              {`${trait}`}
            </Button>

            {loading && activeTrait === trait && <BotHeadSpin />}
            {getClientsByTraitThatCreatedThem(trait).map(client => (
              <Box marginTop={2} marginBottom={2}>
                <div>Created Client:</div>
                <div>{`name: ${client.first_name} ${client.last_name}`}</div>
                <div>{`email: ${client.email}`}</div>
                <div>{`id: ${client.id}`}</div>
                <div>
                  <a href={link} target='_blank' rel='noreferrer'>
                    Go to digest
                  </a>
                </div>
              </Box>
            ))}
          </Box>
        ))}
      </div>
    </Flex>
  )
}
