import React, { Dispatch, SetStateAction } from 'react'
import * as Sentry from '@sentry/browser'

interface ErrorBoundaryProps {
  children: React.ReactNode
  setIsError: Dispatch<SetStateAction<boolean>>
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, Record<string, never>> {
  override componentDidCatch(error, info) {
    this.props.setIsError(true)
    Sentry.captureException(error, {
      extra: info
    })
  }

  override render() {
    return this.props.children
  }
}
