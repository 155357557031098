import { SUPPORTED_LOCALES } from '../locales'
import { SET_LOCALE_DATA } from '../actions/actionTypes'
import { getLanguages } from '../helpers/browser'

const getSupportedLocale = (...args) =>
  [...args, ...getLanguages()]
    .map(localeCode => String(localeCode).slice(0, 2))
    .find(languageCode => SUPPORTED_LOCALES.includes(languageCode)) || 'en'

const initialState = {
  locale: getSupportedLocale()
}

export default (state = initialState, { type, locale }) => {
  switch (type) {
    case SET_LOCALE_DATA:
      return {
        ...state,
        locale: getSupportedLocale(locale)
      }

    default:
      return state
  }
}
