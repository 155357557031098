import React, { useState, useCallback, useEffect, createContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { getDocumentTitle, setDocumentTitle } from '../../../helpers/browser'
import { defaultOpts } from '../../../constants/animations'
import history from '../../../history'

export const ActiveOverlayContext = createContext()

const initialState = {
  activeId: null,
  inactiveIds: []
}

const updateOverlayStateReducer = (state, { id, data }) => {
  const update = { ...state }
  const inactive = update.inactiveIds

  if (data) {
    update.activeId && update.activeId !== id && inactive.unshift(update.activeId)
    update.activeId = id
    update[id] = data
  } else {
    const index = inactive.indexOf(id)
    index > -1 && inactive.splice(index, 1)
    if (update.activeId === id && inactive.length > 0) {
      update.activeId = inactive.pop()
    }
    delete update[id]
  }
  return update
}

const updateNavigationReducer = (state, { id, ...data }) => ({
  ...state,
  [id]: {
    ...state[id],
    ...data
  }
})

export const ActiveOverlayContextProvider = props => {
  const fromTitle = useRef(getDocumentTitle())
  const [exitingPanel, setExitingPanel] = useState(false)
  const [closeStyle, setCloseStyle] = useState(null)
  const [navigation, updateNavigationState] = React.useReducer(updateNavigationReducer, {})
  const [overlayState, updateOverlayState] = React.useReducer(updateOverlayStateReducer, initialState)

  useEffect(() => {
    const resetTitle = fromTitle.current
    const stateData = overlayState[overlayState.activeId]
    if (overlayState.length) {
      setDocumentTitle(`Homebot - ${stateData.title}`)
    } else {
      setDocumentTitle(resetTitle)
    }
  }, [overlayState])

  const activeId = overlayState.activeId
  const doNavBack = useCallback(
    useProvidedBackPath => {
      const { direct, back } = navigation[activeId]
      setExitingPanel(true)
      setCloseStyle({
        opacity: 0,
        transition: `opacity ${defaultOpts.duration}ms ease-out ${defaultOpts.interval}ms`
      })
      setTimeout(() => {
        if ((direct || useProvidedBackPath) && back) {
          history.push(back)
        } else {
          history.goBack()
        }
        setExitingPanel(false)
        setCloseStyle(null)
      }, defaultOpts.duration + defaultOpts.interval)
    },
    [navigation, activeId]
  )

  const value = {
    overlays: overlayState,
    updateOverlayState,
    navigation,
    updateNavigationState,
    doNavBack,
    closeStyle,
    exitingPanel
  }

  return <ActiveOverlayContext.Provider value={value}>{props.children}</ActiveOverlayContext.Provider>
}

ActiveOverlayContextProvider.propTypes = {
  children: PropTypes.any
}
