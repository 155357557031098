import { Icon, Text, Heading } from '@homebotapp/hb-react-component-catalog'
import { NavigationTab } from './NavigationTab'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { selectRouteToForYou } from '../../../store/selectors/routes'
import { Link as ReactRouterLink } from 'react-router-dom'
import { GlobalTrackingEventLocation, TrackingEventConstants } from '../../../constants/tracking'
import { useTrackingContext } from '../../../providers/tracking'
import { NavigationTabProps } from '../../../types/navigation'
import { selectClientIsGuest } from '../../../store/selectors/client'
import { hasCustomerToken } from '../../../auth'
import { toggleAuthModal } from '../../../actions/homeSearchAuthModal'

// Todo: Need up update the intl keys from BottomNav to Navigation
const MSG = defineMessages({
  forYou: {
    id: 'Gallery.forYou',
    defaultMessage: 'For You'
  },
  featureIsIn: {
    id: 'Gallery.featureIsIn',
    defaultMessage: 'Feature is in'
  },
  beta: {
    id: 'Gallery.beta',
    defaultMessage: 'Beta'
  }
})

export const ForYouTab = ({ isSelected, ...tabProps }: NavigationTabProps) => {
  const intl = useIntl()
  const forYouRoute = useSelector(selectRouteToForYou)
  const dispatch = useDispatch()
  const isGuest = useSelector(selectClientIsGuest)

  // Used solely for legacy tracking event
  const { trackingClient } = useTrackingContext()

  const handleClick = e => {
    trackingClient?.buyerNavigationLinkClicked({
      buyer_navigation_link: TrackingEventConstants.FOR_YOU
    })

    if (isGuest && !hasCustomerToken()) {
      e.preventDefault()
      dispatch(toggleAuthModal(true))
      return
    }
  }

  return (
    <NavigationTab
      {...tabProps}
      as={ReactRouterLink}
      to={forYouRoute}
      onClick={handleClick}
      position='relative'
      tracking={{
        guid: 'xrZWiTAkFzvPBM3qNHp8wc',
        ui_context: GlobalTrackingEventLocation.GLOBAL_NAVIGATION_BAR,
        descriptor: MSG.forYou.defaultMessage
      }}
    >
      <Icon name='bolt' data-qa='bolt' marginRight={['0', null, null, '2']} marginBottom={['1', null, null, '0']} />
      <Text as='span' fontWeight='semibold' sx={{ textWrap: 'nowrap' }} size={['3xs', 'sm']} m={0}>
        {intl.formatMessage(MSG.forYou)}
        <Heading
          as='span'
          size='3xs'
          variant='eyebrow'
          position='absolute'
          top={1}
          left={10}
          // @ts-ignore so the text color changes when the tab is active
          color='inherit'
          display={{ base: 'none', lg: 'block' }}
        >
          <span className='screenReaderText'>{intl.formatMessage(MSG.featureIsIn)}</span>
          <span style={{ fontSize: '.5rem', opacity: '.7' }}> {intl.formatMessage(MSG.beta)}</span>
        </Heading>
      </Text>
    </NavigationTab>
  )
}
