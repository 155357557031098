export const PREQUAL = 'prequal'
export const CALCULATOR = 'calculator'
export const PRICEPOINT = 'pricePoint'

export const METHODS = {
  PREQUAL,
  CALCULATOR,
  PRICEPOINT
}

export const FORM_FIELD_NAMES = {
  PRICEPOINT: 'pricePoint',
  INCOME: 'income',
  DEBT: 'debt',
  DOWNPAYMENT: 'downPayment',
  LOANTERM: 'loanTerm'
}

export const NATIONAL_MEDIAN_HOME_PRICE = 289900

export const QUESTION_REQUESTED_PREQUAL = 'requested_prequal'
