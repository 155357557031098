export const LeavingHomebotIllustration = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='445' height='193' viewBox='0 0 445 193' fill='none'>
      <g clipPath='url(#clip0_1608_10591)'>
        <rect width='445' height='192.939' fill='black' fillOpacity='0.04' />
        <circle cx='223' cy='95' r='180' fill='black' fillOpacity='0.04' />
        <circle cx='223' cy='95' r='120' fill='black' fillOpacity='0.04' />
        <path
          d='M165.096 69.2836L160.394 61.1388C161.69 60.9416 163.053 60.9639 164.525 61.1972C165.537 61.3935 166.406 61.2562 167.263 60.7615C169.124 59.6873 169.76 57.2919 168.68 55.4207C167.725 53.7665 166.101 53.5279 165.508 53.4413C162.102 53.0223 159.29 53.2424 156.496 54.1867C155.397 52.445 153.126 51.8507 151.358 52.8713C149.646 53.8597 149.01 56.0125 149.842 57.8035C144.909 61.9531 143.377 68.3605 146.365 73.5355C150.128 80.054 156.348 79.8288 161.835 78.3969L166.744 86.9C164.619 87.3827 162.455 87.3242 160.042 86.7499C159.073 86.4809 158.002 86.6222 157.107 87.1393C155.301 88.1819 154.672 90.6158 155.734 92.4546C156.333 93.492 157.276 94.1432 158.527 94.3886C162.884 95.2664 166.763 95.0546 170.555 93.7092L171.495 95.3373C172.56 97.1824 174.901 97.8324 176.713 96.7863C178.525 95.7402 179.132 93.388 178.067 91.543L177.189 90.023C182.368 85.7375 183.856 79.4312 180.726 74.0104C176.936 67.4459 170.377 67.8663 165.096 69.2836ZM174.126 78.066C175.101 79.7553 174.724 81.713 173.231 83.3741L169.184 76.3631C172.79 75.7518 173.492 76.9683 174.126 78.066ZM152.966 69.4808C152.068 67.9257 152.431 66.0643 153.8 64.4802L157.723 71.2751C154.292 71.7779 153.62 70.6136 152.966 69.4808Z'
          fill='#68646B'
        />
        <mask
          id='mask0_1608_10591'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='144'
          y='52'
          width='39'
          height='46'
        >
          <path
            d='M165.096 69.2836L160.394 61.1388C161.69 60.9416 163.053 60.9639 164.525 61.1972C165.537 61.3935 166.406 61.2562 167.263 60.7615C169.124 59.6873 169.76 57.2919 168.68 55.4207C167.725 53.7665 166.101 53.5279 165.508 53.4413C162.102 53.0223 159.29 53.2424 156.496 54.1867C155.397 52.445 153.126 51.8507 151.358 52.8713C149.646 53.8597 149.01 56.0125 149.842 57.8035C144.909 61.9531 143.377 68.3605 146.365 73.5355C150.128 80.054 156.348 79.8288 161.835 78.3969L166.744 86.9C164.619 87.3827 162.455 87.3242 160.042 86.7499C159.073 86.4809 158.002 86.6222 157.107 87.1393C155.301 88.1819 154.672 90.6158 155.734 92.4546C156.333 93.492 157.276 94.1432 158.527 94.3886C162.884 95.2664 166.763 95.0546 170.555 93.7092L171.495 95.3373C172.56 97.1824 174.901 97.8324 176.713 96.7863C178.525 95.7402 179.132 93.388 178.067 91.543L177.189 90.023C182.368 85.7375 183.856 79.4312 180.726 74.0104C176.936 67.4459 170.377 67.8663 165.096 69.2836ZM174.126 78.066C175.101 79.7553 174.724 81.713 173.231 83.3741L169.184 76.3631C172.79 75.7518 173.492 76.9683 174.126 78.066ZM152.966 69.4808C152.068 67.9257 152.431 66.0643 153.8 64.4802L157.723 71.2751C154.292 71.7779 153.62 70.6136 152.966 69.4808Z'
            fill='black'
          />
        </mask>
        <g mask='url(#mask0_1608_10591)'>
          <rect
            x='148.66'
            y='49.3367'
            width='37.4803'
            height='66.1417'
            transform='rotate(-30 148.66 49.3367)'
            fill='#8E8C96'
          />
        </g>
        <path
          d='M287.283 73.5435L291.986 65.3988C292.805 66.4227 293.467 67.6142 294 69.0054C294.336 69.9797 294.89 70.6644 295.747 71.1591C297.608 72.2333 300 71.587 301.081 69.7158C302.036 68.0616 301.43 66.5354 301.209 65.9791C299.869 62.8195 298.272 60.4941 296.057 58.5467C297.016 56.7244 296.395 54.4606 294.628 53.44C292.916 52.4516 290.733 52.9768 289.598 54.5932C283.538 52.3958 277.223 54.2727 274.235 59.4477C270.472 65.9662 273.776 71.2395 277.76 75.2755L272.851 83.7786C271.37 82.1793 270.339 80.2767 269.63 77.8992C269.378 76.9256 268.721 76.0693 267.825 75.5521C266.019 74.5096 263.597 75.1823 262.535 77.021C261.936 78.0584 261.844 79.2008 262.257 80.407C263.675 84.6186 265.798 87.8723 268.859 90.4837L267.919 92.1117C266.854 93.9568 267.461 96.3089 269.273 97.355C271.085 98.4011 273.426 97.7511 274.491 95.906L275.369 94.386C281.67 96.7283 287.875 94.8638 291.005 89.4429C294.795 82.8785 291.151 77.4083 287.283 73.5435ZM284.192 85.7547C283.217 87.444 281.333 88.0958 279.148 87.6341L283.196 80.623C285.528 83.4405 284.826 84.657 284.192 85.7547ZM281.047 63.1368C281.945 61.5817 283.738 60.965 285.795 61.3587L281.872 68.1537C279.721 65.4339 280.393 64.2696 281.047 63.1368Z'
          fill='#68646B'
        />
        <mask
          id='mask1_1608_10591'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='262'
          y='52'
          width='40'
          height='46'
        >
          <path
            d='M287.283 73.5435L291.986 65.3988C292.805 66.4227 293.467 67.6142 294 69.0054C294.336 69.9797 294.89 70.6644 295.747 71.1591C297.608 72.2333 300 71.587 301.081 69.7158C302.036 68.0616 301.43 66.5354 301.209 65.9791C299.869 62.8195 298.272 60.4941 296.057 58.5467C297.016 56.7244 296.395 54.4606 294.628 53.44C292.916 52.4516 290.733 52.9768 289.598 54.5932C283.538 52.3958 277.223 54.2727 274.235 59.4477C270.472 65.9662 273.776 71.2395 277.76 75.2755L272.851 83.7786C271.37 82.1793 270.339 80.2767 269.63 77.8992C269.378 76.9256 268.721 76.0693 267.825 75.5521C266.019 74.5096 263.597 75.1823 262.535 77.021C261.936 78.0584 261.844 79.2008 262.257 80.407C263.675 84.6186 265.798 87.8723 268.859 90.4837L267.919 92.1117C266.854 93.9568 267.461 96.3089 269.273 97.355C271.085 98.4011 273.426 97.7511 274.491 95.906L275.369 94.386C281.67 96.7283 287.875 94.8638 291.005 89.4429C294.795 82.8785 291.151 77.4083 287.283 73.5435ZM284.192 85.7547C283.217 87.444 281.333 88.0958 279.148 87.6341L283.196 80.623C285.528 83.4405 284.826 84.657 284.192 85.7547ZM281.047 63.1368C281.945 61.5817 283.738 60.965 285.795 61.3587L281.872 68.1537C279.721 65.4339 280.393 64.2696 281.047 63.1368Z'
            fill='black'
          />
        </mask>
        <g mask='url(#mask1_1608_10591)'>
          <rect
            x='296.34'
            y='49.3369'
            width='37.4803'
            height='66.1417'
            transform='rotate(30 296.34 49.3369)'
            fill='#8E8C96'
          />
        </g>
        <path
          d='M226.871 45.0824V35.6777C228.092 36.1549 229.261 36.8558 230.419 37.7936C231.197 38.4695 232.019 38.7856 233.008 38.7856C235.157 38.7856 236.906 37.0294 236.906 34.8687C236.906 32.9587 235.618 31.9397 235.148 31.5685C232.408 29.5025 229.862 28.287 226.971 27.7078C226.89 25.6501 225.22 24 223.179 24C221.203 24 219.575 25.5461 219.4 27.5134C213.053 28.6405 208.523 33.4235 208.523 39.399C208.523 46.926 214.021 49.8404 219.489 51.3439V61.1625C217.407 60.5179 215.563 59.3855 213.76 57.6814C213.055 56.9639 212.058 56.5511 211.023 56.5511C208.938 56.5511 207.177 58.3447 207.177 60.468C207.177 61.6658 207.668 62.7014 208.629 63.5394C211.963 66.4778 215.428 68.234 219.385 68.965V70.8449C219.385 72.9754 221.087 74.7087 223.179 74.7087C225.272 74.7087 226.974 72.9754 226.974 70.8449V69.0897C233.601 67.9678 238.043 63.2504 238.043 56.991C238.043 49.411 232.153 46.4955 226.871 45.0824ZM230.299 57.2031C230.299 59.1537 228.993 60.6603 226.871 61.3528V53.2571C230.299 54.5309 230.299 55.9356 230.299 57.2031ZM216.267 39.188C216.267 37.3923 217.511 35.9615 219.489 35.2743V43.1204C216.267 41.8404 216.267 40.496 216.267 39.188Z'
          fill='#68646B'
        />
        <mask
          id='mask2_1608_10591'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='207'
          y='24'
          width='32'
          height='51'
        >
          <path
            d='M226.871 45.0824V35.6777C228.092 36.1549 229.261 36.8558 230.419 37.7936C231.197 38.4695 232.019 38.7856 233.008 38.7856C235.157 38.7856 236.906 37.0294 236.906 34.8687C236.906 32.9587 235.618 31.9397 235.148 31.5685C232.408 29.5025 229.862 28.287 226.971 27.7078C226.89 25.6501 225.22 24 223.179 24C221.203 24 219.575 25.5461 219.4 27.5134C213.053 28.6405 208.523 33.4235 208.523 39.399C208.523 46.926 214.021 49.8404 219.489 51.3439V61.1625C217.407 60.5179 215.563 59.3855 213.76 57.6814C213.055 56.9639 212.058 56.5511 211.023 56.5511C208.938 56.5511 207.177 58.3447 207.177 60.468C207.177 61.6658 207.668 62.7014 208.629 63.5394C211.963 66.4778 215.428 68.234 219.385 68.965V70.8449C219.385 72.9754 221.087 74.7087 223.179 74.7087C225.272 74.7087 226.974 72.9754 226.974 70.8449V69.0897C233.601 67.9678 238.043 63.2504 238.043 56.991C238.043 49.411 232.153 46.4955 226.871 45.0824ZM230.299 57.2031C230.299 59.1537 228.993 60.6603 226.871 61.3528V53.2571C230.299 54.5309 230.299 55.9356 230.299 57.2031ZM216.267 39.188C216.267 37.3923 217.511 35.9615 219.489 35.2743V43.1204C216.267 41.8404 216.267 40.496 216.267 39.188Z'
            fill='black'
          />
        </mask>
        <g mask='url(#mask2_1608_10591)'>
          <rect x='222.61' y='19.5907' width='37.4803' height='66.1417' fill='#8E8C96' />
        </g>
        <path
          d='M254.701 168.801H191.813C190.235 168.801 188.955 167.228 188.955 165.288V130.158H257.56V165.288C257.56 167.228 256.28 168.801 254.701 168.801Z'
          fill='#C4BCC4'
        />
        <path
          d='M261.255 129.32L243.266 111.307L241 113.577L257.559 130.159H263.276C262.545 130.159 261.813 129.879 261.255 129.32Z'
          fill='#535058'
        />
        <path
          d='M254.701 168.801H220.398C218.819 168.801 217.54 167.519 217.54 165.938V130.158L237.549 110.121L257.559 130.158V165.938C257.559 167.519 256.279 168.801 254.701 168.801Z'
          fill='#F4EBF0'
        />
        <path
          d='M245.907 106.164C245.465 105.094 244.422 104.397 243.266 104.397H203.247C202.489 104.397 201.762 104.698 201.226 105.235L181.216 125.272C180.398 126.091 180.154 127.322 180.596 128.392C181.039 129.461 182.081 130.159 183.237 130.159H223.257C224.015 130.159 224.742 129.857 225.278 129.32L245.288 109.283C246.105 108.465 246.35 107.233 245.907 106.164Z'
          fill='#68646B'
        />
        <path
          d='M263.276 130.159C262.544 130.159 261.813 129.879 261.255 129.32L243.266 111.307L225.278 129.32C224.161 130.438 222.352 130.438 221.235 129.32C220.119 128.202 220.119 126.39 221.235 125.272L241.245 105.235C242.361 104.117 244.171 104.117 245.288 105.235L265.297 125.272C266.414 126.39 266.414 128.202 265.297 129.32C264.739 129.879 264.008 130.159 263.276 130.159Z'
          fill='#8E8C96'
        />
        <path d='M188.955 130.158H217.54V135.883H188.955V130.158Z' fill='#B5ADB1' />
        <path
          d='M239.855 147.285C234.508 147.285 230.173 147.503 230.173 152.839V168.94H249.537V152.839C249.537 147.503 245.202 147.285 239.855 147.285Z'
          fill='#8E8C96'
        />
      </g>
      <defs>
        <clipPath id='clip0_1608_10591'>
          <rect width='445' height='192.939' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
