import { useSelector } from 'react-redux'

// constants
import { PATH_HELPERS } from '../../constants/navigation'

// selectors
import { selectClientId, selectClientFirstHomeId } from '../../store/selectors/client'
import { selectHomeId } from '../../store/selectors/home'

/**
 * Return the url of current clients Homeowner digest. If the client is not a homeowner, return null.
 */
export const useHomeDigestUrl = () => {
  const clientId = useSelector(selectClientId)
  const homeId = useSelector(selectHomeId)
  const fallbackHomeId = useSelector(selectClientFirstHomeId)

  const useHomeId = homeId || fallbackHomeId
  const digestUrl = PATH_HELPERS.home.buildPath(clientId, useHomeId)

  return clientId && useHomeId ? digestUrl : null
}
