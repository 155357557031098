import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import BulletedList from './BulletedList'

import styles from './InlineBulletedList.module.scss'

const propTypes = {
  compact: PropTypes.bool
}

const defaultProps = {
  compact: false
}

const InlineBulletedList = ({ className, compact, itemClass, ...restProps }) => (
  <BulletedList
    className={classnames(className, styles.list)}
    itemClass={classnames(itemClass, styles.listItem, compact && styles.compact)}
    {...restProps}
  />
)

InlineBulletedList.propTypes = propTypes
InlineBulletedList.defaultProps = defaultProps

export default InlineBulletedList
