import qs from 'qs'
import { generateUniqueID } from '../helpers/id'

const AUTH_HOST = `${process.env.REACT_APP_SSO_API_URL}/sign_in`
const PARAMS = qs.stringify(
  {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    nonce: generateUniqueID()
  },
  { encode: false }
)

export const SSO_REDIRECT_URI = `${AUTH_HOST}?${PARAMS}`

export const SESSION_COOKIE = 'homebot-session-cookie'
export const COOKIE_TOKEN_KEY = 'authenticated.access_token'
export const JWT_SESSION_COOKIE = 'homebot-session-token'
export const DOWNLOAD_APP_COOKIE = 'homebot-download-app-cookie'
export const DOWNLOAD_APP_HIDE_BANNER_COOKIE = 'homebot-download-app-hide-banner-cookie'

export const ACCESS_TOKEN_KEY = 'access_token'
