import { produce } from 'immer'
import {
  SHOW_HOME_INSURANCE_DETAIL,
  HIDE_HOME_INSURANCE_DETAIL,
  FETCH_HOME_INSURANCE_REQUEST,
  FETCH_HOME_INSURANCE_SUCCESS,
  FETCH_HOME_INSURANCE_FAILURE,
  UNSET_HOME_INSURANCE,
  SET_HOME_INSURANCE_OVERRIDE
} from '../actions/actionTypes'

export const initialState = {
  data: undefined,
  showDetail: false,
  loading: true,
  error: null
}

export default (state = initialState, { type, data, override, loading, error }) =>
  produce(state, draft => {
    switch (type) {
      case SHOW_HOME_INSURANCE_DETAIL:
        draft.showDetail = true
        break

      case HIDE_HOME_INSURANCE_DETAIL:
        draft.showDetail = false
        break

      case FETCH_HOME_INSURANCE_SUCCESS:
        draft.data = data
        draft.loading = false
        draft.error = null
        break

      case FETCH_HOME_INSURANCE_REQUEST:
        draft.error = null
        break

      case FETCH_HOME_INSURANCE_FAILURE:
        draft.error = error
        draft.loading = false
        break

      case SET_HOME_INSURANCE_OVERRIDE:
        draft.override_enabled = override
        break

      case UNSET_HOME_INSURANCE:
        draft = { ...initialState }
        break

      default:
        break
    }
  })
