import { StorageAdapter } from '../util'
import {
  FETCH_MAPBOX_ZIP_DATA,
  FETCH_MAPBOX_ZIP_DATA_SUCCESS,
  FETCH_MAPBOX_ZIP_DATA_FAILURE
} from '../actions/actionTypes'
import { getSafeLocalStorage } from '../helpers/browser'

const LOCAL_STORAGE_KEY = 'v1mapbox' // Versioned to expire existing if need be
const localStorage = getSafeLocalStorage()
const storage = new StorageAdapter(localStorage, LOCAL_STORAGE_KEY)

const initialState = {
  zipCodes: storage.get('zipCodes', {})
}

const updateZipData = (zipcode, data) => {
  storage.set(`zipCodes.${zipcode}`, data)
  return storage.get('zipCodes')
}

export default (state = initialState, { type, zipcode, data }) => {
  switch (type) {
    case FETCH_MAPBOX_ZIP_DATA:
      return {
        ...state
      }

    case FETCH_MAPBOX_ZIP_DATA_SUCCESS:
      return {
        ...state,
        zipCodes: updateZipData(zipcode, data)
      }

    case FETCH_MAPBOX_ZIP_DATA_FAILURE:
      return {
        ...state
      }

    default:
      return state
  }
}
