import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import createSentryMiddleware from 'redux-sentry-middleware'
import reducers from '../reducers'
import { RootState } from '../types/rootState'
import { extraPrincipalPaymentSavingsApi } from './slices/extraPrincipalPaymentSavings'
import { favoriteListingsApi } from './slices/favoriteListings'
import * as subscribers from './subscribers'
import { savedSearchesApi } from './slices/savedSearches'

const sentryMiddleware = createSentryMiddleware(Sentry, {
  stateTransformer: state => ({}),
  actionTransformer: action => {
    const scrubbedAction: any = {} // eslint-disable-line @typescript-eslint/no-explicit-any

    if (action) {
      Object.keys(action).forEach(key => {
        if (key === 'type') {
          scrubbedAction.type = action.type
        } else if (typeof action[key] === 'boolean') {
          scrubbedAction[key] = action[key]
        } else if (Array.isArray(action[key])) {
          scrubbedAction[`masked__${key}`] = action[key].map(v => typeof v)
        } else if (typeof action[key] === 'object') {
          scrubbedAction[`masked__${key}`] = '{...}'
        } else {
          scrubbedAction[`masked__${key}`] = typeof action[key]
        }
      })
    }

    return scrubbedAction
  }
})

// ideally this will be PreloadedState<RootState>
export const setupStore = (preloadedState?: PreloadedState<any>) => {
  return configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware => {
      const includedMiddleware = getDefaultMiddleware({
        // We have a ton of non-serializable values in redux that cause noisy errors.
        // Disable serializable checking to avoid blowing up the console
        serializableCheck: false,
        immutableCheck: false
      })
        .concat(extraPrincipalPaymentSavingsApi.middleware)
        .concat(favoriteListingsApi.middleware)
        .concat(savedSearchesApi.middleware)

      if (process.env.NODE_ENV === 'production') {
        return includedMiddleware.concat(sentryMiddleware)
      }

      return includedMiddleware
    },
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState
  })
}

const store = setupStore()

Object.values(subscribers).forEach(createSubscriber => {
  const subscriber = createSubscriber(store)
  store.subscribe(subscriber)
})

export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store
