import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Text from '../Text'

import styles from './ErrorHeadline.module.scss'

const propTypes = {
  className: PropTypes.string
}

const defaultProps = {
  element: 'h3',
  paragraph: true,
  size: 'xl',
  weight: 'light'
}

const ErrorHeadline = ({ className, ...restProps }) => (
  <Text
    data-qa='error-headline'
    className={classnames(styles.root, className)}
    variant='neutralCool'
    variantCount='900'
    {...restProps}
  />
)

ErrorHeadline.propTypes = propTypes
ErrorHeadline.defaultProps = defaultProps

export default ErrorHeadline
