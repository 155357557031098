import { defineMessages, useIntl } from 'react-intl'
import { BotHeadSpin } from '../../../Components'
import { Text, Button, Flex } from '@homebotapp/hb-react-component-catalog'
import { useSignUpProvider } from '../SignUpContext'
import styles from './ClientNotFound.module.scss'

export const MSG = defineMessages({
  youNeedAnExistingAccount: {
    id: 'NewBuyerSignUp.clientNotFound.cta',
    defaultMessage: 'You need an existing account'
  },
  noAccountFoundFor: {
    id: 'NewBuyerSignUp.clientNotFound.label',
    defaultMessage: 'There was no account found for:'
  },
  tryDifferentEmail: {
    id: 'NewBuyerSignUp.clientNotFound.diffEmail',
    defaultMessage: 'Try a different email'
  },
  toContinue: {
    id: 'NewBuyerSignUp.clientNotFound.helper',
    defaultMessage: 'To continue, you need an email address that currently receives Homebot emails.'
  }
})

export const ClientNotFound = () => {
  const intl = useIntl()
  const { loading, email, handleResetClick } = useSignUpProvider()

  if (loading) {
    return <BotHeadSpin panelSpacing />
  }

  return (
    <div className={styles.notFoundStep}>
      <div>
        <Text size='lg' className={styles.headline}>
          {intl.formatMessage(MSG.youNeedAnExistingAccount)}
        </Text>
        <Text size='xs'>{intl.formatMessage(MSG.noAccountFoundFor)}</Text>
        <Text color='warning' size='2xs' className={styles.emailAddress} noOfLines={1}>
          {email}
        </Text>
        <Text size='xs' className={styles.helper}>
          {intl.formatMessage(MSG.toContinue)}
        </Text>
      </div>
      <Flex direction='column'>
        <Button className={styles.continueBtn} onClick={handleResetClick}>
          {intl.formatMessage(MSG.tryDifferentEmail)}
        </Button>
      </Flex>
    </div>
  )
}
