import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Svgs
import CalendarSvg from '../../../svgs/Calendar'
import GraphSvg from '../../../svgs/Graph'
import HomeSvg from '../../../svgs/Home'
import MoneySvg from '../../../svgs/Money'
import SnowflakeSvg from '../../../svgs/Snowflake'

// Styles
import styles from './SquareIcon.module.scss'

const BACKGROUND_COLORS = ['success', 'warning', 'none']

const ICONS = ['calendar', 'graph', 'home', 'money', 'snowflake']

const propTypes = {
  backgroundColor: PropTypes.oneOf(BACKGROUND_COLORS).isRequired,
  icon: PropTypes.oneOf(ICONS).isRequired,
  className: PropTypes.string
}

const SquareIcon = ({ backgroundColor, icon, className }) => {
  const iconMapping = {
    calendar: <CalendarSvg />,
    graph: <GraphSvg />,
    home: <HomeSvg />,
    money: <MoneySvg />,
    snowflake: <SnowflakeSvg />
  }

  const wrapperClassName = classnames(styles.iconWrapper, styles[`iconWrapper-${backgroundColor}`], className)

  return (
    <div data-qa='square-icon' className={wrapperClassName}>
      {iconMapping[icon]}
    </div>
  )
}

SquareIcon.propTypes = propTypes

SquareIcon.BackgroundColors = BACKGROUND_COLORS
SquareIcon.Icons = ICONS

export default SquareIcon
