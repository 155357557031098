import { SEND_INVITE_REQUEST, SEND_INVITE_SUCCESS, SEND_INVITE_FAILURE } from '../actions/actionTypes'

const initialState = {
  loading: false,
  error: null
}

export default (state = initialState, { type, error }) => {
  switch (type) {
    case SEND_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case SEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }

    case SEND_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state
  }
}
