import * as Sentry from '@sentry/browser'
import styles from './recaptcha.module.scss' // eslint-disable-line no-unused-vars

const {
  REACT_APP_LGW_RECAPTCHA_KEY: KEY
} = process.env

export const execute = async params => {
  try {
    return window.grecaptcha?.execute(KEY, params)
  } catch(e) {
    Sentry.captureException("Error calling recaptcha execute")
  }
}
