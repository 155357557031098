import React from 'react'
import PropTypes from 'prop-types'
import Select from './Select'
import range from 'lodash/range'

const propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]))
}

const defaultOptions = () => range(1, 31).map((m, i) => ({ key: i, value: m }))

const DaySelect = ({ options, ...restProps }) => <Select options={options || defaultOptions()} {...restProps} />

DaySelect.propTypes = propTypes

export default DaySelect
