export const SavedSearchIcon = () => (
  <svg width='15' height='15' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='bookmark-magnifying-glass'>
      <g id='Vector'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.863 10.3021C18.3155 10.5142 17.7331 10.6216 17.1454 10.6216C15.1995 10.6216 13.4471 9.4495 12.7024 7.65177C11.9577 5.85249 12.3696 3.78371 13.745 2.40756C15.1205 1.03139 17.1883 0.619203 18.9865 1.36435C20.7832 2.1095 21.9547 3.86303 21.9547 5.8098C21.9601 6.82344 21.6407 7.81159 21.0449 8.6319H21.0435L22.7169 10.8176C22.9383 11.1076 23.0362 11.4728 22.9879 11.8353C22.9396 12.1964 22.7491 12.524 22.4592 12.7456C21.8567 13.2074 20.9939 13.092 20.5323 12.4878L18.863 10.3021ZM19.0495 5.02031C18.7301 4.24966 17.9787 3.74754 17.1454 3.74754C16.0075 3.74754 15.0843 4.67125 15.0843 5.80976C15.0843 6.64351 15.5861 7.39537 16.3564 7.7149C17.1266 8.03444 18.0136 7.85719 18.6027 7.26781C19.1917 6.6784 19.3689 5.79096 19.0495 5.02031Z'
          fill='currentColor'
        />
        <path
          d='M1.01744 19.531C1 19.4236 1 19.3175 1 19.2141L1.00022 6.49714C1.00022 3.84014 3.15392 1.6853 5.80949 1.6853H8.90116C9.66065 1.6853 10.2752 2.30022 10.2752 3.06011C10.2752 3.82 9.66065 4.43492 8.90116 4.43492H5.80949C4.67158 4.43492 3.74838 5.35862 3.74838 6.49714V19.2141C3.74972 19.2423 3.74972 19.2719 3.74838 19.3001C3.77655 19.5458 3.89598 19.7727 4.08116 19.9365C4.40317 20.252 4.89162 20.3285 5.29419 20.1244V20.1258L9.0729 17.6232C10.0296 17.0083 11.2561 17.0083 12.2129 17.6232V17.6218L15.9916 20.1244C16.3941 20.3285 16.8812 20.252 17.2046 19.9365C17.3737 19.7659 17.4757 19.5404 17.4891 19.3001V16.1208C17.4891 15.3609 18.1037 14.746 18.8632 14.746C19.6227 14.746 20.2372 15.3609 20.2372 16.1208V19.527C20.1326 20.7944 19.3986 21.9248 18.2821 22.5357C17.1669 23.1452 15.8183 23.1519 14.6965 22.5545C14.6146 22.5142 14.5355 22.4686 14.459 22.4175L10.6803 19.915C10.6454 19.8908 10.5984 19.8908 10.5635 19.915L6.78482 22.4175C6.70967 22.4686 6.63184 22.5142 6.55133 22.5545C5.07526 23.3399 3.25839 23.0593 2.08558 21.8671C1.46697 21.2374 1.08991 20.4104 1.01744 19.531Z'
          fill='currentColor'
        />
      </g>
    </g>
  </svg>
)
