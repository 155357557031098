import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

// Components
import { Button } from '../..'

// Styles
import styles from './ExpandablePanel.module.scss'

const defaultProps = {
  isOpen: false
}

const propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  className: PropTypes.string,
  handleExpandablePanelOnClick: PropTypes.func
}

const Label = ({ isOpen, children, className, handleExpandablePanelOnClick }) => {
  return (
    <div className={classnames(styles.label, className)}>
      <div className={styles.labelContent}>{children}</div>
      <Button
        data-qa='expandable-panel-chevron'
        className={classnames(styles.toggleButton, isOpen && styles.open)}
        onClick={handleExpandablePanelOnClick}
        onMouseUp={e => e.target.blur()}
      />
    </div>
  )
}

Label.defaultProps = defaultProps
Label.propTypes = propTypes
export default Label
