import {
  SET_CUSTOMER_PROFILE,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_REQUEST,
  FETCH_CUSTOMER_PROFILE_FAILURE
} from './actionTypes'
import { getCustomerProfile } from '../api/mikasa/requests'
import { fetchAgent } from '../api/mikasa/token_requests'
import * as TYPES from '../constants/agent_types'
import { fetchSponsor } from './sponsor'
import { fetchCustomerProfilePowerUps } from './powerUps'
import { fetchVideos } from './videos'

export const needsSponsor = profile => (profile.type === TYPES.REA || profile.type === TYPES.IA) && profile.hasSponsor

export const setProfile =
  (data, loading = false) =>
  dispatch => {
    dispatch({
      type: SET_CUSTOMER_PROFILE,
      data,
      loading
    })

    if (data && data.id) {
      dispatch(fetchCustomerProfilePowerUps(data.id))
      dispatch(fetchVideos())
    }

    if (needsSponsor(data)) {
      dispatch(fetchSponsor(data.type, data.id))
    }
  }

const getFetchProfile =
  method =>
  (record, initial = true) =>
  async dispatch => {
    let identifier
    dispatch({
      type: FETCH_CUSTOMER_PROFILE_REQUEST
    })

    if (record) {
      if (record.uuid) {
        // legacy
        identifier = record.uuid
        dispatch(setMinimumProfile(record, initial))
      } else {
        identifier = record
      }
    }

    try {
      const res = await method(identifier)
      const profileData = res.data.data

      dispatch({
        type: FETCH_CUSTOMER_PROFILE_SUCCESS,
        data: profileData
      })

      if (needsSponsor(profileData)) {
        dispatch(fetchSponsor(profileData.type, profileData.id))
      }
    } catch (e) {
      dispatch({
        type: FETCH_CUSTOMER_PROFILE_FAILURE,
        error: e
      })
    }
  }

const setMinimumProfile =
  (record, initial = true) =>
  dispatch => {
    const data = pickMinimumData(record)
    dispatch(setProfile(data, initial))
  }

/**
 * pickMinimumData's goal is to lift just enough info off the
 * legacy customerProfile (agent) record to render Intro.jsx
 * Idea is to render that element asap, while loading the 'final'
 * shape from Mikasa
 */
const pickMinimumData = ({
  uuid: id,
  id: legacyRef,
  type,
  photoUri,
  websiteUri,
  title,
  firstName,
  lastName,
  email,
  phones,
  sponsorId
}) => ({
  id,
  legacyRef,
  photoUri,
  websiteUri,
  title,
  firstName,
  lastName,
  email,
  hasSponsor: Boolean(sponsorId),
  type: type === TYPES.LEGACY_REA ? TYPES.REA : TYPES.LO,
  ...getPhoneNumbers(phones)
})

const getPhoneNumbers = legacyPhonesRecord =>
  (legacyPhonesRecord || []).reduce((sum, record) => {
    switch (record.which) {
      case 'mobile':
        sum.mobilePhone = record.number
        break
      case 'office':
        sum.workPhone = record.number
        break
      default:
        break
    }
    return sum
  }, {})

export const fetchProfile = getFetchProfile(getCustomerProfile)
export const fetchProfileWithToken = getFetchProfile(fetchAgent)
