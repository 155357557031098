import { createSelector } from 'reselect'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import { QUESTION_ACKNOWLEDGED_MARKET_TEMP } from '../../constants/zip_modal'
import { PREQUAL } from '../../constants/affordability'

export const selectBuyerInfo = state => get(state, 'buyerInfo')

export const selectFromBuyerInfo = path => createSelector(selectBuyerInfo, partialRight(get, path))

export const selectBuyerInfoFetchId = state => state.client.fetchId

export const selectBuyerErrorModal = selectFromBuyerInfo('errorModal')
export const selectBuyerId = selectFromBuyerInfo('id')
export const selectLoading = selectFromBuyerInfo('isLoading')
export const selectAffordability = selectFromBuyerInfo('affordability')
export const selectAffordabilityIsDefault = selectFromBuyerInfo('defaultAffordability')
export const selectAffordabilityMethod = selectFromBuyerInfo('affordabilityMethod')
export const selectMarketInterests = selectFromBuyerInfo('marketInterests')

export const selectCloseDate = selectFromBuyerInfo('closeDate')
export const selectBuyerFirstName = selectFromBuyerInfo('firstName')
export const selectIncome = selectFromBuyerInfo('income')
export const selectDebt = selectFromBuyerInfo('debt')
export const selectDownPayment = selectFromBuyerInfo('downPayment')
export const selectLoanTerm = selectFromBuyerInfo('loanTerm')
export const selectBuyerInfoOnboardingState = selectFromBuyerInfo('onboardingState')

const getSelectFromAnswers = field =>
  createSelector(selectFromBuyerInfo('clientAnswers'), answers => {
    if (!answers || answers.length === 0) {
      return undefined
    }

    const answer = answers.find(({ question: q }) => q === field)

    return get(answer, 'response.value')
  })

export const selectMarketInterestZipcodes = createSelector(
  selectMarketInterests,
  interests => interests && interests.map(i => i.zipcode)
)

export const selectHasBuyerInfoAcknolwedgedMarketTemp = createSelector(
  getSelectFromAnswers(QUESTION_ACKNOWLEDGED_MARKET_TEMP),
  value => Boolean(value)
)

export const selectAffordabilityIsPrequal = createSelector(selectAffordabilityMethod, method => method === PREQUAL)
