import { GraphQLClient } from 'graphql-request'
import { getBearerToken } from '../../../../auth'
import { RequestConfig } from './graphQLClientType'

export interface GQLRequestParam {
  endpoint?: string
  options?: RequestConfig // Switching over our moduleResolution in tsconfig to bundler (needed for hb-react-component-catalog) instead of node broke installing this config type from import { RequestConfig } from 'graphql-request/build/esm/types' - just going to use any for now and circle back on this later. You can view the RequestConfig type by command clicking on import { GraphQLClient } from 'graphql-request' and searching for RequestConfig there
}

/**
 * @param param - GQLRequestParam
 */
export const graphqlGLClient = (param?: GQLRequestParam): GraphQLClient => {
  const bearerToken = getBearerToken()
  // if (!bearerToken) {
  //   throw new Error('Bearer token is not set')
  // }
  if (!param?.endpoint && !process.env.REACT_APP_GQL_URI) {
    throw new Error('Endpoint is not set')
  }
  /**
   * We are excluding oban because
   * we have a dedicated QA project instead of
   * the staging project that basically handles all the other bevs.
   *
   * WE are adding the header x-bev-env to the gql client so other bev environments
   * can call staging rye gql "gqlactus" without any issues.
   */
  const excludedDomains = ['oban']
  const mikasaBaseUrl = process.env.REACT_APP_MIKASA_URI
  const bevDomain = mikasaBaseUrl?.includes('-') ? mikasaBaseUrl?.split('://')[1]?.split('-')[0] : undefined
  const endpoint = (param?.endpoint || process.env.REACT_APP_GQL_URI) as string
  const gqlClient = new GraphQLClient(endpoint, param?.options || {})
  gqlClient.setHeader('authorization', `Bearer ${bearerToken}`)
  if (bevDomain && !excludedDomains.includes(bevDomain)) {
    gqlClient.setHeader('x-bev-env', bevDomain)
  }
  return gqlClient
}
