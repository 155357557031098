import { ReactNode } from 'react'
import classnames from 'classnames'
import Text from './..'

import styles from './Headline.module.scss'

export interface IHeadlineProps {
  className?: string
  children?: ReactNode
}

const Headline = ({ className, ...restProps }: IHeadlineProps) => (
  <Text
    className={classnames(styles.root, className)}
    variant='neutralCool'
    variantCount='900'
    align='center'
    element='h2'
    size='xxl'
    weight='light'
    {...restProps}
  />
)

export default Headline
