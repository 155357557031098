import get from 'lodash/get'
import { getCustomerProfilePowerUps } from '../api/mikasa/requests'
import { selectCustomerId } from '../store/selectors/customerProfile'
import {
  FETCH_CUSTOMER_POWERUPS_REQUEST,
  FETCH_CUSTOMER_POWERUPS_SUCCESS,
  FETCH_CUSTOMER_POWERUPS_FAILURE
} from './actionTypes'

export const fetchCustomerProfilePowerUps = argCustomerId => async (dispatch, getState) => {
  const state = getState()
  const stateCustomerId = selectCustomerId(state)
  const customerId = argCustomerId || stateCustomerId

  dispatch({
    type: FETCH_CUSTOMER_POWERUPS_REQUEST
  })

  try {
    const response = await getCustomerProfilePowerUps(customerId)

    const powerUps = {}
    const powerUpList = get(response, 'data.data', [])
    if (powerUpList.length) {
      const connected = powerUpList.filter(p => p.availability === 'connected')
      connected.reduce((obj, p) => {
        obj[p.code] = p
        return obj
      }, powerUps)
    }

    dispatch({
      type: FETCH_CUSTOMER_POWERUPS_SUCCESS,
      powerUps
    })
  } catch (error) {
    dispatch({
      type: FETCH_CUSTOMER_POWERUPS_FAILURE,
      error
    })
  }
}
