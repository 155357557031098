import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import Text from '../Text'
import { IconAngleRight, IconLock } from '../Icon'
import styles from './CTAButton.module.scss'

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  locked: PropTypes.bool
}

const CTAButton = ({ locked, children, className, ...props }) => (
  <Button className={classnames(styles.ctaButtonContainer, className)} {...props}>
    <Text className={styles.ctaText} variant='neutralCool' variantCount='900'>
      {children}
    </Text>
    <NavIcon locked={locked} />
  </Button>
)

const NavIcon = ({ locked }) => (
  <span className={classnames(styles.navIcon, locked && styles.locked)}>
    {locked ? <IconLock /> : <IconAngleRight />}
  </span>
)

NavIcon.propTypes = {
  locked: PropTypes.bool
}

CTAButton.propTypes = propTypes
export default CTAButton
