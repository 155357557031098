import { produce } from 'immer'
import {
  FETCH_LATEST_MORTGAGE_RATES_REQUEST,
  FETCH_LATEST_MORTGAGE_RATES_SUCCESS,
  FETCH_LATEST_MORTGAGE_RATES_FAILURE
} from '../actions/actionTypes'

const initialState = {
  data: undefined,
  loading: true,
  error: null
}

export default (state = initialState, { type, data, loading, error }) =>
  produce(state, draft => {
    switch (type) {
      case FETCH_LATEST_MORTGAGE_RATES_SUCCESS:
        draft.data = data
        draft.loading = false
        draft.error = null
        break

      case FETCH_LATEST_MORTGAGE_RATES_REQUEST:
        draft.error = null
        break

      case FETCH_LATEST_MORTGAGE_RATES_FAILURE:
        draft.error = error
        draft.loading = false
        break

      default:
        break
    }
  })
