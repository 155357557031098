import { FETCH_CUSTOM_MAILER_SUCCESS, FETCH_CUSTOM_MAILER_FAILURE } from '../actions/actionTypes'

const initialState = {
  audience: null,
  createdAt: null,
  error: null,
  videoId: null,
  videoType: null
}

export default (state = initialState, { type, ...payload }) => {
  const customMailer = payload.customMailer
  switch (type) {
    case FETCH_CUSTOM_MAILER_SUCCESS:
      return {
        ...state,
        audience: customMailer.audience,
        createdAt: customMailer.createdAt,
        videoId: customMailer.videoId,
        videoType: customMailer.videoType
      }
    case FETCH_CUSTOM_MAILER_FAILURE:
      return {
        error: payload.error
      }
    default:
      return state
  }
}
