import { compose } from 'recompose'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { immediateHideNotification } from '../../../actions/notification'

const NotificationContainer = compose(
  injectIntl,
  connect(
    state => ({
      message: state.notification.message,
      messageType: state.notification.messageType
    }),
    dispatch => ({
      handleClose: () => dispatch(immediateHideNotification())
    })
  )
)

export default NotificationContainer
