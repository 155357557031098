import { createToast } from '../factories/toast'
import { ADD_TOAST, REMOVE_TOAST } from './actionTypes'

const addToast = (options = {}) => {
  return {
    type: ADD_TOAST,
    toast: createToast(options)
  }
}

const removeToast = id => {
  return {
    type: REMOVE_TOAST,
    id
  }
}

export { addToast, removeToast }
