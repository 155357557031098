import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import CheckBox from './CheckBox'
import BaseCheckBox from './BaseCheckBox'

import styles from './CheckBoxSlider.module.scss'

const propTypes = {
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool
}

const MyInput = ({ className, checked, defaultChecked, onChange, onFocus, onBlur, disabled, ...restProps }) => {
  const [isChecked, setChecked] = useState(defaultChecked)
  const myIsChecked = Boolean(isChecked || checked)
  const [focused, setFocused] = useState(false)

  return (
    <div
      className={classnames(
        styles.root,
        myIsChecked && styles.checked,
        focused && styles.focused,
        disabled && styles.disabled,
        className
      )}
    >
      <div className={styles.circle} />
      <BaseCheckBox
        hide
        checked={myIsChecked}
        onChange={e => {
          setChecked(!myIsChecked)
          if (onChange) onChange(e)
        }}
        onFocus={e => {
          setFocused(true)
          if (onFocus) onFocus(e)
        }}
        onBlur={e => {
          setFocused(false)
          if (onBlur) onBlur(e)
        }}
        onClick={e => e.target.blur()}
        disabled={disabled}
        {...restProps}
      />
    </div>
  )
}

MyInput.propTypes = propTypes

const CheckBoxSlider = ({ ...restProps }) => {
  return <CheckBox input={MyInput} {...restProps} />
}

export default CheckBoxSlider
