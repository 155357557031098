import { createSelector } from 'reselect'
import get from 'lodash/get'
import { selectCustomerHasSponsor } from './customerProfile'
import { selectSponsorshipYearsInIndustry, selectSponsorshipBrandingStatement } from './sponsorship'

export const selectBrandingStats = state => get(state, 'brandingStats.data')

export const selectBrandingClientsInZip = createSelector(selectBrandingStats, branding => get(branding, 'clientsInZip'))

export const selectBrandingRecentLoansCount = createSelector(selectBrandingStats, branding =>
  get(branding, 'loansInLast90Days')
)

export const selectBrandingYearsInIndustry = state => selectSponsorshipYearsInIndustry(state)
export const selectBrandingStatement = state => selectSponsorshipBrandingStatement(state)

export const selectShouldRenderSponsorBranding = createSelector(
  selectCustomerHasSponsor,
  selectBrandingYearsInIndustry,
  (hasSponsor, years) => hasSponsor && years && years > 0
)
