import { useIntl, defineMessages } from 'react-intl'
import { Listing } from '../../../../api/gqlaxy/gql/generated/graphql'
import { Flex, Image, Heading, Text } from '@homebotapp/hb-react-component-catalog'
import { subHours } from 'date-fns'

export const MSG = defineMessages({
  provided: {
    id: 'ListingMetadata.dataProvidedBy',
    defaultMessage: 'Data provided by:'
  },
  disclaimer: {
    id: 'ListingMetadata.disclaimer',
    defaultMessage: 'MLS Disclaimer'
  },
  alt: {
    id: 'HomeSearch.listingDetails.mlsLogo.alt',
    defaultMessage: '{name} Logo'
  },
  txt: {
    id: 'HomeSearch.listingDetails.nmls.dataProvidedByMlsAbrvAndNumber',
    defaultMessage: '{name} • MLS #{num}'
  },
  constellationDisclaimer: {
    id: 'HomeSearch.listingDetails.constellationDisclaimer',
    defaultMessage: 'Powered by Constellation Web Solutions, Inc'
  }
})

export interface ListingMetadataProps {
  listing?: Listing
  hideDisclaimer?: boolean
}

export const ListingMetadata = ({ listing, hideDisclaimer = false }: ListingMetadataProps) => {
  const intl = useIntl()

  return (
    <>
      <Heading as='h3' size='3xs' textAlign='left' variant='eyebrow' mt={2}>
        {intl.formatMessage(MSG.provided)}
      </Heading>
      <Flex align='flex-start' width='100%' direction='row' alignItems='center'>
        {listing?.mlsLogo && (
          <Image
            maxHeight={8}
            src={listing.mlsLogo}
            alt={intl.formatMessage(MSG.alt, { name: listing.originatingSystemName })}
          />
        )}
        {listing?.originatingSystemName && listing?.mlsNumber && (
          <Heading as='h4' size='2xs' textAlign='left' marginLeft={3}>
            {intl.formatMessage(MSG.txt, { name: listing.originatingSystemName, num: listing.mlsNumber })}
          </Heading>
        )}
      </Flex>
      {!hideDisclaimer && (
        <>
          <Heading mb={0} mt={4} size='2xs' textAlign='left'>
            {intl.formatMessage(MSG.disclaimer)}
          </Heading>
          {listing?.disclaimer && (
            <Text size='2xs'>{listing.disclaimer.replace('{{date}}', subHours(new Date(), 1).toLocaleString())}</Text>
          )}
          <Text mt={2} size='2xs'>
            {intl.formatMessage(MSG.constellationDisclaimer)}
          </Text>
        </>
      )}
    </>
  )
}
