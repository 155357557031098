import { useSelector } from 'react-redux'
import { selectClientId, selectClientIsBuyer } from '../../store/selectors/client'
import { PATH_HELPERS } from '../../constants/navigation'
import { INITIALIZE_ROUTE } from '../../store/selectors/routes'

/**
 * Return the url of current clients Buyer digest. If the client is not a buyer, return null.
 */
export const useBuyerDigestUrl = () => {
  const clientId = useSelector(selectClientId)
  const isBuyer = useSelector(selectClientIsBuyer)

  if (isBuyer && clientId) {
    return PATH_HELPERS.buy.buildPath(clientId)
  } else {
    return INITIALIZE_ROUTE
  }
}
