import { initializeScript } from './util'
const SRC = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js'

let initialized = false

export const initialize = async () => {
  if (!initialized) {
    await initializeScript(SRC)
    initialized = true
  }

  return true
}
