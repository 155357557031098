import { useState, useEffect, useRef } from 'react'

const HIDE_LOADER = 'HIDE_LOADER'
const DELAY = 'DELAY'
const DISPLAY_LOADER = 'DISPLAY_LOADER'
const MIN_LOADER_DISPLAY_TIME = 750

type State = typeof HIDE_LOADER | typeof DELAY | typeof DISPLAY_LOADER

export const useDelayLoading = (loading: boolean, delayUntilShow: number, minLoaderDisplayTime?: number) => {
  const [state, setState] = useState<State>(HIDE_LOADER)
  const timeoutRef = useRef<null | NodeJS.Timeout>(null)

  useEffect(() => {
    if (loading && state === HIDE_LOADER) {
      timeoutRef.current && clearTimeout(timeoutRef.current)

      timeoutRef.current = setTimeout(() => {
        if (!loading) {
          setState(HIDE_LOADER)
        }

        setState(DISPLAY_LOADER)
      }, delayUntilShow)

      setState(DELAY)
    }

    if (!loading) {
      if (state !== DISPLAY_LOADER) {
        timeoutRef.current && clearTimeout(timeoutRef.current)
      }

      setTimeout(() => {
        setState(HIDE_LOADER)
      }, minLoaderDisplayTime || MIN_LOADER_DISPLAY_TIME)
    }
  }, [loading, state, delayUntilShow, minLoaderDisplayTime])

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  return state === DISPLAY_LOADER
}
