export const mockAirDnaResponse = () => {
  const shape = {
    data: {
      propertyStats: {
        adr: { ltm: 150.25 },
        occupancy: { ltm: 0.75 },
        revenue: { ltm: 45000 }
      }
    }
  }

  const date = new Date()
  const currentMonth = date.getMonth() + 1
  const currentYear = date.getFullYear()

  if (currentMonth !== 12) {
    shape.data.propertyStats.adr[currentYear] = {}
    shape.data.propertyStats.occupancy[currentYear] = {}
    shape.data.propertyStats.revenue[currentYear] = {}

    for (let i = currentMonth + 1; i <= 12; i++) {
      shape.data.propertyStats.adr[currentYear][i] = getRandomFloat(100, 200)
      shape.data.propertyStats.occupancy[currentYear][i] = getRandomFloat(0, 1)
      shape.data.propertyStats.revenue[currentYear][i] = getRandomInt(2000, 3000)
    }
  }

  shape.data.propertyStats.adr[currentYear + 1] = {}
  shape.data.propertyStats.occupancy[currentYear + 1] = {}
  shape.data.propertyStats.revenue[currentYear + 1] = {}

  for (let i = 1; i <= currentMonth; i++) {
    shape.data.propertyStats.adr[currentYear + 1][i] = getRandomFloat(100, 200)
    shape.data.propertyStats.occupancy[currentYear + 1][i] = getRandomFloat(0, 1)
    shape.data.propertyStats.revenue[currentYear + 1][i] = getRandomInt(2000, 3000)
  }

  return shape
}

function getRandomInt(min, max) {
  return Math.round(getRandom(min, max))
}

function getRandomFloat(min, max) {
  return Math.round(getRandom(min, max) * 100) / 100
}

function getRandom(min, max) {
  return Math.random() * (max - min) + min
}
