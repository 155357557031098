import produce from 'immer'

import {
  ADD_HOMEOWNER_LEAD_REQUEST,
  ADD_HOMEOWNER_LEAD_SUCCESS,
  ADD_HOMEOWNER_LEAD_FAILURE,
  FETCH_PREVIEW_REQUEST,
  FETCH_PREVIEW_SUCCESS,
  FETCH_PREVIEW_FAILURE
} from '../actions/actionTypes'

const getEmptyState = () => ({
  submitting: false,
  submitted: false,
  loading: false,
  data: undefined,
  error: null
})

export default (state = {}, { type, id, data, error }) =>
  produce(state, draft => {
    const update = (props = {}) => Object.assign(draft[id], props)

    switch (type) {
      case ADD_HOMEOWNER_LEAD_REQUEST:
        update({ submitting: true })
        break

      case ADD_HOMEOWNER_LEAD_SUCCESS:
        update({
          submitting: false,
          submitted: true
        })
        break

      case ADD_HOMEOWNER_LEAD_FAILURE:
        update({ submitting: false })
        break

      case FETCH_PREVIEW_REQUEST:
        if (!draft[id]) {
          draft[id] = getEmptyState()
        }

        draft[id].loading = true
        break

      case FETCH_PREVIEW_SUCCESS:
        update({
          loading: false,
          data
        })
        break

      case FETCH_PREVIEW_FAILURE:
        update({
          loading: false,
          error
        })
        break

      default:
        break
    }
  })
