/**
 * All of these statuses are from the RESO standard
 *
 * https://ddwiki.reso.org/display/DDW20/StandardStatus+Field
 */
export enum ListingStatusEnum {
  // Active Listing
  ACTIVE = 'Active',
  // Accepting Backup Offers, Backup Offer, Active With Accepted Offer, Backup, Contingent
  ACTIVE_UNDER_CONTRACT = 'Active Under Contract',
  // Terminated
  CANCELLED = 'Cancelled',
  // Sold, Leased, Rented, Closed Sale
  CLOSED = 'Closed',
  // Delayed Showings
  COMING_SOON = 'Coming Soon',
  // Kill, Zap
  DELETE = 'Delete',
  EXPIRED = 'Expired',
  // Hold Do Not Show, Temp Off Market
  HOLD = 'Hold',
  // Draft, Partially Completed
  INCOMPLETE = 'Incomplete',
  // Offer Accepted, Under Contract
  PENDING = 'Pending',
  // Temp Off Market
  WITHDRAWN = 'Withdrawn',
  // This is a custom status just for us when a status
  // is not recognized in case the RESO statuses change in
  // the future
  UNKNOWN = 'Unknown'
}
