import { defineMessages } from 'react-intl'

export const AVM_DIFF_PERCENT_THRESHOLD = 0.1
export const CONFIDENCE_THRESHOLD = 70
export const QUESTION_ACKNOWLEDGED_TUNE_VALUE = 'acknowledged_tune_value'

const TYV = 'TYV'
export const VALUATION_TYPES = {
  TYV
}

export const AVM_TUNE_TYPES = {
  TYV: 'tune_your',
  CMA: 'cma'
}

export const AVM_SOURCE_TYPES = {
  CLIENT: 'client',
  AGENT: 'agent'
}

export const ADDITIONAL_UPDATE_OPTIONS = defineMessages({
  kitchenRemodel: {
    defaultMessage: 'Kitchen remodel',
    id: 'TYV.panels.additionalUpdates.kitchenRemodel'
  },
  newAppliances: {
    defaultMessage: 'New appliances',
    id: 'TYV.panels.additionalUpdates.newAppliances'
  },
  newDoors: {
    defaultMessage: 'New doors',
    id: 'TYV.panels.additionalUpdates.newDoors'
  },
  bathroomRemodel: {
    defaultMessage: 'Bathroom remodel',
    id: 'TYV.panels.additionalUpdates.bathroomRemodel'
  },
  additionalSqft: {
    defaultMessage: 'Additional sqft',
    id: 'TYV.panels.additionalUpdates.additionalSqft'
  },
  newWindows: {
    defaultMessage: 'New windows',
    id: 'TYV.panels.additionalUpdates.newWindows'
  },
  replacedFloors: {
    defaultMessage: 'Replaced floors',
    id: 'TYV.panels.additionalUpdates.replacedFloors'
  },
  garageUpdates: {
    defaultMessage: 'Garage updates',
    id: 'TYV.panels.additionalUpdates.garageUpdates'
  },
  exteriorUpgrades: {
    defaultMessage: 'Exterior upgrades',
    id: 'TYV.panels.additionalUpdates.exteriorUpgrades'
  },
  backyardPatio: {
    defaultMessage: 'Backyard patio',
    id: 'TYV.panels.additionalUpdates.backyardPatio'
  },
  newSiding: {
    defaultMessage: 'New siding',
    id: 'TYV.panels.additionalUpdates.newSiding'
  },
  newRoof: {
    defaultMessage: 'New roof',
    id: 'TYV.panels.additionalUpdates.newRoof'
  },
  other: {
    defaultMessage: 'Other',
    id: 'TYV.panels.additionalUpdates.other'
  }
})
