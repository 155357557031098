import { useState, useEffect } from 'react'
import { Box, Flex } from '@homebotapp/hb-react-component-catalog'
import { MobileHeaderDownloadApp } from '../MobileHeaderDownloadApp/MobileHeaderDownloadApp'
import { useSelector } from 'react-redux'
import { selectNativeAccessFeature } from '../../../../store/selectors/customerProfile'
import { RootState } from '../../../../types/rootState'
interface MobileHeaderContainerProps {
  children: React.ReactNode
}

export const MobileHeaderContainer = ({ children }: MobileHeaderContainerProps) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [currentScrollPos, setCurrentScrollPos] = useState(0)
  const [isVisible, setIsVisible] = useState(true)
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)

  useEffect(() => {
    const handleScroll = () => {
      setCurrentScrollPos(document.documentElement.scrollTop)
      // cuurentScrollPos <= 20 is for the rubber band effect on iOS where the top scrolls down a bit if you scroll up quickly at the top
      const visible = prevScrollPos > currentScrollPos || currentScrollPos <= 20

      setIsVisible(visible)
      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos, currentScrollPos])

  return (
    <Box>
      <Flex
        transform={!isVisible ? `translateY(-100px)` : 'translateY(0px)'}
        transition='transform 0.5s ease'
        data-qa='mobile-header-container'
        width={'100%'}
        // absolute is for safari not handling fixed positioning well
        position={currentScrollPos <= 0 ? 'absolute' : 'fixed'}
        top='var(--banner-height, 0px)'
        alignItems='center'
        justifyContent='space-between'
        zIndex='sticky'
        minHeight='var(--top-nav-bar-height)'
        bg='black'
        borderBottom='1px solid'
        borderColor='whiteAlpha.300'
        _light={{
          bg: 'white',
          borderBottomColor: 'blackAlpha.200'
        }}
      >
        {children}
      </Flex>
      {hasNativeAccess && (
        <Box
          width={'100%'}
          position={currentScrollPos > 0 ? 'fixed' : 'absolute'}
          display={currentScrollPos > 40 ? 'block' : 'none'}
          zIndex='sticky'
          top='var(--banner-height, 0px)'
        >
          <MobileHeaderDownloadApp />
        </Box>
      )}
    </Box>
  )
}
