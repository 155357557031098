import moment from 'moment'
import get from 'lodash/get'
import ldStartCase from 'lodash/startCase'
import { createSelector } from 'reselect'

export const getThe = key => obj => get(obj, key)
export const getTheBoolean = key => obj => Boolean(get(obj, key))
export const getTheMoment = key => obj => moment(get(obj, key))
export const getFrom = getter => key => obj => get(getter(obj), key)
export const select = selector => getter => createSelector(selector, getter)

export const fullName = (firstName, lastName) => (firstName && lastName ? `${firstName} ${lastName}` : undefined)

export const startCase = str => str && ldStartCase(str.toLowerCase())

export const every = (...args) => args.every(Boolean)
export const some = (...args) => args.some(Boolean)
export const none = (...args) => args.every(v => !v)
