import { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './OverlayMask.module.scss'

const OverlayMask = forwardRef(({ loading, isExact, className, children }, ref) => {
  const [away, setAway] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    if (!isExact) {
      setAway(true)
      setFadeIn(false)
    } else if (away && isExact) {
      setAway(false)
      setFadeIn(true)
      setTimeout(() => setFadeIn(false))
    }
  }, [isExact, away])

  useEffect(() => {
    if (loading) {
      document.documentElement.classList.add(styles.htmlFitToViewport)
      return () => {
        document.documentElement.classList.remove(styles.htmlFitToViewport)
      }
    }
  }, [loading])

  return (
    <div
      ref={ref}
      className={classnames(
        styles.mask,
        loading && styles.hidden,
        !isExact && styles.subdue,
        fadeIn && styles.disableTransition,
        className
      )}
    >
      {children}
    </div>
  )
})

OverlayMask.propTypes = {
  loading: PropTypes.bool,
  isExact: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
}

export default OverlayMask
