import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'

// Components
import { Text } from '../..'

// Context
import { useTheme } from '@homebotapp/hb-react-component-catalog'

// Styles
import styles from './RadialMeter.module.scss'

const RadialMeter = ({ percent, className, ...restProps }) => {
  const { theme } = useTheme()

  return (
    <div className={classnames(styles.meter, className)} {...restProps}>
      <div className={styles.gauge}>
        <div className={classnames(styles.overflow, theme.isLightThemeType && styles.lightOverflow)} />
      </div>
      <div className={styles.dial} style={{ transform: `rotate(${(percent / 100) * 180}deg)` }}>
        <div className={styles.arm} />
        <div className={styles.grommet} />
      </div>
      <div className={classnames(styles.legend, theme.isLightThemeType && styles.lightLegend)}>
        <Text element='span' className={styles.low} variant='neutralCool' variantCount='900'>
          <FormattedMessage id='RiskMeter.legend.low' defaultMessage='up to date' />
        </Text>
        <Text element='span' className={styles.high} variant='neutralCool' variantCount='900'>
          <FormattedMessage id='RiskMeter.legend.high' defaultMessage='time to evaluate' />
        </Text>
      </div>
    </div>
  )
}

RadialMeter.propTypes = {
  className: PropTypes.string,
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

RadialMeter.defaultProps = {
  percent: 0
}

export default RadialMeter
