import { compose, lifecycle, setDisplayName } from 'recompose'
import { withRouter } from 'react-router'
import delay from 'lodash/delay'
import { scrollToElement, getNoTransformPosition } from '../helpers/browser'
import { getRef } from '../store/elementReference'
import { shouldAutoScroll } from './scrollUtils'

const scrollToRefOnMount = compose(
  setDisplayName('scrollToRefOnMount'),
  withRouter,
  lifecycle({
    componentDidMount() {
      const { history, location } = this.props

      if (!shouldAutoScroll(location, history)) {
        return
      }

      delay(() => {
        const ref = getRef(location.hash.replace('#', ''))

        if (ref) {
          scrollToElement(ref.current)
          const time = getNoTransformPosition(ref.current).top / 2
          setTimeout(() => {
            scrollToElement(ref.current)
          }, time)
        }
      }, 100)
    }
  })
)

export default scrollToRefOnMount
