import Select from './Select'
// import { CustomSelect as Select } from './Select'

import DaySelect from './DaySelect'
import MonthSelect from './MonthSelect'

Select.Month = MonthSelect
Select.Day = DaySelect

export default Select

export { MonthSelect, DaySelect }
