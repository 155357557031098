import Svg from '../Components/shared/Svg'

export const GreenDollarSign = ({ className }) => {
  return (
    <Svg fill='none' height={36} width={37} className={className}>
      <rect fill='#1ecd4c' height={36} rx={6} width={36} x={0.5} />
      <path
        d='M25.5 22.051c0-5.966-10.814-5.576-10.814-9.179 0-1.743 1.64-2.595 3.542-2.595 3.2 0 3.769 2.09 5.218 2.09 1.026 0 1.52-.656 1.52-1.393 0-1.712-2.552-3.007-4.998-3.456V5.866c0-1.03-.82-1.866-1.834-1.866-1.014 0-1.835.836-1.835 1.866v1.71c-2.667.618-4.962 2.5-4.962 5.568 0 5.73 10.813 5.499 10.813 9.524 0 1.394-1.484 2.789-3.922 2.789-3.655 0-4.873-2.517-6.358-2.517-.723 0-1.37.62-1.37 1.552 0 1.483 2.443 3.267 5.801 3.765l-.002.012v1.864c0 1.031.822 1.867 1.836 1.867 1.013 0 1.834-.836 1.834-1.867v-1.864c0-.022-.01-.04-.012-.059 3.022-.574 5.543-2.578 5.543-6.159z'
        fill='#fff'
      />
    </Svg>
  )
}
