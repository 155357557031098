import { CLIENT_TUNED_MORTGAGE_COACH_VALUE } from '../actions/actionTypes'

const initialState = { hasTunedMortgageCoachValue: false }

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CLIENT_TUNED_MORTGAGE_COACH_VALUE:
      return {
        ...state,
        hasTunedMortgageCoachValue: true
      }
    default:
      return state
  }
}
