import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Utils
import { forceHistory } from '../../../composables'

// Selectors
import {
  selectCorporateProfile,
  selectCustomerPhone,
  selectCustomerPhotoUri,
  selectCustomerProfile,
  selectFullName,
  selectOfficeProfile
} from '../../../store/selectors/customerProfile'
import { setIsShowingAgentDropdown } from '../../../store/slices/modals'

// Components
import Format from '../Format'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Text
} from '@homebotapp/hb-react-component-catalog'
import { GlobalTrackingEventLocation } from '../../../constants/tracking'

export const AgentDropdown = () => {
  const dispatch = useDispatch()
  const customerProfile = useSelector(selectCustomerProfile)
  const phone = useSelector(selectCustomerPhone)
  const officeProfile = useSelector(selectOfficeProfile) || {}
  const corporateProfile = useSelector(selectCorporateProfile) || {}
  const { avatarUri: logo, name: corporateName } = corporateProfile
  const customerName = useSelector(selectFullName)
  const customerPhoto = useSelector(selectCustomerPhotoUri)

  const fullOfficeAddressPresent = Object.values(officeProfile.address || {}).every(val => Boolean(val) === true)

  useEffect(() => {
    dispatch(forceHistory)
  }, [dispatch])

  if (!(customerProfile && officeProfile)) {
    return null
  }

  return (
    <Menu
      tracking={{
        guid: 'xcfw4QbtMd3co6Zz5gdUYi',
        ui_context: GlobalTrackingEventLocation.GLOBAL_NAVIGATION_BAR,
        descriptor: 'Agent Dropdown'
      }}
    >
      <MenuButton
        as={Button}
        size='sm'
        variant='ghost'
        tracking={{
          guid: 'pa3istnGZ6GkJHzeXdQB7P',
          ui_context: GlobalTrackingEventLocation.GLOBAL_NAVIGATION_BAR,
          descriptor: 'Agent Dropdown'
        }}
      >
        <Avatar name={customerName} size='xs' src={logo} />
      </MenuButton>
      <MenuList>
        <Flex paddingY='4' paddingRight='6' paddingLeft='3'>
          <Avatar name={customerName} src={customerPhoto} marginRight='3' />
          <Box>
            <Box>
              <Heading as='h3' size='2xs' variant='eyebrow' marginBottom={0} color='neutral-700' textAlign='left'>
                {corporateName}
              </Heading>
              <Text as='b' color='neutral-900'>
                {customerName}
              </Text>
            </Box>

            {/* Office info */}
            <Box marginTop='2'>
              {officeProfile.name && (
                // [CFE Global Styling]: This one is caused by CFE styling the h2 elements to be textAlign: center
                <Heading as='h4' size='2xs' textAlign='left'>
                  {officeProfile.name}
                </Heading>
              )}
              {corporateProfile?.showOfficeAddress && fullOfficeAddressPresent && (
                <Box marginTop='1'>
                  <Text size='xs' marginBottom='0'>
                    {officeProfile.address.street}
                  </Text>
                  <Text size='xs'>
                    {officeProfile.address.city}, {officeProfile.address.state} {officeProfile.address.zipCode}
                  </Text>
                </Box>
              )}
            </Box>

            <Box as='hr' marginY='6' />

            {/* Contact info */}
            <Box>
              {phone && (
                <Box marginBottom='2'>
                  <Text size='xs' color='neutral-900' marginBottom='0' fontWeight='semibold'>
                    Phone
                  </Text>
                  <Link size='xs' href={`tel:${phone}`}>
                    <Format.Phone value={phone} />
                  </Link>
                </Box>
              )}
              {customerProfile.email && (
                <Box marginBottom='2'>
                  <Text size='xs' color='neutral-900' marginBottom='0' fontWeight='semibold'>
                    Email
                  </Text>
                  <Link size='xs' href={`mailto:${customerProfile.email}`}>
                    {customerProfile.email}
                  </Link>
                </Box>
              )}
              {customerProfile.nmls && (
                <Text size='2xs' marginTop='9' marginBottom='0'>{`NMLS #${customerProfile.nmls}`}</Text>
              )}
            </Box>
          </Box>
        </Flex>
      </MenuList>
    </Menu>
  )
}
