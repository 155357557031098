import get from 'lodash/get'
import without from 'lodash/without'
import {
  SET_CLIENT_FETCH_ID,
  FETCH_BUYER_INFO_REQUEST,
  FETCH_BUYER_INFO_SUCCESS,
  FETCH_BUYER_INFO_FAILURE,
  UPDATE_BUYER_INFO_REQUEST,
  UPDATE_BUYER_INFO_SUCCESS,
  UPDATE_BUYER_INFO_FAILURE,
  UPDATE_MARKET_INTEREST_SUCCESS,
  UPDATE_MARKET_INTEREST_FAILURE
} from '../actions/actionTypes'
import { NATIONAL_MEDIAN_HOME_PRICE } from '../constants/affordability'

export const initialState = {
  isLoading: true,
  affordability: NATIONAL_MEDIAN_HOME_PRICE,
  defaultAffordability: true,
  errorModal: false,
  fetchId: null
}

const sanitizeAffordability = data =>
  Object.assign(data, {
    affordability: data.affordability || NATIONAL_MEDIAN_HOME_PRICE,
    defaultAffordability: !data.affordability
  })

const replaceMarketInterests = (state, action, data, id) => {
  switch (action) {
    case 'add':
      return [...state.marketInterests, data]

    case 'remove': {
      const toRemove = state.marketInterests.find(interest => interest.id === id)

      return without(state.marketInterests, toRemove)
    }
    default:
      return state.marketInterests
  }
}

export default (state = initialState, { type, data, error, id, action }) => {
  switch (type) {
    case SET_CLIENT_FETCH_ID:
      return {
        ...initialState,
        fetchId: id
      }

    case FETCH_BUYER_INFO_REQUEST:
      return {
        ...state,
        errorModal: false
      }

    case FETCH_BUYER_INFO_SUCCESS:
    case UPDATE_BUYER_INFO_SUCCESS: {
      const updateState =
        data.id === state.fetchId
          ? {
              ...data,
              ...sanitizeAffordability(data)
            }
          : {}

      return {
        ...state,
        isLoading: false,
        ...updateState
      }
    }

    case FETCH_BUYER_INFO_FAILURE:
      if (get(error, 'response.status') === 401) {
        return {
          ...state,
          errorModal: true
        }
      }

      return {
        ...state
      }

    case UPDATE_BUYER_INFO_REQUEST:
      return {
        ...state,
        ...data
      }

    case UPDATE_BUYER_INFO_FAILURE:
      return {
        ...state
      }

    case UPDATE_MARKET_INTEREST_SUCCESS:
      return {
        ...state,
        marketInterests: replaceMarketInterests(state, action, data, id)
      }

    case UPDATE_MARKET_INTEREST_FAILURE:
      return {
        ...state,
        error
      }

    default:
      return state
  }
}
