import { getThe, select } from './utils'
import { createSelector } from 'reselect'

const getTrackingSource = state => state.trackingSource
const selectFromTrackingSource = select(getTrackingSource)

export const selectReferrerSource = createSelector(
  selectFromTrackingSource(getThe('referrer')),
  source => source || 'user'
)
