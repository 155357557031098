export const buildDelayCss = (cssClass, count, interval) =>
  Array(count)
    .fill('')
    .map((_, i) => {
      const selector = Array(i + 2)
        .fill(`.${cssClass}`)
        .join('~')
      return `${selector} { transition-delay: ${interval * (i + 1)}ms; }`
    })
    .join(' ')
