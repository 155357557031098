import { ADD_BUYER_LEAD_REQUEST, ADD_BUYER_LEAD_SUCCESS, ADD_BUYER_LEAD_FAILURE } from '../actions/actionTypes'

const initialState = {
  loading: true,
  error: false
}

export default (state = initialState, { type }) => {
  switch (type) {
    case ADD_BUYER_LEAD_REQUEST:
      return {
        loading: true,
        error: false
      }

    case ADD_BUYER_LEAD_SUCCESS:
      return {
        loading: false,
        error: false
      }

    case ADD_BUYER_LEAD_FAILURE:
      return {
        loading: false,
        error: true
      }

    default:
      return state
  }
}
