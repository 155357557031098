import {
  SET_BUYER_ONBOARDING_STATE,
  SET_BUYER_ONBOARDING_ADDRESS,
  SET_BUYER_ONBOARDING_GOAL,
  FETCH_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE_ERROR,
  SET_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE
} from '../actions/actionTypes'

import { NATIONAL_MEDIAN_HOME_PRICE } from '../constants/affordability'

const initialState = {
  onboardingState: null,
  address: null,
  goal: null,
  price: null,
  error: null
}

export default (state = initialState, action) => {
  const { error, address, goal, price, type, onboardingState } = action
  switch (type) {
    case FETCH_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE_ERROR:
      return {
        ...state,
        price: NATIONAL_MEDIAN_HOME_PRICE,
        error
      }
    case SET_BUYER_ONBOARDING_ADDRESS:
      return {
        ...state,
        address
      }
    case SET_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE:
      return {
        ...state,
        price,
        error: null
      }
    case SET_BUYER_ONBOARDING_GOAL:
      return {
        ...state,
        goal
      }
    case SET_BUYER_ONBOARDING_STATE:
      return {
        ...state,
        onboardingState
      }
    default:
      return state
  }
}
