import { FETCH_SMS_LONG_CODE_SUCCESS, FETCH_CLIENT_VIEWED_DIGEST_SUCCESS } from '../actions/actionTypes'

export const initialState = {
  smsLongCode: null,
  digestViewEvents: null
}

const clientEventsReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case FETCH_SMS_LONG_CODE_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        smsLongCode: data
      }
    }
    case FETCH_CLIENT_VIEWED_DIGEST_SUCCESS: {
      const { digestViewEvents } = payload

      return {
        ...state,
        digestViewEvents
      }
    }

    default:
      return state
  }
}

export default clientEventsReducer
