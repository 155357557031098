import { createSelector } from 'reselect'
import { isAfter, isBefore } from 'date-fns'
import values from 'lodash/values'
import mapValues from 'lodash/mapValues'
import partialRight from 'lodash/partialRight'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { selectBuyerId, selectHasBuyerInfoAcknolwedgedMarketTemp } from './buyerInfo'
import { PRIORITY_AFFORDABLE, PRIORITY_MARKET, PRIORITY_BIGGER, PRIORITY_FAST } from '../../constants/client/onboarding'
import { EXPLORE_MARKET_TABS } from '../../mappings/navigation'
import { QUESTION_REQUESTED_PREQUAL } from '../../constants/affordability'
import { QUESTION_ACKNOWLEDGED_MARKET_TEMP } from '../../constants/zip_modal'
import { QUESTION_BUYER_ADD_HOME, CONFIRMED_TCA_REQUEST } from '../../constants/client/answers'
import { QUESTION_ACKNOWLEDGED_TUNE_VALUE } from '../../constants/valuation'
import { getHomeId } from './home'

export const selectFromClient = path => createSelector(state => state.client, partialRight(get, path))

export const selectFromClientData = path => createSelector(state => state.client.data, partialRight(get, path))

export const selectClientFetchId = state => state.client.fetchId

export const selectClientId = createSelector(
  state => state.client.data.id,
  selectBuyerId,
  (clientId, buyerId) => clientId || buyerId
)

export const selectClientLegacyId = selectFromClientData('legacyRef')
export const selectClientIsBuyer = selectFromClientData('hbbAccess')
export const selectClientLocale = selectFromClientData('locale')
export const selectClientCustomerProfile = selectFromClientData('customerProfile')

export const selectClientHomes = state => state.client.data.homes

export const selectClientHomesCount = createSelector(selectClientHomes, homes => {
  if (!homes) {
    return 0
  }

  return homes.length
})

export const selectHasRequestedClient = state => state.client.hasRequestedClient

export const selectClientIsLoadingData = state => state.client.loadingData

export const selectHasRequestedHomes = state => state.client.hasRequestedHomes

const selectLoadingHomes = state => state.client.loadingHomes

export const selectLoadingState = state =>
  // has not yet requested OR is loading
  !selectHasRequestedClient(state) ||
  selectClientIsLoadingData(state) ||
  !selectHasRequestedHomes(state) ||
  selectLoadingHomes(state)

const getSelectFromAnswers = field =>
  createSelector(selectFromClientData('clientAnswers'), answers => {
    if (isEmpty(answers)) {
      return []
    }

    const answer = answers.find(({ question: q }) => q === field)

    return get(answer, 'response.value')
  })

export const selectHasDeclinedAddHome = createSelector(
  getSelectFromAnswers(QUESTION_BUYER_ADD_HOME),
  value => Boolean(value) && !Array.isArray(value)
)

export const selectHasRequestedPrequal = createSelector(
  getSelectFromAnswers(QUESTION_REQUESTED_PREQUAL),
  value => Boolean(value) && !Array.isArray(value)
)

const selectHasClientAcknolwedgedMarketTemp = createSelector(
  getSelectFromAnswers(QUESTION_ACKNOWLEDGED_MARKET_TEMP),
  value => Boolean(value) && !Array.isArray(value)
)

export const selectHasAcknolwedgedMarketTemp = createSelector(
  selectHasClientAcknolwedgedMarketTemp,
  selectHasBuyerInfoAcknolwedgedMarketTemp,
  (one, two) => one || two
)

export const selectHasClientAcknowledgedTuneValue = createSelector(
  getSelectFromAnswers(QUESTION_ACKNOWLEDGED_TUNE_VALUE),
  value => Boolean(value) && !Array.isArray(value)
)

export const selectClientHasConfirmedTca = createSelector(
  getSelectFromAnswers(CONFIRMED_TCA_REQUEST),
  value => Boolean(value) && !Array.isArray(value)
)

export const selectHasClientAnsweredQuestion = question =>
  createSelector(getSelectFromAnswers(question), value => {
    if (Array.isArray(value)) {
      return value.length > 0
    }
    return value !== undefined
  })

export const selectAnswerCity = getSelectFromAnswers('buyer_city')

export const selectAnswerCityName = createSelector(selectAnswerCity, value => {
  const name = get(value, 'name')

  if (!name || !name.split) {
    return undefined
  }

  return name.split(',')[0]
})

export const selectAnswerCityCenter = createSelector(selectAnswerCity, cityAnswer => {
  if (isEmpty(cityAnswer)) {
    return null
  }
  const { lat, lng } = cityAnswer
  return { lng, lat }
})

export const selectAnswerDate = createSelector(getSelectFromAnswers('buyer_close_date'), date =>
  date && date.length !== 0 ? new Date(date?.toString().replaceAll('-', '/')) : null
)

export const selectAnswerGoal = getSelectFromAnswers('buyer_goals')

export const selectAnswerPriority = getSelectFromAnswers('buyer_priority')

export const selectAnswerInsuranceFeedback = createSelector(getSelectFromAnswers('home_insurance_opt_out'), result =>
  Array.isArray(result) ? undefined : Boolean(result)
)

export const selectSortFieldFromAnswer = createSelector(selectAnswerPriority, priority => {
  switch (priority) {
    case PRIORITY_MARKET:
      return EXPLORE_MARKET_TABS.POPULAR_MARKETS
    case PRIORITY_BIGGER:
      return EXPLORE_MARKET_TABS.BIGGER_HOUSE
    case PRIORITY_FAST:
      return EXPLORE_MARKET_TABS.FASTEST_MOVE
    case PRIORITY_AFFORDABLE:
      return EXPLORE_MARKET_TABS.CHEAPER_BUYS
    default:
      return EXPLORE_MARKET_TABS.CHEAPER_BUYS
  }
})

export const selectIsHomeowner = createSelector(
  selectHasRequestedHomes,
  selectLoadingHomes,
  selectClientHomes,
  (requested, loading, homes) => requested && !loading && (homes || []).length > 0
)

export const selectCreatedAt = createSelector(selectFromClientData('createdAt'), date => date && new Date(date))

export const selectDigestClickedAt = createSelector(
  selectFromClientData('digestClickedAt'),
  date => date && new Date(date)
)

const onboardingReleaseDate = new Date('2019-05-30')

export const selectWelcomeBack = createSelector(selectDigestClickedAt, selectCreatedAt, (viewed, created) => {
  return !!viewed && !!created && isBefore(created, onboardingReleaseDate)
})

export const selectClientHasHomes = createSelector(
  selectHasRequestedHomes,
  selectClientHomes,
  (requested, homes) => requested && homes && homes.length
)

export const selectClientFirstHome = createSelector(selectClientHomes, partialRight(get, '0'))

export const selectClientFirstHomeId = createSelector(selectClientFirstHome, getHomeId)

export const selectClientHomeZipcodes = createSelector(selectClientHomes, homes => {
  const mappedHomesObj = mapValues(homes, 'address.zip')
  const zipCodes = values(mappedHomesObj)
  return zipCodes
})

export const selectShowBuyerAddHome = createSelector(
  selectClientHasHomes,
  selectHasDeclinedAddHome,
  (hasHomes, declinedAddHome) => !hasHomes && !declinedAddHome
)

export const selectClientFirstName = selectFromClientData('firstName')
export const selectClientMobile = selectFromClientData('mobile')
export const selectClientEmail = selectFromClientData('email')
export const selectClientIsUnsubscribedFromCustomer = selectFromClientData('unsubscribedFromCustomer')
export const selectClientIsSubscribedToHomebotListings = selectFromClientData('subscribedToListingAlerts')
export const selectClientIsSubscribedToHome = selectFromClientData('subscribedToHomeDigest')
export const selectClientIsSubscribedToBuy = selectFromClientData('subscribedToBuyerDigest')
export const selectClientIsSubscribedToHomeMarket = selectFromClientData('subscribedToHomeMarket')
export const selectClientIsTwilioBlacklisted = createSelector(
  selectFromClientData('smsVerificationCode'),
  code => code === 'blacklisted'
)
export const selectClientHasSubscribedToSms = createSelector(selectFromClientData('smsOptedInAt'), subbed =>
  Boolean(subbed)
)
export const selectClientIsSubscribedToSms = createSelector(
  selectFromClientData('smsOptedInAt'),
  selectFromClientData('smsUnsubscribedAt'),
  (subbed, unsubbed) => {
    if (!subbed) {
      return false
    }
    if (subbed && !unsubbed) {
      return true
    }

    return isAfter(new Date(subbed), new Date(unsubbed))
  }
)

export const selectClientSaveRequested = createSelector(selectFromClient('saveRequest'), request => Boolean(request))
export const selectClientSaveSuccess = selectFromClient('saveSuccess')
export const selectClientSaveFailure = createSelector(selectFromClient('saveError'), error => Boolean(error))
export const selectClientRequestedUnsubscribe = selectFromClient('flagForUnsubscribe')

export const selectSnoozedAvmHotMarketWarningDate = createSelector(
  getSelectFromAnswers('snoozed_avm_hot_market'),
  date => date && typeof date === 'string' && new Date(date)
)

export const selectClientIsGuest = state => state.client.isGuest
