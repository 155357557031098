import { useCallback } from 'react'
import { useTrackingContext } from '../../providers/tracking'

export const useTrackingDebounceSetters = trackingFunc => {
  const { debouncing, setDebouncing } = useTrackingContext()

  const fireTrackingAndDebounce = useCallback(() => {
    if (!debouncing.has(fireTrackingAndDebounce)) {
      trackingFunc()
      debouncing.add(fireTrackingAndDebounce)
      setDebouncing(debouncing)
    }
  }, [debouncing, setDebouncing]) // ignoring trackingFunc updates so that parent does not need to memoize the callback between rerenders

  const fireTracking = useCallback(() => {
    fireTrackingAndDebounce()
  }, [fireTrackingAndDebounce])

  const clearDebounce = useCallback(() => {
    if (debouncing.has(fireTrackingAndDebounce)) {
      debouncing.delete(fireTrackingAndDebounce)
      setDebouncing(debouncing)
    }
  }, [debouncing, setDebouncing, fireTrackingAndDebounce])

  return [fireTracking, clearDebounce]
}
