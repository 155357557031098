export const KEY_CODES = {
  tab: 9,
  enter: 13,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40
}

export const MOUSE_CODES = {
  leftButton: 1,
  middleButton: 2,
  rightButton: 3
}
