import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Panel.module.scss'

const propTypes = {
  className: PropTypes.string
}

const Footer = ({ className = '', ...restProps }) => (
  <div className={classnames(styles.footer, className)} {...restProps} data-qa='footer' />
)

Footer.propTypes = propTypes

export default Footer
