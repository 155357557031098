import React, { SVGProps } from 'react'

interface SvgProps extends SVGProps<SVGSVGElement> {
  children: React.ReactNode
}

const Svg = ({
  children,
  xmlns = 'http://www.w3.org/2000/svg',
  xmlnsXlink = 'http://www.w3.org/1999/xlink',
  ...restProps
}: SvgProps) => (
  <svg xmlns={xmlns} xmlnsXlink={xmlnsXlink} {...restProps}>
    {children}
  </svg>
)

export default Svg
