import { MARKET_DEMAND_KEYS } from './marketRecords'

export const videoCodes = {
  NEW_HOME_CODE: 'new-home',
  RENT_HOME_CODE: 'rent-home',
  INVESTMENT_CODE: 'investment-property',
  SELL_HOME_CODE: 'sell-home',
  WELCOME_CODE: 'welcome',
  INSTANT_OFFER_CODE: 'instant-offer',
  REFI_30_FIXED: 'refi-30-fixed',
  YOUR_MARKET_VERY_HIGH_DEMAND_CODE: 'your-market-very-high-demand',
  YOUR_MARKET_HIGH_DEMAND_CODE: 'your-market-high-demand',
  YOUR_MARKET_MODERATE_DEMAND_CODE: 'your-market-moderate-demand',
  YOUR_MARKET_LOW_DEMAND_CODE: 'your-market-low-demand',
  YOUR_MARKET_VERY_LOW_DEMAND_CODE: 'your-market-very-low-demand',
  BUYER_WELCOME_CODE: 'buyer-welcome',
  PREQUAL_CODE: 'prequal',
  SHOULD_YOU_SELL_CODE: 'should-you-sell',
  YOUR_HOME_VALUE_CODE: 'your-home-value',
  CO_BRANDED_INTRO_CODE: 'co-branded-intro'
}

export const VIDEO_AUDIENCE = {
  HOMEOWNERS: 'homeowners'
}

export const MARKET_VIDEO_DEMAND_KEYS = MARKET_DEMAND_KEYS
