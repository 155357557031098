import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Components
import { HeightFade } from '../..'

// Styles
import styles from './ExpandablePanel.module.scss'

const propTypes = {
  fadeId: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  className: PropTypes.string
}

const Body = ({ fadeId, isOpen, children, className }) => {
  const panelClassName = classnames([
    styles.body,
    {
      [styles.active]: isOpen
    },
    className
  ])

  const renderChildren = () => {
    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        hidden: !isOpen
      })
    })
  }

  return (
    <HeightFade id={fadeId} className={panelClassName}>
      {renderChildren()}
    </HeightFade>
  )
}

Body.propTypes = propTypes
export default Body
