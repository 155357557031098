import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

// Selectors
import { selectSponsorshipFullName } from '../../../store/selectors/sponsorship'
import { selectZipcode } from '../../../store/selectors/home'
import {
  selectShouldRenderSponsorBranding,
  selectBrandingYearsInIndustry,
  selectBrandingStatement,
  selectBrandingClientsInZip,
  selectBrandingRecentLoansCount
} from '../../../store/selectors/brandingStats'

// Components
import { If, Text } from '../..'

// Styles
import styles from './SponsorBranding.module.scss'

const SponsorBranding = ({ ...restProps }) => {
  const shouldRender = useSelector(selectShouldRenderSponsorBranding)
  const sponsorFullName = useSelector(selectSponsorshipFullName)
  const yearsInIndustry = useSelector(selectBrandingYearsInIndustry)
  const brandingStatement = useSelector(selectBrandingStatement)
  const loansCount = useSelector(selectBrandingRecentLoansCount)
  const clientsInZip = useSelector(selectBrandingClientsInZip)
  const homeZipcode = useSelector(selectZipcode)

  return (
    <If condition={shouldRender}>
      <div {...restProps}>
        <Text variant='neutralCool' variantCount='700' size='s'>
          <FormattedMessage
            id='SponsorStats.intro'
            defaultMessage='{fullName} has been helping people with their financial goals for {formattedYears}.'
            values={{
              fullName: sponsorFullName,
              formattedYears: (
                <Text element='span' weight='bold' variant='neutralCool' variantCount='900'>
                  <FormattedMessage
                    id='SponsorStats.intro.years'
                    defaultMessage='{yearsInIndustry} years'
                    values={{ yearsInIndustry }}
                  />
                </Text>
              )
            }}
          />
        </Text>
        <div className={styles.brandingStats}>
          <If condition={clientsInZip}>
            <div className={styles.brandingStat}>
              <span className={styles.number}>{clientsInZip}</span>
              <Text variant='neutralCool' variantCount='600' transform='uppercase' size='xs' weight='semibold'>
                <FormattedMessage
                  id='SponsorStats.stat.clients'
                  defaultMessage='Clients{br}helped in {zip}'
                  values={{ zip: homeZipcode, br: <br /> }}
                />
              </Text>
            </div>
          </If>
          <If condition={loansCount}>
            <div className={styles.brandingStat}>
              <span className={styles.number}>{loansCount}</span>
              <Text variant='neutralCool' variantCount='600' transform='uppercase' size='xs' weight='semibold'>
                <FormattedMessage
                  id='SponsorStats.stat.loans'
                  defaultMessage='Clients helped{br}in last 90 days'
                  values={{ br: <br /> }}
                />
              </Text>
            </div>
          </If>
        </div>
        <If condition={brandingStatement}>
          <Text variant='neutralCool' variantCount='700' size='s'>
            {`"${brandingStatement}"`}
          </Text>
        </If>
      </div>
    </If>
  )
}

SponsorBranding.propTypes = {
  className: PropTypes.string
}

export default SponsorBranding
