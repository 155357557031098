/* eslint-disable no-case-declarations*/
/* eslint-disable no-useless-escape*/
/* eslint-disable no-console*/

import axios from 'axios'
import jsonApiParser from 'jsonapi-parse'
import changeCaseObject from 'change-case-object'
import { camelizeKeys } from '../../helpers/utils'
import { getAuthHeaders } from '../../auth'

const mockDomain = 'http://localhost:5001'

const clientsRegex =
  /^clients\/[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}\?include=customer-profile,customer-profile\.office-profile,customer-profile\.office-profile\.corporate-profile$/
const clientAnswersRegex =
  /^clients\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}\/client-answers$/
const homesRegex =
  /^clients\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}\/homes\?params%5Bpage%5D%5Blimit%5D=\d+&params%5Bfields%5D%5Bhomes%5D%5B\d%5D=[a-zA-Z\-]+(&params%5Bfields%5D%5Bhomes%5D%5B\d%5D=[a-zA-Z\-]+)*$/
const clientEventsHomeDigestRegex =
  /clients\/[a-f0-9-]+\/client-events\?filter\[name\]=view&filter\[source\]=home-digest&sort=-created-at&page\[limit\]=1/
const clientEventsSMSRegex =
  /clients\/[a-f0-9-]+\/client-events\?filter\[name\]=sent&filter\[source\]=sms&sort=-created-at&page\[limit\]=1/
const valuationHistoryRegex =
  /^clients\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}\/valuation-history\?home_id=[a-fA-F\d]{24}$/
const detailedHomesRegex = /^\/frontend\/detailed_homes\/[a-fA-F\d]{24}$/
const homeSellingInfoRegex = /^homes\/[a-fA-F\d]{24}\/home-selling-info$/
const powerUpsRegex = /^customer-profiles\/[a-fA-F\d]{8}(-[a-fA-F\d]{4}){3}-[a-fA-F\d]{12}\/power-ups$/
const buyersRegex =
  /^buyers\/[a-fA-F\d]{8}(-[a-fA-F\d]{4}){3}-[a-fA-F\d]{12}\?include=market-interests,prequalifications$/
const recommendationsRegex =
  /^clients\/[a-fA-F\d]{8}(-[a-fA-F\d]{4}){3}-[a-fA-F\d]{12}\/homes\/[a-fA-F\d]{8}(-[a-fA-F\d]{4}){3}-[a-fA-F\d]{12}\/recommendations\?limit=\d+$/
const customerProfileRegex =
  /^customer-profiles\/[a-f0-9]{8}-(?:[a-f0-9]{4}-){3}[a-f0-9]{12}\?include=office-profile,office-profile\.corporate-profile$/

const listingsNotCompactRegex = /\/listings\/search(?!.*compact=true).*/
const listingsCompactRegex = /^.*compact=true.*$/
const boundingBoxCompactRegex = /^.*bounding_box.*compact=true.*$/
const boundingBoxNotCompactRegex = /^(?!.*compact=true).*bounding_box.*/
const mortgageRates15Regex =
  /mortgage-rates\?page%5Bsize%5D=52&filter%5Bterm%5D=15&filter%5Bmortgage-type%5D=FIX&filter%5Bdate-range%5D=\d{4}-\d{2}-\d{2}\.\.\d{4}-\d{2}-\d{2}/
const mortgageRates30Regex =
  /mortgage-rates\?page%5Bsize%5D=52&filter%5Bterm%5D=30&filter%5Bmortgage-type%5D=FIX&filter%5Bdate-range%5D=\d{4}-\d{2}-\d{2}\.\.\d{4}-\d{2}-\d{2}/
const pmiRegex =
  /clients\/[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}\/homes\/[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}\/pmi$/
const homeInsuranceRegex = /^homes\/[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}\/home-insurance$/
const marketZipcodesRegex = /.*markets\/zipcodes.*/

const axOpts = {
  contentType: 'application/vnd.api+json',
  transformResponse: [
    function (data) {
      // Convert JsonAPI's default kebab-case to camelCase for easier destructuring in JavaScript
      let parsedData = null
      try {
        // This is borked
        parsedData = changeCaseObject.camelCase(jsonApiParser.parse(data))
      } catch (e) {
        console.error(e)
      }
      return parsedData || data
    }
  ]
}
const axOptsNRAPI = {
  contentType: 'application/json',
  transformRequest: [
    function (data, headers) {
      Object.assign(headers, getAuthHeaders())
      return JSON.stringify(data)
    }
  ],
  transformResponse: [
    function (data) {
      // Convert JsonAPI's default kebab-case to camelCase for easier destructuring in Javascript
      let parsedData = null

      try {
        parsedData = camelizeKeys(JSON.parse(data))
      } catch (e) {
        console.warn('Failed to parse camelCase response data: ', e)
      }

      return parsedData || data
    }
  ]
}

export function mockedClient() {
  const get = async baseURL => {
    switch (baseURL) {
      case baseURL.match(clientsRegex)?.input:
        const clientRes = axios.get(`${mockDomain}/clients`, axOpts)
        return clientRes

      case baseURL.match(clientAnswersRegex)?.input:
        const clienAnswersRes = axios.get(`${mockDomain}/clientAnswers`, axOpts)
        return clienAnswersRes
      case baseURL.match(mortgageRates15Regex)?.input:
        const mortgageRates15Res = axios.get(`${mockDomain}/mortgageRates15`)
        return mortgageRates15Res

      case baseURL.match(mortgageRates30Regex)?.input:
        const mortgageRates30Res = axios.get(`${mockDomain}/mortgageRates30`, axOpts)
        return mortgageRates30Res

      case baseURL.match(homesRegex)?.input:
        const homesRes = axios.get(`${mockDomain}/homes`, axOpts)
        return homesRes

      // clients/{id}/client-events?filter[name]=view&filter[source]=home-digest&sort=-created-at&page[limit]=1
      case baseURL.match(clientEventsHomeDigestRegex)?.input:
        const clientEventsHomeDigestRes = axios.get(`${mockDomain}/clientEventsHomeDigest`, axOpts)
        return clientEventsHomeDigestRes

      // clients/{id}/client-events?filter[name]=sent&filter[source]=sms&sort=-created-at&page[limit]=1
      case baseURL.match(clientEventsSMSRegex)?.input:
        const clientEventsSMSRes = axios.get(`${mockDomain}/clientEventsSMS`, axOpts)
        return clientEventsSMSRes

      case baseURL.match(valuationHistoryRegex)?.input:
        const valuationHistoryRes = axios.get(`${mockDomain}/valuationHistory`, axOpts)
        return valuationHistoryRes

      case baseURL.match(detailedHomesRegex)?.input:
        const detailHomeRes = axios.get(`${mockDomain}/detailedHome`, axOpts)
        return detailHomeRes

      case 'mortgage-rates?filter%5Blatest%5D=true&filter%5Bavailable_products%5D=true':
        const mortgageRateProductsRes = axios.get(`${mockDomain}/mortgageRateProducts`, axOpts)
        return mortgageRateProductsRes

      case baseURL.match(homeSellingInfoRegex)?.input:
        const homeSellingInfoRes = axios.get(`${mockDomain}/homeSellingInfo`, axOpts)
        return homeSellingInfoRes

      case baseURL.match(powerUpsRegex)?.input:
        const powerUpsRes = axios.get(`${mockDomain}/powerUps`, axOpts)
        return powerUpsRes

      case baseURL.match(pmiRegex)?.input:
        const pmiRes = axios.get(`${mockDomain}/pmi`, axOpts)
        return pmiRes

      case baseURL.match(homeInsuranceRegex)?.input:
        const homeInsuranceResp = axios.get(`${mockDomain}/homeInsurance`, axOpts)
        return homeInsuranceResp

      case baseURL.match(buyersRegex)?.input:
        const buyersRes = axios.get(`${mockDomain}/buyers`, axOpts)
        return buyersRes

      case '/videos?page[size]=100':
        const videosRes = axios.get(`${mockDomain}/videos`, axOpts)
        return videosRes

      case '/markets/boundaries':
        const marketBoundariesRes = axios.get(`${mockDomain}/marketBoundaries`, axOpts)
        return marketBoundariesRes

      case baseURL.match(marketZipcodesRegex)?.input:
        const marketZipcodesRes = axios.get(`${mockDomain}/marketZipcodes`, axOpts)
        return marketZipcodesRes

      case baseURL.match(recommendationsRegex)?.input:
        const recommendationsRes = axios.get(`${mockDomain}/recommendations`, axOpts)
        return recommendationsRes

      case 'locations/usa?query=Greenville':
        const searchlocationsRes = axios.get(`${mockDomain}/searchLocationsSearchBar`, axOpts)
        return searchlocationsRes

      case 'locations/usa?query=Greenville%2C%20SC%20city':
        const searchlocationsRes2 = axios.get(`${mockDomain}/searchLocations`, axOpts)
        return searchlocationsRes2

      case baseURL.match(customerProfileRegex)?.input:
        const customerProfileRes = axios.get(`${mockDomain}/customerProfile`, axOpts)
        return customerProfileRes

      case '/home_buyer/favorite_listings':
        const favoriteListingsRes = axios.get(`${mockDomain}/favoriteListings`, axOptsNRAPI)

        return favoriteListingsRes

      case '/listings/633b42b95bb1050001d123a7':
        const listingRes = axios.get(`${mockDomain}/listing`, axOptsNRAPI)
        return listingRes

      case '/listings/633b42b95bb1050001d123a7/similar':
        const similarListingRes = axios.get(`${mockDomain}/similarListings`, axOptsNRAPI)
        return similarListingRes

      case baseURL.match(listingsCompactRegex)?.input: //'/listings/search?route=SC&city_id=9961fff2-ed2a-4683-93d3-0d9f8007385c&per_page=1000&page=1&compact=true&order[days_on_market]=asc&filters[max_price_cents]=&filters[max_sqft]=&filters[max_year_built]=&filters[min_bathrooms_count]=&filters[min_bedrooms_count]=&filters[min_price_cents]=&filters[min_sqft]=&filters[min_year_built]=&filters[statuses][]=active':
        const listingSearchRes1 = axios.get(`${mockDomain}/listingsCompact`, axOptsNRAPI)
        return listingSearchRes1

      case baseURL.match(listingsNotCompactRegex)?.input: // '/listings/search?route=SC&city_id=9961fff2-ed2a-4683-93d3-0d9f8007385c&per_page=20&page=1&order[days_on_market]=asc&filters[max_price_cents]=&filters[max_sqft]=&filters[max_year_built]=&filters[min_bathrooms_count]=&filters[min_bedrooms_count]=&filters[min_price_cents]=&filters[min_sqft]=&filters[min_year_built]=&filters[statuses][]=active':
        const listingSearchRes2 = axios.get(`${mockDomain}/listingsNotCompact`, axOptsNRAPI)
        return listingSearchRes2

      // case baseURL.match(boundingBoxNotCompactRegex)?.input: // https://nrsearch.homebot.test/listings/search?bounding_box[top_left][lat]=34.91550321765999&bounding_box[top_left][lon]=-82.59700671249998&bounding_box[bottom_right][lat]=34.8104277630198&bounding_box[bottom_right][lon]=-82.15755358749998&per_page=1000&page=1&compact=true&order[days_on_market]=asc&filters[max_price_cents]=&filters[max_sqft]=&filters[max_year_built]=&filters[min_bathrooms_count]=&filters[min_bedrooms_count]=&filters[min_price_cents]=&filters[min_sqft]=&filters[min_year_built]=&filters[statuses][]=active
      //   const boundingBoxNotCompactRes = axios.get(
      //     `${mockDomain}/boundingBoxNotCompact`,
      //     axOptsNRAPI
      //   )
      //   return boundingBoxNotCompactRes

      // case baseURL.match(boundingBoxCompactRegex)?.input: // 'https://nrsearch.homebot.test/listings/search?bounding_box%5Btop_left%5D%5Blat%5D=34.91550321765999&bounding_box%5Btop_left%5D%5Blon%5D=-82.59700671249998&bounding_box%5Bbottom_right%5D%5Blat%5D=34.8104277630198&bounding_box%5Bbottom_right%5D%5Blon%5D=-82.15755358749998&per_page=20&page=1&order%5Bdays_on_market%5D=asc&filters%5Bmax_price_cents%5D=&filters%5Bmax_sqft%5D=&filters%5Bmax_year_built%5D=&filters%5Bmin_bathrooms_count%5D=&filters%5Bmin_bedrooms_count%5D=&filters%5Bmin_price_cents%5D=&filters%5Bmin_sqft%5D=&filters%5Bmin_year_built%5D=&filters%5Bstatuses%5D%5B%5D=active&compact=false
      //   const boundingBoxCompactRes = axios.get(
      //     `${mockDomain}/boundingBoxCompact`,
      //     axOptsNRAPI
      //   )
      //   return boundingBoxCompactRes

      default:
        console.log('Request not handled by mock data server')
        console.log(baseURL)
    }
  }

  // methods beside 'get' just here to make typescript happy
  return {
    get,
    put: () => null,
    post: () => null,
    patch: () => null,
    delete: () => null
  }
}
