import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './BaseCheckBox.module.scss'

const propTypes = {
  className: PropTypes.string,
  hide: PropTypes.bool
}

const BaseCheckBox = ({ className, hide, ...restProps }) => (
  <input type='checkbox' className={classnames(styles.root, hide && styles.hide, className)} {...restProps} />
)

BaseCheckBox.propTypes = propTypes

export default BaseCheckBox
