import React from 'react'
import { FormattedMessage } from 'react-intl'

// Components
import Text from '../Text'
import ErrorHeadline from './ErrorHeadline'

const DefaultMessage = () => (
  <>
    <ErrorHeadline>
      <FormattedMessage id='ErrorBoundary.default.headline' defaultMessage='Something went wrong…' />
    </ErrorHeadline>
    <Text variant='neutralCool' variantCount='700' size='s' paragraph>
      <FormattedMessage
        id='ErrorBoundary.default.message'
        defaultMessage='Our team has been notified. Try reloading the page in a few minutes.'
      />
    </Text>
  </>
)

export default DefaultMessage
