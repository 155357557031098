import {
  FETCH_VALUATION_HISTORY_REQUEST,
  FETCH_VALUATION_HISTORY_SUCCESS,
  FETCH_VALUATION_HISTORY_FAILURE
} from '../actions/actionTypes'

const initialState = {
  loading: true,
  error: null
}

export default (state = initialState, { type, data, error, homeId, loading = false }) => {
  switch (type) {
    case FETCH_VALUATION_HISTORY_REQUEST:
      return {
        ...state,
        loading
      }

    case FETCH_VALUATION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        [homeId]: data
      }

    case FETCH_VALUATION_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    default:
      return state
  }
}
