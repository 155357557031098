import { TabProps, ComponentTrackingType, IconProps } from '@homebotapp/hb-react-component-catalog'

export const BUYER = 'BUYER' as const
export const HOMES = 'HOMES' as const
export const MARKETS = 'MARKETS' as const
export const SEARCH = 'SEARCH' as const
export const FORYOU = 'FORYOU' as const

export const NAVIGATION_TAB_NAMES = {
  BUYER,
  HOMES,
  MARKETS,
  SEARCH,
  FORYOU
}

export type NavigationTabName = keyof typeof NAVIGATION_TAB_NAMES

export type NavigationTabProps = TabProps & {
  isSelected?: boolean
}

export interface NavigationShortcutDatum {
  name: string
  icon: React.ReactElement<IconProps>
  body: React.ReactNode
  href?: string
  onClick?: (e) => void
  tracking: ComponentTrackingType
}
