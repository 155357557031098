import numeral from 'numeral'
import map from 'lodash/map'
import values from 'lodash/values'
import groupBy from 'lodash/groupBy'
import reverse from 'lodash/reverse'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'
import { DEFAULT_LOAN_TERM, LOAN_TERMS } from '../../constants/loan'
import { LOWEST_INTEREST_REFI_TERM } from '../../constants/refinance'
import { newHome, rentalProperty, rentBuy } from '../../constants/equity'

const YYYYMM = /([0-9]{4}-[0-9]{2})(.*)/g

const getEndDateYM = x => {
  return x.endDate.replace(YYYYMM, '$1')
}

const getFloat = (...args) => {
  const value = get(...args)
  return isUndefined(value) ? null : parseFloat(value)
}

const getLatestMortgageRates = rateInfo => mapValues(rateInfo, v => getFloat(v, 'data[0].standardizedRate'))

export const selectLatestMortgageRates = createSelector(state => state.mortgageRates, getLatestMortgageRates)

export const selectLowestRefiRate = createSelector(selectLatestMortgageRates, rates => rates[LOWEST_INTEREST_REFI_TERM])

const getLoadingStates = rateInfo => LOAN_TERMS.some(term => rateInfo[term].isLoading)

export const selectMortgageLoadingState = createSelector(state => state.mortgageRates, getLoadingStates)

const mapRatesToMonthlyValues = (rateInfo, loanTerm = 30) => {
  const filteredRates = rateInfo[loanTerm].data

  if (!filteredRates || !filteredRates.length) {
    return []
  }

  const grouped = groupBy(filteredRates, getEndDateYM)
  const mapped = mapValues(grouped, rates => rates[0])
  const mappedVals = values(mapped)
  const mappedDateAndRate = map(mappedVals, rate => ({
    date: getEndDateYM(rate),
    rate: numeral(rate.standardizedRate).format('0.000')
  }))
  const reversed = reverse(mappedDateAndRate)
  return reversed
}

export const selectRatesAsMonthlyValues = createSelector(
  state => state.mortgageRates,
  state => state.buyerInfo.loanTerm || DEFAULT_LOAN_TERM,
  mapRatesToMonthlyValues
)

const getAffordabilityRates = (currentRates, rates, { affordability, loanTerm }) =>
  rates.map(({ date, rate: unparsed }) => {
    const rate = parseFloat(unparsed)
    const currentRate = currentRates[loanTerm || DEFAULT_LOAN_TERM]

    return {
      date,
      rate,
      value: affordability + 0.1 * affordability * (currentRate - rate)
    }
  })

export const selectAffordabilityRates = createSelector(
  state => selectLatestMortgageRates(state),
  state => selectRatesAsMonthlyValues(state),
  state => state.buyerInfo,
  getAffordabilityRates
)

const selectMortgageRateByTerm = loanTerm =>
  createSelector(selectLatestMortgageRates, ratesByTerm => ratesByTerm[loanTerm])

export const selectNewHomeMortgageRate = selectMortgageRateByTerm(newHome.loanTerm)
export const selectRentalPropertyMortgageRate = state =>
  selectMortgageRateByTerm(rentalProperty.loanTerm)(state) + rentalProperty.interestRateAdjustment
export const selectNewPrimaryResidenceMortgageRate = selectMortgageRateByTerm(rentBuy.loanTerm)
