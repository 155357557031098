import upperFirst from 'lodash/upperFirst'
import { getAbbreviatedNumeralFormat } from '../../../helpers/utils'
import numeral from 'numeral'
import moment from 'moment'

import FormatContainer from './FormatContainer'

const DEFAULT_NUMERIC = '0,0[.]00'
const DEFAULT_DATE = 'YYYY-MM-DD'

const getAbbreviatedFormat = value => {
  const tempRoundedVal = Math.round(value)
  const roundValue = Math.abs(tempRoundedVal)

  if (roundValue <= 1000000) {
    return '0a'
  }
  return getAbbreviatedNumeralFormat(roundValue)
}

const number = (
  value,
  { prefix = '', suffix = '', format = DEFAULT_NUMERIC, abbreviate, roundLessThanOne = true, ...options } = {}
) => {
  let abbreviatedFormat = format

  if (Object.prototype.hasOwnProperty.call(options, 'abbreviated')) {
    console.warn("'abbreviated' is an unsupported property. Did you mean abbreviate?")
  }

  if (abbreviate) {
    abbreviatedFormat = getAbbreviatedFormat(value)
  }

  const fixedFormat = `${prefix}${abbreviatedFormat}${suffix}`

  if (value < 1 && value > 0 && roundLessThanOne === true) {
    value = Math.round(value)
  }

  return numeral(value).format(fixedFormat)
}

const currency = (value, { func = number, ...options } = {}) => func(value, { prefix: currency.prefix, ...options })

currency.prefix = '$'

const percentage = (value, { func = number, ...options } = {}) => `${func(value, options)}${percentage.suffix}`

percentage.suffix = '%'

const rate = (value, { format = '0,0[.]00[0]', ...options }) => percentage(value, { format, ...options })

const date = (value, { format = DEFAULT_DATE, fromNow = false } = {}) =>
  fromNow ? moment(value).fromNow() : moment(value).format(format)

const phone = value => {
  if (!value) return null
  value = value.replace(/[^\d]/g, '')
  if (value.length < 10) return null
  if (value.length === 10) {
    value = ['1', value].join('')
  }
  return value.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$2-$3-$4')
}

/**
 * A utility for formatting values in views. Functions in Format
 * come in two flavors:
 * formatter: (lowerFirst)
 *   raw formatting function
 *   ex: Format.formatter(value, options)
 * Formatter: (upperFirst)
 *   functional react component that takes the above options as props
 *   ex: <Format.Formatter value={the value to format} {...options} />
 */
const Format = {
  number,
  rate,
  currency,
  percentage,
  date,
  phone
}

Object.keys(Format).forEach(k => {
  Format[upperFirst(k)] = FormatContainer(Format[k])
})

export default Format
