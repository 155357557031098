import { defineMessages, useIntl } from 'react-intl'
import { Button, Text, Heading, LinkButton } from '@homebotapp/hb-react-component-catalog'
import { openLink } from '../../../api/iframe/requests'
import { EMAIL_PROVIDERS } from '../../../constants/navigation'
import { useSignUpProvider } from '../SignUpContext'
import styles from './MagicLink.module.scss'

export const MSG = defineMessages({
  magicLinkSent: {
    id: 'NewBuyerSignUp.completeStep.salutation',
    defaultMessage: 'Magic Link Sent!'
  },
  weJustSent: {
    id: 'NewBuyerSignUp.completeStep.helper',
    defaultMessage:
      'We just sent you an email with a link that instantly signs you in! It might take a few minutes to arrive.'
  },
  openEmailInNewTab: {
    id: 'NewBuyerSignUp.completeStep.openEmail',
    defaultMessage: 'Open email in new tab'
  },
  sentTo: {
    id: 'NewBuyerSignUp.completeStep.sentTo',
    defaultMessage: 'Sent to'
  }
})

export const MagicLink = () => {
  const intl = useIntl()
  const { GMAIL, OUTLOOK, YAHOO } = EMAIL_PROVIDERS
  const { email, iFramed } = useSignUpProvider()

  const linkProps = (provider: { title: string; url: string }) => {
    const { url } = provider

    if (iFramed) {
      return {
        onClick: () => openLink(url)
      }
    }
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: url
    }
  }
  return (
    <div className={styles.magicLinkStep}>
      <Heading color='neutral-900' textAlign='left'>
        {intl.formatMessage(MSG.magicLinkSent)}
      </Heading>
      <div className={styles.helper}>
        <Text size='sm' color='neutral-700'>
          {intl.formatMessage(MSG.weJustSent)}
        </Text>
      </div>
      <div className={styles.sentTo}>
        <Text size='sm' color='neutral-700'>
          {intl.formatMessage(MSG.sentTo)}
        </Text>
        <Button className={styles.email}>{email}</Button>
      </div>
      <div className={styles.email}>
        <Text size='sm' color='neutral-700'>
          {intl.formatMessage(MSG.openEmailInNewTab)}
        </Text>
        <div className={styles.btnRow}>
          <LinkButton {...linkProps(GMAIL)}>{GMAIL.title}</LinkButton>
          <LinkButton {...linkProps(OUTLOOK)}>{OUTLOOK.title}</LinkButton>
          <LinkButton {...linkProps(YAHOO)}>{YAHOO.title}</LinkButton>
        </div>
      </div>
    </div>
  )
}
