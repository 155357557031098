import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Components
import { Panel } from '../..'

// Actions
import { redrawHeightFadeKey } from '../../../actions/transitions'

// Hooks
import { useUniqueId, useCreateHomeClientEventOnce } from '../../../hooks'

// Styles
import styles from './ExpandablePanel.module.scss'

const propTypes = {
  eventName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  className: PropTypes.string
}

const ExpandablePanel = ({ eventName, children, className }) => {
  const fadeId = useUniqueId('ExpandablePanelId_')
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const trackViewedEvent = useCreateHomeClientEventOnce({ eventName: eventName })

  const togglePanel = () => {
    dispatch(redrawHeightFadeKey(fadeId, 'fast'))
    setIsOpen(!isOpen)
    if (!isOpen) {
      trackViewedEvent()
    }
  }

  const handleExpandablePanelOnClick = () => {
    togglePanel()
  }

  const renderChildren = () => {
    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        fadeId,
        isOpen,
        handleExpandablePanelOnClick
      })
    })
  }

  return (
    <Panel
      data-qa='expandable-panel'
      aria-expanded={isOpen}
      aria-controls={fadeId}
      className={classnames(styles.panel, className)}
    >
      {renderChildren()}
    </Panel>
  )
}

ExpandablePanel.propTypes = propTypes
export default ExpandablePanel
