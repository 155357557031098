import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '../..'

import styles from './Callout.module.scss'

export const Callout = ({ show, position, onClose, children }) => (
  <div className={classnames(styles.callout, styles[`position-${position}`], !show && styles.fadeOut)}>
    <Button.Close className={styles.closeButton} onClick={onClose} />
    {children}
  </div>
)

Callout.defaultProps = {
  position: 'right'
}

Callout.propTypes = {
  show: PropTypes.bool,
  position: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any
}

export default Callout
