import { METADATA_SCOPES } from '../constants/marketRecords'
import {
  FETCH_MARKET_RECORDS,
  FETCH_MARKET_RECORDS_SUCCESS,
  FETCH_MARKET_RECORDS_FAILURE,
  FETCH_MARKET_RECORDS_DETAIL_SUCCESS,
  SET_MARKET_RECORDS_METADATA_SCOPE
} from '../actions/actionTypes'

function consolidateRecords(state, records, detailReq = false) {
  const zipMap = Object.assign({}, state.zipMap)
  const detailRequested = Object.assign({}, state.detailRequested)

  records.forEach(function (rec) {
    if (zipMap[rec.zipcode] && !detailReq) {
      return
    }

    zipMap[rec.zipcode] = rec

    if (detailReq) {
      detailRequested[rec.zipcode] = true
    }
  })

  return { detailRequested, zipMap }
}

export const initialState = {
  loading: true,
  zipMap: {},
  detailRequested: {},
  metadataScope: METADATA_SCOPES.COMPOSITE
}

export default (state = initialState, { type, records, scope, error }) => {
  switch (type) {
    case FETCH_MARKET_RECORDS:
      return {
        ...state,
        loading: true,
        error: undefined
      }

    case FETCH_MARKET_RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    case FETCH_MARKET_RECORDS_SUCCESS:
    case FETCH_MARKET_RECORDS_DETAIL_SUCCESS:
      return {
        ...state,
        ...consolidateRecords(state, records, type === FETCH_MARKET_RECORDS_DETAIL_SUCCESS),
        loading: false
      }

    case SET_MARKET_RECORDS_METADATA_SCOPE:
      return {
        ...state,
        metadataScope: scope
      }

    default:
      return state
  }
}
