import BaseCheckBox from './BaseCheckBox'
import CheckBox from './CheckBox'
import PinkCheckBox from './PinkCheckBox'
import CheckBoxSlider from './CheckBoxSlider'

if (CheckBox) {
  CheckBox.Base = BaseCheckBox
  CheckBox.Pink = PinkCheckBox
  CheckBox.Slider = CheckBoxSlider
}

export default CheckBox

export { BaseCheckBox, PinkCheckBox, CheckBoxSlider }
