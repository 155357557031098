import { createSelector, createStructuredSelector } from 'reselect'
import { selectMetadata, selectHomeAddressState } from './.'
import { selectHasClientAcknowledgedTuneValue } from '../client'
import { select, getThe, getTheBoolean } from '../utils'
import { NON_DISCLOSURE_STATES } from '../../../constants/states'
import { AVM_DIFF_PERCENT_THRESHOLD, CONFIDENCE_THRESHOLD } from '../../../constants/valuation'
import { selectClientHasAcknowledgedLatestAvmValuation } from './answers'
import {
  selectLatestValuationIsACma,
  selectLatestValuationIsTuneYourValue,
  selectLatestAvmPercentageChangeInValuationHistory
} from '../valuationHistory'

const selectFromMetadata = select(selectMetadata)

export const selectHomeValue = selectFromMetadata(getThe('homeValue'))
export const selectConfidence = selectFromMetadata(getThe('confidence'))
export const selectHasCma = selectFromMetadata(getTheBoolean('hasCma'))

export const selectShowNonDisclosureStateWarning = createSelector(
  selectHasClientAcknowledgedTuneValue,
  selectHasCma,
  selectHomeAddressState,
  (acknowledged, hasCma, geoState) => {
    return !acknowledged && !hasCma && NON_DISCLOSURE_STATES.includes(geoState)
  }
)

export const selectShowAVMDiffWarning = createSelector(
  selectShowNonDisclosureStateWarning,
  selectClientHasAcknowledgedLatestAvmValuation,
  selectLatestValuationIsACma,
  selectLatestValuationIsTuneYourValue,
  selectLatestAvmPercentageChangeInValuationHistory,
  (
    showNonDisclosureStateWarning,
    clientHasAcknowledgedLatestAvmValuation,
    latestValuationIsACma,
    latestValuationIsTuneYourValue,
    latestAvmPercentageChangeInValuationHistory
  ) => {
    return (
      !showNonDisclosureStateWarning &&
      !clientHasAcknowledgedLatestAvmValuation &&
      !latestValuationIsACma &&
      !latestValuationIsTuneYourValue &&
      Math.abs(latestAvmPercentageChangeInValuationHistory) > AVM_DIFF_PERCENT_THRESHOLD
    )
  }
)

export const selectShowLowConfidenceWarning = createSelector(
  selectShowAVMDiffWarning,
  selectShowNonDisclosureStateWarning,
  selectHasClientAcknowledgedTuneValue,
  selectHasCma,
  selectConfidence,
  (showAvmDiffWarning, showNonDisclosureStateWarning, acknowledged, hasCma, confidence) => {
    return (
      !showAvmDiffWarning &&
      !showNonDisclosureStateWarning &&
      !acknowledged &&
      !hasCma &&
      !!confidence &&
      confidence < CONFIDENCE_THRESHOLD
    )
  }
)

export const selectWarnings = createStructuredSelector({
  showAVMDiffWarning: selectShowAVMDiffWarning,
  showNonDisclosureStateWarning: selectShowNonDisclosureStateWarning,
  showLowConfidenceWarning: selectShowLowConfidenceWarning
})
