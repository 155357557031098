import classnames from 'classnames'
import { Svg } from '../../..'
import styles from './BotHeadSpin.module.scss'

interface BotHeadSpinProps {
  className?: string
  panelSpacing?: boolean
}

const BotHeadSpin = ({ className, panelSpacing }: BotHeadSpinProps) => (
  <div data-qa='bot-head-spin' className={classnames(styles.root, panelSpacing && styles.inPanel, className)}>
    <Svg viewBox='0 0 33 33' xmlns='http://www.w3.org/2000/svg'>
      <circle cx={16.5} cy={16.5} r={16.5} fill='var(--brand-hb-homebot-fill)' />
      <path
        d='M17.75 16.338a1.982 1.982 0 001.982 1.979 1.979 1.979 0 100-3.959 1.98 1.98 0 00-1.98 1.98h-.001zm-6.425 0a1.982 1.982 0 001.98 1.979 1.979 1.979 0 100-3.959 1.98 1.98 0 00-1.98 1.98zm2.23 7.3h-2.168c-.71 0-1.286-.577-1.286-1.286v-2.18h-.765a.693.693 0 01-.693-.692v-2.8c0-.382.31-.692.693-.692h.765v-2.745c0-.71.576-1.285 1.286-1.285h4.745v-.786a2.032 2.032 0 01.406-4.022 2.033 2.033 0 01.324 4.037v.771h4.76c.71 0 1.287.576 1.287 1.285v2.745h.754c.384 0 .694.31.694.692v2.8c0 .382-.31.692-.694.692h-.754v2.18c0 .71-.577 1.285-1.287 1.285h-8.068v.001zm-.9-3.492a.849.849 0 00-.854.848c0 .468.389.847.853.847h7.91a.847.847 0 100-1.695h-7.91z'
        fill='var(--brand-hb-white)'
      />
    </Svg>
  </div>
)

export default BotHeadSpin
