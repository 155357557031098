import classnames from 'classnames'
import { HeaderContent } from './HeaderContent'
import styles from './Header.module.scss'

export type HeaderProps = {
  className?: string
  children: React.ReactNode
}

export const Header = ({ children, className, ...restProps }: HeaderProps) => (
  <header className={classnames(styles.root, className)} {...restProps}>
    {children}
  </header>
)

Header.Content = HeaderContent
