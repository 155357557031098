import { Modal, ModalOverlay } from '@homebotapp/hb-react-component-catalog'
import { LinkExpiredError } from './LinkExpiredError'
import { CustomerDeactivatedError } from './CustomerDeactivatedError'
import { AuthErrorModalErrorTypes } from '../../types/error'

type errorTypes =
  | AuthErrorModalErrorTypes.HOMEOWNER_ERROR
  | AuthErrorModalErrorTypes.BUYER_ERROR
  | AuthErrorModalErrorTypes.CUSTOMER_ERROR

export type AuthErrorModalProps = {
  showAuthErrorModal: boolean
  errorType: errorTypes
}

export const AuthErrorModal = ({ showAuthErrorModal, errorType }: AuthErrorModalProps) => {
  const errorComponent = () => {
    if (errorType === AuthErrorModalErrorTypes.CUSTOMER_ERROR) {
      return <CustomerDeactivatedError />
    }

    return <LinkExpiredError errorType={errorType} />
  }

  return (
    <Modal
      isOpen={showAuthErrorModal}
      onClose={() => {
        return
      }}
    >
      <ModalOverlay />
      {errorComponent()}
    </Modal>
  )
}
