import { defineMessages } from 'react-intl'

export const LT30 = 30
export const LT15 = 15

export const DEFAULT_LOAN_TERM = LT30

export const LOAN_TERMS = [LT15, LT30]
export const LOAN_FEE_CONSTANT = 2000
export const LOAN_FEE_PERCENTAGE = 0.01

export const ARM_MORTGAGE_TYPE = 'ARM'
export const FIX_MORTGAGE_TYPE = 'FIX'

export const LOAN_TYPE = {
  HELOC: 'HELOC',
  FIRST_CEL: 'FIRST CEL',
  SECOND_CEL: 'SECOND CEL',
  THIRD_CEL: 'THIRD CEL'
}

export const LOAN_STATUS = {
  CURRENT: 'current',
  DELETED: 'deleted',
  EXPIRED: 'expired'
}

export const LOAN_TERM_MESSAGES = defineMessages({
  fix: {
    id: 'Mortgages.loans.term.fixed',
    defaultMessage: '{years} Year Fixed'
  },
  arm: {
    id: 'Mortgages.loans.term.arm',
    defaultMessage: '{years}/1 ARM'
  }
})
