import { SIGN_UP_STEPS } from '../../../constants/signUp'
import Step from '../../../Components/shared/WizardContext/Step'
import { Name } from './Name'
import { Email } from './Email'
import { MagicLink } from './MagicLink'
import { ClientNotFound } from './ClientNotFound'
import { SignUpError } from '../Error'
import styles from './Steps.module.scss'

export const Steps = () => {
  return (
    <div className={styles.container}>
      <section className={styles.contentBody}>
        <SignUpError />
        <Step step={SIGN_UP_STEPS.USER_EMAIL}>
          <Email />
        </Step>
        <Step step={SIGN_UP_STEPS.USER_FIRST_AND_LAST}>
          <Name />
        </Step>
        <Step step={SIGN_UP_STEPS.COMPLETE}>
          {/* @ts-ignore - can remove once its typed */}
          <MagicLink className={styles.step} />
        </Step>
        <Step step={SIGN_UP_STEPS.CLIENT_NOT_FOUND}>
          {/* @ts-ignore - can remove once its typed */}
          <ClientNotFound className={styles.step} />
        </Step>
      </section>
    </div>
  )
}
