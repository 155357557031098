import {
  TOGGLE_AFFORDABILITY_MODAL,
  SET_AFFORDABILITY_METHOD,
  SET_PRICE_POINT,
  SET_AFFORDABILITY_VALUES,
  DISPLAY_PREQUAL_PROMPT,
  SEND_PREQUAL_REQUEST,
  SEND_PREQUAL_SUCCESS,
  SEND_PREQUAL_FAILURE
} from '../actions/actionTypes'

const initialState = {
  showModal: false,
  income: undefined,
  debt: undefined,
  downPayment: undefined,
  loanTerm: undefined,
  affordabilityMethod: undefined,
  pricePoint: undefined,
  showPrequalPrompt: false,
  prequalRequested: false,
  prequalRequestSuccess: false,
  prequalRequestFailure: false
}

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SET_PRICE_POINT:
      return {
        ...state,
        pricePoint: payload.pricePoint
      }
    case SET_AFFORDABILITY_METHOD:
      return {
        ...state,
        affordabilityMethod: payload.affordabilityMethod
      }
    case TOGGLE_AFFORDABILITY_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
        showPrequalPrompt: false,
        prequalRequested: false,
        prequalRequestSuccess: false,
        prequalRequestFailure: false
      }
    case SET_AFFORDABILITY_VALUES:
      return {
        ...state,
        ...payload
      }
    case DISPLAY_PREQUAL_PROMPT:
      return {
        ...state,
        showModal: true,
        showPrequalPrompt: true
      }
    case SEND_PREQUAL_REQUEST:
      return {
        ...state,
        prequalRequested: true,
        prequalRequestSuccess: false,
        prequalRequestFailure: false
      }
    case SEND_PREQUAL_SUCCESS:
      return {
        ...state,
        prequalRequestSuccess: true,
        prequalRequestFailure: false
      }
    case SEND_PREQUAL_FAILURE:
      return {
        ...state,
        prequalRequestSuccess: false,
        prequalRequestFailure: true
      }

    default:
      return state
  }
}
