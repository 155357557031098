import { defineMessages } from 'react-intl'
import { FlameSvg, HouseSvg, MovingTruckSvg, DollarSignSvg } from '../../svgs'

export const BUYER_CITY = 'BUYER_CITY'
export const BUYER_GOALS = 'BUYER_GOALS'
export const BUYER_THINKING_OF_SELLING_HOME = 'BUYER_THINKING_OF_SELLING_HOME'
export const BUYER_CLOSE_DATE = 'BUYER_CLOSE_DATE'
export const BUYER_PRIORITY = 'BUYER_PRIORITY'
export const BUYER_ONBOARDED = 'BUYER_ONBOARDED'
export const BUYER_WELCOME_VIDEO = 'BUYER_WELCOME_VIDEO'
export const BUYER_COMPLETED_ONBOARDING = 'BUYER_COMPLETED_ONBOARDING'

export const NEW_STEPS = {
  BUYER_CITY,
  BUYER_GOALS,
  BUYER_THINKING_OF_SELLING_HOME,
  BUYER_CLOSE_DATE,
  BUYER_PRIORITY,
  BUYER_ONBOARDED,
  BUYER_WELCOME_VIDEO
}

export const NEW_ORDERED_STEPS = [
  BUYER_CITY,
  BUYER_GOALS,
  BUYER_THINKING_OF_SELLING_HOME,
  BUYER_CLOSE_DATE,
  BUYER_PRIORITY,
  BUYER_ONBOARDED,
  BUYER_WELCOME_VIDEO
]

const GOAL_FIRST_HOME = 'purchase_first_home'
const GOAL_NEW_HOME = 'buy_different_home'
const GOAL_INVEST = 'invest_in_real_estate'
const GOAL_EXPLORE = 'explore_for_now'
const GOAL_SELL_CURRENT = 'sell_current'

export const GOALS = {
  GOAL_FIRST_HOME,
  GOAL_NEW_HOME,
  GOAL_INVEST,
  GOAL_EXPLORE,
  GOAL_SELL_CURRENT
}

export const NEW_ORDERED_HOMEOWNER_GOALS = [GOAL_NEW_HOME, GOAL_INVEST, GOAL_EXPLORE]

export const NEW_ORDERED_NON_HOMEOWNER_GOALS = [GOAL_FIRST_HOME, GOAL_NEW_HOME, GOAL_INVEST, GOAL_EXPLORE]

export const NEW_GOAL_MESSAGE_MAP = defineMessages({
  [GOALS.GOAL_EXPLORE]: {
    id: 'NewBuyerOnboarding.goal.explore',
    defaultMessage: 'Just explore'
  },
  [GOALS.GOAL_FIRST_HOME]: {
    id: 'NewBuyerOnboarding.goal.buyNewHome',
    defaultMessage: 'Purchase my first home'
  },
  [GOALS.GOAL_INVEST]: {
    id: 'NewBuyerOnboarding.goal.invest',
    defaultMessage: 'Invest in real estate'
  },
  [GOALS.GOAL_NEW_HOME]: {
    id: 'NewBuyerOnboarding.goal.buyDifferentHome',
    defaultMessage: 'Buy a different home'
  }
})

// new priorities
export const PRIORITY_MARKET = 'popular_market'
export const PRIORITY_BIGGER = 'bigger_home'
export const PRIORITY_AFFORDABLE = 'affordable_area'
export const PRIORITY_FAST = 'move_fast'
export const PRIORITY_OPTIONS = [PRIORITY_MARKET, PRIORITY_BIGGER, PRIORITY_AFFORDABLE, PRIORITY_FAST]
export const PRIORITY_MESSAGE_MAP = defineMessages({
  [PRIORITY_MARKET]: {
    id: 'NewBuyerOnboarding.priority.market',
    defaultMessage: 'Buying in a popular market'
  },
  [PRIORITY_BIGGER]: {
    id: 'NewBuyerOnboarding.priority.bigger',
    defaultMessage: 'Living in a bigger house'
  },
  [PRIORITY_AFFORDABLE]: {
    id: 'NewBuyerOnboarding.priority.affordable',
    defaultMessage: 'Finding more affordable areas'
  },
  [PRIORITY_FAST]: {
    id: 'NewBuyerOnboarding.priority.fast',
    defaultMessage: 'Fastest moving timeline'
  }
})

export const PRIORITY_ICON_MAP = {
  [PRIORITY_MARKET]: FlameSvg,
  [PRIORITY_BIGGER]: HouseSvg,
  [PRIORITY_AFFORDABLE]: DollarSignSvg,
  [PRIORITY_FAST]: MovingTruckSvg
}

export const QUESTION_CITY = 'buyer_city'
export const QUESTION_GOALS = 'buyer_goals'
export const QUESTION_DATE = 'buyer_close_date'
export const QUESTION_ONBOARDED = 'buyer_completed_onboarding'
export const QUESTION_PRIORITY = 'buyer_priority'
