import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { If } from '../../../deprecated/Conditionals'

import styles from './Peek.module.scss'

const propTypes = {
  className: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  top: PropTypes.node,
  children: PropTypes.any
}

const Peek = ({ className, children, top, right, left, ...restProps }) => (
  <div className={classnames(styles.root, className)} {...restProps}>
    <If condition={top}>
      <div className={styles.top}>{top}</div>
    </If>
    <If condition={left}>
      <div className={styles.left}>{left}</div>
    </If>
    <If condition={right}>
      <div className={styles.right}>{right}</div>
    </If>
    {children}
  </div>
)

Peek.propTypes = propTypes

export default Peek
