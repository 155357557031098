import classnames from 'classnames'
import { ExternalLinkButton } from '../../..'

interface HomebotListingsButtonProps {
  listingsUrl: string
  children: React.ReactNode
  className?: string
  fullWidth?: boolean
}

export const HomebotListingsButton = ({ listingsUrl, children, className, fullWidth }: HomebotListingsButtonProps) => (
  <ExternalLinkButton
    className={classnames(className)}
    data-qa='homebotListingsButton'
    href={listingsUrl}
    role='link'
    fullWidth={fullWidth}
  >
    {children}
  </ExternalLinkButton>
)
