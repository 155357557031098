import { Button, Flex, Logo, Link, Image, Box } from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import { GlobalTrackingEventLocation } from '../../../../constants/tracking'
import { selectClientIsGuest } from '../../../../store/selectors/client'
import {
  selectHasCompanyBrandingLogoPresent,
  selectNativeAccessFeature
} from '../../../../store/selectors/customerProfile'
import { setIsShowingNativeAppCtaModal } from '../../../../store/slices/modals'
import { RootState } from '../../../../types/rootState'
import { MobileHeaderContainer } from '../MobileHeaderContainer/MobileHeaderContainer'
import { AgentDropdown } from '../../../../Components/shared/AgentDropdown'
import { useDigestUrl } from '../../../../hooks/navigation/useDigestUrl'
import { BrandLogo } from '../../Navigation/BrandLogo'
import { HBRELogo } from '../../../../Components/shared/HBRE/HBRELogo'

export const MSG = defineMessages({
  getApp: {
    id: 'MobileHeader.getApp',
    defaultMessage: 'Get App'
  }
})

export const MobileHeader = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const homeownerOrBuyerDigestUrl = useDigestUrl()
  const isPublic = useSelector<RootState, boolean>(state => state.auth.isPublic)
  const isGuest = useSelector(selectClientIsGuest)
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)
  const hasBrandingLogoPresent = useSelector(selectHasCompanyBrandingLogoPresent)

  return (
    <>
      <MobileHeaderContainer>
        {isPublic && (
          <Box ml={4}>
            <Link as={ReactRouterLink} to='/home-search/public'>
              <HBRELogo maxWidth={24} />
            </Link>
          </Box>
        )}
        {!isPublic && (
          <>
            <Box ml={4}>
              {hasBrandingLogoPresent ? (
                <BrandLogo />
              ) : (
                <Logo textLocation='none' as={ReactRouterLink} to={homeownerOrBuyerDigestUrl} />
              )}
            </Box>
            <Flex alignItems='center'>
              {hasNativeAccess && !isGuest && (
                <Button
                  marginRight={2}
                  variant='outline'
                  onClick={() => dispatch(setIsShowingNativeAppCtaModal(true))}
                  size='sm'
                  tracking={{
                    guid: 'ddNNXxxiBR4CXKVNACD2SJ',
                    ui_context: GlobalTrackingEventLocation.GLOBAL_MOBILE_TOP_NAV,
                    descriptor: 'Mobile top nav - Get App CTA button clicked'
                  }}
                >
                  {intl.formatMessage(MSG.getApp)}
                </Button>
              )}
              <AgentDropdown />
            </Flex>
          </>
        )}
      </MobileHeaderContainer>
    </>
  )
}
