import classNames from 'classnames'
import { MagnifyingGlassSvg } from '../../../../svgs'
import styles from './MagnifyingGlassLoader.module.scss'

interface MagnifyingGlassLoaderProps {
  className?: string
}

export const MagnifyingGlassLoader = ({ className }: MagnifyingGlassLoaderProps) => (
  <div className={classNames(styles.container, className)}>
    <MagnifyingGlassSvg className={styles.svg} />
    <div className={styles.loader}></div>
  </div>
)
