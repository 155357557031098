import { LegacyThemeProvider } from '@homebotapp/hb-react-component-catalog'
import { useTrackingContext } from './tracking'

// Ugly little bugger to get the ampli context. I went to college for this.
export const InjectTrackingIntoSwashbuckle = ({ theme, children, ...restProps }) => {
  const { trackingClient } = useTrackingContext()

  return (
    <LegacyThemeProvider theme={theme} {...restProps} analyticsClient={trackingClient}>
      {children}
    </LegacyThemeProvider>
  )
}
