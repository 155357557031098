// Components
import Svg from '../Components/shared/Svg'

export const HomeSvg = props => (
  <Svg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.48 7.78401L12.58 4.97068V0.74401H10.64V3.09068L7.74 0.277344L0 7.78401V8.25068H1.93333V12.944H6.77333V10.1307H8.70667V12.944H13.5467V8.25068H15.48V7.78401Z'
      fill='var(--brand-neutral-warm-500)'
    />
  </Svg>
)
