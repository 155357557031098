import React, { Component } from 'react'
import { registerRef, unregisterRef } from '../store/elementReference'

const scrollable = name => Target =>
  class extends Component {
    constructor(props) {
      super(props)
      this.el = React.createRef()
    }

    componentDidMount() {
      registerRef(name, this.el)
    }

    componentWillUnmount() {
      unregisterRef(name)
    }

    render() {
      return (
        <>
          <span ref={this.el} />
          <Target {...this.props} />
        </>
      )
    }
  }

export default scrollable
