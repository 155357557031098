import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  isPublic: boolean
  isLead: boolean
  hasSyncedDataWithTrackingClient: boolean
  externalRedirectUrl: string
  hasPublicKey: boolean
}

const initialState: AuthState = {
  isPublic: false,
  isLead: false,
  hasSyncedDataWithTrackingClient: false,
  externalRedirectUrl: '',
  hasPublicKey: false
}

const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setIsPublic: (state, action: PayloadAction<boolean>) => {
      state.isPublic = action.payload
    },
    setIsLead: (state, action: PayloadAction<boolean>) => {
      state.isLead = action.payload
    },
    setExternalRedirectUrl: (state, action: PayloadAction<string>) => {
      state.externalRedirectUrl = action.payload
    },
    setHasSyncedDataWithTrackingClient: (state, action: PayloadAction<boolean>) => {
      state.hasSyncedDataWithTrackingClient = action.payload
    },
    setHasPublicKey: (state, action: PayloadAction<boolean>) => {
      state.hasPublicKey = action.payload
    }
  }
})

export const { setIsPublic, setIsLead, setHasSyncedDataWithTrackingClient, setExternalRedirectUrl, setHasPublicKey } =
  authSlice.actions

export default authSlice.reducer
