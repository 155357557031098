import React from 'react'
import PropTypes from 'prop-types'
import { If } from '../../../../deprecated/Conditionals'
import { useWizardProvider } from './..'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  step: PropTypes.string
}

const Step = ({ children, step }) => {
  const { currentStep } = useWizardProvider()

  return <If condition={step === currentStep}>{children}</If>
}

Step.propTypes = propTypes

export default Step
