import React from 'react'
import { FormattedMessage } from 'react-intl'
import * as NAME from './names'
import * as TYPE from './types'
import { MonthSelect, DaySelect } from '../Select'
import DollarInput from '../Input/DollarInput'
import NumberInput from '../Input/NumberInput'
import PhoneInput from '../Input/PhoneInput'

export const Email = {
  type: TYPE.EMAIL,
  name: NAME.EMAIL,
  children: <FormattedMessage id='FormField.label.email' defaultMessage='Email Address' />
}

export const FirstName = {
  type: TYPE.TEXT,
  name: NAME.FIRST_NAME,
  children: <FormattedMessage id='FormField.label.firstName' defaultMessage='First Name' />
}

export const LastName = {
  type: TYPE.TEXT,
  name: NAME.LAST_NAME,
  children: <FormattedMessage id='FormField.label.lastName' defaultMessage='Last Name' />
}

export const Phone = {
  input: PhoneInput,
  type: TYPE.TEL,
  name: NAME.PHONE,
  children: <FormattedMessage id='FormField.label.phone' defaultMessage='Phone' />
}

export const StreetAddress = {
  type: TYPE.TEXT,
  name: NAME.STREET_ADDRESS,
  children: <FormattedMessage id='FormField.label.streetAddress' defaultMessage='Street Address' />
}

export const Unit = {
  type: TYPE.TEXT,
  name: NAME.UNIT,
  children: <FormattedMessage id='FormField.label.unit' defaultMessage='Unit #' />
}

export const Zipcode = {
  type: TYPE.TEXT,
  pattern: '[0-9]{5}',
  maxLength: 5,
  name: NAME.ZIPCODE,
  children: <FormattedMessage id='FormField.label.zipcode' defaultMessage='Zip Code' />
}

const currentYear = new Date().getFullYear()

export const Year = {
  type: TYPE.NUMBER,
  name: NAME.YEAR,
  min: '1776',
  max: currentYear,
  step: '1',
  children: <FormattedMessage id='FormField.label.year' defaultMessage='Year' />
}

export const Month = {
  input: MonthSelect,
  type: TYPE.TEXT,
  name: NAME.MONTH,
  children: <FormattedMessage id='FormField.label.month' defaultMessage='Month' />
}

export const Day = {
  input: DaySelect,
  type: TYPE.NUMBER,
  name: NAME.DAY,
  children: <FormattedMessage id='FormField.label.day' defaultMessage='Day' />
}

export const Dollars = {
  input: DollarInput,
  name: NAME.DOLLARS,
  type: TYPE.TEL,
  decimalScale: 0,
  placeholder: '$0',
  allowNegative: false,
  prefix: '$',
  thousandSeparator: true,
  parse: DollarInput.parseValue,
  children: <FormattedMessage id='FormField.label.dollarAmount' defaultMessage='Dollar Amount' />
}

export const SqFt = {
  input: NumberInput,
  name: NAME.SQFT,
  type: TYPE.TEL,
  decimalScale: 0,
  placeholder: '0',
  allowNegative: true,
  thousandSeparator: true,
  children: <FormattedMessage id='FormField.label.sqFt' defaultMessage='Finished interior sqft' />
}
