import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Blur } from '../Blur'
import { If } from '../../../deprecated/Conditionals'
import { RingLock } from './RingLock'

import styles from './Lock.module.scss'

const propTypes = {
  blur: PropTypes.oneOf(['md', 'sm']),
  enabled: PropTypes.bool,
  icon: PropTypes.node,
  copy: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

const copyPropTypes = {
  copy: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string])
}

const defaultProps = {
  blur: 'md',
  enabled: true,
  icon: <RingLock />
}

const Copy = ({ copy }) => <>{copy}</>

const Lock = ({ blur, className, enabled, icon, copy, children }) => (
  <div data-qa='lock-component' className={classnames(className, styles.container)}>
    <If condition={children}>
      <Blur amount={blur} enabled={enabled}>
        {children}
      </Blur>
    </If>
    <If condition={enabled && icon}>
      <div className={styles.iconContainer}>
        {icon}
        {copy && <Copy copy={copy} />}
      </div>
    </If>
  </div>
)

Lock.propTypes = propTypes
Copy.propTypes = copyPropTypes
Lock.defaultProps = defaultProps

export default Lock
