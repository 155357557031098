import { ForwardedRef, ElementType, ReactNode } from 'react'
import classnames from 'classnames'
import BaseButton from './BaseButton'

import styles from './Button.module.scss'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  className?: string
  children?: ReactNode
  disabled?: boolean
  href?: string
  element?: ElementType // render something other than a 'button' tag, such as an anchor
  danger?: boolean // destructive actions
  wide?: boolean // let the container determine a wider button width
  fullWidth?: boolean // let the container determine the button width
  loading?: boolean // indicates that the click event caused an async action
  minimal?: boolean // drops the padding from the button, for use inline with text
  primary?: boolean // blue background on the button, for use in CTAs
  secondary?: boolean // pink background on the button, for use in CTAs
  primaryCustom?: boolean
  compact?: boolean // decrease the padding on the button
  iconEnd?: ElementType // button icon aligned right of button text
  iconStart?: ElementType // button icon aligned left of button text
  ref?: ForwardedRef<HTMLButtonElement>
  onClick?: (args?: any) => void
  track?: string
}

const Button = ({
  children,
  className,
  wide,
  iconEnd: IconEnd,
  iconStart: IconStart,
  loading,
  secondary,
  primaryCustom,
  ref,
  compact = false,
  danger = false,
  disabled = false,
  fullWidth = false,
  minimal = false,
  primary = false,
  ...restProps
}: ButtonProps) => (
  <BaseButton
    className={classnames(
      styles.button,
      compact && styles.compact,
      danger && styles.danger,
      wide && styles.wide,
      fullWidth && styles.fullWidth,
      minimal && styles.minimal,
      primary && styles.primary,
      secondary && styles.secondary,
      primaryCustom && styles.primaryCustom,
      loading && styles.loading,
      (IconEnd || IconStart) && !children && styles.iconOnly,
      className
    )}
    disabled={disabled}
    ref={ref}
    {...restProps}
  >
    {!loading && (
      <>
        {!!IconStart && <IconStart className={styles.iconStart} />}
        {children}
        {!!IconEnd && <IconEnd className={styles.iconEnd} />}
      </>
    )}
  </BaseButton>
)

Button.displayName = 'Button'

export default Button
