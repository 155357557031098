import jsonApiParser from 'jsonapi-parse'
import { getBearerToken } from '../../auth'
import changeCaseObject from 'change-case-object'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ExtraPrincipalPaymentSavings } from '../../../src/types/extraPrincipalPaymentSavings'

async function transformRes(res: Response) {
  try {
    const data = await res.json()
    const parsedData = changeCaseObject.camelCase(jsonApiParser.parse(data))
    return parsedData?.data.interestSavings
  } catch (e) {
    console.error(e)
  }
}

export const extraPrincipalPaymentSavingsApi = createApi({
  reducerPath: 'extraPrincipalPaymentSavingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MIKASA_URI,
    prepareHeaders: headers => {
      const token = getBearerToken()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      headers.set('Content-Type', 'application/vnd.api+json')
      headers.set('Accept', 'application/vnd.api+json')
      return headers
    }
  }),
  endpoints: builder => ({
    getExtraPrincipalPaymentSavings: builder.query<
      ExtraPrincipalPaymentSavings[],
      { clientId: string; homeUuid: string }
    >({
      query: ({ clientId, homeUuid }) => ({
        url: `clients/${clientId}/homes/${homeUuid}/principal-and-interest`,
        responseHandler: res => transformRes(res)
      })
    }),
    getExtraPrincipalPaymentSavingsWithExtraPayment: builder.query<
      ExtraPrincipalPaymentSavings[],
      { clientId: string; homeUuid: string; extraPayment: number }
    >({
      query: ({ clientId, homeUuid, extraPayment }) => ({
        url: `clients/${clientId}/homes/${homeUuid}/principal-and-interest?monthly_extra_payment=${extraPayment}`,
        responseHandler: res => transformRes(res)
      })
    })
  })
})

export const { useGetExtraPrincipalPaymentSavingsQuery, useGetExtraPrincipalPaymentSavingsWithExtraPaymentQuery } = extraPrincipalPaymentSavingsApi
