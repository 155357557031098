import { Text } from '../..'
import styles from './ProgressBar.module.scss'

export interface ProgressBarProps {
  fillColor?: string
  goalPostLabel?: string
  overridePercentFilledValue?: string
  percentFilled: number
}

export const ProgressBar = ({
  fillColor,
  goalPostLabel,
  overridePercentFilledValue,
  percentFilled
}: ProgressBarProps) => {
  const bottomStyles = {
    backgroundColor: fillColor
  }

  const filledStyles = {
    width: `${percentFilled}%`,
    backgroundColor: fillColor || styles.defaultFillColor
  }

  const percentFilledDisplayValue = overridePercentFilledValue || `${Math.round(percentFilled)}%`

  return (
    <div className={styles.wrapper}>
      <div className={styles.bottom} style={bottomStyles} />
      <div className={styles.background}>
        <div className={styles.fill} data-qa='progressBarFillDiv' style={filledStyles}>
          <Text weight='semibold' className={styles.displayValue}>
            {percentFilledDisplayValue}
          </Text>
        </div>
        <div className={styles.line} />
        {goalPostLabel && (
          <Text
            element='div'
            variant='neutralCool'
            variantCount='700'
            weight='semibold'
            size='s'
            className={styles.label}
          >
            {goalPostLabel}
          </Text>
        )}
      </div>
      <div className={styles.end} />
    </div>
  )
}
