import { ElementType } from 'react'
import classnames from 'classnames'
import { IconCheck } from '../Icon'
import BotHeadColored from '../Avatar/BotHeadColored'

import styles from './StatusIcon.module.scss'

export interface StatusIconProps {
  spin: boolean
  success?: boolean
  failure?: boolean
  className?: string
  successIcon?: ElementType
  failureIcon?: ElementType
}

export const StatusIcon = ({
  spin,
  success,
  failure,
  className,
  successIcon: SuccessIcon = IconCheck,
  failureIcon: FailureIcon = BotHeadColored,
  ...restProps
}: StatusIconProps) => {
  return (
    <span
      {...restProps}
      data-qa='status-icon'
      className={classnames(
        className,
        styles.root,
        spin && styles.spinner,
        success && styles.success,
        failure && styles.failure
      )}
    >
      {success && <SuccessIcon variant='success' />}
      {failure && <FailureIcon type='error' size='l' quizzical />}
    </span>
  )
}
