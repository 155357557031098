import produce from 'immer'
import { CREATE_TCA_REQUEST, CREATE_TCA_SUCCESS, CREATE_TCA_FAILURE, CREATE_TCA_RESET } from '../actions/actionTypes'

export const initialState = {
  requested: false,
  success: false,
  error: null,
  tcaUpdated: false,
  leadTimeHours: 0,
  reports: {}
}

export default (state = initialState, { type, leadTimeHours, tcaUpdated, error, reportKey, reportLink }) =>
  produce(state, draft => {
    switch (type) {
      case CREATE_TCA_REQUEST:
        draft.requested = true
        draft.success = false
        draft.error = null
        break

      case CREATE_TCA_SUCCESS:
        draft.success = true
        draft.error = null
        draft.leadTimeHours = leadTimeHours
        draft.tcaUpdated = tcaUpdated

        if (reportLink) {
          draft.reports[reportKey] = reportLink
        }

        break

      case CREATE_TCA_FAILURE:
        draft.success = false
        draft.error = error
        break

      case CREATE_TCA_RESET:
        Object.assign(draft, initialState, { reports: draft.reports })
        break
      default:
        break
    }
  })
