import { selectCustomerType, selectCustomerFirstName } from '../../../../../store/selectors/customerProfile'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import Text from '../../../../shared/Text'
import { Box } from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { CustomerType } from '../../../../../types/customerType'
import { HomeSearchTrackingEventLocation } from '../../../../../constants/tracking'
import { NativeCTALinks } from '../../../../shared/NativeCTALinks/NativeCTALinks'
import styles from './DownloadMobileAppPromptCard.module.scss'

export const MSG = defineMessages({
  dreamHome: {
    id: 'HomeSearch.listingDetails.mobileCtaHeader',
    defaultMessage: 'Your dream home, on the go'
  },
  downloadNow: {
    id: 'HomeSearch.listingDetails.mobileCtaDetails',
    defaultMessage:
      'Download now and explore your personalized home search experience with access to {customer}, your {customerType}.'
  }
})

interface DownloadMobileAppPromptCardProps {
  className?: string
  isLandingPage?: boolean
}

export const DownloadMobileAppPromptCard = ({ className, isLandingPage }: DownloadMobileAppPromptCardProps) => {
  const intl = useIntl()
  const type = useSelector(selectCustomerType)
  const customerFirstName = useSelector(selectCustomerFirstName)
  const customerType = type === CustomerType.LoanOfficers ? 'loan officer' : 'agent'

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.colorLayer}>
        <div className={styles.contents}>
          <Text className={styles.header} element='h1' weight='bold' size='xl'>
            {intl.formatMessage(MSG.dreamHome)}
          </Text>
          <Box maxWidth='25rem'>
            <Text size='s' element='p' variant='white' className={styles.description}>
              {intl.formatMessage(MSG.downloadNow, {
                customer: customerFirstName,
                customerType: customerType
              })}
            </Text>
          </Box>
          <NativeCTALinks
            tracking={{
              guid: '8zeQzp4bSjhbd9TqswkTsg',
              ui_context: isLandingPage
                ? HomeSearchTrackingEventLocation.HOME_SEARCH_NATIVE_APP_CTA
                : HomeSearchTrackingEventLocation.HOME_SEARCH_LISTING_DETAILS_NATIVE_APP_CTA
            }}
          />
        </div>
      </div>
    </div>
  )
}
