import { Button, Flex, Icon, IconButton, Text } from '@homebotapp/hb-react-component-catalog'
import { useState } from 'react'
import cookies from 'js-cookie'
import { DOWNLOAD_APP_COOKIE, DOWNLOAD_APP_HIDE_BANNER_COOKIE } from '../../../../constants/auth'
import { getTopLevelDomain } from '../../../../helpers/browser'
import { FirebaseAppUrl } from './constants'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectHomeId } from '../../../../store/selectors/home'
import { selectShareCode } from '../../../../store/selectors/customerProfile'

export const MSG = defineMessages({
  download: {
    id: 'mobileHeaderDownloadApp.download',
    defaultMessage: 'Download'
  },
  getInstantHomeValues: {
    id: 'mobileHeaderDownloadApp.getInstantHomeValues',
    defaultMessage: 'Get instant home values & market updates'
  },
  closeLabel: {
    id: 'MobileAppDrawer.closeLabel',
    defaultMessage: 'Close'
  }
})

export const MobileHeaderDownloadApp = () => {
  const intl = useIntl()

  const selectedHomeId = useSelector(selectHomeId)
  const shareCode = useSelector(selectShareCode)

  const [hideBanner, setHideBanner] = useState(
    !!cookies.get(DOWNLOAD_APP_COOKIE) || !!cookies.get(DOWNLOAD_APP_HIDE_BANNER_COOKIE)
  )

  const downloadApp = () => {
    cookies.set(DOWNLOAD_APP_COOKIE, 'true', {
      sameSite: 'strict',
      path: '/',
      domain: getTopLevelDomain()
    })
    setHideBanner(true)
    const appUrl = FirebaseAppUrl.replace('$homeId', selectedHomeId).replace('$shareCode', shareCode)
    window.open(appUrl, '_blank')
  }

  const onClose = () => {
    cookies.set(DOWNLOAD_APP_HIDE_BANNER_COOKIE, 'true', {
      sameSite: 'strict',
      path: '/',
      domain: getTopLevelDomain(),
      expires: 60 * 24 * 60 * 60
    })
    setHideBanner(true)
  }

  if (hideBanner) {
    return null
  }

  return (
    <Flex
      _light={{
        borderBottom: '1px solid #E2E8F0',
        background: 'white'
      }}
      boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
      width={'100%'}
      borderBottomRightRadius='16px'
      borderBottomLeftRadius='16px'
      background='black'
      alignItems='center'
      justifyContent='space-between'
      padding={3}
    >
      <Text
        margin={0}
        fontWeight='600'
        color='white'
        _light={{
          color: 'black'
        }}
        style={{
          lineHeight: '20.02px'
        }}
        size='sm'
        flex={0.8}
      >
        {intl.formatMessage(MSG.getInstantHomeValues)}
      </Text>
      <Button
        tracking={{
          guid: 'axLDbRM9yb3iQGFLGG3E8E',
          descriptor: 'Mobile App Download Shortcuts'
        }}
        colorScheme='primary'
        mr='-18px'
        onClick={downloadApp}
      >
        {intl.formatMessage(MSG.download)}
      </Button>
      <IconButton
        tracking={{
          guid: 'dR6jypo6Gcb8HsJPHCTaZ4',
          descriptor: 'Mobile App Download Shortcuts Closed'
        }}
        variant='ghost'
        onClick={onClose}
        icon={<Icon name='times' />}
        aria-label={intl.formatMessage(MSG.closeLabel)}
      />
    </Flex>
  )
}
