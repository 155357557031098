import produce from 'immer'
import get from 'lodash/get'
import set from 'lodash/set'
import camelCase from 'lodash/camelCase'
import {
  SET_HOME_FETCH_ID,
  SET_HOME_FROM_CACHE,
  SET_CLIENT_FETCH_ID,
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
  FETCH_HOME_SUCCESS_FOR_BUYER,
  ADD_HOME_ANSWER_SUCCESS,
  UPDATE_LOAN_BALANCES_REQUEST,
  UPDATE_LOAN_BALANCES_SUCCESS,
  UPDATE_LOAN_BALANCES_FAILURE,
  UPDATE_LOAN_BALANCES_SAVED_RESET
} from '../actions/actionTypes'

export const initialState = {
  fetchId: null,
  loading: true,
  data: null,
  cache: {},
  error: null,
  errorModal: false,
  notFound: false,
  updateLoanBalanceRequested: false,
  updateLoanBalanceLoading: false,
  updateLoanBalanceSaved: false,
  clientId: null
}

const homeReducer = (state = initialState, { type, data, error, id, keepCache = false, loading = false }) =>
  produce(state, draft => {
    const assignDraft = params => Object.assign(draft, params)

    switch (type) {
      case SET_HOME_FETCH_ID: {
        const cache = draft.cache
        if (draft.data && id !== draft.data.id) {
          assignDraft(initialState)
          draft.cache = keepCache ? cache : {}
        }
        draft.fetchId = id
        break
      }
      case SET_HOME_FROM_CACHE: {
        const homeData = state.cache[id]

        if (homeData) {
          draft.data = homeData
        } else {
          assignDraft(initialState)
        }
        draft.fetchId = id
        break
      }

      case SET_CLIENT_FETCH_ID:
        if (id !== draft.clientId) {
          assignDraft(initialState)
        }
        draft.clientId = id
        break

      case FETCH_HOME_REQUEST:
        draft.loading = loading
        draft.errorModal = false
        draft.notFound = false
        break

      case FETCH_HOME_SUCCESS: {
        draft.cache[data.id] = data
        if (state.fetchId === data.id) {
          assignDraft({
            loading: false,
            updateLoanBalanceRequested: false,
            data
          })
        }
        break
      }

      case FETCH_HOME_SUCCESS_FOR_BUYER: {
        draft.cache[data.id] = data
        assignDraft({
          loading: false,
          updateLoanBalanceRequested: false,
          data
        })
        break
      }

      case FETCH_HOME_FAILURE:
        switch (get(error, 'response.status')) {
          case 401:
            draft.errorModal = true
            draft.loading = false
            break

          case 404:
            draft.notFound = true
            draft.loading = false
            break

          default:
            draft.errorModal = true
            draft.loading = false
            break
        }
        break

      case ADD_HOME_ANSWER_SUCCESS: {
        // only update the specific answer that was updated
        const updatedAnswerKey = get(data, 'data.question', null)
        const camelKey = camelCase(updatedAnswerKey)
        const newAnswer = get(data, 'data.response', null)

        set(draft, ['data', 'answers', camelKey], newAnswer)
        break
      }

      case UPDATE_LOAN_BALANCES_REQUEST:
        draft.updateLoanBalanceRequested = true
        draft.updateLoanBalanceLoading = true
        draft.updateLoanBalanceSaved = false
        break

      case UPDATE_LOAN_BALANCES_SUCCESS:
        draft.updateLoanBalanceRequested = true
        draft.updateLoanBalanceLoading = false
        draft.updateLoanBalanceSaved = true
        break

      case UPDATE_LOAN_BALANCES_FAILURE:
        draft.updateLoanBalanceRequested = false
        draft.updateLoanBalanceLoading = false
        draft.updateLoanBalanceSaved = false
        break

      case UPDATE_LOAN_BALANCES_SAVED_RESET:
        draft.updateLoanBalanceSaved = false
        break

      default:
        break
    }
  })

export default homeReducer
