import React from 'react'
import classnames from 'classnames'

import BotHeadColored from '../Avatar/BotHeadColored'
import styles from './Logo.module.scss'

const rootProps = ({ className, ...restProps }) => ({
  className: classnames(className, styles.logo),
  ...restProps
})

const Logo = props => (
  <span {...rootProps(props)}>
    <BotHeadColored className={styles.image} />
    <span className={classnames(styles.text, styles.words)}>homebot</span>
  </span>
)

export default Logo
