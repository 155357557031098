import React, { useRef, ChangeEvent } from 'react'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'
import TextInput from './TextInput'
import { createNewEvent } from '../../../helpers/browser'

export interface NumberInputProps extends NumberFormatProps {
  className?: string
  id?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeValues?: (values: NumberFormatValues) => void
}

const NumberInput = React.forwardRef(({ id, onChange, onChangeValues, ...restProps }: NumberInputProps, ref) => {
  const myRef = useRef(null)
  const inputRef: any = ref || myRef

  const handleChange = (values: NumberFormatValues) => {
    const target = inputRef?.current

    if (target && onChange) {
      const event: any = createNewEvent('change')
      const tracker = inputRef._valueTracker

      tracker?.setValue(values.value)
      target.dispatchEvent(event)
      onChange(event)
    }

    onChangeValues?.(values)
  }

  return (
    <NumberFormat
      id={id}
      inputMode='numeric'
      customInput={TextInput as any}
      getInputRef={inputRef}
      onValueChange={(values: NumberFormatValues) => handleChange(values)}
      {...restProps}
    />
  )
})

NumberInput.displayName = 'NumberInput'

export default NumberInput
