import produce from 'immer'

import {
  PREPARE_AIRDNA_REQUEST,
  FETCH_AIRDNA_REQUEST,
  FETCH_AIRDNA_SUCCESS,
  FETCH_AIRDNA_FAILURE,
  SET_AIRDNA_SETTINGS
} from '../actions/actionTypes'

const getEmptyState = () => ({
  loading: false,
  params: null,
  data: undefined,
  error: null,
  settings: {
    nightsPerMonth: 7,
    pricePerNight: 0
  }
})

export default (state = {}, { type, stateKey, params, data, error, settings }) =>
  produce(state, draft => {
    const update = (props = {}) => Object.assign(draft[stateKey], props)

    const ensureState = () => {
      if (!draft[stateKey]) {
        throw new Error(`AirDNA stateKey (${stateKey}) has not been initialized`)
      }
    }

    switch (type) {
      case PREPARE_AIRDNA_REQUEST:
        if (!draft[stateKey]) {
          draft[stateKey] = getEmptyState()
        }
        break

      case FETCH_AIRDNA_REQUEST:
        ensureState()
        update({ loading: true })
        break

      case FETCH_AIRDNA_SUCCESS:
        ensureState()
        update({
          loading: false,
          params,
          settings,
          data
        })
        break

      case FETCH_AIRDNA_FAILURE:
        ensureState()
        update({
          loading: false,
          error
        })
        break

      case SET_AIRDNA_SETTINGS:
        ensureState()
        Object.assign(draft[stateKey].settings, settings)
        break

      default:
        break
    }
  })
