import React from 'react'
import classnames from 'classnames'
import { string, node, bool } from 'prop-types'

import styles from './Fieldset.module.scss'

const propTypes = {
  children: node.isRequired,
  label: string,
  hideLabel: bool,
  className: string
}

const defaultProps = {
  label: '',
  hideLabel: false
}

const Fieldset = ({ label, children, hideLabel, className, ...restProps }) => {
  return (
    <fieldset className={className} {...restProps}>
      <legend className={classnames(hideLabel && styles.hide)}>{label}</legend>
      {children}
    </fieldset>
  )
}

Fieldset.propTypes = propTypes
Fieldset.defaultProps = defaultProps

export default Fieldset
