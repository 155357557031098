import produce from 'immer'
import { FETCH_SPONSOR_REQUEST, FETCH_SPONSOR_SUCCESS, FETCH_SPONSOR_FAILURE } from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  data: undefined,
  loaded: false // Use as hard check for initial load
}

export default (state = initialState, { type, data }) =>
  produce(state, draft => {
    switch (type) {
      case FETCH_SPONSOR_REQUEST:
        draft.isLoading = true
        break
      case FETCH_SPONSOR_SUCCESS:
        draft.isLoading = false
        draft.data = data
        draft.loaded = true
        break
      case FETCH_SPONSOR_FAILURE:
        draft.isLoading = false
        draft.loaded = true
        break

      default:
        break
    }
  })
