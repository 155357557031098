import { createSelector } from 'reselect'
import {
  selectCustomerProfile,
  selectCustomerHasSponsor,
  getCustomerEmail,
  getCustomerFirstName,
  getCustomerLastName,
  getCustomerPhotoUri,
  getCustomerNmls,
  getCustomerType,
  getCustomerDisclaimer,
  getCustomerListingsUri,
  getCustomerFeatures,
  getCustomerPhone,
  getCustomerCorporationName
} from './customerProfile'
import get from 'lodash/get'
import { fullName } from './utils'

export const sponsorInputSelector = state => get(state, 'sponsor.data')

export const selectSponsorship = createSelector(
  selectCustomerProfile,
  selectCustomerHasSponsor,
  sponsorInputSelector,
  (customerProfile, isSponsored, sponsor) => {
    if (isSponsored) {
      return sponsor
    }

    return undefined
  }
)

const selectFromSponsorship = getter => createSelector(selectSponsorship, getter)

export const selectSponsorshipEmail = selectFromSponsorship(getCustomerEmail)
export const selectSponsorshipFirstName = selectFromSponsorship(getCustomerFirstName)
export const selectSponsorshipLastName = selectFromSponsorship(getCustomerLastName)
export const selectSponsorshipPhotoUri = selectFromSponsorship(getCustomerPhotoUri)
export const selectSponsorshipNmls = selectFromSponsorship(getCustomerNmls)
export const selectSponsorshipType = selectFromSponsorship(getCustomerType)
export const selectSponsorshipDisclaimer = selectFromSponsorship(getCustomerDisclaimer)
export const selectSponsorshipListingsUri = selectFromSponsorship(getCustomerListingsUri)
export const selectSponsorshipFeatures = selectFromSponsorship(getCustomerFeatures)
export const selectSponsorshipPhone = selectFromSponsorship(getCustomerPhone)
export const selectSponsorshipCorporationName = selectFromSponsorship(getCustomerCorporationName)
export const selectSponsorshipLegacyId = createSelector(selectSponsorship, sponsorship =>
  getSponsorshipAttr(sponsorship, 'legacyRef')
)

export const selectSponsorshipId = createSelector(selectSponsorship, sponsorship =>
  getSponsorshipAttr(sponsorship, 'id')
)

export const selectSponsorshipBrandingStatement = createSelector(selectSponsorship, sponsorship =>
  getSponsorshipAttr(sponsorship, 'brandingStatement')
)

export const selectSponsorshipYearsInIndustry = createSelector(selectSponsorship, sponsorship =>
  getSponsorshipAttr(sponsorship, 'yearsInIndustry')
)

export const getSponsorshipAttr = (sponsorship, attr) => {
  return sponsorship ? get(sponsorship, attr) : undefined
}

export const selectSponsorshipFullName = createSelector(
  selectSponsorshipFirstName,
  selectSponsorshipLastName,
  (firstName, lastName) => fullName(firstName, lastName)
)
