import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'

// Actions
import { setCurrentClient } from '../actions/client'

// Components
import Modal from '../Components/shared/Modal/Modal'
import { DefaultMessage, LogoWithMessage } from '../Components/shared/Error'
import { Button } from '../Components'

const ErrorModal = ({ resetState }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (resetState) {
      dispatch(setCurrentClient(null))
    }
  }, [dispatch, resetState])

  return (
    <Modal>
      <LogoWithMessage>
        <DefaultMessage />
      </LogoWithMessage>
      <Button onClick={() => window.location.reload()}>
        <FormattedMessage id='ErrorModal.button.reload' defaultMessage='Reload' />
      </Button>
    </Modal>
  )
}

ErrorModal.defaultProps = {
  resetState: true
}

ErrorModal.propTypes = {
  resetState: PropTypes.bool
}

export default ErrorModal
