import { withStateHandlers } from 'recompose'

export default withStateHandlers(
  { showInfo: false },
  {
    toggleInfo:
      ({ showInfo }) =>
      () => ({ showInfo: !showInfo })
  }
)
