import find from 'lodash/find'
import get from 'lodash/get'

import { createSelector } from 'reselect'
import { videoCodes } from '../../constants/videos'

import { selectIsFirstDigestView } from './clientEvents'

import { MARKET_DEMAND_KEYS } from '../../constants/marketRecords'

export const selectVideos = state => state.videos.data
export const selectLoadingVideos = state => state.videos.loading

// VIDEO OBJECT SELECTORS

export const selectVideoByCode = code => createSelector(selectVideos, videos => find(videos, ['code', code]))

export const selectNewHomeVideo = selectVideoByCode(videoCodes.NEW_HOME_CODE)

export const selectRentHomeVideo = selectVideoByCode(videoCodes.RENT_HOME_CODE)

export const selectInvestmentVideo = selectVideoByCode(videoCodes.INVESTMENT_CODE)

export const selectSellHomeVideo = selectVideoByCode(videoCodes.SELL_HOME_CODE)

export const selectRefi30FixedVideo = selectVideoByCode(videoCodes.REFI_30_FIXED)

export const selectInstantOfferVideo = selectVideoByCode(videoCodes.INSTANT_OFFER_CODE)

export const selectWelcomeVideo = selectVideoByCode(videoCodes.WELCOME_CODE)

export const selectBuyerWelcomeVideo = selectVideoByCode(videoCodes.BUYER_WELCOME_CODE)

export const selectPrequalVideo = selectVideoByCode(videoCodes.PREQUAL_CODE)

export const selectShouldYouSellVideo = selectVideoByCode(videoCodes.SHOULD_YOU_SELL_CODE)

export const selectYourHomeValueVideo = selectVideoByCode(videoCodes.YOUR_HOME_VALUE_CODE)

export const selectCoBrandedIntroVideo = selectVideoByCode(videoCodes.CO_BRANDED_INTRO_CODE)

export const selectYourMarketDemandVideo = demandKey =>
  createSelector(selectVideos, videos => {
    switch (demandKey) {
      case MARKET_DEMAND_KEYS.VERY_HIGH:
        return find(videos, ['code', videoCodes.YOUR_MARKET_VERY_HIGH_DEMAND_CODE])
      case MARKET_DEMAND_KEYS.HIGH:
        return find(videos, ['code', videoCodes.YOUR_MARKET_HIGH_DEMAND_CODE])
      case MARKET_DEMAND_KEYS.MODERATE:
        return find(videos, ['code', videoCodes.YOUR_MARKET_MODERATE_DEMAND_CODE])
      case MARKET_DEMAND_KEYS.LOW:
        return find(videos, ['code', videoCodes.YOUR_MARKET_LOW_DEMAND_CODE])
      case MARKET_DEMAND_KEYS.VERY_LOW:
        return find(videos, ['code', videoCodes.YOUR_MARKET_VERY_LOW_DEMAND_CODE])
      default:
        return undefined
    }
  })

// VIDEO ID SELECTORS

export const selectVideoIdByCode = code =>
  createSelector(selectVideoByCode(code), video => get(video, 'externalVideoId'))

export const selectNewHomeVideoId = createSelector(selectNewHomeVideo, newHomeVideo =>
  get(newHomeVideo, 'externalVideoId')
)

export const selectRentHomeVideoId = createSelector(selectRentHomeVideo, rentHomeVideo =>
  get(rentHomeVideo, 'externalVideoId')
)

export const selectInvestmentVideoId = createSelector(selectInvestmentVideo, investmentVideo =>
  get(investmentVideo, 'externalVideoId')
)

export const selectSellHomeVideoId = createSelector(selectSellHomeVideo, sellHomeVideo =>
  get(sellHomeVideo, 'externalVideoId')
)

export const selectRefi30FixedVideoId = createSelector(selectRefi30FixedVideo, video => get(video, 'externalVideoId'))

export const selectYourMarketDemandVideoId = demandKey =>
  createSelector(selectYourMarketDemandVideo(demandKey), marketVideo => get(marketVideo, 'externalVideoId'))

export const selectWelcomeVideoId = createSelector(selectWelcomeVideo, welcomeVideo =>
  get(welcomeVideo, 'externalVideoId')
)

export const selectInstantOfferVideoId = createSelector(selectInstantOfferVideo, instantOfferVideo =>
  get(instantOfferVideo, 'externalVideoId')
)

export const selectBuyerWelcomeVideoId = createSelector(selectBuyerWelcomeVideo, buyerWelcomeVideo =>
  get(buyerWelcomeVideo, 'externalVideoId')
)

export const selectPrequalVideoId = createSelector(selectPrequalVideo, prequalVideo =>
  get(prequalVideo, 'externalVideoId')
)

export const selectShouldYouSellVideoId = createSelector(selectShouldYouSellVideo, shouldYouSellVideo =>
  get(shouldYouSellVideo, 'externalVideoId')
)

export const selectYourHomeValueVideoId = createSelector(selectYourHomeValueVideo, yourHomeValueVideo =>
  get(yourHomeValueVideo, 'externalVideoId')
)

export const selectCoBrandedIntroVideoId = selectVideoIdByCode(videoCodes.CO_BRANDED_INTRO_CODE)

// VIDEO TYPE SELECTORS

export const selectVideoTypeByCode = code =>
  createSelector(selectVideoByCode(code), video => get(video, 'videoPlatform'))

export const selectNewHomeVideoType = createSelector(selectNewHomeVideo, newHomeVideo =>
  get(newHomeVideo, 'videoPlatform')
)

export const selectRentHomeVideoType = createSelector(selectRentHomeVideo, rentHomeVideo =>
  get(rentHomeVideo, 'videoPlatform')
)

export const selectInvestmentVideoType = createSelector(selectInvestmentVideo, investmentVideo =>
  get(investmentVideo, 'videoPlatform')
)

export const selectSellHomeVideoType = createSelector(selectSellHomeVideo, sellHomeVideo =>
  get(sellHomeVideo, 'videoPlatform')
)

export const selectRefi30FixedVideoType = createSelector(selectRefi30FixedVideo, video => get(video, 'videoPlatform'))

export const selectYourMarketDemandVideoType = demandKey =>
  createSelector(selectYourMarketDemandVideo(demandKey), marketVideo => get(marketVideo, 'videoPlatform'))

export const selectWelcomeVideoType = createSelector(selectWelcomeVideo, welcomeVideo =>
  get(welcomeVideo, 'videoPlatform')
)

export const selectInstantOfferVideoType = createSelector(selectInstantOfferVideo, instantOfferVideo =>
  get(instantOfferVideo, 'videoPlatform')
)

export const selectBuyerWelcomeVideoType = createSelector(selectBuyerWelcomeVideo, buyerWelcomeVideo =>
  get(buyerWelcomeVideo, 'videoPlatform')
)

export const selectPrequalVideoType = createSelector(selectPrequalVideo, prequalVideo =>
  get(prequalVideo, 'videoPlatform')
)

export const selectShouldYouSellVideoType = createSelector(selectShouldYouSellVideo, shouldYouSellVideo =>
  get(shouldYouSellVideo, 'videoPlatform')
)

export const selectYourHomeValueVideoType = createSelector(selectYourHomeValueVideo, yourHomeValueVideo =>
  get(yourHomeValueVideo, 'videoPlatform')
)

export const selectRefi30FixedVideoSyndicated = createSelector(selectRefi30FixedVideo, video =>
  get(video, 'syndicated')
)

export const selectCoBrandedIntroVideoType = selectVideoTypeByCode(videoCodes.CO_BRANDED_INTRO_CODE)

// VIDEO THUMBNAIL SELECTORS

export const selectNewHomeVideoThumbnail = createSelector(selectNewHomeVideo, newHomeVideo =>
  get(newHomeVideo, 'thumbnailUrl')
)

export const selectIntantOfferVideoThumbnail = createSelector(selectInstantOfferVideo, instantOfferVideo =>
  get(instantOfferVideo, 'thumbnailUrl')
)

export const selectRefi30FixedVideoThumbnail = createSelector(selectRefi30FixedVideo, video =>
  get(video, 'thumbnailUrl')
)

export const selectBuyerWelcomeVideoThumbnail = createSelector(selectBuyerWelcomeVideo, buyerWelcomeVideo =>
  get(buyerWelcomeVideo, 'thumbnailUrl')
)

export const selectShouldYouSellVideoThumbnail = createSelector(selectShouldYouSellVideo, shouldYouSellVideo =>
  get(shouldYouSellVideo, 'thumbnailUrl')
)

export const selectShouldDisplayWelcomeVideo = createSelector(
  selectIsFirstDigestView,
  selectWelcomeVideoType,
  selectWelcomeVideoId,
  (isFirstDigestView, videoType, videoId) => isFirstDigestView && !!videoType && !!videoId
)
