import { atom } from 'recoil'
import { StateKeys } from '../../../../constants/stateKeys'
import { StorageKey } from '../../../../constants/StorageKey'
import { storageEffect } from '../../../../effects/storageEffect'

export const recentlySelectedLocations = atom<any[]>({
  key: StateKeys.recentlySelectedLocations,
  default: [],
  effects: [storageEffect(StorageKey.RecentlySelectedLocations)]
})
