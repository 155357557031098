// Components
import Svg from '../Components/shared/Svg'

export interface InfoSvgProps {
  className?: string
}

export const InfoSvg = ({ className }: InfoSvgProps) => (
  <Svg className={className} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/Svg'>
    <path
      d='M8.57143 6.28571V12.5714H7.42857V6.28571H8.57143ZM9 4C9 4.53968 8.53968 5 8 5C7.46032 5 7 4.53968 7 4C7 3.46032 7.46032 3 8 3C8.53968 3 9 3.46032 9 4ZM14.8571 8C14.8571 4.22222 11.7778 1.14286 8 1.14286C4.22222 1.14286 1.14286 4.22222 1.14286 8C1.14286 11.7778 4.22222 14.8571 8 14.8571C11.7778 14.8571 14.8571 11.7778 14.8571 8ZM16 8C16 12.4127 12.4127 16 8 16C3.5873 16 0 12.4127 0 8C0 3.5873 3.5873 0 8 0C12.4127 0 16 3.5873 16 8Z'
      fill='currentColor'
    />
  </Svg>
)
