import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import CheckBox from './CheckBox'
import { IconCheck } from '../Icon'
import BaseCheckBox from './BaseCheckBox'

import styles from './PinkCheckBox.module.scss'

const propTypes = {
  className: PropTypes.string,
  checkClassName: PropTypes.string,
  checkIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool
}

const MyInput = ({
  checked,
  defaultChecked,
  onChange,
  onFocus,
  onBlur,
  checkClassName,
  checkIcon: Icon,
  ...restProps
}) => {
  const [isChecked, setChecked] = useState(defaultChecked)
  const myIsChecked = Boolean(isChecked || checked)
  const [focused, setFocused] = useState(false)

  return (
    <div className={classnames(styles.root, myIsChecked && styles.checked, focused && styles.focused, checkClassName)}>
      <Icon className={classnames(styles.icon, myIsChecked && styles.selected)} />
      <BaseCheckBox
        hide
        checked={myIsChecked}
        onChange={e => {
          setChecked(!myIsChecked)
          if (onChange) onChange(e)
        }}
        onFocus={e => {
          setFocused(true)
          if (onFocus) onFocus(e)
        }}
        onBlur={e => {
          setFocused(false)
          if (onBlur) onBlur(e)
        }}
        {...restProps}
      />
    </div>
  )
}

MyInput.propTypes = propTypes
MyInput.defaultProps = {
  checkIcon: IconCheck
}

const PinkCheckBox = ({ ...restProps }) => {
  return <CheckBox input={MyInput} {...restProps} />
}

export default PinkCheckBox
