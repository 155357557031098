import { fetchSponsor as fetchResource } from '../api/mikasa/requests'
import { FETCH_SPONSOR_REQUEST, FETCH_SPONSOR_SUCCESS, FETCH_SPONSOR_FAILURE } from './actionTypes'

export const fetchSponsor = (agentType, agentId) => async dispatch => {
  dispatch({ type: FETCH_SPONSOR_REQUEST })

  try {
    const res = await fetchResource(agentType, agentId)
    const profileData = res.data.data
    dispatch({
      type: FETCH_SPONSOR_SUCCESS,
      data: profileData
    })
  } catch (error) {
    // ToDo, handle
    dispatch({ type: FETCH_SPONSOR_FAILURE })
  }
}
