import { selectCustomerType, selectCustomerFirstName } from '../../../../../store/selectors/customerProfile'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Text from '../../../../shared/Text'
import { MSG } from '../Desktop/DownloadMobileAppPromptCard'
import { CustomerType } from '../../../../../types/customerType'
import { HomeSearchTrackingEventLocation } from '../../../../../constants/tracking'
import { NativeCTALinks } from '../../../../shared/NativeCTALinks/NativeCTALinks'
import styles from './MobileDownloadMobileAppPromptCard.module.scss'

interface MobileDownloadMobileAppPromptCardProps {
  className?: string
  isLandingPage?: boolean
}

export const MobileDownloadMobileAppPromptCard = ({
  className,
  isLandingPage
}: MobileDownloadMobileAppPromptCardProps) => {
  const intl = useIntl()
  const type = useSelector(selectCustomerType)
  const customerFirstName = useSelector(selectCustomerFirstName)
  const customerType = type === CustomerType.LoanOfficers ? 'loan officer' : 'agent'

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.imageBackground} />
      <div className={styles.contents}>
        <Text className={styles.header} element='h1' weight='bold' size='l'>
          {intl.formatMessage(MSG.dreamHome)}
        </Text>
        <Text className={styles.description} element='p' size='s' variant='white'>
          {intl.formatMessage(MSG.downloadNow, {
            customer: customerFirstName,
            customerType: customerType
          })}
        </Text>
        <NativeCTALinks
          tracking={{
            guid: 'v4xvBJK8YDtco56VnWwPjd',
            ui_context: isLandingPage
              ? HomeSearchTrackingEventLocation.HOME_SEARCH_NATIVE_APP_CTA
              : HomeSearchTrackingEventLocation.HOME_SEARCH_LISTING_DETAILS_NATIVE_APP_CTA
          }}
        />
      </div>
    </div>
  )
}
