import { useState, useEffect, useContext } from 'react'
import { defaultOpts } from '../../constants/animations'
import { ActiveOverlayContext } from '../../Components/shared/Routing/OverlayContext'

export const useSlideOnMount = (opts = {}, initial) => {
  const duration = opts.duration || defaultOpts.duration
  const delay = opts.delay || defaultOpts.delay
  const top = opts.top || defaultOpts.slideTop
  const useDelay = !isNaN(opts.delay)

  const transition = `
    transform ease-in-out ${duration}ms,
    opacity ease-in ${duration * 2.5}ms
  `
  const [style, setStyle] = useState({
    transform: `translateY(${top})`,
    opacity: 0
  })

  useEffect(() => {
    setTimeout(() => {
      setStyle({
        transform: `translateY(0)`,
        opacity: 1,
        transition,
        transitionDelay: useDelay && `${delay}ms`
      })
    }, defaultOpts.buffer)
  }, [])

  const { exitingPanel } = useContext(ActiveOverlayContext) || {
    exitingPanel: false
  }
  if (exitingPanel) {
    return {
      transform: `translateY(calc(${initial ? '' : '-'}1 * ${top}))`,
      opacity: 0,
      transition: `
        transform ease-in-out ${defaultOpts.duration}ms,
        opacity ease-in ${defaultOpts.duration * 0.75}ms
      `,
      transitionDelay: `${initial ? 0 : defaultOpts.interval}ms`
    }
  }

  return style
}

export default useSlideOnMount
