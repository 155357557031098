import { createSelector } from 'reselect'
import {
  selectClientId,
  selectClientHasHomes,
  selectClientFirstHomeId,
  selectClientIsBuyer,
  selectClientHomes,
  selectHasRequestedHomes,
  selectHasRequestedClient
} from './client'
import { selectHomeId } from './home'
import { selectCustomerBuyersEnabled, selectCustomerId } from './customerProfile'

import { PATH_HELPERS, SEARCH_PATH_HELPERS } from '../../constants/navigation'
import { getCurrentPath } from '../../helpers/browser'
import { ROUTE_KEY_HOME, ROUTE_KEY_BUY, ROUTE_KEY_MARKETS } from '../../constants/routes'

export const routeToHome = (clientId, homeId) => PATH_HELPERS.home.buildPath(clientId, homeId)

export const routeToListing = (clientId, listingId) => PATH_HELPERS.listing.buildPath(clientId, listingId)

export const routeToPublicListing = listingId => PATH_HELPERS.publicListing.buildPath(listingId)

export const routeToPublicListings = (level, name, additionalFilters = {}) =>
  PATH_HELPERS.publicListings.buildPath(level, name, additionalFilters)

export const routeToListings = (clientId, level, name, additionalFilters = {}) =>
  PATH_HELPERS.listings.buildPath(clientId, level, name, additionalFilters)

export const routeToSearch = customerId => SEARCH_PATH_HELPERS.search.buildPath(customerId)
export const routeToForYouTab = (customerId, clientId) => SEARCH_PATH_HELPERS.forYouTab.buildPath(customerId, clientId)
export const routeToSavedSearches = customerId => SEARCH_PATH_HELPERS.savedSearches.buildPath(customerId)
export const routeToFavoriteListings = (customerId, clientId) =>
  SEARCH_PATH_HELPERS.favoriteListings.buildPath(customerId, clientId)

export const selectRouteToSearch = createSelector(selectCustomerId, customerId => routeToSearch(customerId))

export const selectRouteToForYou = createSelector(selectCustomerId, selectClientId, (customerId, clientId) =>
  routeToForYouTab(customerId, clientId)
)

export const selectRouteToSavedSearches = createSelector(selectCustomerId, customerId =>
  routeToSavedSearches(customerId)
)
export const selectRouteToFavoriteListings = createSelector(selectCustomerId, selectClientId, (customerId, clientId) =>
  routeToFavoriteListings(customerId, clientId)
)

export const selectRouteToExistingHome = createSelector(
  selectClientId,
  selectHomeId,
  selectClientFirstHomeId,
  (clientId, currentHomeId, firstHomeId) => routeToHome(clientId, currentHomeId || firstHomeId || null)
)

// TODO:: Add specs
export const selectRouteToFirstHome = createSelector(selectClientId, selectClientFirstHomeId, (clientId, firstHomeId) =>
  routeToHome(clientId, firstHomeId || null)
)

export const selectRouteToNewHomeNav = createSelector(
  selectClientId,
  selectClientHasHomes,
  selectRouteToFirstHome,
  (clientId, hasHomes, firstHomeRoute) => {
    if (hasHomes) {
      return firstHomeRoute
    }

    return routeToHome(clientId, 'new')
  }
)

// TODO - Depreciate in favor of selectRouteToNewHomeNav
export const selectRouteToHomeNav = createSelector(
  selectClientId,
  selectClientHasHomes,
  selectRouteToExistingHome,
  (clientId, hasHomes, existing) => {
    if (hasHomes) {
      return existing
    }

    return routeToHome(clientId, 'new')
  }
)

const ROUTES = {
  buy: PATH_HELPERS.buy.regex,
  markets: PATH_HELPERS.markets.regex,
  home: PATH_HELPERS.home.regex,
  savedSearches: SEARCH_PATH_HELPERS.savedSearches.regex,
  listings: PATH_HELPERS.listings.regex,
  homeSearch: PATH_HELPERS.homeSearch.regex
}

const getRouteKeyFromPath = pathname => {
  const key = Object.keys(ROUTES).find(k => ROUTES[k].test(pathname))
  return key === ROUTE_KEY_MARKETS ? ROUTE_KEY_BUY : key
}

export const selectRouteKey = () => getRouteKeyFromPath(getCurrentPath())

export const INITIALIZE_ROUTE = PATH_HELPERS.buy.buildPath('initialize')

// @deprecated in favor of useBuyerDigestUrl as this is not a selector, it's a hook
export const selectBuyingReportRoute = createSelector(selectClientId, selectClientIsBuyer, (clientId, isBuyer) =>
  isBuyer && clientId ? PATH_HELPERS.buy.buildPath(clientId) : INITIALIZE_ROUTE
)

export const selectMarketExploreRoute = createSelector(selectClientId, selectClientIsBuyer, (clientId, isBuyer) =>
  isBuyer && clientId ? PATH_HELPERS.markets.buildPath(clientId, '/explore/map') : INITIALIZE_ROUTE
)

const selectShowHomesLink = createSelector(
  selectHasRequestedHomes,
  selectClientHomes,
  (requested, homes) => requested && homes?.length
)

export const selectShowBuyersLink = createSelector(
  selectRouteKey,
  selectCustomerBuyersEnabled,
  selectHasRequestedClient,
  selectShowHomesLink,
  (routeKey, access, requested, showHomes) =>
    (routeKey === ROUTE_KEY_HOME && access && requested) || (routeKey === ROUTE_KEY_BUY && showHomes)
)
