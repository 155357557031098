import classnames from 'classnames'
import styles from './HeaderContent.module.scss'

export type HeaderContentProps = {
  className?: string
  children: React.ReactNode
}

export const HeaderContent = ({ children, className, ...restProps }: HeaderContentProps) => (
  <div className={classnames(styles.content, className)} {...restProps}>
    {children}
  </div>
)
