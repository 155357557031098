import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { If } from '../../../deprecated/Conditionals'
import { useSlideOnMount } from '../../../hooks/animations'
import { ActiveOverlayContext } from '../Routing/OverlayContext'
import { defaultOpts } from '../../../constants/animations'
import { buildDelayCss } from './generic'

export const SlideOnMount = ({
  disabled,
  className,
  children,
  ...restProps
}: {
  disabled?: boolean
  className?: string
  children?: any
  interval?: number
}) => {
  const css = 'local-animation'
  const maxIntervals = 3

  const { interval = defaultOpts.interval } = restProps
  const styles = useSlideOnMount(restProps)
  const [animating, setAnimating] = useState(true)
  const { exitingPanel } = useContext(ActiveOverlayContext) || {
    exitingPanel: false
  }

  useEffect(() => {
    setTimeout(() => setAnimating(false), interval * (maxIntervals + 1))
  }, [interval])

  return (
    <>
      <If condition={animating}>
        <style>{buildDelayCss(css, maxIntervals, interval)}</style>
      </If>
      <div className={classnames(css, className)} style={disabled && !exitingPanel ? {} : styles} {...restProps}>
        {children}
      </div>
    </>
  )
}

SlideOnMount.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  delay: PropTypes.number,
  disabled: PropTypes.bool,
  duration: PropTypes.number,
  interval: PropTypes.number,
  slideTop: PropTypes.string,
  top: PropTypes.string,
  useDelay: PropTypes.bool
}

export default SlideOnMount
