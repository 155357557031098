/* eslint-disable prettier/prettier */
// PLACE ALL GLOBAL TRACKING EVENTS HERE

export const GLOBAL_AGENT_NAVIGATION = 'global_agent_navigation'
export const GLOBAL_DOWNLOAD_NATIVE_APP_PAGE = 'global_download_native_app_page'
export const GLOBAL_NATIVE_APP_QR_MODAL = 'global_native_app_qr_modal'
export const GLOBAL_NAV_DROPDOWN = 'global_nav_dropdown'
export const GLOBAL_SIGNUP = 'global_signup'
export const GLOBAL_NAVIGATION_BAR = 'global_navigation_bar'
export const GLOBAL_MOBILE_TOP_NAV = 'global_mobile_top_nav'
export const GLOBAL_MOBILE_TOP_NAV_FOR_SEARCH = 'global_mobile_top_nav_for_search'
export const GLOBAL_SIDEBAR_NATIVE_APP_AD = 'global_sidebar_native_app_ad'
export const GLOBAL_SIDEBAR_GALLERIES_AD = 'global_sidebar_galleries_ad'
