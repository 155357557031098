import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import changeCaseObject from 'change-case-object'

import Icon from './Icon'

import styles from './Icon.module.scss'

const propTypes = {
  className: PropTypes.string
}

const defineIcon = (iconClassName, flipped) => {
  const IconType = ({ className = '', ...restProps }) => {
    return <Icon className={classnames(className, iconClassName, flipped && styles.flipped)} {...restProps} />
  }

  IconType.propTypes = propTypes
  IconType.displayName = changeCaseObject.camelCase(iconClassName)

  return IconType
}

export default defineIcon
