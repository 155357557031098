import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../types/rootState'
import { NavigationTabName } from '../../types/navigation'

export interface Navigation {
  mobileNavIsOpen: boolean
  hideMobileBottomNav: boolean
  isShortcutsDrawerOpen: boolean
  activeTab: NavigationTabName | null
  createClientFrom: string | null
}

const initialState: Navigation = {
  mobileNavIsOpen: false,
  hideMobileBottomNav: false,
  isShortcutsDrawerOpen: false,
  activeTab: null,
  createClientFrom: null
}

const navSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setMobileNavIsOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileNavIsOpen = action.payload
    },
    setHideMobileBottomNav: (state, action: PayloadAction<boolean>) => {
      state.hideMobileBottomNav = action.payload
    },
    setIsShortcutsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isShortcutsDrawerOpen = action.payload
    },
    setActiveNavigationTab: (state, action: PayloadAction<NavigationTabName>) => {
      state.activeTab = action.payload
    },
    setCreateClientFrom: (state, action: PayloadAction<string>) => {
      state.createClientFrom = action.payload
    }
  }
})

export const selectMobileNavIsOpen = (state: RootState) => state.navigation.mobileNavIsOpen

export const selectHideMobileBottomNav = (state: RootState) => state.navigation.hideMobileBottomNav

export const selectIsShortcutsDrawerOpen = (state: RootState) => state.navigation.isShortcutsDrawerOpen

export const selectActiveNavigationTab = (state: RootState) => state.navigation.activeTab

export const selectCreateClientFrom = (state: RootState) => state.navigation.createClientFrom

export const {
  setMobileNavIsOpen,
  setHideMobileBottomNav,
  setIsShortcutsDrawerOpen,
  setActiveNavigationTab,
  setCreateClientFrom
} = navSlice.actions

export default navSlice.reducer
