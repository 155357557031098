import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Text, Button, Input } from '../..'
import { IconPlusBTB, IconMinusBTB } from '../Icon'

import styles from './Incrementor.module.scss'

const propTypes = {
  // attach a class to the root to custom style the incrementor
  className: PropTypes.string,
  // Whatever passed as children will render as the label
  children: PropTypes.node,
  // for custom label styling
  labelText: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

const defaultProps = {
  min: 0,
  max: 20,
  value: 0
}

const DefaultLabelText = props => <Text element='span' variant='neutralCool' variantCount='700' size='s' {...props} />

const Incrementor = React.forwardRef(
  (
    { className, children, labelText, min, max, value, ...restProps },
    ref // whatever consumer must have a useRef attribute on that formfield
  ) => {
    const LabelText = labelText || DefaultLabelText

    const handleIncrement = () => {
      const currentVal = parseInt(ref.current.value, 10)

      if (currentVal < max) ref.current.value = currentVal + 1
    }

    const handleDecrement = () => {
      const currentVal = parseInt(ref.current.value, 10)

      if (currentVal > min) ref.current.value = currentVal - 1
    }

    const handleKeyDown = e => {
      const { key } = e

      switch (key) {
        case 'ArrowUp': {
          e.preventDefault()
          handleIncrement()
          break
        }
        case 'ArrowDown': {
          e.preventDefault()
          handleDecrement()
          break
        }
        default: {
        }
      }
    }

    return (
      <div className={classnames(styles.root, className)}>
        <LabelText>{children}</LabelText>
        <div className={styles.block}>
          <Input
            className={styles.counter}
            ref={ref}
            type='number'
            min={min}
            max={max}
            onKeyDown={e => handleKeyDown(e)}
            readOnly
            defaultValue={value}
            {...restProps}
          />
          <div className={styles.incrementorButtons}>
            <Button type='button' className={styles.plus} onClick={() => handleIncrement()}>
              <IconPlusBTB />
            </Button>
            <Button type='button' className={styles.minus} onClick={() => handleDecrement()}>
              <IconMinusBTB />
            </Button>
          </div>
        </div>
      </div>
    )
  }
)

Incrementor.propTypes = propTypes
Incrementor.defaultProps = defaultProps

export default Incrementor
