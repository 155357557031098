import numeral from 'numeral'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

export const formatCurrency = value => numeral(value).format('$0,0[.]00')

export const formatAbbrCurrency = value => {
  if (value < 1000) {
    return numeral(value).format('($0,0)')
  }

  if (value < 1000000) {
    return numeral(value).format('($0a)')
  }

  return numeral(value).format('($0.00a)')
}

// +17574347325 => 7574347325
export const removeCountryCode = phone => {
  if (!phone) return

  const COUNTRY_CODE_REGEX = /^\+[0-9]/
  return phone.replace(COUNTRY_CODE_REGEX, '')
}

// 757-434-7325 => 7574347325
export const isValidPhoneNumber = phone => {
  if (!phone) return false

  return phone.replace(/[^\d]/g, '').length === 10
}

export const formatNumber = value => numeral(value).format('0,0')

export const formatAbbrNumber = value => {
  if (value < 1000) {
    return numeral(value).format('(0,0)')
  }

  if (value < 1000000) {
    return numeral(value).format('(0a)')
  }

  return numeral(value).format('(0.00a)')
}

export const removeCountryFromCityState = str => str.split(',').slice(0, -1).join(',')

export function correctCityCasing(cityName) {
  // City names come back in all caps, to get around this issue and approximate
  // the correct casing, we're using lodash. Long term plan is to get correctly-
  // cased names into the data set and remove this

  const lowerCasedCityName = lowerCase(cityName)
  const formattedCityName = startCase(lowerCasedCityName)
  return formattedCityName
}
