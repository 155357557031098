import { defineMessages } from 'react-intl'

export const FeedCategoryTxn = defineMessages({
  recentlySold: {
    id: 'FeedCategoryTxn.recentlySold',
    defaultMessage: 'Recently Sold'
  },
  recentPriceDrop: {
    id: 'FeedCategoryTxn.recentPriceDrop',
    defaultMessage: 'Price Drop'
  },
  assumables: {
    id: 'FeedCategoryTxn.assumables',
    defaultMessage: 'Assumables'
  },
  concessions: {
    id: 'FeedCategoryTxn.concessions',
    defaultMessage: 'Concessions'
  }
})

export const DENVER_PLACE_ID = 'ChIJzxcfI6qAa4cR1jaKJ_j0jhE'
export const SQFT_LOT_LIMIT = 10890
export const ACRES_TO_SQFT_CONVERSION = 43560
