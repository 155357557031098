import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Confetti from 'react-confetti'
import { If } from '../../../deprecated/Conditionals'

import CONFETTI_DEFAULTS, { DYNAMIC_CONFETTI_DEFAULTS } from './defaults'
import styles from './Confetti.module.scss'

const defaultProps = {
  animation: 'explosion',
  smooth: true,
  dynamic: false
}

const propTypes = {
  className: PropTypes.string,
  go: PropTypes.bool,
  animation: PropTypes.oneOf(['explosion']),
  numberOfPieces: PropTypes.number,
  children: PropTypes.node,
  smooth: PropTypes.bool,
  dynamic: PropTypes.bool,
  tweenDuration: PropTypes.number,
  recycle: PropTypes.bool,
  colors: PropTypes.array,
  wind: PropTypes.number,
  gravity: PropTypes.number,
  friction: PropTypes.number,
  initialVelocityX: PropTypes.number,
  initialVelocityY: PropTypes.number
}

const ConfettiTarget = ({ className = '', children, go, animation, smooth, dynamic, ...restProps }) => {
  const wrapperRef = useRef(null)
  const [mount, setMount] = useState(false)

  const position = {}
  const confettiProps = dynamic
    ? DYNAMIC_CONFETTI_DEFAULTS[animation]()
    : CONFETTI_DEFAULTS[`${animation}${smooth ? '-smooth' : ''}`]

  if (wrapperRef.current && wrapperRef.current.getBoundingClientRect) {
    const buffer = 1000 // High enough that initialVelocityY doesn't clip, can make smarter but this should cover it
    const rect = wrapperRef.current.getBoundingClientRect()
    const scroll = document.scrollingElement || document.documentElement
    const fullHeight = Math.max(scroll.clientHeight, document.body.clientHeight)

    Object.assign(position, {
      left: rect.left * -1,
      top: buffer * -1
    })

    Object.assign(confettiProps, {
      width: window.outerWidth,
      height: fullHeight + buffer - rect.top - scroll.scrollTop,
      confettiSource: {
        w: rect.width || 20,
        h: rect.height || 20,
        x: rect.left,
        y: buffer
      }
    })
  }

  useEffect(() => {
    if (!mount && wrapperRef.current) {
      setMount(true)
    }
  }, [mount])

  const myProps = Object.assign({}, confettiProps, { ...restProps })

  return (
    <div ref={wrapperRef} className={classnames(styles.childWrapper, className)}>
      <If condition={go && wrapperRef.current}>
        <div className={styles.confettiHolder} style={position}>
          <Confetti {...myProps} />
        </div>
      </If>
      {children}
    </div>
  )
}

ConfettiTarget.defaultProps = defaultProps
ConfettiTarget.propTypes = propTypes

export default ConfettiTarget
