import React from 'react'
import FormField from './FormField'
import * as VARIANTS from './variants'
import * as NAMES from './names'
import TYPES from './types'

Object.keys(VARIANTS).forEach(v => {
  FormField[v] = React.forwardRef((props, ref) => <FormField ref={ref} {...props} />)
  FormField[v].defaultProps = VARIANTS[v]
  FormField[v].displayName = `FormField.${v}`
})

FormField.NAMES = NAMES
FormField.TYPES = TYPES

export default FormField

export { NAMES, TYPES }
