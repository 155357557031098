import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import configApp from './configApp'
import './scss/main.scss'
import './scss/mapbox-overrides/index.scss'
import { AppContainer } from './App/AppContainer'

const configPromise = configApp()

async function startApp() {
  await configPromise
  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(<AppContainer />)
}

startApp()
