import { createSelector } from 'reselect'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import nth from 'lodash/nth'
import { getTheBoolean, select } from './utils'
import { selectHomeId } from './home'
import { AVM_TUNE_TYPES } from '../../constants/valuation'

const getValuationHistory = state => state.valuationHistory
const selectFromValuationHistory = select(getValuationHistory)

export const selectValuationHistoryLoading = selectFromValuationHistory(getTheBoolean('success'))

export const selectValuationHistoryByHomeId = homeId =>
  createSelector(partialRight(get, `valuationHistory.${homeId}`), history => get(history, 'data'))

export const selectValuationGraphVisibilityByHomeId = homeId =>
  createSelector(selectValuationHistoryByHomeId(homeId), valuations => (valuations || []).length > 3)

export const selectValuationHistoryForCurrentHome = createSelector(
  selectHomeId,
  getValuationHistory,
  (homeId, valuationHistory) => {
    return get(valuationHistory, [homeId, 'data'], [])
  }
)

export const selectLatestValuationForCurrentHome = createSelector(
  selectValuationHistoryForCurrentHome,
  valuationHistoryForCurrentHome => {
    if (isEmpty(valuationHistoryForCurrentHome)) return {}

    return last(valuationHistoryForCurrentHome)
  }
)

export const selectSecondToLatestValuationForCurrentHome = createSelector(
  selectValuationHistoryForCurrentHome,
  valuationHistoryForCurrentHome => {
    if (isEmpty(valuationHistoryForCurrentHome)) return {}
    if (valuationHistoryForCurrentHome.length < 2) return {}

    return nth(valuationHistoryForCurrentHome, -2)
  }
)

export const selectLatestValuationIsACma = createSelector(
  selectLatestValuationForCurrentHome,
  latestValuationForCurrentHome => {
    if (isEmpty(latestValuationForCurrentHome)) return false

    return latestValuationForCurrentHome.valuationType === AVM_TUNE_TYPES.CMA
  }
)

export const selectLatestValuationIsTuneYourValue = createSelector(
  selectLatestValuationForCurrentHome,
  latestValuationForCurrentHome => {
    if (isEmpty(latestValuationForCurrentHome)) return false

    return latestValuationForCurrentHome.valuationType === AVM_TUNE_TYPES.TYV
  }
)

export const selectAnyValuationIsTuneYourValue = createSelector(
  selectValuationHistoryForCurrentHome,
  valuationHistory => valuationHistory.some(val => val.valuationType === AVM_TUNE_TYPES.TYV)
)

const calculateAvmPercentageChange = ({ latestValuation, secondLatestValuation } = {}) => {
  const latestAvmValue = latestValuation.amount - latestValuation.diff
  const secondLatestAvmValue = secondLatestValuation.amount - secondLatestValuation.diff

  return (latestAvmValue - secondLatestAvmValue) / secondLatestAvmValue
}

export const selectLatestAvmPercentageChangeInValuationHistory = createSelector(
  selectLatestValuationForCurrentHome,
  selectSecondToLatestValuationForCurrentHome,
  (latestValuation, secondLatestValuation) => {
    if (isEmpty(latestValuation) || isEmpty(secondLatestValuation)) return null

    return calculateAvmPercentageChange({
      latestValuation,
      secondLatestValuation
    })
  }
)
