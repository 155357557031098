import React from 'react'
import PropTypes from 'prop-types'

// Components
import Text from '../Text'
import { If } from '../../../deprecated/Conditionals'

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.string
}

const NMLS = ({ value, ...restProps }) => (
  <If condition={value}>
    <Text element='span' {...restProps}>
      {`NMLS #${value}`}
    </Text>
  </If>
)

NMLS.propTypes = propTypes

export default NMLS
