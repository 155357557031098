import React from 'react'
import PropTypes from 'prop-types'
import Svg from '../Components/shared/Svg'

const MovingTruckSvg = ({ className }) => (
  <Svg
    className={className}
    width='67'
    height='50'
    viewBox='0 0 67 50'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0H41V43.6H25.9196C25.3861 47.2166 22.2627 50 18.4999 50C14.7373 50 11.6139 47.2166 11.0804 43.6H0V0ZM11.0537 41.6C11.4995 37.8866 14.6683 35 18.4999 35C22.3319 35 25.5005 37.8864 25.9463 41.6H39V2H2V41.6H11.0537ZM41.6001 18.2H54.8402L57.7103 25.7056L67.0001 28.3494V43.6H60.9196C60.3861 47.2166 57.2627 50 53.4999 50C49.7373 50 46.6139 47.2166 46.0804 43.6H41.6001V18.2ZM46.0537 41.6C46.4995 37.8866 49.6683 35 53.4999 35C57.3319 35 60.5005 37.8864 60.9463 41.6H65.0001V29.8596L56.1999 27.3552L53.4638 20.2H43.6001V41.6H46.0537ZM18.4999 37C15.4683 37 13 39.4683 13 42.5001C13 45.5319 15.4683 48 18.4999 48C21.5318 48 24 45.5319 24 42.5001C24 39.4681 21.5319 37 18.4999 37ZM53.4999 37C50.4683 37 48 39.4683 48 42.5001C48 45.5319 50.4683 48 53.4999 48C56.5318 48 59 45.5319 59 42.5001C59 39.4681 56.5319 37 53.4999 37Z'
      fill='inherit'
    />
  </Svg>
)

MovingTruckSvg.propTypes = {
  className: PropTypes.string
}

export default MovingTruckSvg
