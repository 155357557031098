import { defineMessage } from 'react-intl'

/** TopicId - an identifier that groups a TopicKey, Topic, and TopicMessage together. The keys
 * of the CUSTOMER_MESSAGES map */
export type TopicId = keyof typeof CUSTOMER_MESSAGES
/** TopicKey - one of three parameters needed to message an LO. a mikasa identifier for the message topic */
export type TopicKey = UnionValuesOfSubProperty<typeof CUSTOMER_MESSAGES>['topicKey']
/** TopicKey - one of three parameters needed to message an LO. (extraneous info:) an english phrase that
 * the email will contain to contextualize the message */
export type Topic = UnionValuesOfSubProperty<typeof CUSTOMER_MESSAGES>['topic']
/** TopicMessage - one of three parameters needed to message an LO. a default is provided in the MESSAGE_LO_CONSTANTS
 * map, but a custom message can be provided instead. */
export type TopicMessage = UnionValuesOfSubProperty<typeof CUSTOMER_MESSAGES>['message']

type CUSTOMER_MESSAGES_WITH_SECONDARY_MESSAGE = Pick<
  typeof CUSTOMER_MESSAGES,
  KeysHavingProperty<typeof CUSTOMER_MESSAGES, 'secondaryMessage'>
>

export type TopicSecondaryMessage =
  CUSTOMER_MESSAGES_WITH_SECONDARY_MESSAGE[keyof CUSTOMER_MESSAGES_WITH_SECONDARY_MESSAGE]['secondaryMessage']

type CUSTOMER_MESSAGES_WITH_TERTIARY_MESSAGE = Pick<
  typeof CUSTOMER_MESSAGES,
  KeysHavingProperty<typeof CUSTOMER_MESSAGES, 'tertiaryMessage'>
>

export type TopicTertiaryMessage =
  CUSTOMER_MESSAGES_WITH_TERTIARY_MESSAGE[keyof CUSTOMER_MESSAGES_WITH_TERTIARY_MESSAGE]['tertiaryMessage']

/**
 * Constants mapping for Topics and Topic Keys for Message Customer touch points.
 * The consolidated mapping of this object into enums exists above ^^^
 *
 * secondaryMessage and tertiaryMessage fields are occasionally used in-place of message field and
 * overridden with conditional logic, and sometimes it is used in conjunction with
 * the primary message by passing in the TopicId (map key) two or three times and overriding
 * the second and/or third messages with the secondary/tertiaryMessage.
 */
export const CUSTOMER_MESSAGES = {
  // Homeowners

  USING_EQUITY: {
    topicKey: 'using_equity_to_sponsor',
    topic: 'Cash-Out Options',
    message: defineMessage({ id: 'Homeowner.equity.defaultQuestion', defaultMessage: 'What option is best for me?' })
  },
  HOME_APPRECIATION: {
    topicKey: 'home_appreciation_to_customer',
    topic: 'Home Appreciation',
    message: defineMessage({
      id: 'ShouldYouSell.question.what.best.move',
      defaultMessage: "What's my best move: stay or sell?"
    })
  },
  HOMEOWNER_CASH_POSITION: {
    topicKey: 'homeowner_cash_position_to_customer_with_notify',
    topic: 'Cash Position',
    message: defineMessage({
      id: 'ShouldYouSell.cashPosition.good.body.question',
      defaultMessage: 'Can you pre-qualify me?'
    }),
    secondaryMessage: defineMessage({
      id: 'ShouldYouSell.cashPosition.bad.body.question',
      defaultMessage: 'How far out am I?'
    })
  },
  WHAT_TO_SELL_FOR: {
    topicKey: 'what_to_sell_for_to_customer',
    topic: 'Selling',
    message: defineMessage({
      id: 'ShouldYouSell.WhatSellFor.question.placeholder',
      defaultMessage: 'Do you know any interested buyers?'
    })
  },
  IF_THEY_SHOULD_SELL: {
    topicKey: 'homeowner_should_sell_to_customer',
    topic: 'Selling and Buying',
    message: defineMessage({
      id: 'Questions.homeValueEstimate.MarketForBuyingNewHome',
      defaultMessage: "How's the market for buying a new home?"
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.homeValueEstimate.understandWhatMyHomeIsWorth',
      defaultMessage: 'Can you help me understand what my home is probably worth?'
    })
  },
  SHOULD_SELL_MARKET: {
    topicKey: 'homeowner_should_sell_market_to_customer',
    topic: 'Market Temperature',
    message: defineMessage({
      id: 'Homeowner.marketSpotlight.questions.sell',
      defaultMessage: 'Should I sell now?'
    })
  },
  HOMEOWNER_MARKET: {
    topicKey: 'homeowner_market_to_customer',
    topic: 'Current Neighborhood Market',
    message: defineMessage({
      id: 'Homeowner.marketSpotlight.questions.nextSteps',
      defaultMessage: "How's my neighborhood doing?"
    })
  },
  REFINANCING: {
    topicKey: 'refinancing_to_sponsor',
    topic: 'Refinancing',
    message: defineMessage({
      id: 'SystemRequest.refiMessage',
      defaultMessage: 'Please send me {years} Year {type} refi options.'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.refinance.cashoutQuestion',
      defaultMessage: 'What are my cash-out options?'
    }),
    tertiaryMessage: defineMessage({
      id: 'Questions.refinance.recoupQuestion',
      defaultMessage: 'How long until I recoup closing costs?'
    })
  },
  BUYER_AFFORDABILITY: {
    topicKey: 'buyer_affordability_to_customer',
    topic: 'Affordability',
    message: defineMessage({
      id: 'Questions.affordability.lockInRates',
      defaultMessage: 'How do I lock in before rates rise?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.affordability.downPayment',
      defaultMessage: 'What down payment do I really need?'
    }),
    tertiaryMessage: defineMessage({
      id: 'Questions.placeholder',
      defaultMessage: 'Ask a question…'
    })
  },
  CASHOUT_OPTIONS: {
    topicKey: 'homeowner_emergency_fund_to_sponsor',
    topic: 'Cash-Out Options',
    message: defineMessage({
      id: 'Homeowner.equity.eurotripQuestion',
      defaultMessage: "What's the best use of my equity?"
    })
  },
  PRINCIPAL_PAYMENTS: {
    topicKey: 'principal_payments_to_sponsor',
    topic: 'Principal Payments',
    message: defineMessage({
      id: 'Questions.interest.vsRefinance',
      defaultMessage: 'What are the trade-offs versus refinancing?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.interest.singleLargePayment',
      defaultMessage: 'What if I made a single large payment?'
    })
  },
  HOMEOWNER_SHORT_TERM_RENTALS: {
    topicKey: 'homeowner_short_term_rentals_to_customer',
    topic: 'Short Term Rentals',
    message: defineMessage({
      id: 'Questions.airdna.startToRent',
      defaultMessage: 'Can you help me get started?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.airdna.optimizeRent',
      defaultMessage: 'How else can short term rental income help me?'
    })
  },
  HOME_INSURANCE: {
    topicKey: 'home_insurance_to_customer',
    topic: 'Home Insurance',
    message: defineMessage({
      id: 'Homeowner.marketSpotlight.questions.start',
      defaultMessage: 'Where should I start?'
    }),
    secondaryMessage: defineMessage({
      id: 'Homeowner.homeInsurance.questions.overpaying',
      defaultMessage: 'How do I know if I’m overpaying?'
    }),
    tertiaryMessage: defineMessage({
      id: 'SystemRequest.homeInsuranceMessage',
      defaultMessage: 'Can you help me check my insurance policy?'
    })
  },
  REVERSE_MORTGAGE: {
    topicKey: 'reverse_mortgage_to_sponsor',
    topic: 'Reverse Mortgages',
    message: defineMessage({
      id: 'Questions.reverseMortgages.title',
      defaultMessage: 'Want to learn more?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.reverseMortgages.retirementCashFlow',
      defaultMessage: 'Can you help me plan my retirement cash flow?'
    })
  },
  HOMEOWNER_EMAIL_UPDATE: {
    topicKey: 'homeowner_email_update_to_customer',
    topic: 'Email Updates',
    message: defineMessage({
      id: 'EmailUpdates.panel.message.placeholder',
      defaultMessage: 'Can you update my email?'
    })
  },
  HOME_VALUE: {
    topicKey: 'home_value_to_customer_with_notify',
    topic: 'Home Value',
    message: defineMessage({
      id: 'Homeowner.avmDiffWarning.footer.questionPlaceHolder',
      defaultMessage: 'Can you verify my home value?'
    })
  },
  HOMEOWNER_EMERGENCY_FUND: {
    topicKey: 'homeowner_emergency_fund_to_sponsor',
    topic: 'Emergency Funds',
    message: defineMessage({
      id: 'Homeowner.equity.emergenciesQuestion.cash',
      defaultMessage: 'What are my cash-out options?'
    }),
    secondaryMessage: defineMessage({
      id: 'Homeowner.equity.emergenciesQuestion.rate',
      defaultMessage: 'What would my refi rate be?'
    })
  },
  HOME_IMPROVEMENTS: {
    topicKey: 'home_improvements_to_sponsor',
    topic: 'Home Improvements',
    message: defineMessage({
      id: 'Homeowner.equity.homeImprovementQuestion',
      defaultMessage: 'Should I use equity for home improvements?'
    })
  },
  HOMEOWNER_ADD_SPACE: {
    topicKey: 'homeowner_add_space_to_sponsor',
    topic: 'Adding Space',
    message: defineMessage({
      id: 'Homeowner.equity.addSpaceQuestion',
      defaultMessage: 'Can I build an ADU?'
    })
  },
  HOMEOWNER_STUDENT_DEBT: {
    topicKey: 'homeowner_student_debt_to_sponsor',
    topic: 'Student Debt',
    message: defineMessage({
      id: 'Homeowner.equity.studentDebtQuestion',
      defaultMessage: 'Should I pay off my loans now?'
    })
  },
  HOMEOWNER_CREDIT_CARD_DEBT: {
    topicKey: 'homeowner_credit_card_debt_to_sponsor',
    topic: 'Credit Card Debt',
    message: defineMessage({
      id: 'Homeowner.equity.creditCardDebtQuestion',
      defaultMessage: 'How do I pay off my credit cards?'
    })
  },
  HOMEOWNER_ENERGY_IMPROVEMENTS: {
    topicKey: 'homeowner_energy_improvements_to_sponsor',
    topic: 'Energy Improvements',
    message: defineMessage({
      id: 'Homeowner.equity.solarPanelsQuestion',
      defaultMessage: 'Can I finance solar panels with equity?'
    })
  },
  HOMEOWNER_INVESTMENTS: {
    topicKey: 'homeowner_investments_to_sponsor',
    topic: 'Investments',
    message: defineMessage({
      id: 'Homeowner.equity.exploreInvestmentsQuestion',
      defaultMessage: 'Can we chat through my goals?'
    })
  },
  EQUITY_SMART_SEARCH: {
    topicKey: 'equity_smart_search_to_sponsor',
    topic: 'Equity Smart Search Calculations',
    message: defineMessage({
      id: 'MarketSpotLight.equitySmartSearchCalculation.questionPlaceholder',
      defaultMessage: 'Could you walk me through this?'
    })
  },
  MORTGAGE_INSURANCE: {
    topicKey: 'mortgage_insurance_to_sponsor',
    topic: 'Mortgage Insurance',
    message: defineMessage({
      id: 'Questions.mortgageInsurance.howCanIRemovePmi',
      defaultMessage: 'How can I remove my mortgage insurance?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.mortgageInsurance.dropPMIFaster',
      defaultMessage: 'How would I drop mortgage insurance faster?'
    }),
    tertiaryMessage: defineMessage({
      id: 'Questions.mortgageInsurance.estimatedPMI.footer',
      defaultMessage: 'Do you know if I have PMI?'
    })
  },
  PURCHASING_POWER: {
    topicKey: 'purchasing_power_to_customer',
    topic: 'Purchasing Power',
    message: defineMessage({ id: 'Homeowner.equity.defaultQuestion', defaultMessage: 'What option is best for me?' })
  },
  DREAM_HOME: {
    topicKey: 'buying_dream_home_to_customer_with_notify',
    topic: 'Buying a Dream Home',
    message: defineMessage({
      id: 'Homeowner.purchasePower.dreamHomeQuestion',
      defaultMessage: 'How do I get started?'
    })
  },
  INVESTMENT_PROPERTY: {
    topicKey: 'investment_property_to_customer_with_notify',
    topic: 'Buying an Investment Property',
    message: defineMessage({
      id: 'Homeowner.purchasePower.rentalPropertyQuestion',
      defaultMessage: 'How can I finance a rental?'
    })
  },
  SELLING_HOME: {
    topicKey: 'selling_home_to_customer_with_notify',
    topic: 'Selling a Home',
    message: defineMessage({
      id: 'Homeowner.purchasePower.sellPocketQuestion',
      defaultMessage: 'Does it make sense to sell now?'
    })
  },
  SECOND_HOME: {
    topicKey: 'second_home_to_customer_with_notify',
    topic: 'Buying a Second Home',
    message: defineMessage({
      id: 'Homeowner.purchasePower.rentBuyQuestion',
      defaultMessage: 'Do I need to update my home?'
    })
  },
  MORTGAGE_COACH: {
    topicKey: 'mortgage_coach_to_customer',
    topic: 'Mortgage Coach',
    message: defineMessage({
      id: 'MortgageCoach.confirmTCA.question.placeholder',
      defaultMessage: 'Send {firstName} a quick note…'
    })
  },
  VIDEO_EMAIL: {
    topicKey: 'video_email_to_sponsor',
    topic: 'Your Video Email Message',
    message: defineMessage({
      id: 'Questions.placeholder',
      defaultMessage: 'Ask a question…'
    })
  },
  HOMEOWNER_CUSTOM_MODULE: {
    topicKey: 'homeowner_custom_module_to_customer',
    topic: 'Homeowner Custom Module',
    message: defineMessage({
      id: 'OwnCustomModule.question.learnMore',
      defaultMessage: 'I want to learn more'
    })
  },

  // Buyers

  BUYER_VIDEO_EMAIL: {
    topicKey: 'buyer_video_email_to_sponsor',
    topic: 'Your Video Email Message',
    message: defineMessage({
      id: 'Questions.placeholder',
      defaultMessage: 'Ask a question…'
    })
  },
  BUYER_PREQUAL_REQUEST: {
    topicKey: 'buyer_prequal_request_to_customer',
    topic: 'Buyer Pre-Qualification',
    message: defineMessage({
      id: 'SystemRequest.prequalMessage',
      defaultMessage: 'Can you get me prequalified?'
    })
  },
  // This only shows up for clients that have pre-quals already
  BUYER_WITH_PREQUAL: {
    topicKey: 'buyer_with_prequal_to_customer',
    topic: 'Pre-Qual Request',
    message: defineMessage({
      id: 'Affordability.hasPrequal.question',
      defaultMessage: 'Can you renew my Pre-Qual?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.placeholder',
      defaultMessage: 'Ask a question…'
    })
  },
  BUYER_EXPLORING_MARKETS: {
    topicKey: 'buyer_exploring_markets_to_customer',
    topic: 'Exploring Markets',
    message: defineMessage({
      id: 'Questions.favoriteMarkets.whatMarkets',
      defaultMessage: 'What areas should I be looking at?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.favoriteMarkets.fasterHomeSearch',
      defaultMessage: 'Is there new construction I should know about?'
    }),
    tertiaryMessage: defineMessage({
      id: 'Questions.placeholder',
      defaultMessage: 'Ask a question…'
    })
  },
  BUYER_MOVING_TIMELINE: {
    topicKey: 'buyer_exploring_markets_to_customer',
    topic: 'Moving Timelines',
    message: defineMessage({
      id: 'Questions.marketActivity.getStarted',
      defaultMessage: 'How do I get started?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.timeline.shrink',
      defaultMessage: 'How do I shrink these timelines?'
    }),
    tertiaryMessage: defineMessage({
      id: 'Questions.placeholder',
      defaultMessage: 'Ask a question…'
    })
  },
  BUYER_SHORT_TERM_RENTALS: {
    topicKey: 'buyer_short_term_rentals_to_customer',
    topic: 'Short Term Rentals',
    message: defineMessage({
      id: 'Questions.airdna.startToRent',
      defaultMessage: 'Can you help me get started?'
    }),
    secondaryMessage: defineMessage({
      id: 'Questions.airdna.optimizeRent',
      defaultMessage: 'How else can short term rental income help me?'
    })
  },
  BUYER_LISTINGS: {
    topicKey: 'buyer_listings_to_customer',
    topic: 'Listings',
    message: defineMessage({
      id: 'Questions.listings',
      defaultMessage: 'How can I see homes in {zipcode}?'
    })
  },
  BUYER_EMAIL_UPDATE: {
    topicKey: 'buyer_email_update_to_customer',
    topic: 'Email Updates',
    message: defineMessage({
      id: 'EmailUpdates.panel.message.placeholder',
      defaultMessage: 'Can you update my email?'
    })
  },
  BUYER_ZIP_CODE: {
    topicKey: 'buyer_zip_code_to_customer',
    topic: 'ZIP Code',
    message: defineMessage({
      id: 'Questions.zipCodeDetail.topic',
      defaultMessage: 'What should I know about {zipcode}?'
    })
  },

  // HomeSearch

  LISTINGS_DIRECT_MESSAGE_LO: {
    topicKey: 'listings_direct_message',
    topic: 'Pre-Qualification',
    message: defineMessage({
      id: 'ListingDetails.prequal.question',
      defaultMessage: 'Can I get pre-qualified?'
    })
  },
  LISTINGS_AFFORD: {
    topicKey: 'chromebot_listing_affordability_to_customer',
    topic: 'A Viewed Listing',
    message: defineMessage({
      id: 'ListingDetails.afford.question',
      defaultMessage: 'Can I afford this home?'
    })
  },
  LISTINGS_INVESTMENT: {
    topicKey: 'chromebot_listing_investment_to_customer',
    topic: 'A Viewed Listing',
    message: defineMessage({
      id: 'ListingDetails.investment.question',
      defaultMessage: 'Is this home a good investment?'
    })
  },
  LISTINGS_PREQUAL: {
    topicKey: 'chromebot_listing_prequalification_to_sponsor',
    topic: 'A Viewed Listing',
    message: defineMessage({
      id: 'ListingDetails.prequalForHome.question',
      defaultMessage: 'Can I get pre-qualified for this home?'
    })
  },
  LISTINGS_CUSTOM_QUESTION: {
    topicKey: 'chromebot_listing_custom_to_customer',
    topic: 'A Viewed Listing',
    message: null // message is custom for this DM
  }
} as const
