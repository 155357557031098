import React from 'react'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Button from './Button'
import { IconAngleLeft } from '../Icon'

import styles from './BackButton.module.scss'

const BackButton = ({ className, ...restProps }) => (
  <Button
    data-qa='buttonBack'
    {...restProps}
    className={classnames(styles.root, className)}
    aria-label='back'
    iconStart={IconAngleLeft}
  >
    <span className={styles.text}>
      <FormattedMessage id='Button.Back' defaultMessage='Back' />
    </span>
  </Button>
)

export default BackButton
