import { UseQueryOptions } from 'react-query'
import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import { QueryKey } from '../../constants/queryKey'
import { useFeedQuery } from '../../api/gqlaxy/gql/generated/graphql-hooks'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import { FeedQuery, FeedQueryVariables } from '../../api/gqlaxy/gql/generated/graphql'
import { isAuthTokenSupported } from '../../auth'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E> extends UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}

export const useGetFeed = (
  param: FeedQueryVariables,
  option: Option<FeedQuery, Error> = {
    queryKey: QueryKey.GetFeed as any
  }
) => {
  const toast = useToast()

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // local endpoint
  })

  const { disableErrorPrompts, errorPrompts, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e, ...restArgs] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        duration: 4000,
        position: 'top-right'
      })
    },
    [disableErrorPrompts, errorPrompts, toast]
  )

  return useFeedQuery(client, param, {
    queryKey: [QueryKey.GetFeed, param.feedId],
    onError: handleError,
    enabled: isAuthTokenSupported(),
    ...options
  })
}
