// Components
import Svg from '../Components/shared/Svg'

export const GraphSvg = props => (
  <Svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M16 16H0V0H1.79894V7.66138L6.92063 4.69841L10.2434 6.66667L16 3.32275V5.29101L10.2434 8.59259L6.92063 6.66667L1.79894 9.62963V14.2011H16V16Z'
      fill='var(--brand-neutral-cool-200)'
    />
  </Svg>
)
