import { Box, Button, ButtonGroup, Icon, LinkButton, Flex } from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import { toggleAuthModal } from '../../../../actions/homeSearchAuthModal'
import { GlobalTrackingEventLocation } from '../../../../constants/tracking'
import { selectClientIsGuest } from '../../../../store/selectors/client'
import { selectRouteToFavoriteListings, selectRouteToSavedSearches } from '../../../../store/selectors/routes'
import { RootState } from '../../../../types/rootState'
import { MobileHeaderContainer } from '../MobileHeaderContainer/MobileHeaderContainer'
import { AgentDropdown } from '../../../../Components/shared/AgentDropdown'
import { useDigestUrl } from '../../../../hooks/navigation/useDigestUrl'
import { hasClientToken, hasCustomerToken } from '../../../../auth'
import { selectHasCompanyBrandingLogoPresent } from '../../../../store/selectors/customerProfile'
import { SavedSearchIcon } from '../../../../Components/homeSearch/Listings/SavedSearchToggle/SavedSearchIcon'
import { selectActiveNavigationTab } from '../../../../store/slices/navigation'
import { NAVIGATION_TAB_NAMES } from '../../../../types/navigation'
import { HBRELogo } from '../../../../Components/shared/HBRE/HBRELogo'
import useViewport from '../../../../hooks/useViewport'

export const MSG = defineMessages({
  signup: {
    id: 'MobileSearchHeader.signup',
    defaultMessage: 'Sign up'
  },
  login: {
    id: 'MobileSearchHeader.login',
    defaultMessage: 'Log in'
  },
  favorites: {
    id: 'MobileSearchHeader.favorites',
    defaultMessage: 'Favorites'
  },
  searches: {
    id: 'MobileSearchHeader.searches',
    defaultMessage: 'Searches'
  }
})

export const MobileSearchHeader = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const viewport = useViewport()
  const homeownerOrBuyerDigestUrl = useDigestUrl()
  const isPublic = useSelector<RootState, boolean>(state => state.auth.isPublic)
  const isGuest = useSelector(selectClientIsGuest)
  const isImpersonating = hasCustomerToken()
  const isSignedIn = hasClientToken()
  const favoriteListingsRoute = useSelector(selectRouteToFavoriteListings)
  const savedSearchesRoute = useSelector(selectRouteToSavedSearches)
  const hasBrandingLogoPresent = useSelector(selectHasCompanyBrandingLogoPresent)
  const activeNavigationTab = useSelector(selectActiveNavigationTab)

  const handleSignupSigninClick = () => {
    dispatch(toggleAuthModal(true))
  }

  return (
    <MobileHeaderContainer>
      {(isPublic ||
        activeNavigationTab === NAVIGATION_TAB_NAMES.SEARCH ||
        activeNavigationTab === NAVIGATION_TAB_NAMES.FORYOU) && (
        <Box ml={[2, 3]}>
          <HBRELogo maxWidth={24} />
        </Box>
      )}
      {!isPublic && (
        <Flex alignItems='center' width='100%' justifyContent='flex-end'>
          {isGuest && !isImpersonating ? (
            <ButtonGroup alignItems='center'>
              <Button
                onClick={handleSignupSigninClick}
                size='sm'
                variant='ghost'
                tracking={{
                  guid: 'uc46wX131Xdv16Lg34dxXN',
                  ui_context: GlobalTrackingEventLocation.GLOBAL_MOBILE_TOP_NAV_FOR_SEARCH,
                  descriptor: MSG.signup.defaultMessage
                }}
              >
                {intl.formatMessage(MSG.signup)}
              </Button>
              <Box w='2px' h='20px' bgColor='neutral.300'></Box>
              <Button
                onClick={handleSignupSigninClick}
                size='sm'
                variant='ghost'
                tracking={{
                  guid: 'aAaX4VZTu5qyvdGyQJBvaF',
                  ui_context: GlobalTrackingEventLocation.GLOBAL_MOBILE_TOP_NAV_FOR_SEARCH,
                  descriptor: MSG.login.defaultMessage
                }}
              >
                {intl.formatMessage(MSG.login)}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <LinkButton
                as={ReactRouterLink}
                to={favoriteListingsRoute}
                size={['xs', 'sm']}
                variant='ghost'
                colorScheme='primary'
                leftIcon={<Icon name='heart' />}
                tracking={{
                  guid: 'kRzoMddxc3pcJPZBdVsAwa',
                  ui_context: GlobalTrackingEventLocation.GLOBAL_MOBILE_TOP_NAV_FOR_SEARCH,
                  descriptor: MSG.favorites.defaultMessage
                }}
              >
                <span className={viewport.isSmall ? 'screenReaderText' : ''}>{intl.formatMessage(MSG.favorites)}</span>
              </LinkButton>
              <LinkButton
                as={ReactRouterLink}
                to={savedSearchesRoute}
                size={['xs', 'sm']}
                variant='ghost'
                colorScheme='primary'
                leftIcon={<SavedSearchIcon />}
                tracking={{
                  guid: 'mfak8RSUSZMJJFmLDQ9vPT',
                  ui_context: GlobalTrackingEventLocation.GLOBAL_MOBILE_TOP_NAV_FOR_SEARCH,
                  descriptor: MSG.searches.defaultMessage
                }}
              >
                <span className={viewport.isSmall ? 'screenReaderText' : ''}>{intl.formatMessage(MSG.searches)}</span>
              </LinkButton>
            </ButtonGroup>
          )}
          {(isSignedIn || isImpersonating) && <AgentDropdown />}
        </Flex>
      )}
    </MobileHeaderContainer>
  )
}
