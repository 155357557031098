/* eslint no-template-curly-in-string: 0 */

import { defineMessages } from 'react-intl'
import { IconCheckCircle, IconTimesCircle, IconInfoCircle } from '../Components/shared/Icon'

export const buildRefiKey = option => `${option.type ? option.type.toLowerCase() : ''}-${option.years}`

export const REFI_BULLETS = defineMessages({
  // {years}, {abs_shortterm}, {pmi}
  fix_good: {
    id: 'Refinance.short.bullets.fix_good',
    defaultMessage: 'Long term savings',
    icon: IconCheckCircle
  },
  fix_bad: {
    id: 'Refinance.short.bullets.fix_bad',
    defaultMessage: 'Long term loss',
    icon: IconTimesCircle
  },
  arm_good: {
    id: 'Refinance.short.bullets.arm_good',
    defaultMessage: 'Risky after {years} years',
    icon: IconInfoCircle,
    color: 'success'
  },
  arm_bad: {
    id: 'Refinance.short.bullets.arm_bad',
    defaultMessage: 'Risky after {years} years',
    icon: IconInfoCircle,
    color: 'warning'
  },
  short_good: {
    id: 'Refinance.short.bullets.short_good',
    defaultMessage: 'Payment might go down by {abs_shortterm}/mo',
    icon: IconCheckCircle
  },
  short_same: {
    id: 'Refinance.short.bullets.short_same',
    defaultMessage: 'Payment may not change',
    icon: IconInfoCircle
  },
  short_bad: {
    id: 'Refinance.short.bullets.short_bad',
    defaultMessage: 'Payment might go up by {abs_shortterm}/mo',
    icon: IconTimesCircle
  },
  pmi: {
    id: 'Refinance.short.bullets.pmi',
    defaultMessage: 'Could remove {pmi}/mo in PMI',
    icon: IconCheckCircle
  },
  estimated: {
    id: 'Refinance.short.bullets.estimated',
    defaultMessage: 'Rate is estimated',
    icon: IconInfoCircle,
    color: 'neutralCool'
  }
})

export const REFI_DETAILS_HEADLINE = defineMessages({
  // {abs_shortTerm}, {abs_longTerm}, {years},
  longTermSavings_monthlySavings: {
    id: 'Refinance.details.headline.savings_savings',
    defaultMessage: 'You could potentially save {abs_longTerm} over {years} years and {abs_shortTerm} with a refi'
  },
  longTermSavings_monthlyLoss: {
    id: 'Refinance.details.headline.savings_loss',
    defaultMessage:
      'You could potentially save {abs_longTerm} over {years} years but pay {abs_shortTerm} more each month with a refi'
  },
  longTermLoss_monthlySavings: {
    id: 'Refinance.details.headline.loss_savings',
    defaultMessage:
      'You could potentially lose {abs_longTerm} over {years} years but might save {abs_shortTerm} with a refi'
  },
  longTermLoss_monthlyLoss: {
    id: 'Refinance.details.headline.loss_loss',
    defaultMessage:
      'You could potentially lose {abs_longTerm} over {years} years and pay {abs_shortTerm} more each month with a refi'
  },
  noSavings: {
    id: 'Refinance.details.headline.zero.savings',
    defaultMessage:
      "You probably wouldn't see any long-term savings over {year} years and would have the same monthly payment with a refi"
  }
})

export const REFI_DETAILS_BULLETS = defineMessages({
  // {now_years}, {pmi}, {potential30Year}, {yearsInHome} {national_rate}
  fix: {
    id: 'Refinance.details.bullets.fix',
    defaultMessage: 'A fixed rate means your principal & interest payments will never change',
    icon: IconCheckCircle
  },
  arm: {
    id: 'Refinance.details.bullets.arm',
    defaultMessage: 'This loan is riskier since rates might increase when the term ends in {now_years}',
    icon: IconInfoCircle
  },
  pmi: {
    id: 'Refinance.details.bullets.pmi',
    defaultMessage: 'Remove your monthly PMI payment of {pmi}',
    icon: IconCheckCircle
  },
  potential_good: {
    id: 'Refinance.details.bullets.potential_good',
    defaultMessage:
      'Your estimated savings over 30-years could be {potential30Year} if rates stayed relatively similar to today',
    icon: IconCheckCircle
  },
  potential_bad: {
    id: 'Refinance.details.bullets.potential_bad',
    defaultMessage:
      'Your estimated loss over 30-years could be {potential30Year} if rates stayed relatively similar to today',
    icon: IconTimesCircle
  },
  rate: {
    id: 'Refinance.details.bullets.national_rate',
    defaultMessage: '{nationalRate} is an estimated rate based on national trends',
    icon: IconInfoCircle
  }
})

export const REFI_CHART_MESSAGES = defineMessages({
  // {year}
  tooltip: {
    id: 'Refinance.chart.tootlip',
    defaultMessage: 'Year {year}'
  },
  unknown: {
    id: 'Refinance.chart.unknown',
    defaultMessage: 'Unknown'
  }
})

export const MONTHS_IN_YEAR = 12
export const MONTHS_IN_30_TERM_LOAN = 360
export const NO_INTEREST_PAID = 0
export const REFI_DETAILS_VIEW_EVENT = 'viewed-refi-details'
export const REFI_DETAILS_EVENT_SUBMITTED_KEY = 'refiDetailsEventSubmitted'
export const LOWEST_INTEREST_REFI_TERM = 15
