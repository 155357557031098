import { marketsByBoundaryRequest, marketsByZipcodesRequest } from '../api/mikasa/requests'

import { METADATA_SCOPES } from '../constants/marketRecords'

import {
  FETCH_MARKET_RECORDS,
  FETCH_MARKET_RECORDS_SUCCESS,
  FETCH_MARKET_RECORDS_FAILURE,
  FETCH_MARKET_RECORDS_DETAIL_SUCCESS,
  SET_MARKET_RECORDS_METADATA_SCOPE
} from './actionTypes'

export const fetchMarketRecords = bounds => async dispatch => {
  dispatch({ type: FETCH_MARKET_RECORDS })

  try {
    const records = await marketsByBoundaryRequest(bounds)
    dispatch({
      type: FETCH_MARKET_RECORDS_SUCCESS,
      records
    })
  } catch (error) {
    dispatch({
      type: FETCH_MARKET_RECORDS_FAILURE,
      error
    })
  }
}

export const fetchMarketRecordsDetail = zipcodes => async dispatch => {
  if (!(zipcodes && zipcodes.length)) {
    dispatch({
      type: FETCH_MARKET_RECORDS_FAILURE,
      error: 'No zipcodes provided'
    })
    return
  }
  
  dispatch({ type: FETCH_MARKET_RECORDS })

  try {
    const records = await marketsByZipcodesRequest(zipcodes)
    dispatch({
      type: FETCH_MARKET_RECORDS_DETAIL_SUCCESS,
      records
    })
  } catch (error) {
    dispatch({
      type: FETCH_MARKET_RECORDS_FAILURE,
      error
    })
  }
}

export const setMarketRecordsMetadataScope = scope => dispatch => {
  if (Object.values(METADATA_SCOPES).includes(scope)) {
    dispatch({
      type: SET_MARKET_RECORDS_METADATA_SCOPE,
      scope
    })
  }
}
