import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import setWith from 'lodash/setWith'
/*
 * StorageAdapter
 * This is a wrapper utility for deep sets and gets in local/session storage
 *
 * Rather than only being able to set top-level keys, you can create JSON
 * structures and persist them to storage. See tests for behavior
 */

class StorageAdapter {
  constructor(storage, key) {
    this.storage = storage
    this.key = key
    const data = storage.getItem(key)

    if (!data) {
      this.data = {}
      return
    }

    try {
      this.data = JSON.parse(data)
    } catch (e) {
      Sentry.captureException(e)
      this.data = {}
      this.persist()
    }
  }

  get(path, defaultValue) {
    return get(this.data, path, defaultValue)
  }

  set(path, value) {
    const returned = setWith(this.data, path, value, Object)
    this.persist()
    return returned
  }

  persist() {
    const stringified = JSON.stringify(this.data)
    this.storage.setItem(this.key, stringified)
  }
}

export default StorageAdapter
