import { Box } from '@homebotapp/hb-react-component-catalog'
import { useSelector } from 'react-redux'
import { selectIsSoloInsuranceAgent, selectIsSoloRea } from '../../../store/selectors/customerProfile'
import { selectHideMobileBottomNav } from '../../../store/slices/navigation'
import { NavigationTabs } from '../../../App/Layout/Navigation/NavigationTabs'

export const MobileBottomNav = () => {
  const isSoloRea = useSelector(selectIsSoloRea)
  const isSoloInsuranceAgent = useSelector(selectIsSoloInsuranceAgent)
  const isClientOfUnsponsoredAgent = isSoloRea && isSoloInsuranceAgent
  const hideBottomNav = useSelector(selectHideMobileBottomNav)

  if (hideBottomNav || isClientOfUnsponsoredAgent) {
    return null
  }

  return (
    <Box
      sx={{
        // This padding is to account for the iPhone X notch.
        // Should in theory be handled by the env(safe-area-inset-bottom) CSS property, but it's not working for some reason.
        paddingBottom: 'env(safe-area-inset-bottom)'
      }}
      bg='card-bg-layer-1'
      zIndex='modal'
      position='fixed'
      bottom='0'
      width='100%'
    >
      <NavigationTabs isFitted={true} size='md' />
    </Box>
  )
}
