import { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import styles from './Notification.module.scss'
import { IconCheckCircle } from '../Icon'
import { Box } from '@homebotapp/hb-react-component-catalog'

const ICON_MAPPING = {
  success: IconCheckCircle
}

const defaultProps = {
  messageType: 'error'
}

const propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    }),
    PropTypes.array,
    PropTypes.string
  ]),
  messageType: PropTypes.string,
  icon: PropTypes.string,
  handleClose: PropTypes.func,
  intl: PropTypes.any
}

class Notification extends PureComponent {
  static propTypes = propTypes
  static defaultProps = defaultProps

  getRootProps() {
    const { message, messageType, handleClose } = this.props
    const typeStyles = styles[messageType]

    return {
      onClick: handleClose,
      className: classNames({
        [styles.notification]: true,
        [typeStyles]: true,
        [styles.hide]: !message
      })
    }
  }

  renderMessage = message => {
    const { intl } = this.props

    if (isPlainObject(message)) {
      return intl.formatMessage(message)
    }

    if (isArray(message)) {
      return message.map(this.renderMessage).join(' ')
    }

    return message
  }

  renderIcon(type) {
    const Icon = ICON_MAPPING[type]

    if (Icon) {
      return (
        <div className={styles.icon}>
          <Icon />
        </div>
      )
    }
  }

  render() {
    const { message, messageType } = this.props

    return (
      <Box {...this.getRootProps()} zIndex='toast'>
        {this.renderIcon(messageType)}
        {this.renderMessage(message)}
      </Box>
    )
  }
}

export default injectIntl(Notification)
