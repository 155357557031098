import {
  SET_SIMILAR_LISTINGS,
  TOGGLE_FAVORITE_LISTING,
  APPEND_LISTINGS,
  SET_SEARCH_LISTINGS_TOTAL,
  SET_SELECTED_LISTING_ID,
  SET_PREVIOUSLY_VIEWED_LISTING_ID,
  TOGGLE_LISTING_SEARCH_MODAL,
  FETCH_HOMEBOT_LISTINGS_REQUEST,
  FETCH_HOMEBOT_LISTINGS_SUCCESS,
  FETCH_HOMEBOT_LISTINGS_FAILURE,
  FETCH_LISTING_REQUEST,
  FETCH_LISTING_SUCCESS,
  FETCH_LISTING_FAILURE
} from '../actions/actionTypes'
import { LISTINGS_FAVORITES, LISTINGS_SEARCH } from '../constants/listings'

export const initialState = {
  searchListingsTotal: null,
  listings: {},
  listing: {
    address: {},
    sqft: {},
    images: []
  },
  similarListings: [],
  uiFlags: {
    showSearchModal: false
  },
  loading: {
    [LISTINGS_SEARCH]: true,
    [LISTINGS_FAVORITES]: true
  },
  selectedListingId: '',
  previouslyViewedListingId: ''
}

const listingsReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SET_SIMILAR_LISTINGS: {
      const { listings } = payload

      return {
        ...state,
        similarListings: listings
      }
    }
    case TOGGLE_FAVORITE_LISTING: {
      const { listing, isFavorited, tag, position } = payload
      const listings = { ...state.listings }
      const insertPosition = position == null ? listings[tag].length : position

      if (isFavorited) {
        listings[tag] = [...listings[tag].slice(0, insertPosition), listing, ...listings[tag].slice(insertPosition)]
      } else {
        listings[tag] = listings[tag].filter(l => l.id !== listing.id)
      }

      return {
        ...state,
        listings: { ...listings }
      }
    }
    case SET_SEARCH_LISTINGS_TOTAL: {
      return {
        ...state,
        searchListingsTotal: payload.total
      }
    }
    case SET_SELECTED_LISTING_ID: {
      return {
        ...state,
        selectedListingId: payload.id
      }
    }
    case SET_PREVIOUSLY_VIEWED_LISTING_ID: {
      return {
        ...state,
        previouslyViewedListingId: payload.id
      }
    }
    case APPEND_LISTINGS: {
      const { tag, listings } = payload

      return {
        ...state,
        listings: {
          ...state.listings,
          [tag]: [...state.listings[tag], ...listings]
        },
        loading: {
          [tag]: false
        }
      }
    }
    case TOGGLE_LISTING_SEARCH_MODAL: {
      const { showSearchModal } = payload
      return {
        ...state,
        uiFlags: {
          ...state.uiFlags,
          showSearchModal
        }
      }
    }
    case FETCH_HOMEBOT_LISTINGS_REQUEST: {
      const { tag, page } = payload

      const newListings = {
        ...state.listings
      }

      if (page === 1) {
        newListings[tag] = []
      }

      return {
        ...state,
        listings: newListings,
        loading: {
          ...state.loading,
          [tag]: true
        }
      }
    }
    case FETCH_HOMEBOT_LISTINGS_SUCCESS: {
      const { tag, listings, page } = payload

      let newListings = []

      if (page === 1) {
        newListings = {
          ...state.listings,
          [tag]: listings
        }
      } else {
        newListings = {
          ...state.listings,
          [tag]: [...state.listings[tag], ...listings]
        }
      }

      return {
        ...state,
        listings: newListings,
        loading: {
          ...state.loading,
          [tag]: false
        }
      }
    }
    case FETCH_HOMEBOT_LISTINGS_FAILURE: {
      const { tag } = payload

      return {
        ...state,
        listings: {
          ...state.listings,
          [tag]: []
        },
        loading: {
          ...state.loading,
          [tag]: false
        }
      }
    }

    case FETCH_LISTING_REQUEST: {
      const { listing } = state

      return {
        ...state,
        listing: {
          ...listing,
          loading: true
        }
      }
    }
    case FETCH_LISTING_SUCCESS: {
      const { listing } = payload

      return {
        ...state,
        listing: { ...state.listing, ...listing, loading: false }
      }
    }
    case FETCH_LISTING_FAILURE: {
      return {
        ...state,
        listing: {
          loading: false
        }
      }
    }
    default:
      return state
  }
}

export default listingsReducer
