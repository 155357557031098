import { createSelector } from 'reselect'
import { selectCustomerBuyersEnabled } from './customerProfile'
import { every } from './utils'
import { selectHasRequestedClient, selectClientIsBuyer } from './client'

export const selectClientShouldSeeMarketsAndBuyerNavButtons = createSelector(
  selectHasRequestedClient,
  selectCustomerBuyersEnabled,
  every
)

export const selectHoShouldSeeBuyerReportNav = createSelector(
  selectClientShouldSeeMarketsAndBuyerNavButtons,
  selectClientIsBuyer,
  every
)
