import React from 'react'
import PropTypes from 'prop-types'
import Svg from '../Components/shared/Svg'

const HouseSvg = ({ className }) => (
  <Svg className={className} width='63' height='53' viewBox='0 0 63 53' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.5556 0.955322L42.2639 11.3391V3.10189H51.4028V20.2011L62.8611 31.3122V34.2315H55.2222V52.75H34.625V41.6389H28.4861V52.75H7.88889V34.2315H0.25V31.3122L31.5556 0.955322ZM1.75 31.9471V32.7315H9.38889V51.25H26.9861V40.1389H36.125V51.25H53.7222V32.7315H61.3611V31.9471L49.9028 20.836V4.60189H43.7639V14.8831L31.5556 3.04475L1.75 31.9471Z'
      fill='inherit'
    />
  </Svg>
)

HouseSvg.propTypes = {
  className: PropTypes.string
}

export default HouseSvg
