import { ColorProps, Flex, Image, Text, TextColors, useTheme } from '@homebotapp/hb-react-component-catalog'
import trendDown from '../../../assets/homes/img/trendDown.svg'
import trendUp from '../../../assets/homes/img/trendUp.svg'
import { useMemo } from 'react'
import Format from '../../shared/Format'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'

export interface ListingPriceChangeTagProps {
  listing: ListingCompact | Listing
}

export const ListingPriceChangeTag = ({
  listing: { previousListPriceCents, priceCents }
}: ListingPriceChangeTagProps) => {
  const hasPriceChanged = useMemo(() => previousListPriceCents !== priceCents, [previousListPriceCents, priceCents])
  const priceChange = useMemo(() => priceCents - previousListPriceCents, [previousListPriceCents, priceCents])
  const { theme } = useTheme()

  const status = useMemo(() => {
    return {
      borderColor:
        previousListPriceCents > priceCents
          ? `success.${theme.isLightThemeType ? 400 : 700}`
          : `yellow.${theme.isLightThemeType ? 400 : 700}`,
      bgColor:
        previousListPriceCents > priceCents
          ? `success.${theme.isLightThemeType ? 200 : 900}`
          : `yellow.${theme.isLightThemeType ? 200 : 900}`,
      color:
        previousListPriceCents > priceCents
          ? `success.${theme.isLightThemeType ? 900 : 50}`
          : `yellow.${theme.isLightThemeType ? 900 : 50}`,
      icon: previousListPriceCents > priceCents ? trendDown : trendUp
    }
  }, [hasPriceChanged, priceChange])

  return hasPriceChanged && priceChange < 0 ? (
    <Flex
      alignItems='center'
      justifyItems='center'
      borderWidth={1}
      borderRadius={9}
      borderColor={status.borderColor}
      backgroundColor={status.bgColor as ColorProps['backgroundColor']}
      px={2}
      py={1}
    >
      <Image height='10px' maxHeight='10px' src={status.icon} alt='trend' mr={1} />
      <Text margin={0} fontWeight={600} color={status.color as TextColors} size='2xs'>
        <Format.Currency abbreviate value={Math.abs(priceChange) / 100} />
      </Text>
    </Flex>
  ) : null
}
