import React from 'react'
import PropTypes from 'prop-types'

import styles from './ActionBar.module.scss'

export const Left = ({ children }) => <div className={styles.left}>{children}</div>

export const Right = ({ children }) => <div className={styles.right}>{children}</div>

export const Heading = ({ children }) => <div>{children}</div>

// Note: For positioning of this element to work properly,
// ensure that its parent element has:
//
// `position: relative`
//
const ActionBar = ({ children }) => {
  const sections = {
    left: null,
    heading: null,
    right: null
  }

  React.Children.forEach(children, child => {
    if (!child) return
    switch (child.type) {
      case Left: {
        sections.left = child
        break
      }
      case Right: {
        sections.right = child
        break
      }
      case Heading: {
        sections.heading = child
        break
      }
      default:
        break
    }
  })

  return (
    <div className={styles.container}>
      <div className={styles.actionBar}>
        {sections.left}
        <div className={styles.heading}>{sections.heading}</div>
        {sections.right}
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  opacity: PropTypes.number,
  children: PropTypes.any
}

Left.propTypes = {
  children: PropTypes.any
}

Right.propTypes = {
  children: PropTypes.any
}

Heading.propTypes = {
  children: PropTypes.any
}

export default ActionBar
