import { REMOVE_REDRAW_HEIGHT_FADE_KEY, ADD_REDRAW_HEIGHT_FADE_KEY } from './actionTypes'

import styles from '../Components/shared/Transition/HeightFade/HeightFade.module.scss'

export const redrawHeightFadeKey = (id, speed) => async dispatch => {
  let time = 500

  if (typeof speed === 'number') {
    time = speed
  } else if (typeof speed === 'string') {
    const cssTime = styles[`timeInterval-${speed}`]
    if (cssTime.endsWith('ms')) {
      time = cssTime.replace('ms', '') * 1
    } else if (cssTime.endsWith('s') * 1000) {
      time = cssTime.replace('s', '') * 1000
    }
  }

  const timer = setTimeout(() => dispatch({ type: REMOVE_REDRAW_HEIGHT_FADE_KEY, id }), time)

  dispatch({ type: ADD_REDRAW_HEIGHT_FADE_KEY, id, timer })
}
