import { defineMessages, useIntl } from 'react-intl'
import { SearchResultsEmptyIcon } from './SearchResultsEmptyIcon'
import { useListingSearchContext } from '../../../../../../context/listingSearch'

import styles from './SearchResultsEmpty.module.scss'

export const MSG = defineMessages({
  cta: {
    id: 'Search.initialState.cta',
    defaultMessage: 'Search for a city or zip'
  },
  cta1: {
    id: 'Search.noResults.cta1',
    defaultMessage: "We can't find that place."
  },
  cta2: {
    id: 'Search.noResults.cta2',
    defaultMessage: 'Search for another city or zip.'
  }
})

export const SearchResultsEmpty = () => {
  const intl = useIntl()
  const { isInEmptyState, isInNoResultsState } = useListingSearchContext()

  return (
    <div className={styles.flex}>
      <SearchResultsEmptyIcon />
      {isInEmptyState && intl.formatMessage(MSG.cta)}
      {isInNoResultsState && (
        <>
          <p className={styles.noResultMessage}>{intl.formatMessage(MSG.cta1)}</p>
          <p className={styles.noResultMessage}>{intl.formatMessage(MSG.cta2)}</p>
        </>
      )}
    </div>
  )
}
