import {
  selectNativeAccessFeature,
  selectHasCompanyBrandingLogoPresent
} from '../../../../store/selectors/customerProfile'
import { Link as ReactRouterLink } from 'react-router-dom'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import { RootState } from '../../../../types/rootState'
import { DesktopAuthButtons } from './DesktopAuthButtons'
import { Box, Button, Flex, Icon, Image, Link, Logo } from '@homebotapp/hb-react-component-catalog'
import { selectClientIsGuest } from '../../../../store/selectors/client'
import { setIsShowingNativeAppCtaModal } from '../../../../store/slices/modals'
import { NavigationTabs } from '../../Navigation/NavigationTabs'
import { AgentDropdown } from '../../../../Components/shared/AgentDropdown'
import { useDigestUrl } from '../../../../hooks/navigation/useDigestUrl'
import { hasClientToken, hasCustomerToken } from '../../../../auth'
import { BrandLogo } from '../../Navigation/BrandLogo'
import { INITIALIZE_ROUTE, routeToHome } from '../../../../store/selectors/routes'
import { PATH_HELPERS } from '../../../../constants/navigation'
import { selectActiveNavigationTab } from '../../../../store/slices/navigation'
import { NAVIGATION_TAB_NAMES } from '../../../../types/navigation'
import { HBRELogo } from '../../../../Components/shared/HBRE/HBRELogo'

export const MSG = defineMessages({
  alt: {
    id: 'DesktopHeader.publicSearch.logo.alt',
    defaultMessage: 'Homebot Real Estate Services'
  },
  getApp: {
    id: 'DesktopHeader.getApp',
    defaultMessage: 'Get App'
  }
})

export const DesktopHeader = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const homeownerOrBuyerDigestUrl = useDigestUrl()
  const isGuest = useSelector(selectClientIsGuest)
  const isImpersonating = hasCustomerToken()
  const isSignedIn = hasClientToken()
  const isPublic = useAppSelector(state => state.auth.isPublic)
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)
  const hasBrandingLogoPresent = useSelector(selectHasCompanyBrandingLogoPresent)
  const activeNavigationTab = useSelector(selectActiveNavigationTab)

  const buildLogoLink = () => {
    // allows this nav link to work correctly when homesearch is refreshed while impersonating
    if (isImpersonating) {
      const impersonatedClientId = localStorage.getItem('impersonatedClientId')
      const impersonatedClientIsBuyer = localStorage.getItem('impersonatedClientHbbAccess')
      const impersonatedClientHomeId = localStorage.getItem('impersonatedClientHomeId')
      const ownRoute = routeToHome(impersonatedClientId, impersonatedClientHomeId)

      const buyRoute =
        impersonatedClientIsBuyer && impersonatedClientId
          ? PATH_HELPERS.buy.buildPath(impersonatedClientId)
          : INITIALIZE_ROUTE
      return ownRoute && ownRoute !== '/' ? ownRoute : buyRoute
    } else {
      return homeownerOrBuyerDigestUrl
    }
  }

  return (
    <Box
      as='header'
      position='fixed'
      height='var(--top-nav-bar-height)'
      top='var(--banner-height, 0px)'
      left='0'
      width='100%'
      zIndex='sticky'
      bg='card-bg-layer-1'
      borderBottomWidth='1px'
    >
      <Flex alignItems='center' width='100%' height='100%' marginX='auto' marginY='0' position='relative'>
        <Flex borderRightWidth='1px' paddingLeft='3' paddingRight='4' alignItems='center'>
          {isPublic ||
          activeNavigationTab === NAVIGATION_TAB_NAMES.SEARCH ||
          activeNavigationTab === NAVIGATION_TAB_NAMES.FORYOU ? (
            <HBRELogo maxWidth={24} />
          ) : hasBrandingLogoPresent ? (
            <BrandLogo />
          ) : (
            <Logo as={ReactRouterLink} to={buildLogoLink()} height='6' paddingLeft='2' />
          )}
        </Flex>
        {!isPublic && (
          <Flex height='100%' width='100%' justifyContent='space-between'>
            <NavigationTabs size='sm' />
            <Flex alignItems='center' paddingRight={[3, null, null, 5]}>
              {hasNativeAccess && !isGuest && (
                <Button
                  size='sm'
                  marginRight={1}
                  variant='outline'
                  onClick={() => dispatch(setIsShowingNativeAppCtaModal(true))}
                  leftIcon={<Icon name='circle-arrow-down' width='5' height='5' />}
                >
                  {intl.formatMessage(MSG.getApp)}
                </Button>
              )}
              {/* Naive way to handle this until we can cleanup how the isGuest var is set */}
              {isGuest && !isImpersonating && <DesktopAuthButtons />}
              {(isSignedIn || isImpersonating) && <AgentDropdown />}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
