import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './BotHeadColored.module.scss'

const rootProps = ({ className, quizzical, size, ...restProps }) => ({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 152 152',
  className: classnames(styles.root, className, quizzical && styles.quizzical, styles[`size-${size}`]),
  ...restProps
})

const BotHeadColored = props => (
  <svg {...rootProps(props)}>
    <g className={styles[`type-${props.type}`]} fill='none' fillRule='evenodd'>
      <path
        className={styles.circleFill}
        d='M75.998 148.534c40.058 0 72.527-32.474 72.527-72.53 0-40.059-32.474-72.528-72.527-72.528C35.939 3.476 3.47 35.95 3.47 76.005s32.473 72.529 72.527 72.529z'
        fill='var(--brand-hb-homebot-fill)'
      />
      <path
        className={styles.ring}
        d='M75.998 152C34.092 152 0 117.906 0 76S34.092 0 75.998 0C117.907 0 152 34.094 152 76s-34.092 76-76.002 76zm0-145.059C37.919 6.941 6.94 37.921 6.94 76c0 38.079 30.979 69.059 69.056 69.059 38.079 0 69.062-30.98 69.062-69.059 0-38.08-30.979-69.058-69.061-69.058z'
        fill='var(--brand-hb-homebot)'
      />
      <path
        className={styles.head}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M81.528 75.282c0 4.827 3.916 8.739 8.748 8.739 4.837 0 8.753-3.912 8.753-8.739 0-4.827-3.916-8.744-8.753-8.744-4.827.005-8.748 3.917-8.748 8.744zm-28.384 0c0 4.827 3.916 8.739 8.753 8.739 4.832 0 8.753-3.912 8.753-8.739 0-4.827-3.916-8.744-8.753-8.744-4.837.005-8.753 3.917-8.753 8.744zm9.847 32.242h-9.57a5.679 5.679 0 01-5.679-5.679v-9.63h-3.381a3.062 3.062 0 01-3.06-3.06V76.797a3.057 3.057 0 013.06-3.055h3.376V61.617a5.678 5.678 0 015.68-5.674h20.957v-3.47c-4.105-.833-7.19-4.452-7.19-8.794 0-4.956 4.02-8.972 8.982-8.972 4.96 0 8.986 4.016 8.986 8.972 0 4.47-3.273 8.174-7.55 8.857v3.407h21.022a5.682 5.682 0 015.683 5.674v12.125h3.332a3.059 3.059 0 013.065 3.055V89.16a3.063 3.063 0 01-3.065 3.06h-3.332v9.63a5.686 5.686 0 01-5.683 5.679H62.99v-.005zM58.72 92.106c-1.921 0-3.476 1.59-3.476 3.48v.526c0 1.92 1.56 3.48 3.476 3.48h35.523c1.92 0 3.475-1.59 3.475-3.48v-.525c0-1.921-1.56-3.481-3.475-3.481H58.719z'
        fill='var(--brand-hb-white)'
      />
    </g>
  </svg>
)

BotHeadColored.propTypes = {
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  type: PropTypes.oneOf(['default', 'error', 'danger', 'positive']),
  quizzical: PropTypes.bool,
  className: PropTypes.string
}

BotHeadColored.defaultProps = {
  size: 'm'
}

export default BotHeadColored
