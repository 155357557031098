import { Svg } from '../../../../..'

export interface PlaceIconProps {
  dataQa: string
}

export const PlaceIcon = ({ dataQa }: PlaceIconProps) => (
  <Svg data-qa={dataQa} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.94434 5.5C7.94434 4.94445 7.49989 4.5 6.94434 4.5C6.38878 4.5 5.94434 4.94445 5.94434 5.5C5.94434 6.05556 6.38878 6.5 6.94434 6.5C7.49989 6.5 7.94434 6.05556 7.94434 5.5ZM8.94434 5.5C8.94434 6.61111 8.05545 7.5 6.94434 7.5C5.83322 7.5 4.94434 6.61111 4.94434 5.5C4.94434 4.38889 5.83322 3.5 6.94434 3.5C8.05545 3.5 8.94434 4.38889 8.94434 5.5ZM10.9443 5.5C10.9443 3.29167 9.15267 1.5 6.94434 1.5C4.736 1.5 2.94434 3.29167 2.94434 5.5C2.94434 6.125 3.06934 6.73611 3.37489 7.27778L6.79156 13.5H7.09711L10.5138 7.27778C10.8193 6.73611 10.9443 6.125 10.9443 5.5ZM11.9443 5.5C11.9443 6.30556 11.7638 7.08333 11.4027 7.75L7.69434 14.5H6.19434L2.486 7.75C2.12489 7.08333 1.94434 6.30556 1.94434 5.5C1.94434 2.73611 4.18045 0.5 6.94434 0.5C9.70822 0.5 11.9443 2.73611 11.9443 5.5Z'
      fill='#9D9298'
    />
  </Svg>
)
