import classnames from 'classnames'
import Text from '../../Text'
import styles from './BotHeadSpinWithMessage.module.scss'
import BotHeadSpin from '../BotHeadSpin/BotHeadSpin'

interface BotHeadSpinWithMessageProps {
  className?: string
  children?: React.ReactNode // usually a <FormattedMessage/>
  dataQa?: string
}

export const BotHeadSpinWithMessage = ({
  className,
  children,
  dataQa = 'bot-head-spin-with-message'
}: BotHeadSpinWithMessageProps) => (
  <div className={classnames(styles.root, className)} data-qa={dataQa}>
    <BotHeadSpin />
    {children && (
      <Text className={styles.message} variant='white' size='xl' align='center' element='div'>
        {children}
      </Text>
    )}
  </div>
)
