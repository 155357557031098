import { createSelector, OutputSelectorFields } from 'reselect'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { Finance } from '../../quartermaster/src'
import { selectLoadingState } from './client'
import { selectFromBuyerInfo } from './buyerInfo'

// TODO: TS state and prequal interface
type State = any
type Prequal = any

export const selectLoading = selectLoadingState

export const selectPrequalifications = selectFromBuyerInfo('prequalifications')

export const selectHasPrequal = createSelector<State, boolean>(selectPrequalifications, (prequals: Prequal[]) =>
  Boolean((prequals || []).length)
)

export const selectFirstPrequal = createSelector(selectPrequalifications, partialRight(get, '0'))

export const getFromPrequal = (path: string) => (prequal: { [path: string]: any }) => get(prequal, path)

const getMomentIfValid = (date: string) => {
  const m = moment(date)
  return m.isValid() ? m : null
}

const selectValidMomentFromPrequal = (path: string) => createSelector(getFromPrequal(path), getMomentIfValid)

export const selectRate = createSelector(getFromPrequal('apr'), parseFloat)

export const selectTermMonths = getFromPrequal('loanTermMonths')
export const selectType = getFromPrequal('loanType')
export const selectCreatedAt = selectValidMomentFromPrequal('createdAt')
export const selectUpdatedAt = selectValidMomentFromPrequal('updatedAt')
export const selectExpiresAt = selectValidMomentFromPrequal('expiresAt')
export const selectAdjustable = getFromPrequal('loanAdjustable')
export const selectArmYearsInitial = getFromPrequal('loanArmYearsInitial')
export const selectMaxLoanAmount = getFromPrequal('maxLoanAmount')
export const selectPreapproved = getFromPrequal('preapproved')
export const selectMaxHomePrice = getFromPrequal('maxHomePrice')
export const selectDownpayment = getFromPrequal('downpayment')
export const selectAmount = selectMaxHomePrice

export const selectTermYears = createSelector(selectTermMonths, months => Math.round(months / 12))

export const selectTerm = createSelector<State, { adjustable: boolean; years: number }>(
  selectAdjustable,
  selectArmYearsInitial,
  selectTermYears,
  (adjustable: boolean, armYearsInitial: number, termYears: number) => ({
    adjustable,
    years: adjustable ? armYearsInitial : termYears
  })
)

export const selectMonthlyPayment = createSelector<State, number>(
  selectAmount,
  selectTermMonths,
  selectRate,
  (amount: number, months: number, rate: number) =>
    amount && months && rate && Finance.calculatePayment(amount, months, rate)
)

export const selectNewestPrequal = createSelector<State, Prequal>(selectPrequalifications, (prequals: Prequal[]) => {
  if (!prequals || prequals.length === 0) {
    return undefined
  }

  const sortedPrequals = orderBy(prequals, selectCreatedAt, ['desc'])

  return sortedPrequals[0]
})

export const selectNewestPrequalAmount = createSelector<State, number>(selectNewestPrequal, selectAmount)
