export const initializeScript = src => new Promise(resolve => {
  var script = document.createElement('script')
  script.type = 'text/javascript'

  script.onload = function () {
    script.onload = null
    resolve()
  }

  script.src = src
  document.getElementsByTagName('head')[0].appendChild(script)
})
