import { SET_REFERRER_SOURCE } from '../actions/actionTypes'

const getReferrerSource = source => {
  switch (source) {
    case 'email':
      return 'mandrill'

    case 'sms':
      return 'sms'

    default:
      return ''
  }
}

const initialState = {
  referrer: null
}

export default (state = initialState, { type, referrer }) => {
  switch (type) {
    case SET_REFERRER_SOURCE:
      return {
        ...state,
        referrer: getReferrerSource(referrer)
      }

    default:
      return state
  }
}
