import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

// Hooks
import { useUniqueId } from '../../../hooks'

// Components
import { If, Text } from '../..'
import TextInput from '../Input/TextInput'

// Styles
import styles from './FormField.module.scss'

const propTypes = {
  // the contents of the label
  children: PropTypes.node,

  // set your own id instead of one generated from the name
  id: PropTypes.string,

  // the input name, gets linked to the label
  name: PropTypes.string.isRequired,

  // visually hide the label text, while leaving accessible
  hideLabel: PropTypes.bool,

  className: PropTypes.string,

  // render a specific text component
  labelText: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  // render a specific input or component
  input: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.elementType]),

  captionMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  captionText: PropTypes.func,
  inputClassName: PropTypes.string,
  labelClass: PropTypes.string
}

const DefaultLabelText = props => <Text element='span' variant='neutralCool' variantCount='700' size='xs' {...props} />
const DefaultCaptionText = props => (
  <Text element='span' variant='neutralCool' variantCount='600' size='xs' {...props} />
)

const FormField = React.forwardRef(
  (
    {
      captionMessage,
      captionText,
      children,
      className,
      hideLabel,
      id,
      input,
      inputClassName,
      labelClass,
      labelText,
      name,
      ...restProps
    },
    ref
  ) => {
    const LabelText = labelText || DefaultLabelText
    const CaptionText = captionText || DefaultCaptionText
    const MyInput = input || TextInput
    const defaultId = useUniqueId(`${name}_`)
    const myId = id || defaultId

    return (
      <div className={classnames(styles.root, className)}>
        <label className={classnames(styles.label, labelClass)} htmlFor={myId}>
          <LabelText className={classnames(hideLabel && styles.hide)}>{children}</LabelText>
          <MyInput id={myId} name={name} data-qa={name} className={inputClassName} ref={ref} {...restProps} />
        </label>
        <If condition={captionMessage}>
          <CaptionText className={styles.captionText}>{captionMessage}</CaptionText>
        </If>
      </div>
    )
  }
)

FormField.propTypes = propTypes
FormField.displayName = 'FormField'

export default FormField
