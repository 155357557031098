import result from 'lodash/result'
import { compose, lifecycle, setDisplayName } from 'recompose'
import history from '../history'

/**
 * forceHistory
 * Forces re-render on history change. Useful for circumventing withRouter's
 * dependence on render cascade from parents, which doesn't fire consistently in
 * connect-dependent applications
 */
const forceHistory = compose(
  setDisplayName('forceHistory'),
  lifecycle({
    componentDidMount() {
      this.unlisten = history.listen(() => {
        this.forceUpdate()
      })
    },

    componentWillUnmount() {
      result(this, 'unlisten')
    }
  })
)

export default forceHistory
