import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../Button'
import { removeToast } from '../../../actions/toasts'

import styles from './Toast.module.scss'

export type ToastType = {
  id: string
  message: string
  action: string
  onClose?: () => void
}

const Toast = ({ id, message, action, onClose }: ToastType) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(removeToast(id))
    }, 5700)

    return () => clearTimeout(interval)
  }, [dispatch, id])

  const handleClick = () => {
    if (onClose) {
      onClose()
    }

    dispatch(removeToast(id))
  }

  return (
    <li className={styles.toast} role='alert'>
      <span className={styles.toast__content}>{message}</span>
      {action && (
        <Button className={styles.toast__action} minimal aria-hidden='true' onClick={handleClick}>
          {action}
        </Button>
      )}
    </li>
  )
}

export { Toast }
