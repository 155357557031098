import moment from 'moment'

/*
 * Subscribers
 *
 * This file is imported in the store index and will automatically call any
 * functions here with the store instance passed. Expects a subscriber
 * function in return which will then be passed to store.subscribe
 * This is to prevent circular dependencies
 */

export const momentSubscriber = store => {
  const getCurrent = () => store.getState().locale.locale

  // Initialize current locale
  const locale = getCurrent()
  let lastLocale = locale
  moment.locale(locale)

  // Subscriber function
  return () => {
    const locale = getCurrent()

    if (lastLocale !== locale) {
      lastLocale = locale
      moment.locale(locale)
    }
  }
}
