import { RefObject, createContext, useEffect, useRef, useContext } from 'react'
import { RootState } from '../../types/rootState'
import { useSelector, useDispatch } from 'react-redux'
import { defineMessages, useIntl } from 'react-intl'
import { match as reactRouterMatch } from 'react-router'
import Loading from '../../Components/shared/Loading/LoadingApp'
import OverlayMask from '../../Components/shared/Routing/OverlayMask'
import { AppHeader } from './AppHeader'
import { AuthErrorModal } from '../../features/AuthErrorModal'
import { selectBuyerErrorModal } from '../../store/selectors/buyerInfo'
import { selectHomeIsErrored } from '../../store/selectors/home'
import { selectCustomerProfileState } from '../../store/selectors/customerProfile'
import { Box, SkipNavLink, Text } from '@homebotapp/hb-react-component-catalog'
import { AuthErrorModalErrorTypes } from '../../types/error'
import useViewport from '../../hooks/useViewport'
import { MobileBottomNav } from '../../Components/shared/MobileBottomNav'
import { GuestAuthModal } from '../../features/GuestAuthModal/GuestAuthModal'
import { hasCustomerToken } from '../../auth'
import { ImpersonationBanner } from '../../Components/shared/ImpersonationBanner/ImpersonationBanner'
import { setIsShowingAgentDropdown } from '../../store/slices/modals'

const MSG = defineMessages({
  skiptocontent: {
    id: 'General.a11y.skiptocontent',
    defaultMessage: 'Skip to Content'
  }
})

export type AppLayoutProps = {
  children: React.ReactNode
  enableAgentDropdown: boolean
  loading: boolean
  match: reactRouterMatch
}

export const AppLayoutContext = createContext<{ layoutOverlayMaskRef?: RefObject<any> }>({})

export const useAppLayoutContext = () => {
  const context = useContext(AppLayoutContext)

  if (!context) {
    throw new Error('useAppLayoutContext must be used within a AppLayout.Provider')
  }
  return useContext(AppLayoutContext)
}

export const AppLayout = ({ children, loading, match }: AppLayoutProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { isLargeOrAbove } = useViewport()
  const layoutOverlayMaskRef = useRef<HTMLDivElement>(null)

  const hasHomeownerError = useSelector(selectHomeIsErrored)
  const hasBuyerError = useSelector(selectBuyerErrorModal)
  const customerState = useSelector(selectCustomerProfileState)

  const customerIsInactive = customerState && customerState !== 'active'

  const showAuthErrorModal = hasHomeownerError || hasBuyerError || customerIsInactive

  const errorType =
    (hasHomeownerError && AuthErrorModalErrorTypes.HOMEOWNER_ERROR) ||
    (hasBuyerError && AuthErrorModalErrorTypes.BUYER_ERROR) ||
    (customerIsInactive && AuthErrorModalErrorTypes.CUSTOMER_ERROR)

  const isShowingAgentDropdown = useSelector<RootState, boolean>(state => state.modals.isShowingAgentDropdown)

  // close agentModal is open when user scrolls
  useEffect(() => {
    const handleScroll = () => {
      if (isShowingAgentDropdown) {
        dispatch(setIsShowingAgentDropdown(false))
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isShowingAgentDropdown])

  return (
    // The layoutOverlayMaskRef is used by the HomePriceIndexWidget to set the
    // portal to appear behind the mask. This ensures that the modal is hidden behind
    // the global loader
    <>
      {loading && <Loading />}
      <AppLayoutContext.Provider value={{ layoutOverlayMaskRef }}>
        {/* @ts-ignore */}
        <OverlayMask loading={loading} isExact={match.isExact} ref={layoutOverlayMaskRef}>
          <SkipNavLink>
            <Text>{intl.formatMessage(MSG.skiptocontent)}</Text>
          </SkipNavLink>
          {hasCustomerToken() && <ImpersonationBanner />}

          <AppHeader />
          {!showAuthErrorModal && (
            <Box
              overflow='hidden'
              position='relative'
              top='calc(var(--top-nav-bar-height, 0px) + var(--banner-height, 0px))'
              data-qa='main-content'
            >
              {children}
            </Box>
          )}
          {!isLargeOrAbove && <MobileBottomNav />}
        </OverlayMask>
        {showAuthErrorModal && !loading && (
          <AuthErrorModal showAuthErrorModal={showAuthErrorModal} errorType={errorType} />
        )}

        <GuestAuthModal />
      </AppLayoutContext.Provider>
    </>
  )
}
