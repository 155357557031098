import { initializeScript } from './util'
import { initialize as initializeJquery } from './jQuery'

const SRC = `${process.env.PUBLIC_URL}/vendor/BBCore.min.js`
const GLOBAL_VAR = 'BBCore'

let instance = null

const getBombBomb = () => window[GLOBAL_VAR]

export const initialize = async (accessToken) => {
  if (!instance) {
    await initializeJquery()
    await initializeScript(SRC)
    const BBCore = getBombBomb()

    instance = new BBCore({ accessToken })
  }

  return instance
}

export const getInstance = () => instance
