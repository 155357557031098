import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

const FetchRoute = ({ fetchId, fetches, ...restProps }) => {
  useEffect(fetches(fetchId), [fetchId])

  return <Route {...restProps} />
}

export default FetchRoute
