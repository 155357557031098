import { compose, lifecycle } from 'recompose'
import PropTypes from 'prop-types'
import qs from 'qs'
import { clearClientToken } from '../auth'

/**
 * initializeClientRoutes
 * For use in routing to consume incoming url query parameters
 * that may be required across both buyer and homeowners routes.
 * Uses willMount to ensure expected state prior to dispatching initial REST actions.
 */
const initializeClientRoutes = compose(
  lifecycle({
    UNSAFE_componentWillMount() {
      const { search } = this.props.location || ''

      const { cct } = qs.parse(search.replace(/^\?/, ''))

      if (typeof cct !== 'undefined') {
        clearClientToken()
      }
    }
  })
)

initializeClientRoutes.propTypes = {
  location: PropTypes.string.isRequired
}

export default initializeClientRoutes
