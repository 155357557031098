import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '../..'
import BotHead from '../Avatar/BotHeadColored'

import styles from './LogoWithMessage.module.scss'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  logoSize: PropTypes.string
}

const defaultProps = {
  logoSize: 'xs'
}

const ErrorLogoWithMessage = ({ logoSize, children }) => (
  <div className={styles.container}>
    <BotHead size={logoSize} type='error' quizzical />
    <Text element='div' variant='warning'>
      {children}
    </Text>
  </div>
)

ErrorLogoWithMessage.propTypes = propTypes
ErrorLogoWithMessage.defaultProps = defaultProps

export default ErrorLogoWithMessage
