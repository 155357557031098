import { Icon, Text } from '@homebotapp/hb-react-component-catalog'
import { NavigationTab } from './NavigationTab'
import { defineMessages, useIntl } from 'react-intl'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { INITIALIZE_ROUTE, selectBuyingReportRoute } from '../../../store/selectors/routes'
import { GlobalTrackingEventLocation, TrackingEventConstants } from '../../../constants/tracking'
import { selectClientIsGuest } from '../../../store/selectors/client'
import { hasCustomerToken } from '../../../auth'
import { toggleAuthModal } from '../../../actions/homeSearchAuthModal'
import { useTrackingContext } from '../../../providers/tracking'
import { NavigationTabProps } from '../../../types/navigation'
import { PATH_HELPERS } from '../../../constants/navigation'

const MSG = defineMessages({
  buy: {
    id: 'NavigationTab.buy',
    defaultMessage: 'Buy'
  }
})

export const BuyTab = ({ isSelected, ...tabProps }: NavigationTabProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isGuest = useSelector(selectClientIsGuest)
  const buyingReportRoute = useSelector(selectBuyingReportRoute)
  // Solely used for legacy event tracking
  const { trackingClient } = useTrackingContext()

  const iconName = isSelected ? 'buy-filled' : 'dollar-circle'

  const buildBuyingReportRoute = () => {
    if (hasCustomerToken()) {
      const impersonatedClientId: string = localStorage.getItem('impersonatedClientId') || ''
      const impersonatedClientIsBuyer: boolean = localStorage.getItem('impersonatedClientHbbAccess') === 'true'

      const buyRoute =
        impersonatedClientIsBuyer && !!impersonatedClientId
          ? PATH_HELPERS.buy.buildPath(impersonatedClientId)
          : INITIALIZE_ROUTE
      return buyRoute
    } else {
      return buyingReportRoute
    }
  }

  const handleClick = e => {
    // Keep legacy tracking working for now
    trackingClient?.buyerNavigationLinkClicked({
      buyer_navigation_link: TrackingEventConstants.BUYING_REPORT
    })

    if (isGuest && !hasCustomerToken()) {
      e.preventDefault()
      dispatch(toggleAuthModal(true))
      return
    }
  }

  return (
    <NavigationTab
      {...tabProps}
      as={ReactRouterLink}
      to={buildBuyingReportRoute()}
      onClick={handleClick}
      tracking={{
        guid: '5vXfHMheZYV3eLPH5EXWm8',
        ui_context: GlobalTrackingEventLocation.GLOBAL_NAVIGATION_BAR,
        descriptor: MSG.buy.defaultMessage
      }}
    >
      <Icon
        name={iconName}
        data-qa={iconName}
        marginRight={['0', null, null, '2']}
        marginBottom={['1', null, null, '0']}
      />
      <Text sx={{ textWrap: 'nowrap' }} size={['3xs', 'sm']} m={0} fontWeight='semibold'>
        {intl.formatMessage(MSG.buy)}
      </Text>
    </NavigationTab>
  )
}
