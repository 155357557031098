import get from 'lodash/get'
import { whoAmI } from '../api/lockbox/requests'
import { hasCustomerToken } from '../auth'
import { initializeBombBomb } from '../store/slices/bombBomb'

import { FETCH_CURRENT_USER_REQUEST, FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_FAILURE } from './actionTypes'

export const fetchCurrentUser = () => async dispatch => {
  if (!hasCustomerToken()) {
    return
  }

  dispatch({
    type: FETCH_CURRENT_USER_REQUEST
  })

  try {
    const response = await whoAmI()
    const userData = get(response, 'data.data')

    dispatch({
      type: FETCH_CURRENT_USER_SUCCESS,
      userData
    })

    const bombBombProvider = userData?.providers?.find(provider => provider.name === 'bomb_bomb')

    bombBombProvider && dispatch(initializeBombBomb(bombBombProvider?.apiKey))
  } catch (e) {
    dispatch({
      type: FETCH_CURRENT_USER_FAILURE
    })
  }
}
