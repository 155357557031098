import { TrackingEventConstants } from '../constants/tracking'
import { MARKET_TAB, SOLO_BUYER } from '../constants/navigation'
import { NEW_HOME, RENTAL_PROPERTY, RENT_BUY } from '../constants/gems.js'

export const FROM = {
  NEW_HOME,
  RENTAL_PROPERTY,
  RENT_BUY,
  MARKET_TAB,
  SOLO_BUYER
}
export const EXPLORE_MARKET_TABS = {
  POPULAR_MARKETS: 'score',
  BIGGER_HOUSE: 'sqft',
  CHEAPER_BUYS: 'price',
  FASTEST_MOVE: 'timeline'
}
