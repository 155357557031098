export const ADD_SPACE = 'add_space'
export const ADJUST_HOME_INSURANCE_POLICY_START_DATE = 'adjust_policy_date'
export const AIRBNB = 'airbnb'
export const AVM = 'avm'
export const BASIC_OR_FANCY = 'basic_or_fancy'
export const BUYING_REPORT = 'buying_report'
export const CASHOUT = 'cashout'
export const CLICK_CONTINUE = 'click_continue'
export const CLICK_CREATE_ACCOUNT = 'click_create_account'
export const CLIENT_ADDED_LOAN = 'added_loan'
export const CLIENT_CONFIRMED_LOAN = 'confirmed_loan'
export const CLIENT_REQUESTED_PREQUAL = 'prequal'
export const CLIENT_SCHEDULED_SHOWING = 'showing'
export const CLIENT_UPDATED_BUDGET = 'updated_budget'
export const CLIENT_UPDATED_LOAN = 'updated_loan'
export const CLIENT_UPDATED_LOAN_BALANCE = 'updated_loan_balance'
export const CLIENT_UPDATED_PRICE_POINT = 'updated_price_point'
export const CLIENT_UPDATED_TIMELINE = 'updated_buying_timeline'
export const CONFIRM_INSURANCE_DETAILS = 'confirm_insurance'
export const COPY_INVITE_LINK = 'copy_invite_link'
export const CUSTOM_MARKET_ANALYSIS = 'cma'
export const CUSTOMER_TYPE_IA = 'insurance_agent'
export const CUSTOMER_TYPE_LO = 'loan_officer'
export const CUSTOMER_TYPE_REA = 'real_estate_agent'
export const ELIMINATE_CREDIT_CARD_DEBT = 'credit_card_debt'
export const EMERGENCY_RESERVE = 'emergency_fund'
export const ESTIMATED_PAYMENT_BREAKDOWN = 'estimated_payment_breakdown'
export const EUROTRIP = 'european_vacation'
export const EXPLORE_INVESTMENTS = 'new_investments'
export const EXPLORE_MARKETS = 'explore_markets'
export const FAVORITE_LISTINGS = 'favorite_listings'
export const FILTER_BATH_MIN = 'bathroom_min'
export const FILTER_BED_MIN = 'bedroom_min'
export const FILTER_PRICE_MAX = 'price_max'
export const FILTER_PRICE_MIN = 'price_min'
export const FILTER_MONTHLY_PAYMENT_MAX = 'monthly_payment_max'
export const FILTER_MONTHLY_PAYMENT_MIN = 'monthly_payment_min'
export const FILTER_PROPERTY_TYPE = 'home_type'
export const FILTER_SQFT_MAX = 'sqft_max'
export const FILTER_SQFT_MIN = 'sqft_min'
export const FILTER_ACRES_MAX = 'acres_max'
export const FILTER_ACRES_MIN = 'acres_min'
export const FILTER_HAS_SELLER_CONCESSION = 'has_seller_concession'
export const FILTER_IS_ASSUMABLE = 'is_assumable'
export const FILTER_YR_MAX = 'construction_year_max'
export const FILTER_YR_MIN = 'construction_year_min'
export const FOR_YOU = 'for_you'
export const HIGH_INSURANCE_RISK = 'high'
export const HOME_DETAILS = 'home_details'
export const HOME_HIGHLIGHTS = 'home_highlights'
export const HOME_IMPROVEMENT = 'home_improvement'
export const HOME_IMPROVEMENTS = 'home_improvements'
export const HOME_INSURANCE = 'home_insurance'
export const INSTANT_OFFER = 'instant_offer'
export const LISTING_DESCRIPTION = 'listing_description'
export const LISTINGS_DETAILED = 'listing_detailed'
export const LOW_INSURANCE_RISK = 'low'
export const MAP_PANNED = 'map_panned'
export const MAP_ZOOM_IN = 'zoomed_in'
export const MAP_ZOOM_OUT = 'zoomed_out'
export const MARKET_CRITERIA_BIGGER_HOUSE = 'bigger_house'
export const MARKET_CRITERIA_CHEAPER_BUYS = 'cheaper_buys'
export const MARKET_CRITERIA_FASTEST_MOVE = 'fastest_move'
export const MARKET_CRITERIA_POPULAR_MARKETS = 'popular_markets'
export const MARKET_TEMP_BALANCED = 'balanced'
export const MARKET_TEMP_COLD = 'cold'
export const MARKET_TEMP_COOLING_OFF = 'cooling_off'
export const MARKET_TEMP_IN_DEMAND = 'in_demand'
export const MARKET_TEMP_IN_HIGH_DEMAND = 'in_high_demand'
export const MEDIUM_INSURANCE_RISK = 'medium'
export const MONTHLY_REVENUE = 'monthly_revenue'
export const MORE_INFO_ON_HOME_INSURANCE = 'more_info'
export const NATIVE_APP_STORE_TYPE_APPLE = 'apple'
export const NATIVE_APP_STORE_TYPE_GOOGLE = 'google'
export const NATIVE_APP_STORE_LOCATION_HOME_SIDEBAR = 'homeowner_digest_sidebar'
export const NATIVE_APP_STORE_LOCATION_BUYER_SIDEBAR = 'buyer_digest_sidebar'
export const NATIVE_APP_STORE_LOCATION_MOBILE_HEADER = 'mobile_header_menu'
export const NATIVE_APP_STORE_LOCATION_DESKTOP_HEADER = 'desktop_header'
export const NATIVE_APP_STORE_LOCATION_LISTINGS_FOOTER = 'listings_footer'
export const NATIVE_APP_STORE_LOCATION_LISTING_DETAILS = 'listing_detail_page'
export const NET_WORTH = 'net_worth'
export const NIGHTLY_RATE = 'nightly_rate'
export const NUMBER_OF_NIGHTS = 'number_of_nights'
export const PAY_EXTRA_PRINCIPAL = 'pay_extra_principal'
export const PMI_INDICATED_NOT_YET = 'not_yet'
export const PMI_INDICATED_NOW = 'maybe_now'
export const PMI_INDICATED_SOON = 'soon'
export const PMI_QUICK_CHECK_NO = 'no'
export const PMI_QUICK_CHECK_UNSURE = 'unsure'
export const PMI_QUICK_CHECK_YES = 'yes'
export const PRINCIPAL_INTEREST = 'principal_interest'
export const PRINCIPAL_PAYMENT = 'principal_payment'
export const PRIVATE_MORTGAGE_INSURANCE = 'pmi'
export const PURCHASE_POWER = 'purchase_power'
export const REFI = 'refi'
export const REVERSE_MORTGAGE = 'reverse_mortgage'
export const SAVED_SEARCHES = 'saved_searches'
export const SEARCH_HOMES = 'search_homes'
export const SELDOM_OR_OFTEN = 'seldom_or_often'
export const SEND_EMAIL_INVITE = 'send_email_invite'
export const SHARE_ON_FACEBOOK = 'share_on_facebook'
export const SHARE_VIA_MOBILESHARE = 'share_via_mobileshare'
export const SIMILAR_HOMES = 'similar_homes'
export const SHOULD_I_SELL = 'should_i_sell'
export const SMART_MOVE = 'smart_move'
export const SNOOZE_INSURANCE_DETAILS = 'snooze_insurance'
export const SOLAR_PANELS = 'solar_panels'
export const SORT_LISTINGS_BY_PRICE_HIGH = 'sort_price_high'
export const SORT_LISTINGS_BY_PRICE_LOW = 'sort_price_low'
export const SORT_LISTINGS_BY_SQFT_LARGE = 'sort_sqft_largest'
export const SORT_LISTINGS_BY_SQFT_SMALL = 'sort_sqft_smallest'
export const SORT_LISTINGS_BY_ACRES_LARGE = 'sort_acres_largest'
export const SORT_LISTINGS_BY_ACRES_SMALL = 'sort_acres_smallest'
export const SORT_LISTINGS_BY_DAYS_ON_MARKET = 'sort_days_on_market'
export const STUDENT_DEBT = 'student_loans'
export const TOTAL_COST_ANALYSIS = 'tca'
export const TUNED_VALUE_DECREASE = 'tune_decrease'
export const TUNED_VALUE_INCREASE = 'tune_increase'
export const UPDATE_HOME_INSURANCE_POLICY = 'update_policy_details'
export const WIN = 'win'
export const TOGGLE_SUBSCRIBE = 'toggle_subscribe'
export const TOGGLE_UNSUBSCRIBE = 'toggle_unsubscribe'
