import { getBuyerInfoData, setBuyerSettings, setMarketInterest, removeMarketInterest } from '../api/mikasa/requests'
import {
  FETCH_BUYER_INFO_REQUEST,
  FETCH_BUYER_INFO_SUCCESS,
  FETCH_BUYER_INFO_FAILURE,
  UPDATE_BUYER_INFO_REQUEST,
  UPDATE_BUYER_INFO_SUCCESS,
  UPDATE_BUYER_INFO_FAILURE,
  UPDATE_MARKET_INTEREST_SUCCESS,
  UPDATE_MARKET_INTEREST_FAILURE
} from './actionTypes'
import { selectClientId, selectClientIsBuyer } from '../store/selectors/client'
import { setBuyerOnboardingState } from './buyerOnboarding'
import get from 'lodash/get'

export const fetchBuyerInfoData = buyerId => async dispatch => {
  dispatch({
    type: FETCH_BUYER_INFO_REQUEST
  })

  try {
    const response = await getBuyerInfoData(buyerId)
    dispatch(fetchBuyerInfoSuccess(response))

    const onboardingState = get(response, 'data.data.onboardingState')
    dispatch(setBuyerOnboardingState(onboardingState))
  } catch (error) {
    dispatch(fetchBuyerInfoFailure(error))
  }
}

export const fetchBuyerInfoSuccess = response => dispatch => {
  dispatch({
    type: FETCH_BUYER_INFO_SUCCESS,
    data: response.data.data
  })
}

export const fetchBuyerInfoFailure = error => dispatch => {
  dispatch({
    type: FETCH_BUYER_INFO_FAILURE,
    error
  })
}

export const updateBuyerInfo = data => async (dispatch, getState) => {
  dispatch(updateBuyerInfoRequest(data))

  const {
    buyerInfo: { fetchId: buyerId }
  } = getState()

  try {
    const res = await setBuyerSettings(buyerId, data)
    dispatch(updateBuyerInfoSuccess(res))
  } catch (err) {
    dispatch(updateBuyerInfoFailure(err))
  }
}

const updateBuyerInfoRequest = data => ({
  type: UPDATE_BUYER_INFO_REQUEST,
  ...data
})

const updateBuyerInfoSuccess = response => ({
  type: UPDATE_BUYER_INFO_SUCCESS,
  data: response.data.data
})

const updateBuyerInfoFailure = error => ({
  type: UPDATE_BUYER_INFO_FAILURE,
  error: {
    message: error.message
  }
})

export const createMarketInterest = zipcode => async (dispatch, getState) => {
  const state = getState()
  const { marketRecords } = state
  const clientId = selectClientId(state)
  const clientIsBuyer = selectClientIsBuyer(state)

  if (!clientIsBuyer) return

  try {
    const { data } = await setMarketInterest(clientId, zipcode)
    dispatch({
      type: UPDATE_MARKET_INTEREST_SUCCESS,
      action: 'add',
      data: data.data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_MARKET_INTEREST_FAILURE,
      error,
      zipcode
    })
  }
}

export const deleteMarketInterest = id => async (dispatch, getState) => {
  const { buyerInfo } = getState()

  try {
    await removeMarketInterest(buyerInfo.id, id)
    dispatch({
      type: UPDATE_MARKET_INTEREST_SUCCESS,
      action: 'remove',
      id
    })
  } catch (error) {
    dispatch({
      type: UPDATE_MARKET_INTEREST_FAILURE,
      error
    })
  }
}
