import { Flex, Heading, ModalBody, ModalContent, ModalHeader, Text } from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import BotHeadColored from '../../../Components/shared/Avatar/BotHeadColored'

export const MSG = defineMessages({
  title: {
    id: 'CustomerError.modal.title',
    defaultMessage: 'Sorry!'
  },
  body: {
    id: 'CustomerError.modal.body',
    defaultMessage: 'The person who was sending you Homebot ended their subscription.'
  }
})

export const CustomerDeactivatedError = () => {
  const intl = useIntl()

  return (
    <ModalContent data-qa='customer-deactivated-error' margin='4'>
      <ModalHeader>
        <Heading as='h3' color='brand' size='lg'>
          {intl.formatMessage(MSG.title)}
        </Heading>
      </ModalHeader>
      <ModalBody>
        <Flex paddingBottom='4' align='center' justify='center' direction='column'>
          <Text align='center' marginBottom='4' size='md'>
            {intl.formatMessage(MSG.body)}
          </Text>
          <BotHeadColored quizzical size='l' />
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}
