import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ModalState {
  isShowingNativeAppCtaModal: boolean
  isShowingAgentDropdown: boolean
}

const initialState: ModalState = {
  isShowingNativeAppCtaModal: false,
  isShowingAgentDropdown: false
}

const modalsSlice = createSlice({
  name: 'Modals',
  initialState,
  reducers: {
    setIsShowingNativeAppCtaModal: (state, action: PayloadAction<boolean>) => {
      state.isShowingNativeAppCtaModal = action.payload
    },
    setIsShowingAgentDropdown: (state, action: PayloadAction<boolean>) => {
      state.isShowingAgentDropdown = action.payload
    }
  }
})

export const { setIsShowingNativeAppCtaModal, setIsShowingAgentDropdown } = modalsSlice.actions

export default modalsSlice.reducer
