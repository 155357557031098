import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme
} from '@homebotapp/hb-react-component-catalog'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import topographicalMapDark from '../../../assets/homes/img/topo-bg-wide.svg'
import topographicalMapLight from '../../../assets/homes/img/topo-bg-wide-light.svg'
import assumablesHomeIcon from '../../../assets/homes/img/assumable-home-icon.png'
import { GenericFeedDirectMessage } from '../GenericFeedDirectMessage/GenericFeedDirectMessage'

const MSG = defineMessages({
  learnMore: {
    id: 'BuyerPerksLearnMoreModal.learnMore',
    defaultMessage: 'Learn more'
  },
  learnMoreAbout: {
    id: 'BuyerPerksLearnMoreModal.learnMoreAbout',
    defaultMessage: 'LEARN MORE ABOUT'
  },
  concessions: {
    id: 'BuyerPerksLearnMoreModal.concessions',
    defaultMessage: 'Concessions'
  },
  whatAreConcessions: {
    id: 'BuyerPerksLearnMoreModal.whatAreConcessions',
    defaultMessage: 'What are concessions?'
  },
  concessionsExplanation: {
    id: 'BuyerPerksLearnMoreModal.assumableMortgageExplanation',
    defaultMessage:
      'Concessions are special agreements or benefits offered by the seller to make the deal more appealing to the buyer. They can come in many forms, but here are some common examples:'
  },
  closingCosts: {
    id: 'BuyerPerksLearnMoreModal.closingCosts',
    defaultMessage: 'Closing costs:'
  },
  closingCostsDesc: {
    id: 'BuyerPerksLearnMoreModal.closingCostsDesc',
    defaultMessage: 'The seller pays for some or all of the fees required to finalize the sale.'
  },
  hoaFees: {
    id: 'BuyerPerksLearnMoreModal.hoaFees',
    defaultMessage: 'HOA fees:'
  },
  hoaFeesDesc: {
    id: 'BuyerPerksLearnMoreModal.hoaFeesDesc',
    defaultMessage: 'The seller pays for some or all of the HOA dues for an agreed-upon period of time.'
  },
  homeRepairs: {
    id: 'BuyerPerksLearnMoreModal.homeRepairs',
    defaultMessage: 'Repairs:'
  },
  homeRepairsDesc: {
    id: 'BuyerPerksLearnMoreModal.homeRepairsDesc',
    defaultMessage: 'The seller fixes issues with the property before the sale is completed.'
  },
  upgrades: {
    id: 'BuyerPerksLearnMoreModal.upgrades',
    defaultMessage: 'Upgrades:'
  },
  upgradesDesc: {
    id: 'BuyerPerksLearnMoreModal.upgradesDesc',
    defaultMessage: 'The seller agrees to make certain upgrades or improvements to the home.'
  },
  extras: {
    id: 'BuyerPerksLearnMoreModal.extras',
    defaultMessage: 'Extras:'
  },
  extrasDesc: {
    id: 'BuyerPerksLearnMoreModal.extrasDesc',
    defaultMessage: 'The seller provides additional items like appliances, furniture or home warranties.'
  },
  workWith: {
    id: 'BuyerPerksLearnMoreModal.workWith',
    defaultMessage: 'Work with your real estate agent and loan officer to iron out the details on your transaction.'
  },
  cancel: {
    id: 'BuyerPerksLearnMoreModal.cancel',
    defaultMessage: 'Cancel'
  },
  confirm: {
    id: 'BuyerPerksLearnMoreModal.confirm',
    defaultMessage: 'Confirm'
  },
  buyerPerksDm: {
    id: 'BuyerPerksLearnMoreModal.buyerPerksDm',
    defaultMessage: 'Ask {name} if you qualify'
  },
  success: {
    id: 'BuyerPerksLearnMoreModal.success',
    defaultMessage: 'Direct message sent!'
  },
  fail: {
    id: 'BuyerPerksLearnMoreModal.fail',
    defaultMessage: 'Direct message failed to send.'
  },
  assumedMortgage: {
    id: 'BuyerPerksLearnMoreModal.assumedMortgage',
    defaultMessage: 'Assumed mortgage'
  },
  downPayment: {
    id: 'BuyerPerksLearnMoreModal.downPayment',
    defaultMessage: 'Down payment'
  },
  lowerRate: {
    id: 'BuyerPerksLearnMoreModal.lowerRate',
    defaultMessage: 'Lower rate'
  },
  keepSellersRateAndTerm: {
    id: 'BuyerPerksLearnMoreModal.keepSellersRateAndTerm',
    defaultMessage: `Keep the seller\'s rate and term`
  },
  cashTowardRemainingBalance: {
    id: 'BuyerPerksLearnMoreModal.cashTowardRemainingBalance',
    defaultMessage: 'Cash toward the remaining balance'
  },
  borrowTheRestAtTheCurrentRate: {
    id: 'BuyerPerksLearnMoreModal.borrowTheRestAtTheCurrentRate',
    defaultMessage: 'Borrow the rest at the current rate'
  },
  secondMortgage: {
    id: 'BuyerPerksLearnMoreModal.secondMortgage',
    defaultMessage: 'Second mortgage'
  },
  higherRate: {
    id: 'BuyerPerksLearnMoreModal.higherRate',
    defaultMessage: 'Higher rate'
  }
})

export interface BuyerPerksLearnMoreModalProps {
  asLink?: boolean
}

export const BuyerPerksLearnMoreModal = ({ asLink }: BuyerPerksLearnMoreModalProps) => {
  const intl = useIntl()
  const { theme } = useTheme()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button
        variant={asLink ? 'link' : 'outline'}
        colorScheme='primary'
        bg={!asLink ? 'neutral.50' : undefined}
        width={asLink ? 'fit-content' : '100%'}
        size={asLink ? 'xs' : ['sm', 'md']}
        onClick={() => setShowModal(true)}
      >
        {intl.formatMessage(MSG.learnMore)}
      </Button>
      <Modal isOpen={showModal} size='large' onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent overflow='hidden' borderRadius={16} marginInline={[0, 5, '20%']} marginTop={[0, 6]} mb={[0, 8]}>
          <ModalHeader
            backgroundImage={`url(${theme.isLightThemeType ? topographicalMapLight : topographicalMapDark})`}
            backgroundSize='cover'
            backgroundBlendMode='lighten'
            _dark={{
              backgroundColor: 'blackAlpha.600'
            }}
          >
            <Flex>
              <Image src={assumablesHomeIcon} aria-hidden='true' h={20} marginLeft={-6} />
              <Box ml={4}>
                <Text size={'md'} mb={0}>
                  {intl.formatMessage(MSG.learnMoreAbout)}
                </Text>
                <Heading as={'h3'} size={'lg'} textAlign={'left'}>
                  {intl.formatMessage(MSG.concessions)}
                </Heading>
              </Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as={'h4'} size={'md'} mb={3}>
              {intl.formatMessage(MSG.whatAreConcessions)}
            </Heading>
            <Text style={{ whiteSpace: 'pre-line' }} size={'xs'}>
              {intl.formatMessage(MSG.concessionsExplanation)}
            </Text>
            <Box mt={8}>
              <Text as='span' fontWeight='bold'>
                {intl.formatMessage(MSG.closingCosts)}
              </Text>
              <Text ml={2} as='span' size='sm'>
                {intl.formatMessage(MSG.closingCostsDesc)}
              </Text>
            </Box>
            <Box mt={2}>
              <Text as='span' fontWeight='bold'>
                {intl.formatMessage(MSG.hoaFees)}
              </Text>
              <Text ml={2} as='span' size='sm'>
                {intl.formatMessage(MSG.hoaFeesDesc)}
              </Text>
            </Box>
            <Box mt={2}>
              <Text as='span' fontWeight='bold'>
                {intl.formatMessage(MSG.homeRepairs)}
              </Text>
              <Text ml={2} as='span' size='sm'>
                {intl.formatMessage(MSG.homeRepairsDesc)}
              </Text>
            </Box>
            <Box mt={2}>
              <Text as='span' fontWeight='bold'>
                {intl.formatMessage(MSG.upgrades)}
              </Text>
              <Text ml={2} as='span' size='sm'>
                {intl.formatMessage(MSG.upgradesDesc)}
              </Text>
            </Box>
            <Box mt={2}>
              <Text as='span' fontWeight='bold'>
                {intl.formatMessage(MSG.extras)}
              </Text>
              <Text ml={2} as='span' size='sm'>
                {intl.formatMessage(MSG.extrasDesc)}
              </Text>
            </Box>
            <Text mt={8} size='sm'>
              {intl.formatMessage(MSG.workWith)}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Box textAlign={'center'} margin={'auto'} width={['100%', '50%']}>
              <GenericFeedDirectMessage topic='buyer perks' />
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
