const userTypeKeys = ['CLIENT', 'CUSTOMER', 'HOMEBOTTER', 'UNAUTHED'] as const
type userType = 'client' | 'customer' | 'homebotter' | 'unauthed'

export type AuthUserTypes = (typeof userTypeKeys)[number]

export const AUTH_USER_TYPES: Record<AuthUserTypes, userType> = {
  CLIENT: 'client',
  CUSTOMER: 'customer',
  HOMEBOTTER: 'homebotter',
  UNAUTHED: 'unauthed'
} as const
