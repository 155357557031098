import { SET_SELECTED_LISTING_ID, SET_PREVIOUSLY_VIEWED_LISTING_ID, TOGGLE_LISTING_SEARCH_MODAL } from './actionTypes'

export const setSelectedListingId = id => ({
  type: SET_SELECTED_LISTING_ID,
  id
})

export const setPreviouslyViewedListingId = id => ({
  type: SET_PREVIOUSLY_VIEWED_LISTING_ID,
  id
})

export const toggleListingSearchModal = bool => ({
  type: TOGGLE_LISTING_SEARCH_MODAL,
  showSearchModal: bool
})
