import { UseMutationOptions } from 'react-query'
import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import { useCreateClientEventMutation } from '../api/gqlaxy/gql/generated/graphql-hooks'
import { graphqlGLClient } from '../api/gqlaxy/gql/queries/graphQLClient'
import { CreateClientEventMutationVariables, CreateClientEventMutation } from '../api/gqlaxy/gql/generated/graphql'
import { selectClientIsGuest } from '../store/selectors/client'
import { useSelector } from 'react-redux'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E, V> extends UseMutationOptions<D, E, V> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}

export const useCreateClientEvent = (
  option: Option<CreateClientEventMutation, Error, CreateClientEventMutationVariables> = {}
) => {
  const toast = useToast()

  const isGuest = useSelector(selectClientIsGuest)

  const client = graphqlGLClient()

  const { disableErrorPrompts, errorPrompts, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        duration: 4000,
        position: 'top-right'
      })
    },
    [disableErrorPrompts, errorPrompts, toast]
  )

  const mutation = useCreateClientEventMutation(client, {
    onError: handleError,
    ...options
  })

  const mutateAsync = useCallback(
    async (variables: CreateClientEventMutationVariables) => {
      if (isGuest) {
        // This is to satisfy TS's Not all code paths return a value error
        return Promise.resolve(undefined)
      }
      return mutation.mutateAsync(variables)
    },
    [mutation]
  )

  return {
    ...mutation,
    mutateAsync
  }
}
