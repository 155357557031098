import React from 'react'
import classnames from 'classnames'
import styles from './Text.module.scss'

const TEXT_ALIGNMENTS = ['left', 'right', 'center', 'justify', 'start', 'end'] as const
type TextAlignments = (typeof TEXT_ALIGNMENTS)[number]

const TEXT_SIZES = ['smallest', 'tiny', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl'] as const

export type TextSizes = (typeof TEXT_SIZES)[number]

const TEXT_TRANSFORMS = ['capitalize', 'lowercase', 'uppercase'] as const
export type TextTransforms = (typeof TEXT_TRANSFORMS)[number]

const TEXT_WEIGHTS = ['light', 'regular', 'semibold', 'bold'] as const
export type TextWeights = (typeof TEXT_WEIGHTS)[number]

const LETTER_SPACINGS = ['normal', '10'] as const
export type LetterSpacings = (typeof LETTER_SPACINGS)[number]

const TEXT_VARIANTS = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'neutralCool',
  'neutralWarm',
  'white'
] as const

export type TextVariants = (typeof TEXT_VARIANTS)[number]

const TEXT_VARIANT_COUNTS = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'] as const
export type TextVariantCounts = (typeof TEXT_VARIANT_COUNTS)[number]

interface TextProps {
  align?: TextAlignments // add text-alignment
  element?: keyof JSX.IntrinsicElements // render something other than a 'p' tag, such as an h1
  ellipsis?: boolean // truncate single-line overflowing text with ellipsis
  size?: TextSizes // font size
  weight?: TextWeights // font weight
  letterSpacing?: LetterSpacings // modify the letter spacing percent
  variant?: TextVariants // font color
  variantCount?: TextVariantCounts // maps to color shade
  nowrap?: boolean
  transform?: TextTransforms
  paragraph?: boolean
  upperspaced?: boolean
  className?: string
  /** pass along id for the input this text is the label for */
  htmlFor?: string
  children?: React.ReactNode
}

const Text = ({
  align,
  className,
  element: Element = 'p',
  ellipsis = false,
  paragraph = false,
  size = 'm',
  nowrap,
  transform,
  weight = 'regular',
  upperspaced = false,
  letterSpacing = 'normal',
  variant,
  variantCount,
  ...restProps
}: TextProps) => {
  let variantStyle = ''

  if (variant && !variantCount) {
    variantStyle = styles[`text__${variant}`]
  }
  if (variant && variantCount) {
    variantStyle = styles[`text__${variant}-${variantCount}`]
  }

  const textClassName = classnames(
    className,
    styles.text,
    ellipsis && styles.ellipsis,
    styles[`size-${size}`],
    styles[`weight-${weight}`],
    styles[`align-${align}`],
    styles[`transform-${transform}`],
    styles[`letter-spacing-${letterSpacing}`],
    nowrap && styles.nowrap,
    paragraph && styles.paragraph,
    upperspaced && styles.upperspaced,
    variantStyle
  )

  return <Element className={textClassName} {...restProps} />
}

Text.Variants = TEXT_VARIANTS
Text.VariantCounts = TEXT_VARIANT_COUNTS
Text.Alignments = TEXT_ALIGNMENTS
Text.Sizes = TEXT_SIZES
Text.Weights = TEXT_WEIGHTS
Text.Transforms = TEXT_TRANSFORMS
Text.LetterSpacings = LETTER_SPACINGS

export default Text
