import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './BorderedTag.module.scss'

const defaultProps = {
  component: 'p',
  type: 'default'
}

const propTypes = {
  type: PropTypes.string,
  component: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

const BorderedTag = ({ children, component: Component, type }) => (
  <Component className={classnames(styles.container, styles[`type-${type}`])}>{children}</Component>
)

BorderedTag.propTypes = propTypes
BorderedTag.defaultProps = defaultProps

export default BorderedTag
