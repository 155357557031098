import classnames from 'classnames'
import styles from './StatusBadge.module.scss'
import { useListingStatusDisplayObj } from '../../../hooks/useListingStatusDisplayObj'
import { ListingStatusEnum } from '../../../types/listingStatus'

interface StatusBadgeProps {
  status: string
  size?: 'xs'
}
export const StatusBadge = ({ status, size = 'xs' }: StatusBadgeProps) => {
  const currentStatus = useListingStatusDisplayObj(status as ListingStatusEnum)
  const { icon: StatusIcon, text } = currentStatus

  return (
    <div className={classnames(styles.badge, size && [`badge--${size}`])}>
      <div className={styles.leadingVisual}>
        <StatusIcon />
      </div>
      <span>{text}</span>
    </div>
  )
}
