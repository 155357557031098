import { ColorProps, Flex, Image, Text, TextColors, useTheme } from '@homebotapp/hb-react-component-catalog'
import { useMemo } from 'react'
import Format from '../../shared/Format'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { defineMessages, useIntl } from 'react-intl'

const MSG = defineMessages({
  assumable: {
    id: 'Listings.listing.assumable',
    defaultMessage: 'Assumable'
  }
})

export interface ListingAssumableRatePullProps {
  listing: ListingCompact | Listing
}

export const ListingAssumableRatePill = ({ listing: { assumableRatePercent } }: ListingAssumableRatePullProps) => {
  const { theme } = useTheme()
  const intl = useIntl()

  const colors = useMemo(
    () =>
      theme.isLightThemeType
        ? {
            border: `success.400`,
            bg: `success.200`,
            text: `success.900`
          }
        : {
            border: `success.700`,
            bg: `success.900`,
            text: `success.50`
          },
    [theme.isLightThemeType]
  )

  return typeof assumableRatePercent === 'number' && assumableRatePercent > 0 ? (
    <Flex
      alignItems='center'
      justifyItems='center'
      borderWidth={1}
      borderRadius={9}
      borderColor={colors.border}
      backgroundColor={colors.bg as ColorProps['backgroundColor']}
      px={2}
      py={1}
    >
      <Text margin={0} fontWeight={600} color={colors.text as TextColors} size='2xs'>
        {Format.percentage(assumableRatePercent)} {intl.formatMessage(MSG.assumable)}
      </Text>
    </Flex>
  ) : null
}
