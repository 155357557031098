import { ReactNode, useState } from 'react'
import { HOMEOWNER_DIGEST_INTRO_MODULE } from '../../../../constants/tracking/trackingEventLocations/homeownerDigestTrackingEventLocation'
import { LeavingHomebotModal } from '../LeavingHomebotModal'
import { Button, ComponentTrackingType } from '@homebotapp/hb-react-component-catalog'

interface LeavingHomebotCtaButtonProps {
  dataQa?: string
  ctaLinkUrl?: string
  ctaButtonVariant?: 'ghost' | 'outline' | 'solid'
  ctaButtonColorScheme: 'primary' | 'secondary'
  ctaButtonSize: 'sm' | 'md'
  className?: string
  tracking?: ComponentTrackingType
  renderModalBody?: () => ReactNode
  children: ReactNode & Omit<ReactNode, 'null' | 'undefined'> // the intl'd button text
}

export const LeavingHomebotCtaButton = ({
  dataQa,
  ctaLinkUrl,
  ctaButtonVariant,
  ctaButtonColorScheme,
  ctaButtonSize,
  className,
  renderModalBody,
  tracking,
  children
}: LeavingHomebotCtaButtonProps) => {
  const [isCtaModalOpen, setIsCtaModalOpen] = useState(false)

  if (!ctaLinkUrl) {
    return null
  }

  return (
    <>
      <Button
        data-qa={dataQa}
        className={className}
        onClick={() => setIsCtaModalOpen(true)}
        size={ctaButtonSize}
        colorScheme={ctaButtonColorScheme}
        variant={ctaButtonVariant}
        tracking={
          tracking ?? {
            guid: 'qjrRRtg7QQenNSt5y4yZYo',
            ui_context: HOMEOWNER_DIGEST_INTRO_MODULE, // todo - update this
            descriptor: 'intro cta button clicked'
          }
        }
      >
        {children}
      </Button>
      <LeavingHomebotModal
        isOpen={isCtaModalOpen}
        renderModalBody={renderModalBody}
        handleClose={() => setIsCtaModalOpen(false)}
        ctaLinkUrl={ctaLinkUrl}
      />
    </>
  )
}
