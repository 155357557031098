/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import result from 'lodash/result'
import FocusTrap from 'focus-trap-react'
import Button from '../Button'
import { If } from '../../../deprecated/Conditionals'
import Text from '../Text'
import usePortal from 'react-cool-portal'
import { useFixBodyHeight } from '../../../hooks'
import { handleKeyNavBack, handleKeyNavForward } from '../../../helpers/input'

import styles from './Modal.module.scss'

const defaultProps = {
  overrideContent: false,
  type: 'none',
  size: 'm',
  resetScroll: false,
  scrollOnWrapper: false
}

const propTypes = {
  overrideContent: PropTypes.bool,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  subHeader: PropTypes.node,
  toggle: PropTypes.func,
  darkHeader: PropTypes.bool,
  hasTabs: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['info', 'error', 'quiet', 'warn', 'none']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.oneOf(['m', 'l']),
  scrollOnWrapper: PropTypes.bool,
  resetScroll: PropTypes.bool
}

const ModalClose = ({ toggle, className }) => (
  <Button.Close
    className={classnames(styles.close, className)}
    onClick={e => {
      toggle()
      e.preventDefault()
    }}
  />
)
ModalClose.propTypes = {
  toggle: PropTypes.func,
  className: PropTypes.string
}

const ModalInner = ({ children }) => <div className={styles.inner}>{children}</div>
ModalInner.propTypes = {
  children: PropTypes.any
}

const ModalBody = ({ title, subtitle, subheader, darkHeader, hasTabs, children, toggle }) => (
  <div>
    <If condition={title || subtitle || toggle}>
      <div
        className={classnames(styles.header, {
          [styles.dark]: darkHeader,
          [styles.noSubheader]: !subheader,
          [styles.hasTabs]: hasTabs
        })}
      >
        {toggle && <ModalClose toggle={toggle} />}
        {title && (
          <Text element='h2' weight='light' size='xl' className={styles.title} variant='neutralCool' variantCount='900'>
            {title}
          </Text>
        )}
        {subtitle && (
          <Text variant='neutralCool' variantCount='700' size='s' align='center' className={styles.subtitle}>
            {subtitle}
          </Text>
        )}
        {subheader}
      </div>
    </If>
    <ModalInner>{children}</ModalInner>
  </div>
)
ModalBody.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  subheader: PropTypes.node,
  darkHeader: PropTypes.bool,
  children: PropTypes.any,
  toggle: PropTypes.func,
  hasTabs: PropTypes.bool
}

const Modal = props => {
  const { Portal } = usePortal({
    defaultShow: true
  })

  useFixBodyHeight()

  let wrapper
  useEffect(() => {
    if (wrapper) {
      if (props.resetScroll) {
        if (typeof wrapper.scroll === 'function') {
          wrapper.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        } else {
          wrapper.scrollTop = 0
          wrapper.scrollLeft = 0
        }
      }
    }
  }, [props.children, props.resetScroll, props.scrollOnWrapper, wrapper])

  const levelClass = props.level && styles[`level${props.level}`]
  const typeClass = styles[props.type]
  const handleToggle = () => result(props, 'toggle')

  return (
    <Portal>
      <FocusTrap
        focusTrapOptions={{
          fallbackFocus: () => wrapper,
          clickOutsideDeactivates: true
        }}
      >
        <div
          className={classnames(styles.wrapper, levelClass, props.resetScroll && styles.allowScroll)}
          onKeyDown={handleKeyNavBack(props.toggle)}
          ref={ref => (wrapper = ref)}
          role='link'
          tabIndex='0'
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            className={classnames(styles.backdrop, levelClass)}
            onClick={handleToggle}
            onKeyDown={handleKeyNavForward(handleToggle)}
            role='button'
            tabIndex='0'
          />
          <div
            className={classnames(
              styles.container,
              styles[`size-${props.size}`],
              props.progress && styles.withProgress,
              levelClass,
              typeClass,
              props.className
            )}
          >
            {props.overrideContent ? props.children : <ModalBody {...props} />}
            <If condition={props.progress}>
              <div
                className={styles.progress}
                style={{
                  width: `${props.progress || 0}%`,
                  backgroundColor: props.progressColor
                }}
              />
            </If>
          </div>
        </div>
      </FocusTrap>
    </Portal>
  )
}
Modal.propTypes = {
  progress: PropTypes.number,
  progressColor: PropTypes.string,
  level: PropTypes.string
}

Modal.defaultProps = defaultProps
Modal.propTypes = propTypes

Modal.Close = ModalClose
Modal.Inner = ModalInner

export default Modal
