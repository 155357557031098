import { compose, lifecycle, setDisplayName } from 'recompose'
import { withRouter } from 'react-router'
import delay from 'lodash/delay'
import { scrollToAnchor } from '../helpers/browser'
import { shouldAutoScroll } from './scrollUtils'

const scrollToAnchorOnMount = compose(
  setDisplayName('scrollToAnchorOnMount'),
  withRouter,
  lifecycle({
    componentDidMount() {
      const { history, location } = this.props

      if (!shouldAutoScroll(location, history)) {
        return
      }

      delay(() => scrollToAnchor(location.hash, { padding: 32 }), 100)
    }
  })
)

export default scrollToAnchorOnMount
