import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Input.module.scss'

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
}

const Input = React.forwardRef(({ className, type, ...restProps }, ref) => (
  <input
    className={classnames(
      styles.root,
      type === 'number' && styles.number,
      type === 'search' && styles.search,
      className
    )}
    type={type}
    ref={ref}
    {...restProps}
  />
))

Input.propTypes = propTypes
Input.displayName = 'Input'

export default Input
