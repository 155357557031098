import {
  SET_HO_LOAN_FOCUS,
  SET_HO_CHECKED_NUMBERS,
  DIRECT_MESSAGE_REQUEST,
  DIRECT_MESSAGE_SUCCESS,
  DIRECT_MESSAGE_FAILURE
} from '../actions/actionTypes'

const initialState = {
  loanFocus: false,
  hasCheckedLoanNumbers: false,
  directMessageRequest: false,
  directMessageSuccess: false,
  directMessageFailure: false
}

export default (state = initialState, { type, loanFocus }) => {
  switch (type) {
    case SET_HO_LOAN_FOCUS:
      return {
        ...state,
        loanFocus: Boolean(loanFocus)
      }

    case SET_HO_CHECKED_NUMBERS:
      return {
        ...state,
        hasCheckedLoanNumbers: true
      }
    case DIRECT_MESSAGE_REQUEST:
      return {
        ...state,
        directMessageRequest: true
      }
    case DIRECT_MESSAGE_SUCCESS:
      return {
        ...state,
        directMessageRequest: false,
        directMessageSuccess: true
      }
    case DIRECT_MESSAGE_FAILURE:
      return {
        ...state,
        directMessageRequest: false,
        directMessageSuccess: false,
        directMessageFailure: true
      }

    default:
      return state
  }
}
