import React, { useState, createContext, useContext, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Route } from 'react-router-dom'
import { Button, DisclaimersButton } from '../..'
import { IconAngleLeft, IconAngleRight } from '../Icon'
import { defaultOpts } from '../../../constants/animations'

import styles from './Page.module.scss'

const childrenPropType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])

const Navigation = createContext()

const Page = ({ component: Component, render, path, navigation, className, children }) => {
  const [bodyStyle, setBodyStyle] = useState({ left: 0, opacity: 1 })
  const navContext = useMemo(
    () => ({
      ...navigation,
      bodyStyle,
      setBodyStyle
    }),
    [navigation, bodyStyle]
  )

  if (!path) {
    return <div className={classnames(styles.pageContents, className)}>{children}</div>
  }

  return (
    <Route
      path={path}
      render={args => (
        <Navigation.Provider value={navContext}>
          <SwipeNavigation
            setBodyStyle={setBodyStyle}
            enabled={navigation && navigation.swipeEnabled}
            className={classnames(styles.pageContents, className)}
          >
            {Component && <Component {...args} />}
            {!Component && render && render(args)}
          </SwipeNavigation>
        </Navigation.Provider>
      )}
    />
  )
}
Page.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  render: PropTypes.func,
  path: PropTypes.string,
  navigation: PropTypes.shape({
    onPrev: PropTypes.func,
    onNext: PropTypes.func,
    swipeEnabled: PropTypes.bool
  }),
  className: PropTypes.string,
  children: childrenPropType
}

const Carousel = ({ prev, next, indicateIndex, indicateTotal, children }) => {
  const navContext = useContext(Navigation)

  const handleClick = useCallback(usingPrev => {
    const { duration } = defaultOpts
    navContext.setBodyStyle({
      left: `${usingPrev ? '' : '-'}600px`,
      opacity: 0,
      transition: `left ${duration}ms ease-in, opacity ${duration}ms ease-out`
    })
    setTimeout(() => {
      if (usingPrev) {
        prev ? prev() : navContext.onPrev()
      } else {
        next ? next() : navContext.onNext()
      }
    }, duration)
  })

  return (
    <div className={styles.outerCarousel}>
      <div className={styles.navWrapper}>
        <Button className={styles.navButton} aria-label='previous button' compact onClick={() => handleClick(true)}>
          <IconAngleLeft />
        </Button>
      </div>
      <div className={styles.innerCarousel}>
        {children}
        <div className={styles.indicatorBar}>
          {Array(indicateTotal)
            .fill()
            .map((_, i) => (
              <span
                key={`indicator_bubble_${i}`}
                className={classnames(styles.bubble, i === indicateIndex && styles.active)}
              />
            ))}
        </div>
      </div>
      <div>
        <Button className={styles.navButton} aria-label='next button' compact onClick={() => handleClick(false)}>
          <IconAngleRight />
        </Button>
      </div>
    </div>
  )
}
Carousel.propTypes = {
  prev: PropTypes.func,
  next: PropTypes.func,
  indicateIndex: PropTypes.number,
  indicateTotal: PropTypes.number,
  children: childrenPropType
}

const Body = ({ className, children, dataQa }) => {
  const navContext = useContext(Navigation)

  return (
    <div
      data-qa={dataQa || 'body'}
      style={navContext && navContext.bodyStyle}
      className={classnames(styles.pageBody, className)}
    >
      {children}
    </div>
  )
}
Body.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  children: childrenPropType
}

const Disclaimer = () => (
  <div className={styles.disclaimer}>
    <DisclaimersButton />
  </div>
)

const Footer = ({ className, children }) => (
  <>
    <div className={styles.footSpacer} />
    <div className={classnames(styles.footer, className)}>
      <div className={styles.footContent}>{children}</div>
    </div>
  </>
)
Footer.propTypes = {
  className: PropTypes.string,
  showDisclaimer: PropTypes.bool,
  children: childrenPropType
}

const SwipeNavigation = ({ setBodyStyle, enabled, className, children }) => {
  const [mounting, setMounting] = useState(true)
  const [startX, setStartX] = useState(null)
  const [deltaX, setDeltaX] = useState(0)
  const [swiping, setSwiping] = useState(false)
  const [navDirection, setNavDirection] = useState(null)
  const [navAt, setNavAt] = useState(null)
  const navContext = useContext(Navigation)

  // TODO: Prop/css driven
  const navBreakpoint = '15%'
  const minPx = 20
  const maxPx = Math.min(600, document.body.clientWidth)
  const speed = 100

  useEffect(() => {
    const timer = setTimeout(() => setMounting(false))
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setBodyStyle({
      left: `${deltaX}px`,
      opacity: 1 - Math.abs(deltaX) / maxPx
    })
  }, [setBodyStyle, deltaX, maxPx])

  useEffect(() => {
    if (!Number.isNaN(parseFloat(navBreakpoint))) {
      if (navBreakpoint.endsWith('%')) {
        setNavAt(maxPx * (parseFloat(navBreakpoint) / 100))
      } else {
        setNavAt(Math.max(minPx, parseFloat(navBreakpoint)))
      }
    } else {
      console.warn('invalid navBreakpoint prop priveded to SwipeNavigation')
    }
  }, [navBreakpoint, minPx, maxPx])

  return (
    <div
      className={classnames(
        styles.swipeWrapper,
        enabled && styles.swipeEnabled,
        swiping && styles.swipeActive,
        navDirection > 0 && styles.navigatingRight,
        navDirection < 0 && styles.navigatingLeft,
        navDirection === 0 && styles.navigationCancel,
        mounting && styles.mounting,
        className
      )}
      onTouchStart={e => {
        if (enabled) {
          setStartX(e.changedTouches[0].pageX)
          setNavDirection(null)
        }
      }}
      onTouchMove={e => {
        if (enabled && e.changedTouches[0].pageX - startX > minPx) {
          setDeltaX(e.changedTouches[0].pageX - startX - minPx)
          setSwiping(true)
        } else if (enabled && e.changedTouches[0].pageX - startX < -minPx) {
          setDeltaX(e.changedTouches[0].pageX - startX + minPx)
          setSwiping(true)
        }
      }}
      onTouchEnd={e => {
        if (enabled) {
          if (deltaX > navAt) {
            setNavDirection(1)
            setTimeout(() => navContext.onPrev(), speed)
          } else if (-1 * deltaX > navAt) {
            setNavDirection(-1)
            setTimeout(() => navContext.onNext(), speed)
          } else {
            setNavDirection(0)
            setTimeout(() => setNavDirection(null), speed)
          }
          setStartX(null)
          setSwiping(false)
          setDeltaX(0)
        }
      }}
    >
      {children}
    </div>
  )
}

SwipeNavigation.propTypes = {
  setBodyStyle: PropTypes.func,
  enabled: PropTypes.bool,
  className: PropTypes.string,
  children: childrenPropType
}

Page.Carousel = Carousel
Page.Body = Body
Page.Footer = Footer
Page.Disclaimer = Disclaimer

export default Page
