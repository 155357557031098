export const EMAIL = 'email'
export const NUMBER = 'number'
export const TEL = 'tel'
export const TEXT = 'text'
export const RANGE = 'range'

export default {
  EMAIL,
  NUMBER,
  TEL,
  TEXT,
  RANGE
}
