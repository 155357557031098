import Button from './Button'
import Group from './ButtonGroup'
import Back from './BackButton'
import Forward from './ForwardButton'
import Close from './CloseButton'

const ButtonComponent: any = Button || {}

ButtonComponent.Group = Group
ButtonComponent.Back = Back
ButtonComponent.Close = Close
ButtonComponent.Forward = Forward

export default Button

export { Back, Close, Group, Forward }
