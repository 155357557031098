import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import maxBy from 'lodash/maxBy'
import sumBy from 'lodash/sumBy'
import some from 'lodash/some'
import moment from 'moment'
import finance from './finance'
import Helpers from './helpers'
import { Home, Valuation, ValuationSource, Loan, LoanScheduleDate, Agent } from '../types'

const GlobalDictionary = function () {
  return {
    currentLoanWithMax: 'amount', // amount, date, rate

    loanStatus: 'current', // current, expired

    init: function (args: { currentLoanWithMax: string; loanStatus: string }) {
      if (args) {
        if (args.currentLoanWithMax && ['amount', 'date', 'rate'].indexOf(args.currentLoanWithMax) !== -1)
          this.currentLoanWithMax = args.currentLoanWithMax
        if (args.loanStatus && ['current', 'expired'].indexOf(args.loanStatus) !== -1) this.loanStatus = args.loanStatus
      }
    },

    avm: function (home: Home): number {
      return this.cma(home) || this.avmWithoutCMA(home) || 0
    },

    avmDiffPercent: function (home: Home) {
      if (!home.avm || !home.avm.length) return null

      let avms = [...home.avm].filter(avm => avm.type === 'avm')
      avms = Helpers.sortByDate(avms, 'updated')

      if (!avms.length || avms.length < 2) return null

      return (avms[0].amount / avms[1].amount - 1) * 100
    },

    avmObjectWithoutCMA: function (home: Home): Valuation | void {
      if (!home.avm || !home.avm.length) return undefined

      let avms = home.avm.filter(loan => loan.rental !== true)
      avms = this.sortMostRecentAVMs(avms)

      return (
        this._avmObjectFromSource(avms, 'core_logic') ||
        this._avmObjectFromSource(avms, 'sitex') ||
        this._avmObjectFromSource(avms, 'realtytrac') ||
        this._avmObjectFromSource(avms, 'zillow') ||
        undefined
      )
    },

    sortMostRecentAVMs: function (avms: Valuation[]) {
      return avms.sort((a, b) => {
        if (new Date(a.updated) > new Date(b.updated)) {
          return -1
        } else if (new Date(a.updated) < new Date(b.updated)) {
          return 1
        } else {
          if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return -1
          } else if (new Date(a.createdAt) < new Date(b.createdAt)) {
            return 1
          } else {
            return 0
          }
        }
      })
    },

    _avmObjectFromSource: function (avms: Valuation[], source: ValuationSource): Valuation | void {
      avms = avms.filter(avm => avm.source === source)
      if (avms.length) return avms[0]
      return undefined
    },

    avmWithoutCMA: function (home: Home): number {
      const avm = this.avmObjectWithoutCMA(home)
      return avm ? avm.amount : 0
    },

    cma: function (home: Home): number {
      if (isEmpty(home.cma)) return 0

      const avmWithoutCMA = this.avmWithoutCMA(home)

      if (!avmWithoutCMA) return home.cma.amount
      else if (home.cma.amount === home.cma.diff) return avmWithoutCMA

      return avmWithoutCMA + parseInt((home.cma.diff || '0').toString(), 10)
    },

    cmaDone: function (home: Home): boolean {
      return Boolean(this.hasRealCMA(home) && !this.needsCMA(home))
    },

    appreciation: function (home: Home): number {
      return this.avm(home) - home.soldPrice
    },

    hasAutoCMA: function (home: Home): boolean {
      return Boolean(home.cma && home.cma.systemGenerated)
    },

    hasRealCMA: function (home: Home): boolean {
      return Boolean(!isEmpty(home.cma) && !this.hasAutoCMA(home))
    },

    fhaPmiMonthlyTotal: function (home: Home): number {
      const maxLoan = this.maxLoan(home)

      if (!maxLoan || maxLoan.financeType !== 'FHA') return 0

      const pmiRate = maxLoan ? this.pmiRateForFHA(maxLoan) : 0

      if (pmiRate) {
        const pmiForFHA = maxLoan.amount * pmiRate
        return pmiForFHA / 12
      }

      return 0
    },

    currentHelocs: function (home: Home): Loan[] {
      return filter(this.loans(home), function (loan) {
        return loan.status === 'current' && loan.loanType === 'HELOC'
      })
    },

    currentLoans: function (home: Home): Loan[] {
      return filter(this.loans(home), { status: 'current' })
    },

    currentLoansNoHelocs: function (home: Home): Loan[] {
      return filter(this.loans(home), function (loan) {
        return loan.status === 'current' && loan.loanType !== 'HELOC'
      })
    },

    // TODO if current loan is refi then can't use originalLoanAmount since this returns the current loan sum
    // either need to change originalLoanAmount or use expired loans to calc originalLoanAmount
    downPayment: function (home: Home): number {
      return home.soldPrice - this.originalLoanAmountTotal(home)
    },

    equity: function (home: Home): number {
      const homeValue = this.avm(home)
      const loans = this.currentLoansNoHelocs(home)
      const debt = this.outstandingPrincipalTotal({ loans: loans })

      if (!loans.length) return homeValue

      return homeValue - debt
    },

    equityPercent: function (home: Home): number {
      const homeValue = this.avm(home)
      const loans = this.currentLoansNoHelocs(home)
      const debt = this.outstandingPrincipalTotal({ loans: loans })

      if (!loans.length) return 100

      return (1 - debt / homeValue) * 100
    },

    hasRecentLoan: function (homeLoanField: { loans: Loan[] }): boolean {
      return some(homeLoanField.loans, function (loan) {
        const fiveMonthsAgo = moment().subtract(5, 'months')
        return moment(loan.date).isAfter(fiveMonthsAgo)
      })
    },

    hasRecentClosedEndLoan: function (home: Home): boolean {
      const currentLoansNoHelocs = this.currentLoansNoHelocs(home)
      return this.hasRecentLoan({ loans: currentLoansNoHelocs })
    },

    hasUpToDateCMA: function (home: Home): boolean {
      if (!this.hasRealCMA(home)) return false

      const requestDate = moment(home.hasRequestedCma).subtract(24, 'hours')

      if (home.hasRequestedCma && moment(home.cma.updated).isBefore(requestDate)) return false

      return true
    },

    highestRateLoan: function (home: Home): Loan | undefined {
      const maxFunc = (loan: Loan): number => {
        return loan.rate || loan.rateEstimated || 0
      }
      const filtered = filter(home.loans, { status: 'current' }) || []
      return maxBy(filtered, maxFunc)
    },

    interestForFullLoanTotal: function (home: Home): number {
      if (home.loans && home.loans.length)
        return sumBy(filter(home.loans, { status: this.loanStatus }), 'facts.totalInterest')
      return 0
    },

    interestStatus: function (home: Home, args: { rateType: string; rate?: number; periods?: number }): string {
      if (this.hasRecentClosedEndLoan(home)) return 'recentLoans'
      else if (this.refiSavingsTotal(home, args) < 0) return 'good'
      else return 'bad'
    },

    interestPaidTotal: function (home: Home): number {
      if (home.loans && home.loans.length)
        return sumBy(filter(home.loans, { status: this.loanStatus }), 'progress.interestPaid')
      return 0
    },

    interestRate: function (home: Home): number | '' {
      const maxLoan = this.maxLoan(home)
      if (maxLoan) return maxLoan.rate || maxLoan.rateEstimated

      return this.marketRateOnSoldDate(home)
    },

    isConsolidation: function (home: Home): boolean {
      return home.loans.length > 1
    },

    isFHA: function (home: Home): boolean {
      if (!home.loans || !home.loans.length) return false
      const maxLoan = this.maxLoan(home)

      return maxLoan ? maxLoan.financeType === 'FHA' : false
    },

    latestAvmIsCma: function (home: Home): boolean {
      if (!home.avm || !home.avm.length) return false

      let avms = [...home.avm]
      avms = Helpers.sortByDate(avms, 'updated')

      return avms[0].type === 'cma'
    },

    loans: function (home: Home): Loan[] {
      return home && home.loans && home.loans.length ? home.loans : []
    },

    marketRateOnSoldDate: function (home: Home): number | '' {
      const maxLoan = this.maxLoan(home)
      if (maxLoan) return maxLoan.rate || maxLoan.rateEstimated

      return ''
    },

    maturityDate: function (home: Home): string {
      const maxLoan = this.maxLoan(home)
      if (maxLoan && maxLoan.maturityDate) return maxLoan.maturityDate.nochange.date

      return ''
    },

    maxLoan: function (home: Home): Loan | undefined | null {
      const maxLoan = maxBy(filter(home.loans, { status: this.loanStatus }), this.currentLoanWithMax)

      if (!isEmpty(maxLoan)) return maxLoan
      else return null
    },

    monthlyPaymentTotal: function (home: Home): number {
      if (home.loans && home.loans.length)
        return sumBy(filter(home.loans, { status: this.loanStatus }), 'payment.total')
      return 0
    },

    needsCMA: function (home: Home): boolean {
      return Boolean(home.hasRequestedCma && !this.hasUpToDateCMA(home))
    },

    originalLoanAmountTotal: function (home: Home): number {
      if (home.loans && home.loans.length) return sumBy(filter(home.loans, { status: this.loanStatus }), 'amount')
      return home.soldPrice
    },

    originalLoanDate: function (home: Home): string {
      const maxLoan = this.maxLoan(home)
      if (maxLoan) return maxLoan.date

      return home.soldDate
    },

    outstandingInterestTotal: function (home: Home): number {
      if (home.loans && home.loans.length)
        return sumBy(filter(home.loans, { status: this.loanStatus }), 'progress.interestLeft')
      return 0
    },

    outstandingPrincipalTotal: function (home: { loans: Loan[] }): number {
      if (home.loans && home.loans.length)
        return sumBy(filter(home.loans, { status: this.loanStatus }), 'progress.principalLeft')
      return 0
    },

    periods: function (home: Home): number {
      return this.termYears(home) * 12
    },

    principalPaidTotal: function (home: Home): number {
      if (home.loans && home.loans.length)
        return sumBy(filter(home.loans, { status: 'current' }), 'progress.principalPaid')
      return 0
    },

    pmiRateForFHA: function (loan: Loan): number {
      if (!loan.date) return 0

      if (moment(loan.date).isBetween('2013-06-30', '2015-01-01')) return 0.0135

      if (moment(loan.date).isAfter('2014-12-31')) return 0.0085

      return 0
    },

    refiRate: function (home: Home, args?: { rate?: number; periods?: number; rateType: string }): number {
      const maxLoan = this.maxLoan(home)
      if (maxLoan && maxLoan.refiToday && args && args.rateType) return maxLoan.refiToday[args.rateType].rate

      return 0
    },

    refiPeriods: function (
      home: Home,
      args?: { rate?: number; periods?: number; rateType: string }
    ): number | undefined {
      const maxLoan = this.maxLoan(home)
      if (maxLoan && maxLoan.refiToday && args) return maxLoan.refiToday[args.rateType].periods
      return undefined
    },

    remainingPeriods: function (home: Home): number {
      const periods = this.periods(home)
      const maxLoan = this.maxLoan(home)

      if (periods && maxLoan && maxLoan.date) {
        const curentMonth = moment().startOf('month')
        const loanStartingMonth = moment(maxLoan.date).startOf('month')
        const previousPeriods = curentMonth.diff(loanStartingMonth, 'months')
        return periods - previousPeriods
      }

      return 0
    },

    refiSavingsTotal: function (home: Home, args?: { rate?: number; periods?: number; rateType: string }): number {
      const rate = args && args.rate ? args.rate : this.refiRate(home, args)
      const periods = args && args.periods ? args.periods : this.refiPeriods(home, args) || 0

      const consolidationInterest = finance.calculateInterest(
        this.outstandingPrincipalTotal(home),
        periods,
        rate,
        new Date(),
        0
      )

      const pmiPaymentsForFHA = this.fhaPmiMonthlyTotal(home) * this.remainingPeriods(home)

      return -(consolidationInterest - (this.outstandingInterestTotal(home) + pmiPaymentsForFHA))
    },

    reverseMortgageEnabled: function (home: Home): boolean {
      return Boolean(home.customerProfile.officeProfile.corporateProfile.showReverseMortgage)
    },

    reverseMortgageQualified: function (home: Home): boolean {
      const equityPercent = this.equityPercent(home)

      const homeownerAgeQualified = some(home.users, 'reverseMortgage')

      if (equityPercent >= 50 && homeownerAgeQualified) {
        return true
      } else {
        return false
      }
    },

    sellingAgent: function (home: Home): Agent {
      try {
        if (home.sellingAgent.datasources.rets.status === 'success') return home.sellingAgent
        return null
      } catch (e) {
        return null
      }
    },

    showAvmDiffWarning: function (home: Home, diffPercent = 10): boolean {
      return (
        !this.showNonDisclosureStateWarning(home) &&
        !this.latestAvmIsCma(home) &&
        home.metadata.avmDiffPercent > diffPercent
      )
    },

    showNonDisclosureStateWarning: function (home: Home): boolean {
      const nonDisclosureStates = ['AK', 'ID', 'KS', 'LA', 'MS', 'MO', 'MT', 'NM', 'ND', 'TX', 'UT', 'WY']
      const state = home.address.state.toUpperCase()

      if (this.cma(home)) return false

      return nonDisclosureStates.includes(state)
    },

    showRefiForFHA: function (home: Home): boolean {
      const maxLoan = this.maxLoan(home)
      return Boolean(this.isFHA(home) && maxLoan && this.pmiRateForFHA(maxLoan))
    },

    termYears: function (home: Home): number {
      const maxLoan = this.maxLoan(home)
      if (maxLoan) return maxLoan.termYears || maxLoan.termYearsEstimated

      return 30
    },

    unverifiedLoans: function (home: Home): Loan[] {
      return filter(home.loans, (loan: Loan) => {
        return loan.status === this.loanStatus && (!loan.termYears || !loan.rate)
      })
    }
  }
}

export default GlobalDictionary
