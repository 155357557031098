import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  selectCustomerFirstName,
  selectCustomerPhotoUri,
  selectCustomerTitle
} from '../../../store/selectors/customerProfile'
import Avatar from '../Avatar'

interface CustomerAvatarProps {
  className?: string
}

const CustomerAvatar = ({ className }: CustomerAvatarProps) => {
  const intl = useIntl()

  const customerPhotoUri = useSelector(selectCustomerPhotoUri)
  const customerFirstName = useSelector(selectCustomerFirstName)
  const customerTitle = useSelector(selectCustomerTitle)

  const messages = defineMessages({
    profileAltTag: {
      id: 'CustomerAvatar.alt',
      defaultMessage: 'Photo of your {type}, {firstName}.'
    }
  })

  return (
    <Avatar
      src={customerPhotoUri}
      className={className}
      alt={intl.formatMessage(messages.profileAltTag, {
        type: customerTitle,
        firstName: customerFirstName
      })}
    />
  )
}

export default CustomerAvatar
