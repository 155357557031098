export const DAY = 'day'
export const DOLLARS = 'dollars'
export const DOLLARS_AND_CENTS = 'dollars_and_cents'
export const EMAIL = 'email'
export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const MONTH = 'month'
export const PHONE = 'phone'
export const RATE = 'rate'
export const STREET_ADDRESS = 'streetAddress'
export const SQFT = 'sqFt'
export const UNIT = 'unit'
export const YEAR = 'year'
export const ZIPCODE = 'zipcode'
