import { TrackingEventConstants } from '../../constants/tracking'
import { selectCustomerId, selectCustomerPublicKey } from '../../store/selectors/customerProfile'
import { createContext, useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useSelector } from 'react-redux'
import { execute } from '../../vendor/recaptcha'
import { useWizardProvider } from '../../Components/shared/WizardContext'
import { createNewBuyerLead } from '../../api/mikasa/token_requests'
import { chromeBotSignInLink } from '../../api/mikasa/chromebot/customerRequests'
import { setAgentToken } from '../../auth/widget'
import { requestClose } from '../../api/iframe/requests'
import { isIFramed } from '../../helpers/browser'
import { SIGN_UP_STEPS } from '../../constants/signUp'
import { useTrackingContext } from '../../providers/tracking'
import { RootState } from '../../types/rootState'
import { useIntl } from 'react-intl'

interface SignUpContextValue {
  customerPublicKey: string
  customerProfileId: string
  redirectUrl: string
  iFramed: boolean
  referrer: string
  handleIframeClose: () => void
  handleCheckClient: (emailAddress: string) => Promise<void | null>
  handleCreateClick: (firstNameInput: string, lastNameInput: string) => Promise<void | null>
  handleResetClick: () => void
  inputError: boolean
  submitError: boolean
  loading: boolean
  setInputError: (value: boolean) => void
  setLoading: (value: boolean) => void
  setSubmitError: (value: boolean) => void
  firstName: string
  lastName: string
  email: string
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setEmail: (value: string) => void
}

const SignUpContext = createContext<SignUpContextValue>({
  customerPublicKey: '',
  customerProfileId: '',
  redirectUrl: '',
  iFramed: false,
  referrer: '',
  handleIframeClose: () => null,
  handleCheckClient: async () => null,
  handleCreateClick: async () => null,
  handleResetClick: () => null,
  inputError: false,
  submitError: false,
  loading: false,
  setInputError: () => null,
  setLoading: () => null,
  setSubmitError: () => null,
  firstName: '',
  lastName: '',
  email: '',
  setFirstName: () => null,
  setLastName: () => null,
  setEmail: () => null
})

interface SignUpProviderProps {
  children: React.ReactNode
}

const SignUpProvider = ({ children }: SignUpProviderProps) => {
  const { trackingClient } = useTrackingContext()
  const [loading, setLoading] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const intl = useIntl()
  const locale = intl?.locale

  const [inputError, setInputError] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  // Props from iframe
  const [redirectUrl, setRedirectUrl] = useState('')
  const [customerPublicKey, setCustomerPublicKey] = useState('')
  const [customerProfileId, setCustomerProfileId] = useState('')
  const [referrer, setReferrer] = useState('')

  // chromebot is the only place it will be iframed
  const iFramed = isIFramed()

  // Customer Profile id and public key when not iframed
  const customerProfileIdFromState = useSelector<RootState, string>(selectCustomerId)
  const customerProfilePublicKeyFromState = useSelector<RootState, string>(selectCustomerPublicKey)

  // Wizard
  const { goForward, goTo } = useWizardProvider()

  const handleIframeClose = () => {
    if (iFramed) {
      requestClose()
    }
  }

  const handleCheckClient = async emailAddress => {
    setLoading(true)

    try {
      setEmail(emailAddress)
      trackingClient?.searchLeadAdded({
        search_lead_flow: TrackingEventConstants.CLICK_CONTINUE
      })
      await chromeBotSignInLink(redirectUrl, emailAddress, locale)
      // @ts-ignore - can remove once wizardProvider is typed
      goTo(SIGN_UP_STEPS.COMPLETE)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      switch (err?.response?.status) {
        case 404:
          // @ts-ignore - can remove once wizardProvider is typed
          goForward()
          break
        case 403:
          // @ts-ignore - can remove once wizardProvider is typed
          goTo(SIGN_UP_STEPS.CLIENT_NOT_FOUND)
          break
        default:
          console.error(err)
          Sentry.captureException(err)
      }
    }

    setLoading(false)
  }

  const handleResetClick = () => {
    // @ts-ignore - can remove once wizardProvider is typed
    goTo(SIGN_UP_STEPS.USER_EMAIL)
  }

  const handleCreateClick = async (firstNameInput: string, lastNameInput: string) => {
    setLoading(true)
    setFirstName(firstNameInput)
    setLastName(lastNameInput)

    let validationToken
    try {
      validationToken = await execute({ action: 'property_search' })
    } catch (e) {
      setSubmitError(true)
      setLoading(false)
      console.error(e)
      Sentry.captureException(e)

      return
    }

    try {
      trackingClient?.searchLeadAdded({
        search_lead_flow: TrackingEventConstants.CLICK_CREATE_ACCOUNT
      })
      await createNewBuyerLead({
        email,
        firstName: firstNameInput,
        lastName: lastNameInput,
        redirectUrl,
        validationToken,
        referrer,
        locale
      })
      // @ts-ignore - can remove once wizardProvider is typed
      goForward()
    } catch (err) {
      setSubmitError(true)
      setLoading(false)
      console.error(err)
      Sentry.captureException(err)
    }
  }

  const validRedirectPath = pathname => {
    const validPathNames = ['home-search']

    return validPathNames.some(validPathName => {
      const pattern = new RegExp(validPathName, 'i')
      return pattern.test(pathname)
    })
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    // Redirect to the provided redirect url
    // otherwise fallback to the current url if the current path name is a valid redirect pathname
    if (params.redirectUrl) {
      setRedirectUrl(params.redirectUrl)
    } else if (validRedirectPath(window.location.pathname)) {
      setRedirectUrl(window.location.href)
    }

    const cpId = params.customerProfileId || customerProfileIdFromState
    const cpPublicKey = params.customerPublicKey || customerProfilePublicKeyFromState
    const ref = params.ref

    setReferrer(ref)

    setCustomerProfileId(cpId)
    setCustomerPublicKey(cpPublicKey)
    setAgentToken(cpPublicKey)

    return () => {
      // Reset the redirect url when the component unmounts
      // in case the client opens and closes the modal on several different pages
      setRedirectUrl('')
    }
  }, [iFramed, customerProfileIdFromState, customerProfilePublicKeyFromState])

  const store = {
    iFramed,
    customerProfileId,
    customerPublicKey,
    referrer,
    handleIframeClose,
    handleCheckClient,
    handleCreateClick,
    handleResetClick,
    inputError,
    submitError,
    loading,
    redirectUrl,
    setInputError,
    setLoading,
    setSubmitError,
    firstName,
    lastName,
    email,
    setFirstName,
    setLastName,
    setEmail
  }

  return <SignUpContext.Provider value={store}>{children}</SignUpContext.Provider>
}

const useSignUpProvider = () => {
  const context = useContext(SignUpContext)

  if (!context) {
    throw new Error('useSignUpProvider must be used within a SignUpProvider')
  }

  return context
}

export { SignUpProvider, useSignUpProvider, SignUpContext }
