import {
  HomeSearchTrackingEventLocation,
  BuyerDigestTrackingEventLocation,
  HomeownerDigestTrackingEventLocation
} from '../../../constants/tracking'
import { getYear } from 'date-fns'
import { matchPath } from 'react-router'
import { useSelector } from 'react-redux'
import { useIntl, defineMessages } from 'react-intl'
import { RootState } from '../../../types/rootState'
import { NativeCTALinks } from '../NativeCTALinks/NativeCTALinks'
import { TOS_LINK, PRIVACY_LINK, PATH_HELPERS, A11Y_LINK } from '../../../constants/navigation'
import { selectNativeAccessFeature } from '../../../store/selectors/customerProfile'
import { Box, Text, Logo, Heading, LinkButton, Flex } from '@homebotapp/hb-react-component-catalog'

export const MSG = defineMessages({
  getHomebotOnYourPhone: {
    id: 'Digest.Footer.MobileCTA.GetHomebotOnYourPhone.label',
    defaultMessage: 'Get Homebot on your phone'
  },
  copyright: {
    id: 'Footer.copyright',
    defaultMessage: '© {currentYear} Homebot Inc. All rights reserved.'
  },
  trademark: {
    id: 'Footer.trademark',
    defaultMessage:
      'Homebot™ and the Homebot logo are trademarks of Homebot Inc. Homebot is an unaffiliated business party. Homebot is a service purchased by us to provide to you, our customer.'
  },
  policy: {
    id: 'Footer.link.privacyPolicy',
    defaultMessage: 'Privacy Policy'
  },
  terms: {
    id: 'Footer.link.terms',
    defaultMessage: 'Terms of Service'
  },
  a11y: {
    id: 'Footer.link.a11y',
    defaultMessage: 'Accessibility Statement'
  }
})

export interface FooterTextProps {
  noHomebotReferences?: boolean
}

export const FooterText = ({ noHomebotReferences = false }: FooterTextProps) => {
  const intl = useIntl()
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)

  const getTrackingContext = () => {
    const isPath = (path: any) => !!matchPath(window.location.pathname, { path })
    if (isPath(PATH_HELPERS.buy.matchPath)) {
      return BuyerDigestTrackingEventLocation.BUYER_DIGEST_FOOTER
    } else if (isPath(PATH_HELPERS.home.matchPath)) {
      return HomeownerDigestTrackingEventLocation.HOMEOWNER_DIGEST_FOOTER
    } else if (isPath(PATH_HELPERS.listing.matchPath)) {
      return HomeSearchTrackingEventLocation.HOME_SEARCH_LISTING_DETAILS_FOOTER
    } else if (isPath(PATH_HELPERS.listings.matchPath)) {
      return HomeSearchTrackingEventLocation.HOME_SEARCH_LISTINGS_FOOTER
    } else if (isPath(PATH_HELPERS.homeSearch.matchPat || isPath(PATH_HELPERS.searchLandingPage.matchPath))) {
      return HomeSearchTrackingEventLocation.HOME_SEARCH_FOOTER
    } else {
      return null
    }
  }

  if (noHomebotReferences)
    return (
      <Flex justify='flex-start' gap={2} flexWrap={'wrap'} data-qa='homebot-disclaimer'>
        <LinkButton size='xs' variant='link' colorScheme='primary' href={PRIVACY_LINK} isExternal>
          {intl.formatMessage(MSG.policy)}
        </LinkButton>
        <LinkButton size='xs' variant='link' colorScheme='primary' href={TOS_LINK} isExternal>
          {intl.formatMessage(MSG.terms)}
        </LinkButton>
        <LinkButton size='xs' variant='link' colorScheme='primary' href={A11Y_LINK} isExternal>
          {intl.formatMessage(MSG.a11y)}
        </LinkButton>
      </Flex>
    )

  return (
    <Box data-qa='homebot-disclaimer'>
      <Text size='2xs'>{intl.formatMessage(MSG.copyright, { currentYear: getYear(new Date()) })}</Text>
      <Text size='2xs'>{intl.formatMessage(MSG.trademark)}</Text>
      <Logo my={4} justifyContent='flex-start' />
      <Flex justify='flex-start' gap={2} flexWrap={'wrap'}>
        <LinkButton size='xs' variant='link' colorScheme='primary' href={PRIVACY_LINK} isExternal>
          {intl.formatMessage(MSG.policy)}
        </LinkButton>
        <LinkButton size='xs' variant='link' colorScheme='primary' href={TOS_LINK} isExternal>
          {intl.formatMessage(MSG.terms)}
        </LinkButton>
        <LinkButton size='xs' variant='link' colorScheme='primary' href={A11Y_LINK} isExternal>
          {intl.formatMessage(MSG.a11y)}
        </LinkButton>
      </Flex>
      {hasNativeAccess && (
        <Box marginTop={8} marginBottom={3}>
          <Heading as='h4' size='2xs' marginBottom={2} variant='eyebrow' fontWeight='semibold'>
            {intl.formatMessage(MSG.getHomebotOnYourPhone)}
          </Heading>
          <NativeCTALinks
            tracking={{
              guid: 'dEhMg32JTqCt3xRMBHRBX3',
              // @ts-ignore
              ui_context: getTrackingContext()
            }}
          />
        </Box>
      )}
    </Box>
  )
}
