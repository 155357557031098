import produce from 'immer'
import {
  SEND_REFI_REQUEST,
  SEND_REFI_SUCCESS,
  SEND_REFI_FAILURE,
  RESET_REFI_REQUEST,
  SET_REFI_MONTHS_IN_HOME,
  SET_REFI_DETAILS_INDEX
} from '../actions/actionTypes'

export const initialState = {
  refiRequested: false,
  refiRequestSuccess: false,
  refiRequestFailure: false,
  yearsInHome: 10, // TODO: Save and retrieve default with pretty animation
  refiDetailsIndex: null
}

export default (state = initialState, { type, data, years, index }) =>
  produce(state, draft => {
    switch (type) {
      case SEND_REFI_REQUEST:
        draft.refiRequested = true
        draft.refiRequestSuccess = false
        draft.refiRequestFailure = false
        break

      case SEND_REFI_SUCCESS:
        draft.refiRequestSuccess = true
        draft.refiRequestFailure = false
        break

      case SEND_REFI_FAILURE:
        draft.refiRequestSuccess = false
        draft.refiRequestFailure = true
        break

      case RESET_REFI_REQUEST:
        draft.refiRequested = false
        draft.refiRequestSuccess = false
        draft.refiRequestFailure = false
        break

      case SET_REFI_MONTHS_IN_HOME:
        draft.yearsInHome = years
        break

      case SET_REFI_DETAILS_INDEX:
        draft.refiDetailsIndex = index
        break

      default:
        break
    }
  })
