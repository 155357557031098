import { Flex, Text } from '@homebotapp/hb-react-component-catalog'
import { FeedType } from '../../api/gqlaxy/gql/generated/graphql'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

export interface FeedEmptyStateProps {
  feedType?: FeedType
}

export const MSG = defineMessages({
  noListingFound: {
    id: 'General.noListingFound',
    defaultMessage: 'No listings found'
  },
  emptyStateRecentPriceDrop: {
    id: 'FeedEmptyState.recentPriceDrop',
    defaultMessage: 'No properties have dropped in price recently'
  },
  emptyStateAssumables: {
    id: 'FeedEmptyState.assumables',
    defaultMessage: 'No properties with assumable loan found'
  },
  emptyStateConcessions: {
    id: 'FeedEmptyState.concessions',
    defaultMessage: "No properties with seller's concessions found"
  },
  emptyStateRecentlySold: {
    id: 'FeedEmptyState.recentlySold',
    defaultMessage: 'No properties have been sold here recently'
  }
})

export const FeedEmptyState = ({ feedType }: FeedEmptyStateProps) => {
  const intl = useIntl()

  const feedTypeContent = useMemo(() => {
    switch (feedType) {
      case FeedType.RecentPriceDrop:
        return {
          description: intl.formatMessage(MSG.emptyStateRecentPriceDrop)
        }
      case FeedType.Assumables:
        return {
          description: intl.formatMessage(MSG.emptyStateAssumables)
        }
      case FeedType.Concessions:
        return {
          description: intl.formatMessage(MSG.emptyStateConcessions)
        }
      case FeedType.RecentlySold:
        return {
          description: intl.formatMessage(MSG.emptyStateRecentlySold)
        }
      default:
        return {
          description: intl.formatMessage(MSG.noListingFound)
        }
    }
  }, [feedType])

  return (
    <Flex
      p={5}
      flexDirection={['column', 'row']}
      alignItems='center'
      justifyContent='center'
      background='neutral.200'
      borderRadius='16px'
      borderWidth='1px'
      borderColor='neutral.300'
      gap={2}
    >
      <Text m={0} size='md'>
        😴
      </Text>
      <Text textAlign='center' fontWeight={600} size='md' m={0}>
        {feedTypeContent?.description}
      </Text>
    </Flex>
  )
}
