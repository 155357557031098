import { UseMutationOptions, useQueryClient } from 'react-query'
import { SendMessageMutation, SendMessageMutationVariables } from '../../api/gqlaxy/gql/generated/graphql'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import { useSendMessageMutation } from '../../api/gqlaxy/gql/generated/graphql-hooks'

export const useSendListingMessage = (
  options?: UseMutationOptions<SendMessageMutation, Error, SendMessageMutationVariables>
) => {
  const queryClient = useQueryClient()

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // Local endpoint
  })

  return useSendMessageMutation(client, {
    ...options
  })
}
