export const withHomeCreateState = s => ({
  homeCreate: homeCreateState(s)
})

export const homeCreateState = ({
  compiling = false,
  creating = false,
  created = false,
  error = null,
  timeout = null
} = {}) => ({
  compiling,
  creating,
  created,
  error,
  timeout
})
