import { useBuyerDigestUrl } from './useBuyerDigestUrl'
import { useHomeDigestUrl } from './useHomeDigestUrl'

/**
 * If the client has homes present, return the homeowner digest url, otherwise
 * return the buyer digest url.
 */
export const useDigestUrl = () => {
  const homeDigestUrl = useHomeDigestUrl()
  const buyerDigestUrl = useBuyerDigestUrl()

  return homeDigestUrl || buyerDigestUrl
}
